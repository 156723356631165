var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "model_bac" }, [
    _c(
      "div",
      {
        staticClass: "model_bac_div",
        style: {
          height: _vm.height + "px",
          width: _vm.height * (375 / 667) + "px"
        }
      },
      [
        _c(
          "div",
          {
            style: {
              boxSizing: "border-box",
              flexShrink: 0,
              width: "375px",
              height: "667px",
              paddingBottom: "60px",
              overflow: "auto",
              transform: "scale(" + _vm.height / 667 + ")"
            },
            attrs: { id: "modelId" }
          },
          [
            _c("video-two-com", {
              attrs: {
                videoSrc: _vm.$pathPrefix + _vm.videoUrl,
                "subtitles-src": _vm.subtitleUrl,
                showPlay: true,
                videocover: _vm.$pathPrefix + _vm.coverUrl,
                width: "100%",
                height: "200"
              }
            }),
            _c(
              "div",
              { staticStyle: { padding: "0px 12px" } },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  [
                    _c("el-tab-pane", { attrs: { label: "简介", name: "1" } }, [
                      _c("div", { staticClass: "user_con_bac" }, [
                        _c("div", { staticClass: "user_con_div" }, [
                          _vm.headPic
                            ? _c("img", {
                                staticClass: "user_avatar",
                                attrs: { src: _vm.$pathPrefix + _vm.headPic }
                              })
                            : _c("img", {
                                staticClass: "user_avatar",
                                attrs: {
                                  src: _vm.$pathPrefix + _vm.$systemAvatar
                                }
                              }),
                          _c("div", [
                            _c("div", { staticClass: "user_nickname" }, [
                              _vm._v(_vm._s(_vm.nickname))
                            ]),
                            _c("div", { staticClass: "user_date" }, [
                              _vm._v(_vm._s(_vm._f("showDate")(_vm.date)))
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "attention_but_no" }, [
                          _vm._v("+ 关注")
                        ])
                      ]),
                      _c("div", { staticClass: "title_div" }, [
                        _vm._v(_vm._s(_vm.title))
                      ]),
                      _c("div", { staticClass: "content_div" }, [
                        _vm._v(_vm._s(_vm.html))
                      ]),
                      _vm.currentModule != 7
                        ? _c(
                            "div",
                            { staticClass: "tag_bac" },
                            _vm._l(_vm.tags, function(item, index) {
                              return _c("div", { staticClass: "tag_div" }, [
                                _vm._v("# " + _vm._s(item.name))
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.company.id
                        ? _c(
                            "div",
                            {
                              staticClass: "company_div_bac",
                              on: {
                                click: function($event) {
                                  return _vm.tryToOpenAppCompany(_vm.company.id)
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "company_avatar",
                                attrs: {
                                  src:
                                    _vm.$pathPrefix +
                                    "index/unicorn/company/icon/" +
                                    _vm.company.icon
                                }
                              }),
                              _c("div", { staticClass: "company_con_bac" }, [
                                _c("div", { staticClass: "company_con_name" }, [
                                  _vm._v(_vm._s(_vm.company.name))
                                ]),
                                _c("div", { staticClass: "company_con_des" }, [
                                  _vm._v(_vm._s(_vm.company.title))
                                ])
                              ]),
                              _c("img", {
                                staticClass: "company_biao",
                                attrs: {
                                  src: require("@/assets/img/company_biao_icon.png")
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "评论 " + _vm.rowsCount, name: "2" } },
                      [
                        _vm.newsId
                          ? [
                              _vm._l(_vm.commentList, function(item, index) {
                                return _c(
                                  "div",
                                  { staticClass: "comment_con_bac" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "comment_user_bac" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "comment_user_left" },
                                          [
                                            item.disAccount.userInfo.headPic
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "comment_avatar_div"
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "comment_user_avatar",
                                                      attrs: {
                                                        src:
                                                          _vm.$pathPrefix +
                                                          item.disAccount
                                                            .userInfo.headPic
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "comment_avatar_div"
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass:
                                                        "comment_user_avatar",
                                                      attrs: {
                                                        src:
                                                          _vm.$pathPrefix +
                                                          _vm.$systemAvatar
                                                      }
                                                    })
                                                  ]
                                                ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "comment_user_nickname"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.disAccount.userInfo
                                                      .nickname
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c("img", {
                                          staticClass: "comment_user_right",
                                          attrs: {
                                            src: require("@/assets/img/diandiandian_icon.png")
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "comment_con_div" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "comment_con_text" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.content) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "comment_bot_bac" },
                                          [
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("commShowDate")(
                                                      item.createTime
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "10px"
                                                  }
                                                },
                                                [_vm._v(" · ")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ipCountryName) +
                                                  " "
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "comment_bot_right"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.likeNum) +
                                                    " "
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "comment_xinno_icon",
                                                  attrs: {
                                                    src: require("@/assets/img/xinxin_no_icon.png")
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              _vm.getLoading
                                ? _c(
                                    "div",
                                    { staticClass: "comm_footer_text" },
                                    [_vm._v("加载中...")]
                                  )
                                : [
                                    _vm.pageIndex + 1 >= _vm.totalPages
                                      ? _c(
                                          "div",
                                          { staticClass: "comm_footer_text" },
                                          [_vm._v("- THE END -")]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "comm_footer_text",
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function($event) {
                                                return _vm.comPageFun()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(" 查看更多内容 "),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-right",
                                              staticStyle: {
                                                "font-size": "16px",
                                                color: "#86909C"
                                              }
                                            })
                                          ]
                                        )
                                  ]
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }