var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.infoId ? "编辑" : "新增",
        width: "900px",
        "close-on-click-modal": false,
        visible: _vm.visible
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      !_vm.infoId
        ? _c(
            "div",
            {
              staticStyle: {
                "margin-left": "20px",
                "margin-bottom": "20px",
                color: "#000000"
              }
            },
            [
              _vm._v(
                " 说明：新建账号成功后，使用手机号或邮箱地址接收验证码即可进行等登录 "
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _vm.infoId
            ? _c(
                "el-form-item",
                { attrs: { label: "头像", prop: "headPic" } },
                [
                  _vm.visible
                    ? _c("uploadImg", {
                        attrs: {
                          image: _vm.ruleForm.headPic,
                          userId: _vm.infoId,
                          width: "60px",
                          height: "60px"
                        },
                        on: {
                          "update:image": function($event) {
                            return _vm.$set(_vm.ruleForm, "headPic", $event)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "用户昵称", prop: "nickName" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20", "show-word-limit": "" },
                model: {
                  value: _vm.ruleForm.nickName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "nickName", $$v)
                  },
                  expression: "ruleForm.nickName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "性别", prop: "gender" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.ruleForm.gender,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "gender", $$v)
                    },
                    expression: "ruleForm.gender"
                  }
                },
                _vm._l(_vm.sexDisct, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "出生日期" } },
            [
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "bir" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "picker-options": _vm.pickerOptions0
                        },
                        model: {
                          value: _vm.ruleForm.bir,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "bir", $$v)
                          },
                          expression: "ruleForm.bir"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "IP归属地" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    filterable: "",
                    clearable: ""
                  },
                  on: {
                    focus: function($event) {
                      return _vm.getCountryDisct()
                    }
                  },
                  model: {
                    value: _vm.ruleForm.location,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "location", $$v)
                    },
                    expression: "ruleForm.location"
                  }
                },
                _vm._l(_vm.countryDisct, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "常居国家" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    filterable: "",
                    clearable: ""
                  },
                  on: {
                    focus: function($event) {
                      return _vm.getCountryDisct()
                    }
                  },
                  model: {
                    value: _vm.ruleForm.countryId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "countryId", $$v)
                    },
                    expression: "ruleForm.countryId"
                  }
                },
                _vm._l(_vm.countryDisct, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.name,
                      value: item.id,
                      disabled: item.id == 9998 || item.id == 9999
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行业" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    filterable: "",
                    clearable: ""
                  },
                  on: {
                    focus: function($event) {
                      return _vm.getIndustryDisct()
                    }
                  },
                  model: {
                    value: _vm.ruleForm.systemIndustryId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "systemIndustryId", $$v)
                    },
                    expression: "ruleForm.systemIndustryId"
                  }
                },
                _vm._l(_vm.idustryDisct, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "个人简介", prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "200",
                  rows: "4",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户手机号", prop: "phone" } },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px", "margin-right": "10px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.ruleForm.countryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "countryCode", $$v)
                        },
                        expression: "ruleForm.countryCode"
                      }
                    },
                    _vm._l(_vm.countryList, function(item, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: {
                            value: item.country_code,
                            label: item.country_code
                          }
                        },
                        [_vm._v("+" + _vm._s(item.country_code))]
                      )
                    }),
                    1
                  ),
                  _c("el-input", {
                    directives: [
                      {
                        name: "limitInput",
                        rawName: "v-limitInput:positiveInteger",
                        arg: "positiveInteger"
                      }
                    ],
                    model: {
                      value: _vm.ruleForm.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "phone", $$v)
                      },
                      expression: "ruleForm.phone"
                    }
                  })
                ],
                1
              ),
              !_vm.ruleForm.email && !_vm.ruleForm.phone
                ? _c("div", { staticClass: "mes_error_text" }, [
                    _vm._v("请输入手机号")
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户邮箱", prop: "email" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.email,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "email", $$v)
                  },
                  expression: "ruleForm.email"
                }
              }),
              !_vm.ruleForm.email && !_vm.ruleForm.phone
                ? _c("div", { staticClass: "mes_error_text" }, [
                    _vm._v("请输入邮箱")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推荐状态", prop: "recommendState" } },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.recommendState,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "recommendState", $$v)
                        },
                        expression: "ruleForm.recommendState"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("未推荐")
                      ]),
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("已推荐")
                      ])
                    ],
                    1
                  ),
                  _c("br"),
                  _c("el-input", {
                    directives: [
                      {
                        name: "limitInput",
                        rawName: "v-limitInput:digit",
                        arg: "digit"
                      }
                    ],
                    staticStyle: {
                      width: "100px",
                      "text-align": "center",
                      "margin-left": "10px"
                    },
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.recommendNum,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "recommendNum", $$v)
                      },
                      expression: "ruleForm.recommendNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    color: "gray",
                    "font-size": "10px",
                    "line-height": "15px"
                  }
                },
                [
                  _vm._v(
                    " 注：1：已设置权重的，则权重排序，权重越大，排序越前，"
                  ),
                  _c("br"),
                  _vm._v(
                    " 2：没设置权重的，则按用户注册成功时间的倒叙进行排序 "
                  )
                ]
              )
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "资讯发布权限", prop: "qualState" } },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function($event) {
                      return _vm.setRemarkCh()
                    }
                  },
                  model: {
                    value: _vm.ruleForm.qualState,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "qualState", $$v)
                    },
                    expression: "ruleForm.qualState"
                  }
                },
                _vm._l(_vm.qualStateDisct, function(item) {
                  return _c("el-radio", { attrs: { label: item.value } }, [
                    _vm._v(_vm._s(item.name))
                  ])
                }),
                1
              ),
              _c("br"),
              _c(
                "div",
                {
                  staticStyle: {
                    color: "gray",
                    "font-size": "10px",
                    "line-height": "15px"
                  }
                },
                [_vm._v(" 开启后，该用户可通过客户端或pc端发布资讯文章 ")]
              )
            ],
            1
          ),
          _vm.ruleForm.qualState != 0
            ? [
                _c("el-form-item", { attrs: { label: "认证说明" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _vm._v(" （中文） "),
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.ruleForm.authRemarkCh,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "authRemarkCh", $$v)
                          },
                          expression: "ruleForm.authRemarkCh"
                        }
                      }),
                      _vm._v(" （英文） "),
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.ruleForm.authRemarkEn,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "authRemarkEn", $$v)
                          },
                          expression: "ruleForm.authRemarkEn"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm.ruleForm.qualState == 1
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "绑定资讯企业", prop: "infoCompanyId" }
                      },
                      [
                        _vm.visible
                          ? _c("selectCompany", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                placement: "top",
                                filterrype: "1",
                                inputCompanyId: "1",
                                id: _vm.ruleForm.infoCompanyId,
                                name: _vm.ruleForm.infoCompanyName
                              },
                              on: {
                                "update:id": function($event) {
                                  return _vm.$set(
                                    _vm.ruleForm,
                                    "infoCompanyId",
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        !_vm.ruleForm.infoCompanyId &&
                        _vm.ruleForm.qualState == 1 &&
                        _vm.EditIsClick
                          ? _c("div", { staticClass: "mes_error_text" }, [
                              _vm._v("请选择企业")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "招聘发布权限", prop: "qualState" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.recruitState,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "recruitState", $$v)
                    },
                    expression: "ruleForm.recruitState"
                  }
                },
                _vm._l(_vm.zhaopinStateDisct, function(item) {
                  return _c("el-radio", { attrs: { label: item.value } }, [
                    _vm._v(_vm._s(item.name))
                  ])
                }),
                1
              ),
              _c("br"),
              _c(
                "div",
                {
                  staticStyle: {
                    color: "gray",
                    "font-size": "10px",
                    "line-height": "15px"
                  }
                },
                [_vm._v(" 开启后，该用户可通过客户端或pc端发布招聘文章 ")]
              )
            ],
            1
          ),
          _vm.ruleForm.recruitState == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "绑定招聘企业", prop: "recruitCompanyId" } },
                [
                  _vm.visible
                    ? _c("selectCompany", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          placement: "top",
                          filterrype: "1",
                          inputCompanyId: "2",
                          id: _vm.ruleForm.recruitCompanyId,
                          name: _vm.ruleForm.recruitCompanyName
                        },
                        on: {
                          "update:id": function($event) {
                            return _vm.$set(
                              _vm.ruleForm,
                              "recruitCompanyId",
                              $event
                            )
                          }
                        }
                      })
                    : _vm._e(),
                  !_vm.ruleForm.recruitCompanyId &&
                  _vm.ruleForm.recruitState == 1 &&
                  _vm.EditIsClick
                    ? _c("div", { staticClass: "mes_error_text" }, [
                        _vm._v("请选择企业")
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.resetForm("ruleForm")
                }
              }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" }
              ],
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("ruleForm")
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }