<!-- 用户选择组件 -->
<template>
  <el-cascader
    filterable clearable collapse-tags
    :props="casProps"
    :options="channelCateDisct"
    v-model="channelCateIds"
    :show-all-levels="false"
    :change-on-select="true">
  </el-cascader>
</template>

<script>
import { systemTerritoryTree } from '@/api/utilsManage'
export default {
  name: 'cratChannelCom',
  props: {
    cateIds: { 
      type: Array,
      default(){
        return []
      }
    },
    channelIds: { 
      type: Array,
      default(){
        return []
      }
    },
  },
  data() {
    return {
      channelCateIds: [...this.$props.cateIds, ...this.$props.channelIds],
      channelCateDisct: [],
      cateIdList: [],
      casProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
        emitPath: false
      }
    };
  },
  watch : {
		'channelCateIds.length' : function(val){
      let cateList = []// 分类
      let channelList = []// 业务
      if(this.channelCateIds && this.channelCateIds.length > 0){
        this.channelCateIds.forEach( id => {
          if(this.cateIdList.includes(id) ){
            cateList.push(id)
          }else{
            channelList.push(id)
          }
        })
      }
      this.$emit("update:cateIds", cateList)
      this.$emit("update:channelIds", channelList)
      this.$emit('returnGetData')
		}
	},
  created() {
    this.getTerritory()
  },
  mounted() {
    
  },
  methods: {
    // 获取目标市场和目标业务枚举列表
    getTerritory(){
      systemTerritoryTree({}).then(( data ) => {
        this.cateIdList = []
        this.channelCateDisct = []
        this.channelCateDisct = this.chuliChnnel(data.data)
      })
    },
    // 处理channelCateDisct数据
    chuliChnnel(list){
      list.forEach( item => {
        if(item.dataType == 1){
          this.cateIdList.push(item.id)
        }
        if(item.children && item.children.length>0){
          this.chuliChnnel(item.children)
        }else{
          delete item.children
        }
      })
      return list
    },
  },
  destroyed() {
    
  },
};
</script>
</style>
