<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="activeMenu"
            :collapse="collapse"
            background-color="#FFF"
            text-color="rgb(51, 54, 57)"
            active-text-color="#206CFF"
            unique-opened
            collapse-transition
            router
        >
            <!-- <el-input v-model="seach" placeholder="请输入名称" 
                class="router_seach_input" 
                @change="seachChange"
                v-show="!collapse">
            </el-input> -->
            <template v-for="item in items">
                <template v-if="item.children"><!--isRqrzAccount && item.meta.title == '审核管理'-->
                    <el-submenu :index="item.path" :key="item.path">
                        <!--一级菜单-->
                        <template slot="title">
                            <i :class="item.meta.icon" class="icon_class_default"></i>
                            <span slot="title">{{ item.meta.title }}</span>
                        </template>
                        <template v-for="subItem in item.children">
                            <el-submenu  v-if="subItem.children" :index="subItem.path" :key="subItem.path">
                                <template slot="title"> 
                                    <i :class="subItem.meta.icon" class="icon_class_default"></i>{{ subItem.meta.title }}
                                </template>
                                <el-menu-item v-for="(threeItem,i) in subItem.children" :key="threeItem.path" :index="threeItem.path">
                                    <i :class="threeItem.meta.icon" class="icon_class_default"></i>
                                    {{ threeItem.meta.title }}
                                </el-menu-item>
                            </el-submenu>
                            <!--二级菜单-->
                            <el-menu-item v-else :index="subItem.path" :key="subItem.path" v-show="!isRqrzAccount || subItem.meta.title != '举报列表'">
                                <i :class="subItem.meta.icon" class="icon_class_default"></i>
                                {{ subItem.meta.title }}
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.path" :key="item.path">
                        <i :class="item.meta.icon" class="icon_class_default"></i>
                        <span slot="title">{{ item.meta.title }}</span>
                    </el-menu-item>
                </template>
            </template>

        </el-menu>
    </div>
</template>

<script>
import bus from '../common/bus';
export default {
    data() {
        return {
            collapse: false,
            allItems: [],
            items: [],
            seach: '',
            isRqrzAccount:false,
            activeMenu: null,
        };
    },
    computed: {
        onRoutes() {
            //放回当前点击的页面path
            // return  this.$route.path;
            //返回最后在路由导航上的path
            console.log("最后的",this.$store.getters.lastRouteGet.path)
            return this.$store.getters.lastRouteGet.path
        }
    },
    
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if(!route.meta.hideInMenu){
                    this.activeMenu = route.path;
                }else{
                    this.activeMenu = this.$store.getters.lastRouteGet.path
                }
            },
        },
    },
    created() {
        this.allItems = this.$store.getters.menuList
        this.items = this.$store.getters.menuList

        // 通过 Event Bus 进行组件间通信，来折叠侧边栏
        bus.$on('collapse', msg => {
            this.collapse = msg;
            bus.$emit('collapse-content', msg);
        });
    },
    methods: {
        //搜索
        seachChange(){
            this.items = this.filterTree(this.allItems, this.seach)
        },
        //递归筛选树形路由数据
        filterTree(arr, keyword,first=true) {
            if(first){//首次传入深度克隆数据防止修改源数据
                arr=JSON.parse(JSON.stringify(arr)) 
            }
            let emptyArr = [];
            for (let item of arr) {
                if (item.meta.title.includes(keyword)) {
                    if (item.children &&Array.isArray(item.children)&& item.children.length > 0) {
                        item.children = this.filterTree(item.children, keyword,false);
                    }
                    emptyArr.push(item);
                } else if (item.children&&Array.isArray(item.children) && item.children.length > 0) {
                    item.children = this.filterTree(item.children, keyword,false);
                    if (item.children.length) {
                        emptyArr.push(item);
                    }
                }
            }
            return emptyArr;
        },

    }
    
};
</script>

<style lang='scss' scoped>
$baiTextColor: rgb(51, 54, 57);
$heiTextColor: #e5e7eb; 
$baiBacColor: #FFF;
$heiBacColor: #18181C;
$activeBacColor: rgba(100, 100, 100, 0.3);
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 54px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
.is-active{
    background: $activeBacColor !important;
}
.router_seach_input{
    width: 90%;
    margin-left: 5%;
}
.icon_class_default{
    color: $baiTextColor ;
    font-size: 20px;
    margin-right: 10px;
}
:deep .el-submenu__icon-arrow{
    color: $baiTextColor !important;
    font-size: 16px !important;
}
</style>
