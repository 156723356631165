import { render, staticRenderFns } from "./selectSpCom.vue?vue&type=template&id=81595eb8&scoped=true&"
import script from "./selectSpCom.vue?vue&type=script&lang=js&"
export * from "./selectSpCom.vue?vue&type=script&lang=js&"
import style0 from "./selectSpCom.vue?vue&type=style&index=0&id=81595eb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81595eb8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/pasa_admin_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('81595eb8')) {
      api.createRecord('81595eb8', component.options)
    } else {
      api.reload('81595eb8', component.options)
    }
    module.hot.accept("./selectSpCom.vue?vue&type=template&id=81595eb8&scoped=true&", function () {
      api.rerender('81595eb8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/newsManage/auditOrEdit/selectSpCom.vue"
export default component.exports