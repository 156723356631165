<!-- 多选下拉框组件 -->
<template>
  <div class="tag_list_bac">
    <el-select v-model="countryids" placeholder="请选择" style="width: 300px;"
      filterable multiple collapse-tags clearable>
      <el-option v-for="item in $props.listDisct" :key="item.id" :label="item.name" :value="item.id">
      </el-option>
    </el-select>
    <div v-for="(item, index) in countryList" :key="item.index" class="select_tag_div">
      {{ item.name }}
      <i class="el-icon-error delete_tag_icon" @click="deleteTagFun(item)"></i> 
    </div>
  </div>
</template>

<!-- <el-form-item label="测试多选">
  <multipleCom :list.sync="testList" :listDisct="countryDisct"/>
</el-form-item> -->
<script>
import { systemTerritory } from '@/api/utilsManage'
export default {
  name: 'multipleCom',
  props: {
    listDisct: {
      type: Array,
      default(){
        return []
      }
    },
    list: {
      type: Array,
      default(){
        return []
      }
    },
    ids: {
      type: Array,
      default(){
        return []
      }
    },
    limit: { // 限制数量，没有则不限
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      countryids: this.$props.ids, // 选中的数据id
      countryList: this.$props.list, // 选中的数据
    };
  },
  watch : {
		'countryids.length' : function(val){
      this.$emit('update:ids', this.countryids)
      if(val > 0){
        this.countryList = this.countryids.map(
          id => this.$props.listDisct.filter(i => i.id == id)[0]
        );
      }else{
        this.countryList = []
      }
		},
		'list.length' : function(val){
      this.countryList = this.$props.list
		},
		'countryList.length' : function(val){
      this.$emit('update:list', this.countryList)
      this.countryids = []
      if(val > 0){
        this.countryList.forEach( item => {
          this.countryids.push(item.id)
        })
      }
		}
	},
  created() {
  },
  mounted() {
    
  },
  methods: {
    // 删除标签
    deleteTagFun(obj){
      this.countryList = this.countryList.filter(item => item !== obj);
    },
    
  },
  destroyed() {
    
  },
};
</script>
<style lang="scss" scoped>
.tag_list_bac{
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
}
.select_tag_div{
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgba(32, 108, 255, 0.08);
  position: relative;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
}
.delete_tag_icon{
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 16px;
  cursor: pointer;
}
.tag_op_bot{
  font-size: 12px;
  color:#C1C1C1;
  line-height: 12px;
}
</style>
