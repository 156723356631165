<template>
<div>
  <svg :class="svgClass" aria-hidden="true" v-on="$listeners" :color="color" :width="size+'px'" :height="size+'px'">
    <use :xlink:href="iconName" />
  </svg>
</div>
</template>
<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#000000',
    },
    size: {
      type: Number,
      default: 16,
    }

  },
  computed: {
    // 图标名称
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    // 样式类名
    svgClass() {
      if (this.className) {
        return `svg-icon ${this.className}`;
      }
      return 'svg-icon';
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
.svg-icon{
  fill: currentColor; /* 图标填充颜色，currentColor是一个变量，表示当前元素的color值，如当前无指定，则从父元素继承 */
  overflow: hidden;
}
</style>