/**
 * 注册全局组件
 * 命名规则：驼峰标识
 */
import videoCom from './video-com/index.vue'
import videoTwoCom from './video-two-com/index.vue'
import uploadImg from './uploadImg/index.vue'
import uploadImgCollection from './uploadImgCollection/index.vue'
import uploadImgEx from './uploadImgEx/index.vue'
import uploadImgUtils from './uploadImgUtils/index.vue'
import uploadImgCompany from './uploadImgCompany/index.vue'
import uploadVideoPoint from './uploadVideoPoint'
import uploadVideoEasy from './uploadVideoEasy'
import uploadSubtitle from './uploadSubtitle'
import importXlsx from './importXlsx/index.vue'
import comWangEditor from './comWangEditor'
import cratChannelCom from './cratChannelCom'

import articleModel from './model/articleModel.vue'
import articleVideoModel from './model/articleVideoModel.vue'
import previewModel from './model/previewModel.vue'
import previewVideoModel from './model/previewVideoModel.vue'

import selectUser from './select/selectUser.vue'
import selectUserDuo from './select/selectUserDuo.vue'
import selectUserMultiple from './select/selectUserMultiple.vue'
import selectUserMultipleTwo from './select/selectUserMultipleTwo.vue'
import selectTag from './select/selectTag.vue'
import selectCompany from './select/selectCompany.vue'
import multipleCom from './select/multipleCom.vue'
import multipleTgCom from './select/multipleTgCom.vue'

const components = [
  videoCom,
  videoTwoCom,
  uploadImg,
  uploadImgCollection,
  uploadImgEx,
  uploadImgUtils,
  uploadImgCompany,
  uploadVideoPoint,
  uploadVideoEasy,
  uploadSubtitle,
  importXlsx,
  articleModel,
  articleVideoModel,
  previewModel,
  previewVideoModel,
  comWangEditor,
  selectTag,
  selectCompany,
  selectUser,
  selectUserDuo,
  cratChannelCom,
  multipleCom,
  multipleTgCom,
  selectUserMultiple,
  selectUserMultipleTwo,
]

export default {
  install(Vue, opts = {}) {
    components.map(component => {
      Vue.component(component.name, component)
    })
  }
}
