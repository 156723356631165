<!-- 资讯列表 -->
<template>
  <div class="container">
    <div style="margin-bottom: 14px;">
      <el-radio-group v-model="sourceType" @change="getDataList()" size="mini">
        <el-radio-button :label="item.value" v-for="item in sourceTypeDisct" :key="item.value">{{ item.name }}</el-radio-button>
      </el-radio-group>
    </div>
    <div style="margin-bottom: 14px;" v-show="sourceType == 3">
      <el-radio-group v-model="crawlerChannel" @change="getDataList()" size="mini">
        <el-radio-button :label="item.code" v-for="item in crawlerChannelDisct" :key="item.code">{{ item.name }}</el-radio-button>
      </el-radio-group>
    </div>
    <!-- 筛选 -->
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" >
      <el-form-item label="创作者ID">
        <el-input v-model="dataForm.search1" placeholder="请输入" clearable size="mini" @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="创作者昵称">
        <el-input v-model="dataForm.search2" placeholder="请输入" clearable size="mini" @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="dataForm.search3" placeholder="请输入" clearable size="mini" @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="目标市场">
        <el-select v-model="dataForm.countryIds" placeholder="请选择" style="width: 200px;"  size="mini"
          filterable multiple collapse-tags clearable  @change="getDataList()">
          <el-option v-for="item in countryDisct" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="目标业务或分类">
        <cratChannelCom 
          :cateIds.sync="dataForm.cateIds" 
          :channelIds.sync="dataForm.channelIds"
          @returnGetData="getDataList()"/>
      </el-form-item>
      <el-form-item label="发布状态" prop="cate">
        <el-select clearable v-model="dataForm.timeType" placeholder="请选择" @change="getDataList()" size="mini">
          <el-option :label="item.name" :value="item.value"
            v-for="item in timeTypeDisct" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审核状态" prop="cate">
        <el-select clearable v-model="dataForm.state" placeholder="请选择" @change="getDataList()" size="mini">
          <el-option :label="item.name" :value="item.value"
            v-for="item in stateDisct" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="sourceType==3? '采集时间' : '发布时间'">
        <el-date-picker v-model="dataForm.createTime" type="datetimerange" @change="getDataList()" size="mini"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getDataList()" size="mini">查询</el-button>
        <template>
          <!-- <el-button type="success" size="mini"  
            :disabled="multipleSelection.length == 0" @click="auditFun()" >批量快速审核</el-button> -->
          <el-button type="success" size="mini"  
            @click="goUpdatePage()">详细审核</el-button>
        </template>
      </el-form-item>
    </el-form>
    <!-- 列表 height="500" v-adaptive="{bottomOffset: 50,iModal: false}" -->
    <el-table 
      :data="this.page.list" border v-loading="dataListLoading"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;"
      @selection-change="handleSelectionChange">
      <!-- <el-table-column type="selection" :selectable="checkSelectable" width="55"/> -->
      <el-table-column label="文章ID" prop="id" width="150"/>
      <el-table-column label="发布时间" width="90">
        <template slot-scope="scope">
          {{scope.row.lastTime | showDate()}}
        </template>
      </el-table-column>
      <el-table-column label="所属市场" width="200">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p style=" width: 200px;">{{ scope.row.countryStr }}</p>
            <div slot="reference" class="table_li_text">{{ scope.row.countryStr }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="所属业务" width="180">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 180px;">{{ scope.row.channelStr }}</p>
            <div slot="reference" class="table_li_text">{{ scope.row.channelStr }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="所属分类" width="180">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 180px;">{{ scope.row.cateStr }}</p>
            <div slot="reference" class="table_li_text">{{ scope.row.cateStr }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="创作者ID" prop="authorId"/>
      <el-table-column label="创作者昵称" prop="authorNickName"/>
      <!-- <el-table-column label="所属创作者账号" width="120">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 180px;" v-if="scope.row.authorId">{{ scope.row.authorId }}-{{ scope.row.authorNickName }}</p>
            <div slot="reference" class="table_li_text" v-if="scope.row.authorId">
              {{ scope.row.authorId }}-{{ scope.row.authorNickName }}
            </div>
          </el-popover>
        </template>
      </el-table-column> -->
      <el-table-column label="标题" width="200">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 200px;">{{ scope.row.title }}</p>
            <div slot="reference" class="table_li_text">{{ scope.row.title }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="采集时间" v-if="sourceType==3">
        <template slot-scope="scope">
          {{scope.row.collectTime | showDate()}}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" v-else>
        <template slot-scope="scope">
          {{scope.row.createTime | showDate()}}
        </template>
      </el-table-column>
      <!-- <el-table-column label="定时时间">
        <template slot-scope="scope">
          {{scope.row.preTime | showDate()}}
        </template>
      </el-table-column> -->
      <el-table-column label="发布状态">
        <template slot-scope="scope">
          {{scope.row.reallyTime}}
        </template>
      </el-table-column> 
      
      <el-table-column label="权重排序" prop="infoSort"  width="100" sortable>
        <template slot-scope="scope">
          {{scope.row.infoSort }}
        </template>
      </el-table-column>
      <el-table-column label="审核状态">
        <template slot-scope="scope">
          {{scope.row.state | stateFilter(stateDisct)}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="250" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" plain @click="goUpdatePage(scope.row.id)" 
            :disabled="returnDel(scope.row.deleteFlag)">审核 / 编辑</el-button>
          <el-button type="primary" size="mini" plain @click="interactFun(scope.row.id)" 
            :disabled="returnDel(scope.row.deleteFlag)">评论 / 点赞</el-button>
          <div style="margin-top: 10px;">
            <el-button type="primary" size="mini" plain @click="openPreviewFun(scope.row)"  
              :disabled="returnDel(scope.row.deleteFlag)">预览</el-button>
            <el-button type="primary" size="mini" plain @click="openTrTg(scope.row.id)" 
              :disabled="returnDel(scope.row.deleteFlag) || scope.row.state != 1001">转发</el-button>
              <el-button type="warning" size="mini" @click="deleteNews(scope.row.id)">删除</el-button>
              <!-- <el-popover trigger="click" placement="top" width="100" style="margin-left: 10px;">
                <p style="text-align: center;">确认删除？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="deVisible = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="deleteNews(scope.row.id)">确定</el-button>
                </div>
                <el-button slot="reference" type="warning" size="mini">删除</el-button>
              </el-popover> -->


          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="page.pageNumber"
        :page-size="page.pageSize"
        :total="parseInt(page.total)"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 批量快速审核弹窗 -->
    <quickAuditCom ref="quickAuditRef" @returnGetData="getDataList(page.pageNumber)"/>
    <!-- 转发tg群弹窗 -->
    <transpondTgCom ref="transpondTgRef" @returnGetData="getDataList(page.pageNumber)"/>
    <!-- 预览弹窗 -->
    <previewModel ref="previewModelRef"/>
    <!-- 评论点赞弹窗 -->
    <InteractDiaCom ref="InteractDiaComRef"/>
  </div>
</template>

<script>
import { formatDateTime,utcUpdatedDate } from '@/utils'
import {  auditList, deleteAudit } from '@/api/newsManage'
import { systemTerritory, systemDict  } from '@/api/utilsManage'
import quickAuditCom from '../com/quickAuditCom.vue'
import transpondTgCom from '../com/transpondTgCom.vue'
import InteractDiaCom from '../com/InteractDiaCom.vue'
import { 
  sourceTypeDisctE,
  crawlerChannelDisctE,
  newsStateDisct,
  timeTypeDisctE
} from '@/utils/disct.js'

export default {
  components: { quickAuditCom, transpondTgCom, InteractDiaCom },
  name: 'newsList',
  data () {
    return {
      module: 1,// 当前模块
      sourceType: 3,
      crawlerChannel: 100,
      multipleSelection: [],
      countryDisct: [], // 目标市场枚举
      sourceTypeDisct: sourceTypeDisctE,   // 文章来源
      crawlerChannelDisct: [], // 采集来源渠道
      stateDisct: newsStateDisct, // 审核状态（1000：等待审核；1001：审核通过；1003已被编辑过；1004：回收站）
      timeTypeDisct: timeTypeDisctE,

      dataForm: { 
        search1: '',
        search2: '',
        search3: '',
        countryIds: [],// 目标市场
        channelIds: [],// 目标业务
        cateIds: [], //所属分类
        state: '', // 状态
        timeType: '',
        createTime: [],//注册时间
      },
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        list: []
      },
      dataListLoading: false,
      //快熟审核参数
      auditKuai: {
        show: false,
        isClick: false,
        radio1: 1,
        inputValue1: '',
        radio2: 1,
        authorId: '',
        inputValue2: '',
      },
      visible2: false,
    }
  },
  created () {
    console.log("返回")
    this.getTerritory()
    this.getSystemDict()
  },
  activated() {
    if(this.crawlerChannelDisct.length > 0){
      // 需要重新请求的写在这里
      this.getDataList(this.page.pageNumber)
    }
  },
  mounted () {
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
  },
  methods: {
    returnDel(date){
      return date ? true : false
    },
    //列表内容居中
    tableStyle(){
      return "text-align:center"
    },
    // 条件禁用列
    checkSelectable(row){
      if (row.state == 1000 && !row.deleteFlag) {
        return true
      } else {
        return false
      }
    },
    // 获取来源
    getSystemDict(){
      systemDict({key: 'NEWS_CRAWLER_CHANNEL'}).then(( res ) => {
        this.crawlerChannelDisct = res.data
        this.crawlerChannel = this.crawlerChannelDisct[0].code
        // 需要重新请求的写在这里
        this.getDataList(this.page.pageNumber)
      })
    },
    // 获取目标市场和目标业务枚举列表
    getTerritory(){
      systemTerritory({isLoadCate: true}).then(( data ) => {
        this.countryDisct = data.data.country
      })
    },
    // 获取数据列表
    getDataList (pageNumber) {
      this.page.pageNumber = pageNumber ? pageNumber : 1
      this.dataListLoading = true
      let obj = {
          pageNumber: this.page.pageNumber,
          pageSize: this.page.pageSize,
          sourceType: this.sourceType,// 来源
          module: this.module,// 1：咨询，2：快讯, 3 社区 
      }
      if(this.sourceType == 3) obj.crawlerChannel = this.crawlerChannel // 来源渠道（采集才有）
      if(this.dataForm.search1) obj.authorId = this.dataForm.search1.trim()
      if(this.dataForm.search2) obj.authorNickName = this.dataForm.search2.trim()
      if(this.dataForm.search3) obj.title = this.dataForm.search3.trim()
      if(this.dataForm.countryIds.length>0) obj.countryIds = this.dataForm.countryIds
      if(this.dataForm.channelIds.length>0) obj.channelIds = this.dataForm.channelIds
      if(this.dataForm.cateIds.length>0) obj.cateIds = this.dataForm.cateIds
      if(this.dataForm.state) obj.state = this.dataForm.state
      if(this.dataForm.timeType) obj.timeType = this.dataForm.timeType
      //发布时间 
      if(this.dataForm.createTime && this.dataForm.createTime.length > 0){
        if(this.sourceType == 3){
          obj.startCreateDate = formatDateTime(this.dataForm.createTime[0])
          obj.endCreateDate =formatDateTime(this.dataForm.createTime[1])
        }else{
          obj.startCollectDate = formatDateTime(this.dataForm.createTime[0])
          obj.endCollectDate =formatDateTime(this.dataForm.createTime[1])
        }
      }
      auditList( obj ).then( res => {
        this.page.list = res.data
        if(this.page.list.length > 0){
          this.page.list .forEach( item => {
            item.countryStr = ''  // 市场
            item.channelStr = '' // 业务
            item.cateStr = ''  // 分类
            if(item.countryList.length > 0){
              item.countryList.forEach( (country, index) => {
                item.countryStr += item.countryList.length != index+1 ? country.name+' / ' : country.name
              })
            }
            if(item.channelList.length > 0){
              item.channelList.forEach( (channel, index) => {
                item.channelStr += item.channelList.length != index+1 ? channel.name+' / ' : channel.name
              })
            }
            if(item.cateList.length > 0){
              item.cateList.forEach( (channel, index) => {
                item.cateStr += item.cateList.length != index+1 ? channel.name+' / ' : channel.name
              })
            }
          })
        }
        this.page.total = res.totalCount;
        this.dataListLoading = false
      }).catch((error) => {
        this.$message('查询出错了')
        this.dataListLoading = false
      })
    },
    // 勾选改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 打开批量审核弹窗
    auditFun(){ 
      let ids = []
      this.multipleSelection.forEach( item => {
        ids.push(item.id)
      })
      this.$refs.quickAuditRef.open(ids, this.module)
    },
    // 预览
    openPreviewFun(item){
      let info = {
        id: item.id,
        module: this.module,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      this.$refs.previewModelRef.open(info)
    },
    // 去编辑与详细审核
    goUpdatePage(id){
      let query = {
        id: id ? id : '',
        module: this.module,
      }
      if(this.sourceType == 3) query.crawlerChannel = this.crawlerChannel
      this.$router.push({
        path: '/auditOrEdit',
        query: query
      })
    },
    //评论/点赞
    interactFun(id){
      // this.$router.push({
      //   path: '/newsInteract',
      //   query: {
      //     id: id,
      //     module: this.module
      //   }
      // })
      
      let info = {
        id: id,
        module: this.module,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      this.$refs.InteractDiaComRef.open(info)
    },
    // 打开转发TG群
    openTrTg(id){
      this.$refs.transpondTgRef.open(id, this.module)
    },
    
    // 删除
    deleteNews(id){
      this.$confirm('确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = {
          ids: [id],
          module: this.module
        }
        this.$openLoading()
        deleteAudit(info).then(( res ) => {
          this.$closeLoading()
          this.$message.success("删除成功")
          // 需要重新请求的写在这里
          this.getDataList(this.page.pageNumber)
        }).catch((error) => {
          this.$message.error(error)
          this.$closeLoading()
        })
      }).catch(() => { });
    },

  }
}
</script>
<style lang="scss" scoped>
.mes_error_text{
  font-size: 12px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
}
</style>
