export const countryCodeList =  [
  {
    "id": 877,
    "country_code": 86,
    "continent": "亚洲",
    "country_name": "中国"
  },
  {
    "id": 878,
    "country_code": 7,
    "continent": "亚洲",
    "country_name": "阿布哈兹"
  },
  {
    "id": 879,
    "country_code": 93,
    "continent": "亚洲",
    "country_name": "阿富汗"
  },
  {
    "id": 880,
    "country_code": 355,
    "continent": "欧洲",
    "country_name": "阿尔巴尼亚"
  },
  {
    "id": 881,
    "country_code": 213,
    "continent": "欧洲",
    "country_name": "阿尔及利亚"
  },
  {
    "id": 882,
    "country_code": 684,
    "continent": "美洲",
    "country_name": "美属萨摩亚群岛"
  },
  {
    "id": 883,
    "country_code": 376,
    "continent": "大洋洲",
    "country_name": "安道尔共和国"
  },
  {
    "id": 884,
    "country_code": 244,
    "continent": "非洲",
    "country_name": "安哥拉"
  },
  {
    "id": 885,
    "country_code": 1264,
    "continent": "美洲",
    "country_name": "安圭拉岛"
  },
  {
    "id": 886,
    "country_code": 1268,
    "continent": "北美洲",
    "country_name": "安提瓜和巴布达"
  },
  {
    "id": 887,
    "country_code": 54,
    "continent": "南美洲",
    "country_name": "阿根廷"
  },
  {
    "id": 888,
    "country_code": 374,
    "continent": "亚洲",
    "country_name": "亚美尼亚"
  },
  {
    "id": 889,
    "country_code": 297,
    "continent": "南美洲",
    "country_name": "阿鲁巴"
  },
  {
    "id": 890,
    "country_code": 61,
    "continent": "大洋洲",
    "country_name": "澳大利亚"
  },
  {
    "id": 891,
    "country_code": 43,
    "continent": "欧洲",
    "country_name": "奥地利"
  },
  {
    "id": 892,
    "country_code": 994,
    "continent": "亚洲",
    "country_name": "阿塞拜疆"
  },
  {
    "id": 893,
    "country_code": 1242,
    "continent": "北美洲",
    "country_name": "巴哈马"
  },
  {
    "id": 894,
    "country_code": 973,
    "continent": "亚洲",
    "country_name": "巴林"
  },
  {
    "id": 895,
    "country_code": 880,
    "continent": "亚洲",
    "country_name": "孟加拉国"
  },
  {
    "id": 896,
    "country_code": 1246,
    "continent": "美洲",
    "country_name": "巴巴多斯"
  },
  {
    "id": 897,
    "country_code": 375,
    "continent": "欧洲",
    "country_name": "白俄罗斯"
  },
  {
    "id": 898,
    "country_code": 32,
    "continent": "欧洲",
    "country_name": "比利时"
  },
  {
    "id": 899,
    "country_code": 501,
    "continent": "美洲",
    "country_name": "伯利兹"
  },
  {
    "id": 900,
    "country_code": 229,
    "continent": "非洲",
    "country_name": "贝宁"
  },
  {
    "id": 901,
    "country_code": 1441,
    "continent": "北美洲",
    "country_name": "百慕大群岛"
  },
  {
    "id": 902,
    "country_code": 975,
    "continent": "亚洲",
    "country_name": "不丹"
  },
  {
    "id": 903,
    "country_code": 591,
    "continent": "南美洲",
    "country_name": "玻利维亚"
  },
  {
    "id": 904,
    "country_code": 387,
    "continent": "欧洲",
    "country_name": "波斯尼亚和黑塞哥维那"
  },
  {
    "id": 905,
    "country_code": 267,
    "continent": "非洲",
    "country_name": "博茨瓦纳"
  },
  {
    "id": 906,
    "country_code": 55,
    "continent": "美洲",
    "country_name": "巴西"
  },
  {
    "id": 907,
    "country_code": 246,
    "continent": "欧洲",
    "country_name": "英属印度洋领地"
  },
  {
    "id": 908,
    "country_code": 673,
    "continent": "亚洲",
    "country_name": "文莱"
  },
  {
    "id": 909,
    "country_code": 359,
    "continent": "欧洲",
    "country_name": "保加利亚"
  },
  {
    "id": 910,
    "country_code": 226,
    "continent": "非洲",
    "country_name": "布基纳法索"
  },
  {
    "id": 911,
    "country_code": 257,
    "continent": "非洲",
    "country_name": "布隆迪"
  },
  {
    "id": 912,
    "country_code": 855,
    "continent": "亚洲",
    "country_name": "柬埔寨"
  },
  {
    "id": 913,
    "country_code": 237,
    "continent": "非洲",
    "country_name": "喀麦隆"
  },
  {
    "id": 914,
    "country_code": 1,
    "continent": "美洲",
    "country_name": "加拿大"
  },
  {
    "id": 915,
    "country_code": 238,
    "continent": "非洲",
    "country_name": "佛得角"
  },
  {
    "id": 916,
    "country_code": 1345,
    "continent": "美洲",
    "country_name": "开曼群岛"
  },
  {
    "id": 917,
    "country_code": 236,
    "continent": "非洲",
    "country_name": "中非共和国"
  },
  {
    "id": 918,
    "country_code": 235,
    "continent": "非洲",
    "country_name": "乍得"
  },
  {
    "id": 919,
    "country_code": 56,
    "continent": "美洲",
    "country_name": "智利"
  },
  {
    "id": 920,
    "country_code": 57,
    "continent": "南美洲",
    "country_name": "哥伦比亚"
  },
  {
    "id": 921,
    "country_code": 269,
    "continent": "非洲",
    "country_name": "科摩罗"
  },
  {
    "id": 922,
    "country_code": 242,
    "continent": "非洲",
    "country_name": "刚果"
  },
  {
    "id": 923,
    "country_code": 243,
    "continent": "非洲",
    "country_name": "刚果民主共和国"
  },
  {
    "id": 924,
    "country_code": 682,
    "continent": "大洋洲",
    "country_name": "库克群岛"
  },
  {
    "id": 925,
    "country_code": 506,
    "continent": "美洲",
    "country_name": "哥斯达黎加"
  },
  {
    "id": 926,
    "country_code": 225,
    "continent": "非洲",
    "country_name": "科特迪瓦共和国"
  },
  {
    "id": 927,
    "country_code": 385,
    "continent": "欧洲",
    "country_name": "克罗地亚"
  },
  {
    "id": 928,
    "country_code": 53,
    "continent": "美洲",
    "country_name": "古巴"
  },
  {
    "id": 929,
    "country_code": 357,
    "continent": "欧洲",
    "country_name": "塞浦路斯"
  },
  {
    "id": 930,
    "country_code": 420,
    "continent": "欧洲",
    "country_name": "捷克共和国"
  },
  {
    "id": 931,
    "country_code": 45,
    "continent": "欧洲",
    "country_name": "丹麦"
  },
  {
    "id": 932,
    "country_code": 253,
    "continent": "非洲",
    "country_name": "吉布提"
  },
  {
    "id": 933,
    "country_code": 1767,
    "continent": "北美洲",
    "country_name": "多米尼克"
  },
  {
    "id": 934,
    "country_code": 1809,
    "continent": "北美洲",
    "country_name": "多米尼加共和国"
  },
  {
    "id": 935,
    "country_code": 670,
    "continent": "亚洲",
    "country_name": "东帝汶"
  },
  {
    "id": 936,
    "country_code": 593,
    "continent": "美洲",
    "country_name": "厄瓜多尔"
  },
  {
    "id": 937,
    "country_code": 20,
    "continent": "非洲",
    "country_name": "埃及"
  },
  {
    "id": 938,
    "country_code": 503,
    "continent": "美洲",
    "country_name": "萨尔瓦多"
  },
  {
    "id": 939,
    "country_code": 240,
    "continent": "非洲",
    "country_name": "赤道几内亚"
  },
  {
    "id": 940,
    "country_code": 372,
    "continent": "欧洲",
    "country_name": "爱沙尼亚"
  },
  {
    "id": 941,
    "country_code": 251,
    "continent": "非洲",
    "country_name": "埃塞俄比亚"
  },
  {
    "id": 942,
    "country_code": 500,
    "continent": "南美洲",
    "country_name": "马尔维纳斯群岛"
  },
  {
    "id": 943,
    "country_code": 298,
    "continent": "欧洲",
    "country_name": "法罗群岛"
  },
  {
    "id": 944,
    "country_code": 679,
    "continent": "大洋洲",
    "country_name": "斐济"
  },
  {
    "id": 945,
    "country_code": 358,
    "continent": "欧洲",
    "country_name": "芬兰"
  },
  {
    "id": 946,
    "country_code": 33,
    "continent": "欧洲",
    "country_name": "法国"
  },
  {
    "id": 947,
    "country_code": 594,
    "continent": "美洲",
    "country_name": "法属圭亚那"
  },
  {
    "id": 948,
    "country_code": 689,
    "continent": "大洋洲",
    "country_name": "玻利尼西亚"
  },
  {
    "id": 949,
    "country_code": 241,
    "continent": "非洲",
    "country_name": "加蓬"
  },
  {
    "id": 950,
    "country_code": 220,
    "continent": "非洲",
    "country_name": "冈比亚"
  },
  {
    "id": 951,
    "country_code": 995,
    "continent": "亚洲",
    "country_name": "格鲁吉亚"
  },
  {
    "id": 952,
    "country_code": 49,
    "continent": "欧洲",
    "country_name": "德国"
  },
  {
    "id": 953,
    "country_code": 233,
    "continent": "非洲",
    "country_name": "加纳"
  },
  {
    "id": 954,
    "country_code": 350,
    "continent": "欧洲",
    "country_name": "直布罗陀"
  },
  {
    "id": 955,
    "country_code": 30,
    "continent": "欧洲",
    "country_name": "希腊"
  },
  {
    "id": 956,
    "country_code": 299,
    "continent": "美洲",
    "country_name": "格陵兰岛"
  },
  {
    "id": 957,
    "country_code": 1473,
    "continent": "美洲",
    "country_name": "格林纳达"
  },
  {
    "id": 958,
    "country_code": 590,
    "continent": "美洲",
    "country_name": "瓜德罗普岛"
  },
  {
    "id": 959,
    "country_code": 502,
    "continent": "美洲",
    "country_name": "危地马拉"
  },
  {
    "id": 960,
    "country_code": 224,
    "continent": "非洲",
    "country_name": "几内亚"
  },
  {
    "id": 961,
    "country_code": 245,
    "continent": "非洲",
    "country_name": "几内亚比绍"
  },
  {
    "id": 962,
    "country_code": 592,
    "continent": "美洲",
    "country_name": "圭亚那"
  },
  {
    "id": 963,
    "country_code": 509,
    "continent": "美洲",
    "country_name": "海地"
  },
  {
    "id": 964,
    "country_code": 504,
    "continent": "美洲",
    "country_name": "洪都拉斯"
  },
  {
    "id": 965,
    "country_code": 852,
    "continent": "亚洲",
    "country_name": "中国香港"
  },
  {
    "id": 966,
    "country_code": 36,
    "continent": "欧洲",
    "country_name": "匈牙利"
  },
  {
    "id": 967,
    "country_code": 354,
    "continent": "欧洲",
    "country_name": "冰岛"
  },
  {
    "id": 968,
    "country_code": 91,
    "continent": "亚洲",
    "country_name": "印度"
  },
  {
    "id": 969,
    "country_code": 62,
    "continent": "亚洲",
    "country_name": "印度尼西亚"
  },
  {
    "id": 970,
    "country_code": 98,
    "continent": "亚洲",
    "country_name": "伊朗"
  },
  {
    "id": 971,
    "country_code": 964,
    "continent": "亚洲",
    "country_name": "伊拉克"
  },
  {
    "id": 972,
    "country_code": 353,
    "continent": "欧洲",
    "country_name": "爱尔兰"
  },
  {
    "id": 973,
    "country_code": 972,
    "continent": "亚洲",
    "country_name": "以色列"
  },
  {
    "id": 974,
    "country_code": 39,
    "continent": "欧洲",
    "country_name": "意大利"
  },
  {
    "id": 975,
    "country_code": 1876,
    "continent": "美洲",
    "country_name": "牙买加"
  },
  {
    "id": 976,
    "country_code": 81,
    "continent": "亚洲",
    "country_name": "日本"
  },
  {
    "id": 977,
    "country_code": 44,
    "continent": "欧洲",
    "country_name": "泽西岛"
  },
  {
    "id": 978,
    "country_code": 962,
    "continent": "亚洲",
    "country_name": "约旦"
  },
  {
    "id": 979,
    "country_code": 7,
    "continent": "亚洲",
    "country_name": "哈萨克斯坦"
  },
  {
    "id": 980,
    "country_code": 254,
    "continent": "非洲",
    "country_name": "肯尼亚"
  },
  {
    "id": 981,
    "country_code": 965,
    "continent": "亚洲",
    "country_name": "科威特"
  },
  {
    "id": 982,
    "country_code": 996,
    "continent": "亚洲",
    "country_name": "吉尔吉斯斯坦"
  },
  {
    "id": 983,
    "country_code": 856,
    "continent": "亚洲",
    "country_name": "老挝"
  },
  {
    "id": 984,
    "country_code": 371,
    "continent": "欧洲",
    "country_name": "拉脱维亚"
  },
  {
    "id": 985,
    "country_code": 961,
    "continent": "亚洲",
    "country_name": "黎巴嫩"
  },
  {
    "id": 986,
    "country_code": 266,
    "continent": "非洲",
    "country_name": "莱索托"
  },
  {
    "id": 987,
    "country_code": 231,
    "continent": "非洲",
    "country_name": "利比里亚"
  },
  {
    "id": 988,
    "country_code": 218,
    "continent": "非洲",
    "country_name": "利比亚"
  },
  {
    "id": 989,
    "country_code": 423,
    "continent": "欧洲",
    "country_name": "列支敦士登"
  },
  {
    "id": 990,
    "country_code": 370,
    "continent": "欧洲",
    "country_name": "立陶宛"
  },
  {
    "id": 991,
    "country_code": 352,
    "continent": "欧洲",
    "country_name": "卢森堡"
  },
  {
    "id": 992,
    "country_code": 853,
    "continent": "亚洲",
    "country_name": "中国澳门"
  },
  {
    "id": 993,
    "country_code": 389,
    "continent": "欧洲",
    "country_name": "马其顿"
  },
  {
    "id": 994,
    "country_code": 261,
    "continent": "非洲",
    "country_name": "马达加斯加"
  },
  {
    "id": 995,
    "country_code": 265,
    "continent": "非洲",
    "country_name": "马拉维"
  },
  {
    "id": 996,
    "country_code": 60,
    "continent": "亚洲",
    "country_name": "马来西亚"
  },
  {
    "id": 997,
    "country_code": 960,
    "continent": "亚洲",
    "country_name": "马尔代夫"
  },
  {
    "id": 998,
    "country_code": 223,
    "continent": "非洲",
    "country_name": "马里"
  },
  {
    "id": 999,
    "country_code": 356,
    "continent": "欧洲",
    "country_name": "马耳他"
  },
  {
    "id": 1000,
    "country_code": 692,
    "continent": "大洋洲",
    "country_name": "马绍尔群岛"
  },
  {
    "id": 1001,
    "country_code": 596,
    "continent": "非洲",
    "country_name": "马提尼克"
  },
  {
    "id": 1002,
    "country_code": 222,
    "continent": "非洲",
    "country_name": "毛里塔尼亚"
  },
  {
    "id": 1003,
    "country_code": 230,
    "continent": "非洲",
    "country_name": "毛里求斯"
  },
  {
    "id": 1004,
    "country_code": 52,
    "continent": "北美洲",
    "country_name": "墨西哥"
  },
  {
    "id": 1005,
    "country_code": 373,
    "continent": "欧洲",
    "country_name": "摩尔多瓦"
  },
  {
    "id": 1006,
    "country_code": 377,
    "continent": "欧洲",
    "country_name": "摩纳哥"
  },
  {
    "id": 1007,
    "country_code": 976,
    "continent": "亚洲",
    "country_name": "蒙古"
  },
  {
    "id": 1008,
    "country_code": 382,
    "continent": "欧洲",
    "country_name": "黑山共和国"
  },
  {
    "id": 1009,
    "country_code": 1664,
    "continent": "欧洲",
    "country_name": "蒙特塞拉特岛"
  },
  {
    "id": 1010,
    "country_code": 212,
    "continent": "非洲",
    "country_name": "摩洛哥"
  },
  {
    "id": 1011,
    "country_code": 258,
    "continent": "非洲",
    "country_name": "莫桑比克"
  },
  {
    "id": 1012,
    "country_code": 264,
    "continent": "非洲",
    "country_name": "纳米比亚"
  },
  {
    "id": 1013,
    "country_code": 674,
    "continent": "大洋洲",
    "country_name": "瑙鲁"
  },
  {
    "id": 1014,
    "country_code": 977,
    "continent": "亚洲",
    "country_name": "尼泊尔"
  },
  {
    "id": 1015,
    "country_code": 31,
    "continent": "欧洲",
    "country_name": "荷兰"
  },
  {
    "id": 1016,
    "country_code": 599,
    "continent": "南美洲",
    "country_name": "荷属安的列斯"
  },
  {
    "id": 1017,
    "country_code": 687,
    "continent": "大洋洲",
    "country_name": "新喀里多尼亚岛"
  },
  {
    "id": 1018,
    "country_code": 64,
    "continent": "大洋洲",
    "country_name": "新西兰"
  },
  {
    "id": 1019,
    "country_code": 505,
    "continent": "北美洲",
    "country_name": "尼加拉瓜"
  },
  {
    "id": 1020,
    "country_code": 227,
    "continent": "非洲",
    "country_name": "尼日尔"
  },
  {
    "id": 1021,
    "country_code": 234,
    "continent": "非洲",
    "country_name": "尼日利亚"
  },
  {
    "id": 1022,
    "country_code": 61,
    "continent": "大洋洲",
    "country_name": "诺福克岛"
  },
  {
    "id": 1023,
    "country_code": 47,
    "continent": "欧洲",
    "country_name": "挪威"
  },
  {
    "id": 1024,
    "country_code": 968,
    "continent": "亚洲",
    "country_name": "阿曼"
  },
  {
    "id": 1025,
    "country_code": 92,
    "continent": "亚洲",
    "country_name": "巴基斯坦"
  },
  {
    "id": 1026,
    "country_code": 680,
    "continent": "大洋洲",
    "country_name": "帕劳"
  },
  {
    "id": 1027,
    "country_code": 970,
    "continent": "亚洲",
    "country_name": "巴勒斯坦"
  },
  {
    "id": 1028,
    "country_code": 507,
    "continent": "美洲",
    "country_name": "巴拿马"
  },
  {
    "id": 1029,
    "country_code": 675,
    "continent": "大洋洲",
    "country_name": "巴布亚新几内亚"
  },
  {
    "id": 1030,
    "country_code": 595,
    "continent": "美洲",
    "country_name": "巴拉圭"
  },
  {
    "id": 1031,
    "country_code": 51,
    "continent": "美洲",
    "country_name": "秘鲁"
  },
  {
    "id": 1032,
    "country_code": 63,
    "continent": "亚洲",
    "country_name": "菲律宾"
  },
  {
    "id": 1033,
    "country_code": 48,
    "continent": "欧洲",
    "country_name": "波兰"
  },
  {
    "id": 1034,
    "country_code": 351,
    "continent": "欧洲",
    "country_name": "葡萄牙"
  },
  {
    "id": 1035,
    "country_code": 974,
    "continent": "亚洲",
    "country_name": "卡塔尔"
  },
  {
    "id": 1036,
    "country_code": 95,
    "continent": "亚洲",
    "country_name": "缅甸"
  },
  {
    "id": 1037,
    "country_code": 262,
    "continent": "欧洲",
    "country_name": "留尼汪"
  },
  {
    "id": 1038,
    "country_code": 40,
    "continent": "欧洲",
    "country_name": "罗马尼亚"
  },
  {
    "id": 1039,
    "country_code": 7,
    "continent": "欧洲",
    "country_name": "俄罗斯"
  },
  {
    "id": 1040,
    "country_code": 250,
    "continent": "非洲",
    "country_name": "卢旺达"
  },
  {
    "id": 1041,
    "country_code": 1809,
    "continent": "美洲",
    "country_name": "圣马丁岛"
  },
  {
    "id": 1042,
    "country_code": 1758,
    "continent": "美洲",
    "country_name": "圣露西亚"
  },
  {
    "id": 1043,
    "country_code": 784,
    "continent": "北美洲",
    "country_name": "圣文森特和格林纳丁斯"
  },
  {
    "id": 1044,
    "country_code": 685,
    "continent": "大洋洲",
    "country_name": "萨摩亚"
  },
  {
    "id": 1045,
    "country_code": 378,
    "continent": "欧洲",
    "country_name": "圣马力诺"
  },
  {
    "id": 1046,
    "country_code": 239,
    "continent": "非洲",
    "country_name": "圣多美和普林西比民主共和国"
  },
  {
    "id": 1047,
    "country_code": 966,
    "continent": "亚洲",
    "country_name": "沙特阿拉伯"
  },
  {
    "id": 1048,
    "country_code": 221,
    "continent": "非洲",
    "country_name": "塞内加尔"
  },
  {
    "id": 1049,
    "country_code": 381,
    "continent": "欧洲",
    "country_name": "塞尔维亚共和国"
  },
  {
    "id": 1050,
    "country_code": 248,
    "continent": "非洲",
    "country_name": "塞舌尔群岛"
  },
  {
    "id": 1051,
    "country_code": 232,
    "continent": "非洲",
    "country_name": "塞拉利昂"
  },
  {
    "id": 1052,
    "country_code": 65,
    "continent": "亚洲",
    "country_name": "新加坡"
  },
  {
    "id": 1053,
    "country_code": 421,
    "continent": "欧洲",
    "country_name": "斯洛伐克"
  },
  {
    "id": 1054,
    "country_code": 386,
    "continent": "欧洲",
    "country_name": "斯洛文尼亚"
  },
  {
    "id": 1055,
    "country_code": 677,
    "continent": "大洋洲",
    "country_name": "所罗门群岛"
  },
  {
    "id": 1056,
    "country_code": 252,
    "continent": "非洲",
    "country_name": "索马里"
  },
  {
    "id": 1057,
    "country_code": 27,
    "continent": "非洲",
    "country_name": "南非"
  },
  {
    "id": 1058,
    "country_code": 82,
    "continent": "亚洲",
    "country_name": "韩国"
  },
  {
    "id": 1059,
    "country_code": 7,
    "continent": "亚洲",
    "country_name": "南奥塞梯"
  },
  {
    "id": 1060,
    "country_code": 211,
    "continent": "非洲",
    "country_name": "南苏丹"
  },
  {
    "id": 1061,
    "country_code": 34,
    "continent": "欧洲",
    "country_name": "西班牙"
  },
  {
    "id": 1062,
    "country_code": 94,
    "continent": "亚洲",
    "country_name": "斯里兰卡"
  },
  {
    "id": 1063,
    "country_code": 1869,
    "continent": "北美洲",
    "country_name": "圣基茨和尼维斯"
  },
  {
    "id": 1064,
    "country_code": 249,
    "continent": "非洲",
    "country_name": "苏丹"
  },
  {
    "id": 1065,
    "country_code": 597,
    "continent": "南美洲",
    "country_name": "苏里南共和国"
  },
  {
    "id": 1066,
    "country_code": 268,
    "continent": "非洲",
    "country_name": "斯威士兰"
  },
  {
    "id": 1067,
    "country_code": 46,
    "continent": "欧洲",
    "country_name": "瑞典"
  },
  {
    "id": 1068,
    "country_code": 41,
    "continent": "欧洲",
    "country_name": "瑞士"
  },
  {
    "id": 1069,
    "country_code": 963,
    "continent": "亚洲",
    "country_name": "叙利亚"
  },
  {
    "id": 1070,
    "country_code": 886,
    "continent": "亚洲",
    "country_name": "中国台湾"
  },
  {
    "id": 1071,
    "country_code": 992,
    "continent": "亚洲",
    "country_name": "塔吉克斯坦"
  },
  {
    "id": 1072,
    "country_code": 255,
    "continent": "非洲",
    "country_name": "坦桑尼亚"
  },
  {
    "id": 1073,
    "country_code": 66,
    "continent": "亚洲",
    "country_name": "泰国"
  },
  {
    "id": 1074,
    "country_code": 228,
    "continent": "大洋洲",
    "country_name": "多哥"
  },
  {
    "id": 1075,
    "country_code": 676,
    "continent": "大洋洲",
    "country_name": "汤加"
  },
  {
    "id": 1076,
    "country_code": 1868,
    "continent": "美洲",
    "country_name": "特立尼达和多巴哥"
  },
  {
    "id": 1077,
    "country_code": 216,
    "continent": "非洲",
    "country_name": "突尼斯"
  },
  {
    "id": 1078,
    "country_code": 90,
    "continent": "欧洲",
    "country_name": "土耳其"
  },
  {
    "id": 1079,
    "country_code": 993,
    "continent": "亚洲",
    "country_name": "土库曼斯坦"
  },
  {
    "id": 1080,
    "country_code": 1649,
    "continent": "美洲",
    "country_name": "特克斯和凯科斯群岛"
  },
  {
    "id": 1081,
    "country_code": 256,
    "continent": "非洲",
    "country_name": "乌干达"
  },
  {
    "id": 1082,
    "country_code": 380,
    "continent": "欧洲",
    "country_name": "乌克兰"
  },
  {
    "id": 1083,
    "country_code": 971,
    "continent": "亚洲",
    "country_name": "阿拉伯联合酋长国"
  },
  {
    "id": 1084,
    "country_code": 44,
    "continent": "欧洲",
    "country_name": "英国"
  },
  {
    "id": 1085,
    "country_code": 1,
    "continent": "美洲",
    "country_name": "美国"
  },
  {
    "id": 1086,
    "country_code": 598,
    "continent": "美洲",
    "country_name": "乌拉圭"
  },
  {
    "id": 1087,
    "country_code": 998,
    "continent": "亚洲",
    "country_name": "乌兹别克斯坦"
  },
  {
    "id": 1088,
    "country_code": 678,
    "continent": "大洋洲",
    "country_name": "瓦努阿图"
  },
  {
    "id": 1089,
    "country_code": 58,
    "continent": "美洲",
    "country_name": "委内瑞拉"
  },
  {
    "id": 1090,
    "country_code": 84,
    "continent": "亚洲",
    "country_name": "越南"
  },
  {
    "id": 1091,
    "country_code": 1340,
    "continent": "北美洲",
    "country_name": "美属维尔京群岛"
  },
  {
    "id": 1092,
    "country_code": 681,
    "continent": "欧洲",
    "country_name": "瓦利斯群岛和富图纳群岛群岛"
  },
  {
    "id": 1093,
    "country_code": 967,
    "continent": "亚洲",
    "country_name": "也门"
  },
  {
    "id": 1094,
    "country_code": 260,
    "continent": "非洲",
    "country_name": "赞比亚"
  },
  {
    "id": 1095,
    "country_code": 263,
    "continent": "非洲",
    "country_name": "津巴布韦"
  }
]