import {captchaVerify, login} from '@/api/adminSecurity'
import {getToken, setToken, setUserInfo} from '@/config/util'
import {getMenuByRouter} from '@/utils'
import routes from '@/router/routers';

export default {
    state: {
        userinfo: {},
        adminToken: getToken(),
    },
    mutations: {
        setUserinfo(state, info) {
            state.userinfo = info
            setUserInfo(info)
        },
        setToken(state, adminToken) {
            state.adminToken = adminToken
            setToken(adminToken)
        },
    },
    getters: {
        //获取路由列表
        menuList: (state, getters) => getMenuByRouter(routes, null),
    },
    actions: {
        // 登录
        handleLogin({commit}, info) {
            return new Promise((resolve, reject) => {
                login(info).then(async data => {
                    commit('setToken', data.data.token)
                    commit('setUserinfo', data.data.accountInfo)
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 退出登录
        handleLogOut({state, commit}) {
            return new Promise((resolve, reject) => {
                // 如果你的退出登录无需请求接口，则可以直接使用下面三行代码而无需使用logout调用接口
                commit('setToken', '')
                resolve()
            })
        },
        captchaVerify({}, data) {
            return new Promise((resolve, reject) => {
                captchaVerify(data).then(async res => {
                    resolve(res)
                })
            });
        }

    }
}
