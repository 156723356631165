export default {
  props: {
    // 字幕路径
    subtitlesSrc: { type: String },
  },
  watch: {
    subtitlesSrc: {
      async handler(src) {
        this.cancelHttp();
        await this.$nextTick();
        if(!src){
          this.cancelTextTracks()
        }
        if (src && this.$refs.video) {
          this.__subtitlesHttpSignal = new AbortController();
          const res = await fetch(src, {
            signal: this.__subtitlesHttpSignal.signal,
          });
          const srtString = await res.text();
          const data = this.parseSubtitles(srtString);

          // 创建一个新的字幕轨道
          if (this.__track) {
            this.__track.mode = 'disabled';
          }
          this.__track = this.$refs.video.addTextTrack('subtitles', 'Subtitles');
          // 添加到轨道
          data.forEach(item => {
            this.__track.addCue(new VTTCue(item.ss / 1000, item.t / 1000, item.text));
          });
          this.__track.mode = 'showing';
        }
      },
      immediate: true,
    },
  },
  methods: {
    parseSubtitles(subtitlesContent) {
      const texts = subtitlesContent.split('\n');
      const data = [];
      for (let i = 0; i < texts.length; i++) {
        const text = texts[i];
        if (text.includes('-->')) {
          const temp = text.split('-->');
          data.push({
            ss: this.parseTime(temp[0]),
            t: this.parseTime(temp[1]),
            text: texts[++i].trim(),
          });
        }
      }
      return data;
    },
    parseTime(str = '') {
      return str.split(':').reduce(
        (time, current, index, arr) => time + (Math.max(0, (
          parseFloat(current) || 0
        ))) * (60 ** (arr.length - 1 - index))
      , 0) * 1000;
    },
    cancelHttp() {
      if (this.__subtitlesHttpSignal) {
        this.__subtitlesHttpSignal.abort();
      }
    },
    // 移除字幕
    cancelTextTracks() {
      if(this.__track){
        this.__track.mode = 'disabled';
      }
    },
  },
  beforeDestroy() {
    this.cancelHttp();
  },
};
