<template>
  <video ref="video" :width="width" :height="height" :poster="poster" controls>
    <source :src="src" type="video/ogg" />
    <source :src="src" type="video/mp4" />
    <source :src="src" type="video/webm" />
    <object :data="src" :width="width" :height="height">
      <embed :src="src" :width="width" :height="height" />
    </object>
  </video>
</template>
<script>
import subtitles from '@/mixin/subtitles';

export default {
  name: 'videoCom',
  mixins: [subtitles],
  props: {
    //视频路径
    src:{
      type: String,
      default: ''
    },
    //预览图
    poster:{
      type: String,
      default: ''
    },
    width:{
      type: String,
      default: '100%'
    },
    height:{
      type: String,
      default: '100%'
    },
  },
}
</script>