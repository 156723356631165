import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event'
import { returnContent } from './utils'
// 设置表格高度
// 使用，bottomOffset 代表距离底部的距离，一般预留用来放分页的
// <高度属性height，100无具体意义，仅为初始值，不可省略
/*   <el-table v-adaptive="{bottomOffset: 85,iModal: false}" height="100" */
// 这是element-ui封装的resize-event，copy过来用的
const doResize = async(el, binding, vnode) => {
  let that = vnode.context  
  // 获取表格Dom对象
  const { componentInstance: $table} = await vnode
  // 获取调用传递过来的数据 
  const { value } = binding
  // console.log("$table==",that.$setModalTableHeight)
  if (!$table.height) {
    throw new Error(`el-$table must set the height. Such as height='100px'`)
  }
  if (!$table) return
  // 获取距底部距离（用于展示页码等信息）
  const bottomOffset = (value && value.bottomOffset) || 30
  const isModal = (value && value.isModal) || false
  var height = 0
  //判断是否是弹窗中使用
  if(isModal){
    //弹窗的最底部位置
    console.log("$table====",$table.$parent)
    let modalInnerHeight = returnContent($table.$parent)
    height = modalInnerHeight - el.getBoundingClientRect().top - bottomOffset 
    $table.layout.setHeight(height)
    $table.doLayout()
  }else{
    height = window.innerHeight - el.getBoundingClientRect().top - bottomOffset - 36
    $table.layout.setHeight(height)
    $table.doLayout()
  }
}

export default {
  // 初始化设置
  //最先执行的钩子函数,只执行一次
  async bind(el, binding, vnode) {
    await doResize(el, binding, vnode)
    // 设置resize监听方法
    el.resizeListener = async () => {
      await doResize(el, binding, vnode)
    }
    // 绑定监听方法到addResizeListener
    addResizeListener(window.document.body, el.resizeListener)
  },
  // 绑定组件更新后触发执行
  async componentUpdated(el,binding,vnode){
    await doResize(el, binding, vnode)
  },
  // 绑定默认高度
  async inserted(el, binding, vnode) {
    await doResize(el, binding, vnode)
  },
  // 销毁时设置
  unbind(el) {
    // 移除resize监听
    removeResizeListener(el, el.resizeListener)
  }
}