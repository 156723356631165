<template>
  <div class="container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" style="padding: 10px 0;">
      <el-form-item label="话题">
        <el-input v-model="dataForm.search1" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="dataForm.select1" size="mini" placeholder="请选择" clearable 
          style="width: 120px;" @change="getDataList()">
          <el-option v-for="item in stateDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="dataForm.dateTime" type="datetimerange" size="mini"
          style="width: 310px;"  @change="getDataList()" 
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="openAddOrUpdate()">新增</el-button>
      </el-form-item>
    </el-form>
    <!--用户列表   height="500" v-adaptive="{bottomOffset: 50,iModal: false}"-->
    <el-table
      :data="dataList" border v-loading="dataListLoading"
      height="100" v-adaptive="{bottomOffset: 50,iModal: false}"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;">
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          {{ scope.row.createTime | showDate() }}
        </template>
      </el-table-column>
      <el-table-column prop="id" label="话题ID"> </el-table-column>
      <el-table-column prop="title" label="热议话题"></el-table-column>
      <el-table-column prop="introduction" label="话题介绍">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 300px;" class="content_p_class_clamp">{{ scope.row.introduction }}</p>
            <div slot="reference"  style="display: flex;align-items: center;justify-content: center;">
              <div class="content_div_class_clamp">{{ scope.row.introduction }}</div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="fixJoinNum" label="参与数"></el-table-column>
      <el-table-column prop="fixBrowNum" label="固定浏览量"></el-table-column>
      <el-table-column prop="reallyBrowNum" label="浏览量"></el-table-column>
      <el-table-column prop="sort" label="权重"></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{scope.row.state | stateFilter(stateDisct)}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="240" label="操作">
        <template slot-scope="scope">
          <div style="line-height:40px;justify-content: space-between;">
              <el-button type="success" plain size="small" @click="openAddTopic(scope.row)">添加动态</el-button>
              <el-button type="primary" plain size="mini" @click="openAddOrUpdate(scope.row)">编辑</el-button>
            <el-button type="warning" plain size="mini" @click="deleteFun(scope.row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 弹窗, 文章管理-->
    <hotTopicNews ref="hotTopicNewsRef" @refreshDataList="getDataList(pageIndex)" />
    
    <!-- 弹窗, 新增 / 修改 -->
    <addOrUpdate ref="addOrUpdateRef" @refreshDataList="getDataList(pageIndex)" 
      v-if="addOrUpdateShow" :show.sync="addOrUpdateShow"></addOrUpdate>
    
  </div>
</template>

<script>
import { formatDateTime,utcUpdatedDate, dateUpdateUtc } from '@/utils'
import { hotTopicLise, hotTopicPostAdd, hotTopicDelete } from '@/api/configManage'
import {  auditList } from '@/api/newsManage'
import hotTopicNews from './hotTopicNews'
import addOrUpdate from './addOrUpdate'
export default {
  name: 'hotTopic',
  data () {
    return {
      stateDisct: [   //在线状态字典
        {value: 1, name: '开启'},
        {value: 2, name: '关闭'},
      ],
      dataForm: {
        search1: '', //
        select1: '',
        dateTime: [],
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateShow: false,

      newsVisible: false,
      ruleForm: {
        id: '',
        title: '',
        newsIds: 1,
      },
      selectNewsList: [],
    }
  },
  components: {
    hotTopicNews,
    addOrUpdate,
  },
  created () {
    
  },
  mounted () {
    this.getDataList()
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
    showCollege (value) {
      if (value && value.length>0) {
        let valueStr = ""
          for (const key in value) {
            valueStr = valueStr + value[key] + " ";
          }
        return valueStr
      }
    },
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 条件禁用列
    checkSelectable(row){
      if (row.qualState == 0) {
        return true
      } else {
        return false
      }
    },
    // 获取数据列表
    getDataList (pageNum) {
      this.pageIndex = pageNum ? pageNum : 1
      this.dataListLoading = true
      let obj = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
      }
      if(this.dataForm.search1) obj.title = this.dataForm.search1.trim()
      if(this.dataForm.select1) obj.state = this.dataForm.select1
      if(this.dataForm.dateTime && this.dataForm.dateTime.length > 0){
        obj.startTime = this.dataForm.dateTime.length != 0 ? dateUpdateUtc(this.dataForm.dateTime[0]) : null
        obj.endTime = this.dataForm.dateTime.length != 0 ? dateUpdateUtc(this.dataForm.dateTime[1]) : null
      }
      // 日期
      hotTopicLise(obj).then(( data ) => {
        this.dataList = data.data
        this.totalPage = data.totalCount
        this.dataListLoading = false
      }).catch((error) => {
        this.dataListLoading = false
        this.$message.error(error);
      })
    },

    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 打开新增编辑话题
    openAddOrUpdate(row={}){
      this.addOrUpdateShow = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateRef.init(row)
      })
    },
    // 打开文章管理
    openAddTopic (row) {
      this.$nextTick(() => {
        this.$refs.hotTopicNewsRef.init(row)
      })
    },
    // 打开新增话题动态
    openAddTopicCopy(row){
      let obj = {
        pageNumber: 1,
        pageSize: 1000,
        hotTopicId: row.id,
        module: 3,// 1：咨询，2：快讯, 3 社区 
      }
      this.$openLoading()
      auditList(obj).then(( res ) => {
        this.$closeLoading()
        this.newsVisible = true 
        this.ruleForm = {
          id: row.id,
          title: row.title,
          newsIds: [],
        }
        let list = res.data
        if(list && list.length != 0){ // 循环是否有图片
          list.forEach( item => {
            item.urlList = []
            if(item.cover){
              item.urlList = item.cover.split(',')
            }
          });
        }
        this.selectNewsList = list
      }).catch((error) => {
        this.$closeLoading()
        this.$message.error(error);
      })
    },
    // // 新增话题动态
    // submitForm(id){
    //   this.$confirm('请再次确认以免操作有误！', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     let data = {
    //       hotTopicId:  this.ruleForm.id,
    //       postIds: this.ruleForm.newsIds,
    //     }
    //     console.log("参数",data)
    //     hotTopicPostAdd( data ).then( res => {
    //       this.getDataList(this.pageIndex)  
    //       this.newsVisible = false
    //       this.$message.success("操作成功")
    //     }).catch((error) => {
    //       this.$message("操作失败"+error)
    //     })
    //   }).catch(() => { });
    // },

    // 删除
    deleteFun(id){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$openLoading()
        hotTopicDelete( { hotTopicId: id } ).then( res => {
          this.$closeLoading()
          this.getDataList(this.pageIndex)  
          this.$message.success("删除成功")
        }).catch((error) => {
          this.$closeLoading()
          this.$message("删除失败"+error)
        })
      }).catch(() => { });
    }

  }
}
</script>
<style scoped>
.tui_col_div{
  width: 100%;
  text-align: center;
  margin-bottom: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.id_name_div{
  text-align: left;
  line-height: 10px;
  width: 180px; /* 设置div的宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>