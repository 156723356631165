var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page_bac_div" }, [
    _c(
      "div",
      {
        staticClass: "form_div_bac",
        style: { paddingRight: _vm.leftWidth + "px" }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "space-between"
            }
          },
          [
            _vm.InfoId
              ? _c(
                  "div",
                  {
                    staticClass: "head_back",
                    on: {
                      click: function($event) {
                        return _vm.$router.back()
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-arrow-left",
                      staticStyle: { "font-size": "18px", "font-weight": "600" }
                    }),
                    _c("h4", [_vm._v("返回")])
                  ]
                )
              : _vm._e(),
            _c("h2", [_vm._v(_vm._s(_vm.InfoId ? "编辑草稿" : "创建文章"))])
          ]
        ),
        _c(
          "el-form",
          {
            ref: "newsInfo",
            staticClass: "demo-newsInfo",
            attrs: {
              model: _vm.newsInfo,
              rules: _vm.rules,
              "label-width": "140px"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "所属模块" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      disabled: _vm.timeReturn(_vm.InfoId)
                    },
                    on: {
                      change: function($event) {
                        return _vm.moduleChange()
                      }
                    },
                    model: {
                      value: _vm.newsInfo.module,
                      callback: function($$v) {
                        _vm.$set(_vm.newsInfo, "module", $$v)
                      },
                      expression: "newsInfo.module"
                    }
                  },
                  _vm._l(_vm.moduleDisct, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.name, value: item.value }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.newsInfo.module == 1
              ? _c("el-form-item", { attrs: { label: "* 所属创作者账号" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("selectUser", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          id: _vm.newsInfo.authorId,
                          nickName: _vm.newsInfo.nickName,
                          headPic: _vm.newsInfo.headPic
                        },
                        on: {
                          "update:id": function($event) {
                            return _vm.$set(_vm.newsInfo, "authorId", $event)
                          },
                          "update:nickName": function($event) {
                            return _vm.$set(_vm.newsInfo, "nickName", $event)
                          },
                          "update:nick-name": function($event) {
                            return _vm.$set(_vm.newsInfo, "nickName", $event)
                          },
                          "update:headPic": function($event) {
                            return _vm.$set(_vm.newsInfo, "headPic", $event)
                          },
                          "update:head-pic": function($event) {
                            return _vm.$set(_vm.newsInfo, "headPic", $event)
                          },
                          userChange: _vm.userChange
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "margin-left": "20px"
                          }
                        },
                        [
                          _c("span", [_vm._v("企业：")]),
                          _c("selectCompany", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              disabled: !_vm.newsInfo.authorId,
                              placeholder: "关联独角兽公司",
                              id: _vm.newsInfo.companyId,
                              name: _vm.newsInfo.companyName
                            },
                            on: {
                              "update:id": function($event) {
                                return _vm.$set(
                                  _vm.newsInfo,
                                  "companyId",
                                  $event
                                )
                              },
                              "update:name": function($event) {
                                return _vm.$set(
                                  _vm.newsInfo,
                                  "companyName",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.newsInfo.authorId && _vm.confShow
                    ? _c("div", { staticClass: "mes_error_text" }, [
                        _vm._v("请选择作者")
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.newsInfo.module != 2
              ? [
                  _c(
                    "el-form-item",
                    { attrs: { label: "* 所属目标市场", prop: "countryList" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择",
                            filterable: "",
                            multiple: "",
                            clearable: ""
                          },
                          on: {
                            focus: function($event) {
                              return _vm.getCountryDisct()
                            }
                          },
                          model: {
                            value: _vm.newsInfo.countryList,
                            callback: function($$v) {
                              _vm.$set(_vm.newsInfo, "countryList", $$v)
                            },
                            expression: "newsInfo.countryList"
                          }
                        },
                        _vm._l(_vm.countryDisct, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      ),
                      _vm.newsInfo.countryList.length == 0 && _vm.confShow
                        ? _c("div", { staticClass: "mes_error_text" }, [
                            _vm._v("请选择目标市场")
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "* 所属业务或分类",
                        prop: "channelCateList"
                      }
                    },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          filterable: "",
                          clearable: "",
                          props: _vm.casProps,
                          options: _vm.channelCateDisct,
                          "show-all-levels": false,
                          "change-on-select": true
                        },
                        on: {
                          "visible-change": function($event) {
                            return _vm.getTerritory()
                          }
                        },
                        model: {
                          value: _vm.newsInfo.channelCateList,
                          callback: function($$v) {
                            _vm.$set(_vm.newsInfo, "channelCateList", $$v)
                          },
                          expression: "newsInfo.channelCateList"
                        }
                      }),
                      _c("span", { staticClass: "mes_text_div" }, [
                        _vm._v(" 选中1-6个业务或分类")
                      ]),
                      (_vm.newsInfo.channelCateList.length < 1 ||
                        _vm.newsInfo.channelCateList.length > 6) &&
                      _vm.confShow
                        ? _c("div", { staticClass: "mes_error_text" }, [
                            _vm._v(" 请选中1-6个业务或分类 ")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "文章语言", prop: "originLang" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "请选择",
                      filterable: "",
                      clearable: ""
                    },
                    on: {
                      focus: function($event) {
                        return _vm.getLanguage()
                      }
                    },
                    model: {
                      value: _vm.newsInfo.originLang,
                      callback: function($$v) {
                        _vm.$set(_vm.newsInfo, "originLang", $$v)
                      },
                      expression: "newsInfo.originLang"
                    }
                  },
                  _vm._l(_vm.langDisct, function(item) {
                    return _c("el-option", {
                      key: item.code,
                      attrs: { label: item.name, value: item.code }
                    })
                  }),
                  1
                ),
                !_vm.newsInfo.originLang && _vm.confShow
                  ? _c("div", { staticClass: "mes_error_text" }, [
                      _vm._v("请选择文章语言")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm.newsInfo.module == 1
              ? _c(
                  "el-form-item",
                  { attrs: { label: "封面", prop: "content" } },
                  [
                    _c("uploadImgUtils", {
                      attrs: {
                        image: _vm.newsInfo.cover,
                        width: "80px",
                        height: "80px",
                        userId: _vm.snowId
                      },
                      on: {
                        "update:image": function($event) {
                          return _vm.$set(_vm.newsInfo, "cover", $event)
                        },
                        "update:userId": function($event) {
                          _vm.snowId = $event
                        },
                        "update:user-id": function($event) {
                          _vm.snowId = $event
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "* 标题", prop: "title" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.newsInfo.title,
                    callback: function($$v) {
                      _vm.$set(_vm.newsInfo, "title", $$v)
                    },
                    expression: "newsInfo.title"
                  }
                }),
                !_vm.newsInfo.title && _vm.confShow
                  ? _c("div", { staticClass: "mes_error_text" }, [
                      _vm._v("请输入标题")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "正文内容", prop: "content", required: "" } },
              [
                _c("comWangEditor", {
                  attrs: {
                    height: "600px",
                    content: _vm.newsInfo.content,
                    module: _vm.newsInfo.module,
                    id: _vm.snowId
                  },
                  on: {
                    "update:content": function($event) {
                      return _vm.$set(_vm.newsInfo, "content", $event)
                    },
                    "update:module": function($event) {
                      return _vm.$set(_vm.newsInfo, "module", $event)
                    },
                    "update:id": function($event) {
                      _vm.snowId = $event
                    }
                  }
                }),
                (_vm.newsInfo.content == "<p><br></p>" ||
                  !_vm.newsInfo.content) &&
                _vm.confShow
                  ? _c("div", { staticClass: "mes_error_text" }, [
                      _vm._v("请输入内容")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm.newsInfo.module != 2
              ? [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择标签", prop: "labelList" } },
                    [
                      _c("selectTag", {
                        attrs: { labelList: _vm.newsInfo.labelList },
                        on: {
                          "update:labelList": function($event) {
                            return _vm.$set(_vm.newsInfo, "labelList", $event)
                          },
                          "update:label-list": function($event) {
                            return _vm.$set(_vm.newsInfo, "labelList", $event)
                          }
                        }
                      }),
                      _vm._v(" 最多可添加20个标签 ")
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "权重排序", prop: "infoSort" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "limitInput",
                                rawName: "v-limitInput:positiveInteger",
                                arg: "positiveInteger"
                              }
                            ],
                            staticStyle: { width: "215px" },
                            attrs: { maxlength: "10" },
                            model: {
                              value: _vm.newsInfo.infoSort,
                              callback: function($$v) {
                                _vm.$set(_vm.newsInfo, "infoSort", $$v)
                              },
                              expression: "newsInfo.infoSort"
                            }
                          }),
                          _c("span", { staticClass: "mes_text_div" }, [
                            _vm._v("数值越大，排序越靠前")
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "权重有效时间", prop: "infoSort" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.sortExpireRadio,
                                callback: function($$v) {
                                  _vm.sortExpireRadio = $$v
                                },
                                expression: "sortExpireRadio"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("设置有效时间")
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("不设置有效时间")
                              ])
                            ],
                            1
                          ),
                          _c("el-date-picker", {
                            staticStyle: { "margin-left": "16px" },
                            attrs: {
                              disabled: _vm.sortExpireRadio == 2,
                              type: "datetime",
                              placeholder: "选择时间",
                              "default-time": "23:59:59",
                              "picker-options": _vm.pickerOptions()
                            },
                            model: {
                              value: _vm.newsInfo.infoSortExpire,
                              callback: function($$v) {
                                _vm.$set(_vm.newsInfo, "infoSortExpire", $$v)
                              },
                              expression: "newsInfo.infoSortExpire"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.sortExpireRadio == 1 &&
                      !_vm.newsInfo.infoSortExpire &&
                      _vm.confShow
                        ? _c("div", { staticClass: "mes_error_text" }, [
                            _vm._v("请选择定时发布时间")
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _c("div", { staticClass: "fen_ge_xian" }),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              "margin-top": "40px"
            }
          },
          [
            _c(
              "el-button",
              {
                attrs: {
                  size: "medium",
                  type: "primary",
                  disabled: _vm.subLoading
                },
                on: { click: _vm.submitFun }
              },
              [_vm._v("确认")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "medium", type: "warning" },
                on: { click: _vm.cancelFun }
              },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  size: "medium",
                  type: "success",
                  disabled: _vm.subLoading
                },
                on: { click: _vm.saveFun }
              },
              [_vm._v("存储为草稿")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "phone_righ_bac" },
      [
        _c("articleModel", {
          staticClass: "creat_model_com",
          attrs: {
            height: Math.min(_vm.windowSize.height * 0.8, 667),
            html: _vm.newsInfo.content,
            title: _vm.newsInfo.title,
            tags: _vm.newsInfo.labelList,
            currentModule: _vm.newsInfo.module,
            nickname: _vm.newsInfo.nickName,
            headPic: _vm.newsInfo.headPic,
            urlList: _vm.urlList
          },
          on: {
            "update:html": function($event) {
              return _vm.$set(_vm.newsInfo, "content", $event)
            },
            "update:title": function($event) {
              return _vm.$set(_vm.newsInfo, "title", $event)
            },
            "update:tags": function($event) {
              return _vm.$set(_vm.newsInfo, "labelList", $event)
            },
            "update:currentModule": function($event) {
              return _vm.$set(_vm.newsInfo, "module", $event)
            },
            "update:current-module": function($event) {
              return _vm.$set(_vm.newsInfo, "module", $event)
            },
            "update:nickname": function($event) {
              return _vm.$set(_vm.newsInfo, "nickName", $event)
            },
            "update:headPic": function($event) {
              return _vm.$set(_vm.newsInfo, "headPic", $event)
            },
            "update:head-pic": function($event) {
              return _vm.$set(_vm.newsInfo, "headPic", $event)
            },
            "update:urlList": function($event) {
              _vm.urlList = $event
            },
            "update:url-list": function($event) {
              _vm.urlList = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }