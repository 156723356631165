var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "com_div_bac" },
    [
      !_vm.uploaing
        ? [
            _c(
              "el-upload",
              {
                attrs: {
                  action: "#",
                  "show-file-list": false,
                  "auto-upload": true,
                  "on-change": _vm.uploadBefore,
                  limit: 1,
                  accept: ".vtt, .srt"
                }
              },
              [_c("div", { staticClass: "upload_bac" }, [_vm._v(" 点击上传 ")])]
            )
          ]
        : _c("div", { staticClass: "upload_loading_div" }, [
            _c("i", {
              staticClass: "el-icon-loading",
              staticStyle: { color: "#FFF", "font-size": "18px" }
            })
          ]),
      _vm.urlStr
        ? _c("div", { staticClass: "url_bac" }, [
            _c("div", { staticClass: "url_div" }, [
              _vm._v(_vm._s(_vm.$pathPrefix) + _vm._s(_vm.urlStr))
            ]),
            _c("i", {
              staticClass: "el-icon-error close_img_icon",
              on: {
                click: function($event) {
                  return _vm.deleteUrl()
                }
              }
            })
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }