var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.infoId ? "编辑" : "新增",
        width: "900px",
        "close-on-click-modal": false,
        visible: _vm.visible
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type"
                  }
                },
                _vm._l(_vm.typeDisct, function(item) {
                  return _c(
                    "el-radio",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "首页推荐", prop: "indexShowState" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.indexShowState,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "indexShowState", $$v)
                    },
                    expression: "ruleForm.indexShowState"
                  }
                },
                _vm._l(_vm.recommendDisct, function(item) {
                  return _c(
                    "el-radio",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.title,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片" } },
            [
              _vm.visible
                ? _c("uploadImgCollection", {
                    attrs: {
                      image: _vm.ruleForm.cover,
                      width: "80px",
                      height: "80px"
                    },
                    on: {
                      "update:image": function($event) {
                        return _vm.$set(_vm.ruleForm, "cover", $event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "总浏览量" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _vm._v(" " + _vm._s(_vm.ruleForm.realyBrowNum)),
                _c("span", { staticStyle: { margin: "0 12px" } }, [
                  _vm._v(" +")
                ]),
                _c("el-input", {
                  directives: [
                    {
                      name: "limitInput",
                      rawName: "v-limitInput:positiveInteger",
                      arg: "positiveInteger"
                    }
                  ],
                  staticStyle: { width: "215px", "margin-left": "20px" },
                  attrs: { maxlength: "10" },
                  model: {
                    value: _vm.ruleForm.fixBrowNum,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "fixBrowNum", $$v)
                    },
                    expression: "ruleForm.fixBrowNum"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "权重", prop: "sort" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "limitInput",
                    rawName: "v-limitInput:positiveInteger",
                    arg: "positiveInteger"
                  }
                ],
                staticStyle: { width: "215px" },
                attrs: { maxlength: "10" },
                model: {
                  value: _vm.ruleForm.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "sort", $$v)
                  },
                  expression: "ruleForm.sort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "state" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.state,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "state", $$v)
                    },
                    expression: "ruleForm.state"
                  }
                },
                _vm._l(_vm.stateDisct, function(item) {
                  return _c(
                    "el-radio",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "简介", prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "200",
                  rows: "4",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.content,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "content", $$v)
                  },
                  expression: "ruleForm.content"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" }
              ],
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("ruleForm")
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }