<!-- 预览视频资讯模型 -->
<template>
  <!-- 预览弹窗 -->
  <div class="preview_div" v-if="show" @click="show=false">
    <div @click.stop style="display: flex">
      <articleVideoModel 
        ref="articleVideoModelRef"
        @click.stop
        :height="Math.min(windowSize.height * .8, 667)"
        :videoUrl.sync="previewInfo.videoUrl" 
        :html.sync="previewInfo.html" 
        :title.sync="previewInfo.title" 
        :tags.sync="previewInfo.labelList"
        :currentModule.sync="currentModule"
        :nickname.sync="previewInfo.nickName"
        :date.sync="previewInfo.date" 
        :newsId.sync="newsId" 
        :headPic.sync="previewInfo.headPic"/>
      <div class="but_bac" v-if="previewInfo.videoUrl">
        <el-button type="primary" @click="returnUrl('')" style="margin: 0px" v-preventReClick
          :disabled="!subtitleLang">取消</el-button>
        <el-button type="primary" :disabled="!previewInfo.subtitleZh || subtitleLang == 'zh'" style="margin: 0px"
          @click.stop="returnUrl($pathPrefix+previewInfo.subtitleZh)" v-preventReClick>中文</el-button>
        <el-button type="primary"  :disabled="!previewInfo.subtitleEn || subtitleLang == 'en'" style="margin: 0px"
          @click.stop="returnUrl($pathPrefix+previewInfo.subtitleEn)" v-preventReClick>英文</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import windowSize from '@/mixin/windowSize'
import { auditDetails } from '@/api/newsManage'
export default {
  name: 'previewVideoModel',
  mixins: [windowSize],
  data() {
    return { 
      newsId: '',
      show: false,
      currentModule: 9,
      subtitleLang: '',
      previewInfo: {
        title: '',
        nickName: '',
        date: '',
        html: '',
        labelList: [],
        headPic: '',
      },
      company: {
        icon: "",
        id: "",
        name: "",
        title: ""
      }
    };
  },
  methods: {
    closeModal(event) {
      // 阻止事件冒泡，以免点击内容也触发外层div的关闭
      event.stopPropagation();
      this.show = false;
    },
    async open( info ){
      if(!info.id || !info.module) {
        this.$message("获取参数失败")
        console.log("请求文章参数===",info)
        return
      }
      this.newsId = info.id
      this.currentModule = info.module
      this.subtitleLang = false
      
      this.$openLoading()
      auditDetails( info ).then( res => {
        this.$closeLoading()
        let date = ''
        if(res.data.state == 1001){
          date = res.data.lastTime
        }else{
          if(res.data.preTime){
            date = res.data.preTime
          }else{
            date = res.data.createTime
          }
        }
        this.previewInfo = {
          title: res.data.title || '',
          nickName: res.data.nickName,
          date: date,
          html: res.data.content,
          labelList: res.data.labelList,
          headPic: res.data.headPic,
          videoUrl: res.data.videoInfo.videoUrl,
          subtitleZh: res.data.videoInfo.subtitleZh,
          subtitleEn: res.data.videoInfo.subtitleEn
        }
        this.show = true
      }).catch((error) => {
        this.$closeLoading()
        this.$message("获取详情失败", error)
      })
    },
    
    // set字幕
    returnUrl(url){
      if(url){
        if(this.subtitleLang == url.slice(-2)){
          return
        }
        this.subtitleLang = url.slice(-2)
      }else{
        if(!this.subtitleLang){
          return
        }
        this.subtitleLang = ''
      }
      this.$refs.articleVideoModelRef.setSubtitle(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.preview_div{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.but_bac{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  gap: 12px;
  margin-left: 12px;
}
</style>
