<!-- 新增与修改 -->
<template>
  <el-dialog :title="infoId ? '编辑' : '新增'"
    width='900px'
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="标题(中文)" prop="title">
        <el-input v-model="ruleForm.title"/>
        <div class="mes_error_text" v-if="ruleArr.includes('title')">请输入标题(中文)</div>
      </el-form-item>
      <el-form-item label="标题(英文)">
        <el-input v-model="ruleForm.titleEn"/>
        <div class="mes_error_text" v-if="ruleArr.includes('titleEn')">请输入标题(英文)</div>
      </el-form-item>
      <el-form-item label="时间" prop="preTime">
        <el-date-picker v-model="ruleForm.preTime" type="daterange"  size="mini"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="图片" prop="coverUrl">
        <uploadImgEx v-if="visible" :image.sync="ruleForm.coverUrl" width="80px" height="80px"/>
      </el-form-item>
      <el-form-item label="展会官网">
        <el-input v-model="ruleForm.officeUrl"/>
      </el-form-item>
      <el-form-item label="国家" prop="countryId">
        <el-select v-model="ruleForm.countryId" placeholder="请选择" filterable clearable @focus="getCountryDisct()">
          <el-option v-for="item in countryDisct" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地址(中文)" prop="address">
        <el-input v-model="ruleForm.address"/>
        <div class="mes_error_text" v-if="ruleArr.includes('address')">请输入地址(中文)</div>
      </el-form-item>
      <el-form-item label="地址(英文)">
        <el-input v-model="ruleForm.addressEn"/>
        <div class="mes_error_text" v-if="ruleArr.includes('addressEn')">请输入地址(英文)</div>
      </el-form-item>
      <el-form-item label="简介(中文)" prop="introduction">
        <el-input type="textarea"  v-model="ruleForm.introduction" rows='4'>
        </el-input>
        <div class="mes_error_text" v-if="ruleArr.includes('introduction')">请输入简介(中文)</div>
      </el-form-item>
      <el-form-item label="简介(英文)">
        <el-input type="textarea"  v-model="ruleForm.introductionEn" rows='4'>
        </el-input>
        <div class="mes_error_text" v-if="ruleArr.includes('introductionEn')">请输入简介(英文)</div>
      </el-form-item>
      <el-form-item label="内容(中文)" prop="content">
        <el-input type="textarea" v-model="ruleForm.content" rows='4'>
        </el-input>
        <div class="mes_error_text" v-if="ruleArr.includes('content')">请输入内容(中文)</div>
      </el-form-item>
      <el-form-item label="内容(英文)">
        <el-input type="textarea" v-model="ruleForm.contentEn" rows='4'>
        </el-input>
        <div class="mes_error_text" v-if="ruleArr.includes('contentEn')">请输入内容(英文)</div>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio-group v-model="ruleForm.state">
          <el-radio :label="item.value" v-for="item in stateDisct" :key="item.value">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="web顶部推荐">
        <el-radio-group v-model="ruleForm.webTopRecState">
          <el-radio :label="item.value" v-for="item in webTopRecStateDisct" :key="item.value">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { utcUpdatedDate, dateUpdateUtc  } from '@/utils'
import { exhibitionSave } from '@/api/configManage'
import { systemTerritory } from '@/api/utilsManage'
export default {
  name: 'addOrUpdate',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    let urlCheck = (rule, value, callback) => {
      const regex = /^https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*/;
      if(!value){
        callback();
      }else if (!regex.test(value)) {
        callback(new Error('不是合法的链接地址'));
      } else {
        callback();
      }
    };
    return {
      pageModule: 3,
      infoId: '',
      visible: false,
      stateDisct: [   //在线状态字典
        {value: 1, name: '开启'},
        {value: 2, name: '关闭'},
      ],
      webTopRecStateDisct: [   //在线状态字典
        {value: 1, name: '开启'},
        {value: 0, name: '关闭'},
      ],
      countryDisct: [],
      snowId: '',
      ruleForm: {},
      ruleFormCopy: {
        titleEn: '',
        preTime: [],// 开始结束时间
        coverUrl: '',
        officeUrl: '',
        countryId: '',
        address: '',
        addressEn: '',
        introduction: '',
        introductionEn: '',
        content: '',
        contentEn: '',
        state: 1,
        webTopRecState: 1,
      },
      rules: {
        preTime: [
          { type: 'array', required: true, message: '请选择时间', trigger: 'blur' }
        ],
        coverUrl: [
          { required: true, message: '请选择图片', trigger: 'blur' },
        ],
        officeUrl: [
          { validator: urlCheck, trigger: 'blur' } // 自定义校验
        ],
        countryId: [
          { required: true, message: '请选择国家', trigger: 'change' },
        ],
        // title: [
        //   { required: true, message: '请输入标题', trigger: 'blur' },
        // ],
        // address: [
        //   { required: true, message: '请输入地址', trigger: 'blur' },
        // ],
        // introduction: [
        //   { required: true, message: '请输入简介', trigger: 'blur' },
        // ],
        // content: [
        //   { required: true, message: '请输入内容', trigger: 'blur' },
        // ],
      },
      ruleArr: [],

    }
  },
  watch : {
		'visible' : function(val){
      if(!val){
        this.$emit('update:show', false)
      }
		}
	},
  methods: {
    // 获取归属地
    getCountryDisct(){
      if(this.countryDisct.length == 0){
        systemTerritory({}).then(( data ) => {
          this.countryDisct = data.data.country
          this.countryDisct.unshift({id: '0', name: '全球'})
        })
      }
    },
    init (row) {
      this.getCountryDisct()
      this.ruleForm = {}
      this.visible = true
      if(row.id){
        this.infoId = row.id + ''
        let rowCopy = JSON.parse(JSON.stringify(row))
        rowCopy.preTime = [utcUpdatedDate(rowCopy.startTime), utcUpdatedDate(rowCopy.endTime)]
        rowCopy.titleEn = ""
        rowCopy.addressEn = ""
        rowCopy.introductionEn = ""
        rowCopy.contentEn = ""
        if(rowCopy.titleTrans && rowCopy.titleTrans.length > 0){
          rowCopy.titleEn = rowCopy.titleTrans[0].content
        }
        if(rowCopy.addressTrans && rowCopy.addressTrans.length > 0){
          rowCopy.addressEn = rowCopy.addressTrans[0].content
        }
        if(rowCopy.introTrans && rowCopy.introTrans.length > 0){
          rowCopy.introductionEn = rowCopy.introTrans[0].content
        }
        if(rowCopy.contentTrans && rowCopy.contentTrans.length > 0){
          rowCopy.contentEn = rowCopy.contentTrans[0].content
        }
        this.ruleForm = rowCopy
      }else{
        this.ruleForm = this.ruleFormCopy
      }
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let info = JSON.parse(JSON.stringify(this.ruleForm))
          this.ruleArr = []
          if(!info.title && !info.address &&!info.introduction &&!info.content &&!info.titleEn 
            && !info.addressEn &&!info.introductionEn &&!info.contentEn){
              this.$message("请填写中文或英文标题,地址，简介，内容信息")
              return
          }
          if(info.title || info.address || info.introduction || info.content){
            if(!info.title){
              this.ruleArr.push('title')
              this.$message("请填写标题(中文)")
              return 
            }
            if(!info.address){
              this.ruleArr.push('address')
              this.$message("请填写地址(中文)")
              return 
            }
            if(!info.introduction){
              this.ruleArr.push('introduction')
              this.$message("请填写简介(中文)")
              return 
            }
            if(!info.content){
              this.ruleArr.push('content')
              this.$message("请填写内容(中文)")
              return 
            }
          }
          if(info.titleEn || info.addressEn || info.introductionEn || info.contentEn){
            if(!info.titleEn){
              this.$message("请填写标题(英文)")
              this.ruleArr.push('titleEn')
              return 
            }
            if(!info.addressEn){
              this.$message("请填写地址(英文)")
              this.ruleArr.push('addressEn')
              return 
            }
            if(!info.introductionEn){
              this.$message("请填写简介(英文)")
              this.ruleArr.push('introductionEn')
              return 
            }
            if(!info.contentEn){
              this.$message("请填写内容(英文)")
              this.ruleArr.push('introductionEn')
              return 
            }
          }

          info.startTime = dateUpdateUtc(new Date(info.preTime[0]))
          info.endTime = dateUpdateUtc(new Date(info.preTime[1]))
          info.titleTrans = [ { languageCode: "en", content: "" } ]
          if(info.titleEn) info.titleTrans[0].content = info.titleEn
          info.addressTrans = [ { languageCode: "en", content: "" } ]
          if(info.addressEn) info.addressTrans[0].content = info.addressEn
          info.introTrans = [ { languageCode: "en", content: "" } ]
          if(info.introductionEn) info.introTrans[0].content = info.introductionEn
          info.contentTrans = [ { languageCode: "en", content: "" } ]
          if(info.contentEn) info.contentTrans[0].content = info.contentEn
          
          delete info.preTime
          delete info.titleEn
          delete info.addressEn
          delete info.introductionEn
          delete info.contentEn
          this.$openLoading()
          exhibitionSave( info ).then( res => {
            this.$closeLoading()
            this.visible = false
            this.$message( {message: '操作成功',  type: 'success'} );
            this.$emit('refreshDataList')
          }).catch((error) => {
            this.$closeLoading()
            this.$message.error(error );
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.mes_error_text{
  font-size: 12px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
}
</style>

