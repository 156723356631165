var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑",
        width: "80vw",
        "close-on-click-modal": false,
        visible: _vm.showDia
      },
      on: {
        "update:visible": function($event) {
          _vm.showDia = $event
        }
      }
    },
    [
      _c("div", { staticClass: "page_bac_div" }, [
        _c(
          "div",
          {
            staticClass: "form_div_bac",
            style: { paddingRight: _vm.leftWidth + "px" }
          },
          [
            _c(
              "el-form",
              {
                ref: "newsInfo",
                staticClass: "demo-newsInfo",
                attrs: {
                  model: _vm.newsInfo,
                  rules: _vm.rules,
                  "label-width": "auto"
                }
              },
              [
                _c("el-form-item", { attrs: { label: "所属模块" } }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("stateFilter")(
                        _vm.newsInfo.module,
                        _vm.moduleDisct
                      )
                    )
                  )
                ]),
                [1, 3].includes(_vm.newsInfo.module)
                  ? [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "所属目标市场", prop: "countryList" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择",
                                filterable: "",
                                multiple: "",
                                clearable: ""
                              },
                              on: {
                                focus: function($event) {
                                  return _vm.getCountryDisct()
                                }
                              },
                              model: {
                                value: _vm.newsInfo.countryList,
                                callback: function($$v) {
                                  _vm.$set(_vm.newsInfo, "countryList", $$v)
                                },
                                expression: "newsInfo.countryList"
                              }
                            },
                            _vm._l(_vm.countryDisct, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          ),
                          _vm.newsInfo.countryList.length == 0 &&
                          _vm.EditIsClick
                            ? _c("div", { staticClass: "mes_error_text" }, [
                                _vm._v("请选择目标市场")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属业务或分类",
                            prop: "channelList"
                          }
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              clearable: "",
                              props: _vm.casProps,
                              options: _vm.channelCateDisct,
                              "show-all-levels": false,
                              "change-on-select": true
                            },
                            on: {
                              "visible-change": function($event) {
                                return _vm.getTerritory()
                              }
                            },
                            model: {
                              value: _vm.newsInfo.channelCateList,
                              callback: function($$v) {
                                _vm.$set(_vm.newsInfo, "channelCateList", $$v)
                              },
                              expression: "newsInfo.channelCateList"
                            }
                          }),
                          _c("div", { staticClass: "mes_text_div" }, [
                            _vm._v(" 选中1-6个业务或分类")
                          ]),
                          _vm.newsInfo.channelCateList.length > 6 ||
                          (_vm.newsInfo.channelCateList.length < 1 &&
                            _vm.EditIsClick)
                            ? _c("div", { staticClass: "mes_error_text" }, [
                                _vm._v(" 请选中1-6个业务或分类 ")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm.newsInfo.module != 3
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "标题", prop: "title", required: "" } },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.newsInfo.title,
                            callback: function($$v) {
                              _vm.$set(_vm.newsInfo, "title", $$v)
                            },
                            expression: "newsInfo.title"
                          }
                        }),
                        !_vm.newsInfo.title && _vm.EditIsClick
                          ? _c("div", { staticClass: "mes_error_text" }, [
                              _vm._v("请输入标题")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.newsInfo.module == 1
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "封面", prop: "content" } },
                      [
                        _c("uploadImgUtils", {
                          attrs: {
                            image: _vm.newsInfo.cover,
                            width: "80px",
                            height: "80px"
                          },
                          on: {
                            "update:image": function($event) {
                              return _vm.$set(_vm.newsInfo, "cover", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                [1, 2, 7].includes(_vm.newsInfo.module)
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "* 正文内容", prop: "content" } },
                      [
                        this.newsInfo.crawlerChannel &&
                        this.newsInfo.module === 1
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-bottom": "10px",
                                  display: "flex",
                                  "justify-content": "end",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-right": "10px" } },
                                  [
                                    _c("span", [
                                      _vm._v("系统识别当前语言为: ")
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "bold",
                                          color: "#009"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.detectLanguage))]
                                    )
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "preventReClick",
                                        rawName: "v-preventReClick"
                                      }
                                    ],
                                    attrs: {
                                      type: "warning",
                                      icon: "el-icon-basketball",
                                      loading: _vm.isInTranslate,
                                      disabled:
                                        this.isChinese == null ||
                                        this.isChinese === true
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onTranslate()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isChinese === null
                                            ? "识别语言中"
                                            : _vm.isChinese
                                            ? "目前已是中文"
                                            : "点击一键翻译为中文"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("comWangEditor", {
                          ref: "comWangEditor",
                          attrs: {
                            height: "600px",
                            content: _vm.newsInfo.content,
                            module: _vm.newsInfo.module,
                            id: _vm.newsInfo.id
                          },
                          on: {
                            "update:content": function($event) {
                              return _vm.$set(_vm.newsInfo, "content", $event)
                            },
                            "update:module": function($event) {
                              return _vm.$set(_vm.newsInfo, "module", $event)
                            },
                            "update:id": function($event) {
                              return _vm.$set(_vm.newsInfo, "id", $event)
                            }
                          }
                        }),
                        _vm.newsInfo.content == "<p><br></p>" && _vm.EditIsClick
                          ? _c("div", { staticClass: "mes_error_text" }, [
                              _vm._v("请输入内容")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "正文内容",
                          prop: "content",
                          required: ""
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", rows: 4 },
                          model: {
                            value: _vm.newsInfo.content,
                            callback: function($$v) {
                              _vm.$set(_vm.newsInfo, "content", $$v)
                            },
                            expression: "newsInfo.content"
                          }
                        }),
                        !_vm.newsInfo.content && _vm.EditIsClick
                          ? _c("div", { staticClass: "mes_error_text" }, [
                              _vm._v("请输入内容")
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                _vm.newsInfo.module == 3
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "正文图片", prop: "content" } },
                      [
                        _c("uploadImgUtils", {
                          attrs: {
                            limit: 9,
                            images: _vm.urlList,
                            width: "80px",
                            height: "80px"
                          },
                          on: {
                            "update:images": function($event) {
                              _vm.urlList = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.newsInfo.module != 2 && _vm.newsInfo.module != 7
                  ? [
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择标签", prop: "labelList" } },
                        [
                          _c("selectTag", {
                            attrs: { labelList: _vm.newsInfo.labelList },
                            on: {
                              "update:labelList": function($event) {
                                return _vm.$set(
                                  _vm.newsInfo,
                                  "labelList",
                                  $event
                                )
                              },
                              "update:label-list": function($event) {
                                return _vm.$set(
                                  _vm.newsInfo,
                                  "labelList",
                                  $event
                                )
                              }
                            }
                          }),
                          _vm._v(" 最多可添加20个标签 ")
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm.newsInfo.module != 2
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "权重排序", prop: "infoSort" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c("el-input", {
                              directives: [
                                {
                                  name: "limitInput",
                                  rawName: "v-limitInput:positiveInteger",
                                  arg: "positiveInteger"
                                }
                              ],
                              staticStyle: { width: "215px" },
                              attrs: { maxlength: "10" },
                              model: {
                                value: _vm.newsInfo.infoSort,
                                callback: function($$v) {
                                  _vm.$set(_vm.newsInfo, "infoSort", $$v)
                                },
                                expression: "newsInfo.infoSort"
                              }
                            }),
                            _c("span", { staticClass: "mes_text_div" }, [
                              _vm._v("数值越大，排序越靠前")
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.newsInfo.module != 2
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "权重有效时间", prop: "infoSort" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.sortExpireRadio,
                                  callback: function($$v) {
                                    _vm.sortExpireRadio = $$v
                                  },
                                  expression: "sortExpireRadio"
                                }
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("设置有效时间")
                                ]),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("不设置有效时间")
                                ])
                              ],
                              1
                            ),
                            _c("el-date-picker", {
                              staticStyle: { "margin-left": "16px" },
                              attrs: {
                                disabled: _vm.sortExpireRadio == 2,
                                type: "datetime",
                                placeholder: "选择时间",
                                "default-time": "23:59:59",
                                "picker-options": _vm.pickerOptions()
                              },
                              model: {
                                value: _vm.newsInfo.infoSortExpire,
                                callback: function($$v) {
                                  _vm.$set(_vm.newsInfo, "infoSortExpire", $$v)
                                },
                                expression: "newsInfo.infoSortExpire"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.sortExpireRadio == 1 &&
                        !_vm.newsInfo.infoSortExpire &&
                        _vm.EditIsClick
                          ? _c("div", { staticClass: "mes_error_text" }, [
                              _vm._v("请选择定时发布时间")
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "phone_righ_bac" },
          [
            _c("articleModel", {
              staticClass: "model_com",
              attrs: {
                newsId: _vm.newsInfo.id,
                height: _vm.modelHeight,
                title: _vm.newsInfo.title,
                nickname: _vm.newsInfo.nickName,
                date: _vm.newsInfo.date,
                html: _vm.newsInfo.content,
                currentModule: _vm.newsInfo.module,
                urlList: _vm.urlList,
                headPic: _vm.newsInfo.headPic,
                tags: _vm.newsInfo.labelList,
                company: _vm.company
              },
              on: {
                "update:title": function($event) {
                  return _vm.$set(_vm.newsInfo, "title", $event)
                },
                "update:nickname": function($event) {
                  return _vm.$set(_vm.newsInfo, "nickName", $event)
                },
                "update:date": function($event) {
                  return _vm.$set(_vm.newsInfo, "date", $event)
                },
                "update:html": function($event) {
                  return _vm.$set(_vm.newsInfo, "content", $event)
                },
                "update:currentModule": function($event) {
                  return _vm.$set(_vm.newsInfo, "module", $event)
                },
                "update:current-module": function($event) {
                  return _vm.$set(_vm.newsInfo, "module", $event)
                },
                "update:urlList": function($event) {
                  _vm.urlList = $event
                },
                "update:url-list": function($event) {
                  _vm.urlList = $event
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.showDia = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" }
              ],
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("newsInfo")
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }