<!-- 新增与修改 -->
<template>
  <el-dialog :title="infoId ? '编辑' : '新增'"
    width='900px'
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="话题标题" prop="title">
        <el-input v-model="ruleForm.title"/>
      </el-form-item>
      <el-form-item label="参与量" prop="fixJoinNum">
        <el-input v-model="ruleForm.fixJoinNum" style="width: 215px;" maxlength="10" v-limitInput:positiveInteger/>
      </el-form-item>
      <el-form-item label="总浏览量">
        <div style="display: flex;align-items: center;">
          {{ ruleForm.reallyBrowNum }}<span style="margin: 0 12px"> +</span>
          <el-input v-model="ruleForm.fixBrowNum" style="width: 215px;" maxlength="10" v-limitInput:positiveInteger/>
        </div>
      </el-form-item>
      <el-form-item label="权重" prop="sort">
        <el-input v-model="ruleForm.sort" style="width: 215px;" maxlength="10" v-limitInput:positiveInteger/>
      </el-form-item>
      <el-form-item label="介绍">
        <el-input type="textarea"  v-model="ruleForm.intro" rows='4'>
        </el-input>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio-group v-model="ruleForm.state">
          <el-radio :label="item.value" v-for="item in stateDisct" :key="item.value">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { countryCodeList } from '@/utils/countryCode.js'
import { hotTopicSave } from '@/api/configManage'
export default {
  name: 'addOrUpdate',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      infoId: '',
      visible: false,
      stateDisct: [   //在线状态字典
        {value: 1, name: '开启'},
        {value: 2, name: '关闭'},
      ],
      ruleForm: {},
      ruleFormCopy: {
        id: '',
        title: '',
        fixJoinNum: 0,
        fixBrowNum: 0,
        reallyBrowNum: 0,
        sort: 1,
        state: 1,
        intro: '',
      },
      countryDisct: [],
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '请输入权重', trigger: 'blur' },
        ],
      },

    }
  },
  watch : {
		'visible' : function(val){
      if(!val){
        this.$emit('update:show', false)
      }
		}
	},
  methods: {
    init (row) {
      this.ruleForm = {}
      this.visible = true
      if(row.id){
        this.infoId = row.id + ''
        let rowCopy =  JSON.parse(JSON.stringify(row))
        rowCopy.intro = rowCopy.introduction
        delete rowCopy.introduction
        this.ruleForm = rowCopy
      }else{
        this.ruleForm = this.ruleFormCopy
      }
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let infoData = JSON.parse(JSON.stringify(this.ruleForm))
          if(!infoData.fixJoinNum) infoData.fixJoinNum = 0
          if(!infoData.fixBrowNum) infoData.fixBrowNum = 0
          if(!infoData.reallyBrowNum) infoData.reallyBrowNum = 0
          if(!infoData.id) delete infoData.id
          this.$openLoading()
          hotTopicSave( infoData ).then( res => {
            this.$closeLoading()
            this.visible = false
            this.$message( {message: '操作成功',  type: 'success'} );
            this.$emit('refreshDataList')
          }).catch((error) => {
            this.$closeLoading()
            this.$message.error(error );
          })
        } else {
          return false;
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.mes_error_text{
  font-size: 12px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
}
</style>

