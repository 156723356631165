var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "转发",
        width: "900px",
        "close-on-click-modal": false,
        visible: _vm.show
      },
      on: {
        "update:visible": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c("div", { staticClass: "con_title_div" }, [_vm._v("转发TG")]),
      _c(
        "el-form",
        {
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.auditKuai, "label-width": "100px" }
        },
        [
          _c("el-form-item", { attrs: { label: "发布时间" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.auditKuai.radio2,
                      callback: function($$v) {
                        _vm.$set(_vm.auditKuai, "radio2", $$v)
                      },
                      expression: "auditKuai.radio2"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("立即发布")
                    ]),
                    _c("el-radio", { attrs: { label: 2 } }, [
                      _vm._v("定时发布")
                    ])
                  ],
                  1
                ),
                _c("el-date-picker", {
                  staticStyle: { "margin-left": "16px" },
                  attrs: {
                    disabled: _vm.auditKuai.radio2 == 1,
                    type: "datetime",
                    placeholder: "选择定时发布时间",
                    "default-time": "12:00:00",
                    "picker-options": _vm.pickerOptions()
                  },
                  model: {
                    value: _vm.auditKuai.preTime,
                    callback: function($$v) {
                      _vm.$set(_vm.auditKuai, "preTime", $$v)
                    },
                    expression: "auditKuai.preTime"
                  }
                })
              ],
              1
            ),
            _vm.auditKuai.radio2 == 2 && !_vm.auditKuai.preTime && _vm.tgClick
              ? _c("div", { staticClass: "mes_error_text" }, [
                  _vm._v("请选择定时发布时间")
                ])
              : _vm._e()
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "转载至tg群" } },
            [
              _c("multipleTgCom", {
                attrs: { list: _vm.tgGroupList, listDisct: _vm.telegramDisct },
                on: {
                  "update:list": function($event) {
                    _vm.tgGroupList = $event
                  },
                  focus: _vm.getTelegramGroups
                }
              }),
              _vm.tgGroupList.length == 0 && _vm.tgClick
                ? _c("div", { staticClass: "mes_error_text" }, [
                    _vm._v("请选择需要转发的TG群")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "but_bac_div" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitKuaiForm("auditKuai")
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }