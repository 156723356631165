var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "批量快速审核",
        width: "750px",
        "close-on-click-modal": false,
        visible: _vm.show
      },
      on: {
        "update:visible": function($event) {
          _vm.show = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-ruleForm",
          staticStyle: { height: "350px" },
          attrs: { model: _vm.auditKuai, "label-width": "100px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "审核状态", prop: "content" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.auditKuai.radio1,
                    callback: function($$v) {
                      _vm.$set(_vm.auditKuai, "radio1", $$v)
                    },
                    expression: "auditKuai.radio1"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1000 } }, [
                    _vm._v("待审核")
                  ]),
                  _c("el-radio", { attrs: { label: 1001 } }, [_vm._v("通过")]),
                  _c("el-radio", { attrs: { label: 1004 } }, [_vm._v("拒绝")])
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _vm.auditKuai.radio1 == 1004
                    ? _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入拒绝内容",
                          maxlength: "100",
                          rows: "4"
                        },
                        model: {
                          value: _vm.auditKuai.inputValue1,
                          callback: function($$v) {
                            _vm.$set(_vm.auditKuai, "inputValue1", $$v)
                          },
                          expression: "auditKuai.inputValue1"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.auditKuai.radio1 == 1004 &&
              !_vm.auditKuai.inputValue1 &&
              _vm.isClick
                ? _c("div", { staticClass: "mes_error_text" }, [
                    _vm._v("请输入拒绝内容")
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.currentModule != 7 && _vm.auditKuai.radio1 != 1004
            ? _c("el-form-item", { attrs: { label: "所属创作者" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _vm.show
                      ? _c("selectUserDuo", {
                          staticStyle: {
                            width: "400px",
                            "margin-right": "20px"
                          },
                          attrs: {
                            maxLength: _vm.idList.length,
                            ids: _vm.auditKuai.authorIds
                          },
                          on: {
                            "update:ids": function($event) {
                              return _vm.$set(
                                _vm.auditKuai,
                                "authorIds",
                                $event
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#84909D",
                      "line-height": "16px",
                      "margin-top": "6px",
                      width: "70%"
                    }
                  },
                  [
                    _vm._v(
                      " 可通过搜索创作者手机号、邮箱地址、昵称。 当前已选择"
                    ),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm.idList.length))
                    ]),
                    _vm._v("篇文章， 最多可选择"),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(_vm._s(_vm.idList.length))
                    ]),
                    _vm._v(
                      "个作者，选择作者后按顺序分配 已选择的创作者数量少于文章数量，将按顺序重复分配创作者。 "
                    )
                  ]
                ),
                _vm.auditKuai.authorIds.length == 0 && _vm.isClick
                  ? _c("div", { staticClass: "mes_error_text" }, [
                      _vm._v("请选择作者")
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.auditKuai.radio1 == 1001
            ? _c("el-form-item", { attrs: { label: "发布时间" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.auditKuai.radio2,
                          callback: function($$v) {
                            _vm.$set(_vm.auditKuai, "radio2", $$v)
                          },
                          expression: "auditKuai.radio2"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("立即发布")
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("定时发布")
                        ])
                      ],
                      1
                    ),
                    _c("el-date-picker", {
                      staticStyle: { "margin-left": "16px" },
                      attrs: {
                        disabled: _vm.auditKuai.radio2 == 1,
                        type: "datetime",
                        placeholder: "选择定时发布时间",
                        "default-time": "12:00:00",
                        "picker-options": _vm.pickerOptions()
                      },
                      model: {
                        value: _vm.auditKuai.preTime,
                        callback: function($$v) {
                          _vm.$set(_vm.auditKuai, "preTime", $$v)
                        },
                        expression: "auditKuai.preTime"
                      }
                    })
                  ],
                  1
                ),
                _vm.auditKuai.radio2 == 2 && !_vm.auditKuai.preTime
                  ? _c("div", { staticClass: "mes_error_text" }, [
                      _vm._v("请选择定时发布时间")
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "content" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "100",
                  rows: "4",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.auditKuai.inputValue2,
                  callback: function($$v) {
                    _vm.$set(_vm.auditKuai, "inputValue2", $$v)
                  },
                  expression: "auditKuai.inputValue2"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.show = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" }
              ],
              attrs: {
                type: "primary",
                disabled: _vm.auditKuai.radio1 == 1000
              },
              on: {
                click: function($event) {
                  return _vm.submitKuaiForm("auditKuai")
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }