<template>
  <el-dialog
    :title="title"
    :visible="!!src"
    width="820px"
    :close-on-click-modal="false"
    append-to-body
    destroy-on-close
    @close="closeHandler"
  >
    <div><img ref="image" :class="$style.image" :src="src"></div>
    <span slot="footer">
      <el-button @click="closeHandler">取 消</el-button>
      <el-button type="primary" @click="cropHandler">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import Cropper from 'cropperjs';
  import 'cropperjs/dist/cropper.css';

  export default {
    name: 'cropImage',
    props: {
      title: { type: String, default: '图片裁剪' },
      source: {
        type: [String, File],
      },
      aspectRatio: { type: Number, default: 1 },
    },
    data() {
      return {
        url: null,
      };
    },
    computed: {
      src() {
        return this.url || this.source;
      },
    },
    watch: {
      source(value) {
        if (value instanceof File) {
          this.url = URL.createObjectURL(value);
        }
      },
      url(n, oldValue) {
        oldValue && URL.revokeObjectURL(oldValue);
      },
      src: {
        async handler(val) {
          this.__cropper && this.__cropper.destroy();
          if (!val) {
            return;
          }

          await this.$nextTick();
          if (this.$refs.image) {
            this.__cropper = new Cropper(this.$refs.image, {
              viewMode: 1,
              autoCropArea: 1,
              aspectRatio: this.aspectRatio,
            });
          }
        },
        immediate: true,
      },
    },
    methods: {
      closeHandler() {
        this.url = null;
        this.$emit('cancel');
      },
      cropHandler() {
        this.__cropper && this.__cropper.getCroppedCanvas().toBlob(blob => {
          if (blob) {
            let name = 'croppedImage.jpg', type = 'image/jpeg';
            if (this.source instanceof File) {
              name = this.source.name;
              type = this.source.type;
            }
            const file = new File([blob], name, { type });
            this.$emit('crop', file);
            this.closeHandler();
          }
        });
      },
    },
    destroyed() {
      this.url && URL.revokeObjectURL(this.url);
    },
  };
</script>

<style module>
  .image {
    display: block;
    max-width: 100%;
  }
</style>
