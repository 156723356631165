<template>
  <!-- 编辑/创建文章 -->
  <div class="page_bac_div">
    <!-- 左边表单区 -->
    <div class="form_div_bac" :style="{paddingRight: leftWidth +'px'}">
      <div style="display:flex;align-items: center;justify-content: space-between;">
        <div class="head_back" @click="$router.back()" v-if="InfoId">
          <i class="el-icon-arrow-left" style="font-size:18px;font-weight: 600;"></i>
          <h4>返回</h4>
        </div> 
        <h2>{{ InfoId ? '编辑草稿' : '创建文章' }}</h2>
      </div>
      <el-form :model="newsInfo" :rules="rules" ref="newsInfo" label-width="140px" class="demo-newsInfo">
        <el-form-item label="所属模块">
          <el-select v-model="newsInfo.module" placeholder="请选择" :disabled="timeReturn(InfoId)" @change="moduleChange()">
            <el-option :label="item.name" :value="item.value" 
              v-for="item in moduleDisct" :key="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="* 所属创作者账号" v-if="newsInfo.module == 1">
          <div style="display: flex;align-items: center;">
            <selectUser 
              :id.sync="newsInfo.authorId" 
              :nickName.sync="newsInfo.nickName" 
              :headPic.sync="newsInfo.headPic" 
              @userChange="userChange"
              style="width: 300px;"/>
            <div style="display: flex;align-items: center;margin-left: 20px;">
              <span>企业：</span>
              <selectCompany 
                :disabled="!newsInfo.authorId"
                placeholder="关联独角兽公司"
                :id.sync="newsInfo.companyId"
                :name.sync="newsInfo.companyName" 
                style="width: 350px;"/>
            </div>
          </div>
          <div class="mes_error_text" v-if="!newsInfo.authorId && confShow">请选择作者</div>
        </el-form-item>
        <!-- <el-form-item label="关联独角兽" v-if="newsInfo.module == 1">
          <div style="display: flex;align-items: center;">
            <selectCompany 
              :id.sync="newsInfo.companyId" 
              :name.sync="newsInfo.companyName" 
              style="width: 400px;"/>
            <span class="mes_text_div">可通过公司名称搜索</span>
          </div>
        </el-form-item> -->
        <template  v-if="newsInfo.module != 2">
          <el-form-item label="* 所属目标市场" prop="countryList">
            <el-select v-model="newsInfo.countryList" placeholder="请选择" @focus="getCountryDisct()"
              filterable multiple clearable  style="width: 100%;">
              <el-option v-for="item in countryDisct" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <div class="mes_error_text" v-if="newsInfo.countryList.length==0 && confShow">请选择目标市场</div>
          </el-form-item>
          <el-form-item label="* 所属业务或分类" prop="channelCateList">
            <el-cascader
              style="width: 100%;"
              filterable clearable
              :props="casProps"
              :options="channelCateDisct"
              v-model="newsInfo.channelCateList"
              :show-all-levels="false"
              :change-on-select="true"
              @visible-change="getTerritory()">
            </el-cascader>
            <span class="mes_text_div"> 选中1-6个业务或分类</span>
            <div class="mes_error_text" v-if="(newsInfo.channelCateList.length < 1 || newsInfo.channelCateList.length > 6) && confShow">
              请选中1-6个业务或分类
            </div>
          </el-form-item>
        </template>
        <el-form-item label="文章语言" prop="originLang">
          <el-select v-model="newsInfo.originLang" placeholder="请选择" @focus="getLanguage()"
            filterable clearable>
            <el-option v-for="item in langDisct" :key="item.code" :label="item.name" :value="item.code">
            </el-option>
          </el-select>
          <div class="mes_error_text" v-if="!newsInfo.originLang && confShow">请选择文章语言</div>
        </el-form-item>


        <!-- <el-form-item label="标题" prop="title" v-if="newsInfo.module!=3" required>
          <el-input v-model="newsInfo.title" />
          <div class="mes_error_text" v-if="!newsInfo.title && confShow">请输入标题</div>
        </el-form-item>
        <el-form-item label="正文内容" prop="content" required>
          <comWangEditor 
            :content.sync="newsInfo.content"
            :module.sync="newsInfo.module"
            :id.sync="newsInfo.id"
            />
          <div class="mes_error_text" v-if="newsInfo.content == '<p><br></p>' && confShow">请输入内容</div>
        </el-form-item> -->

        <el-form-item label="封面" prop="content" v-if="newsInfo.module == 1">
          <uploadImgUtils :image.sync="newsInfo.cover" width="80px" height="80px" :userId.sync="snowId"/>
        </el-form-item>

        <el-form-item label="* 标题" prop="title">
          <el-input v-model="newsInfo.title"/>
          <div class="mes_error_text" v-if="!newsInfo.title && confShow">请输入标题</div>
        </el-form-item>
        <el-form-item label="正文内容" prop="content" required >
          <comWangEditor
            height="600px"
            :content.sync="newsInfo.content"
            :module.sync="newsInfo.module"
            :id.sync="snowId"
            />
          <div class="mes_error_text" v-if="(newsInfo.content == '<p><br></p>' || !newsInfo.content) && confShow">请输入内容</div>
        </el-form-item>
        <!-- <template v-else>
          <el-form-item label="正文内容" prop="content" required>
            <el-input v-model="newsInfo.content" type="textarea" :rows="4"/>
            <div class="mes_error_text" v-if="!newsInfo.content && confShow">请输入内容</div>
          </el-form-item>
          <el-form-item label="正文封面" prop="content">
            <uploadImgUtils :limit="9" :images.sync="urlList" width="80px" height="80px"/>
          </el-form-item>
        </template> -->
        

        <template  v-if="newsInfo.module != 2">
          <el-form-item label="选择标签" prop="labelList" >
            <selectTag :labelList.sync="newsInfo.labelList"/>
            最多可添加20个标签
          </el-form-item>
          <el-form-item label="权重排序" prop="infoSort">
            <div style="display: flex;align-items: center;">
              <el-input v-model="newsInfo.infoSort" style="width: 215px;" maxlength="10" v-limitInput:positiveInteger/>
              <span class="mes_text_div">数值越大，排序越靠前</span>
            </div>
          </el-form-item>

          <el-form-item label="权重有效时间" prop="infoSort">
            <div style="display: flex;align-items: center;">
              <el-radio-group v-model="sortExpireRadio"> 
                <el-radio :label="1">设置有效时间</el-radio>
                <el-radio :label="2">不设置有效时间</el-radio>
              </el-radio-group>
              <el-date-picker 
                style="margin-left: 16px;"
                :disabled="sortExpireRadio == 2"
                v-model="newsInfo.infoSortExpire"
                type="datetime"
                placeholder="选择时间"
                default-time="23:59:59"
                :picker-options="pickerOptions()">
              </el-date-picker>
            </div>
            <div class="mes_error_text" v-if="sortExpireRadio==1 && !newsInfo.infoSortExpire  && confShow">请选择定时发布时间</div>
          </el-form-item>


        </template>
      </el-form>
      <div class="fen_ge_xian"></div>
      <div style="display: flex;align-items: center;justify-content: center;margin-top: 40px;">
        <el-button size="medium" type="primary" @click="submitFun" :disabled="subLoading">确认</el-button>
        <el-button size="medium" type="warning" @click="cancelFun">取消</el-button>
        <el-button size="medium" type="success" @click="saveFun" :disabled="subLoading">存储为草稿</el-button>
      </div>
    </div>
    <!-- 手机模型 -->
    <div class="phone_righ_bac">
      <articleModel 
        class="creat_model_com"
        :height="Math.min(windowSize.height * .8, 667)"
        :html.sync="newsInfo.content" 
        :title.sync="newsInfo.title" 
        :tags.sync="newsInfo.labelList"
        :currentModule.sync="newsInfo.module"
        :nickname.sync="newsInfo.nickName"
        :headPic.sync="newsInfo.headPic"
        :urlList.sync="urlList"/>
    </div>
  </div>
</template>

<script>
import windowSize from '@/mixin/windowSize'
import { utcUpdatedDate, dateUpdateUtc  } from '@/utils'
import { auditDetails } from '@/api/newsManage'
import { systemTerritory, systemTerritoryTree, systemLanguage, userCompany,systemSnowId  } from '@/api/utilsManage'
import { auditEdit } from '@/api/newsManage'
export default {
  name: 'createArticle',
  mixins: [windowSize],
  data () {
    return {
      snowId: '',// 雪花id
      InfoId: '',
      leftWidth: 400,
      countryDisct: [], // 目标市场枚举
      channelCateDisct: [], // 目标业务分类枚举
      channelIdList: [], // 目标type1分类枚举
      langDisct: [],// 语言列表
      moduleDisct: [   //购买方式
        {value: 1, name: '资讯'},
        {value: 2, name: '快讯'},
      ],
      tagInput: '',// 标签输入
      tagList: [],// 查询到的标签列表
      contryIds: [], // 保存的市场id
      channelCateIds: [], // 保存的业务id
      newsInfoCopy: {
        module: 1, // 所属模块
        companyId: '', // 公司id
        authorId: '',// 创作者id
        countryList: ['0'],// 市场
        channelCateList: [],// 业务
        originLang: 'zh',//语言
        title: '',// 标题
        draftTitle: '',// 草稿标题
        cover: '', // 封面
        content: '',// 富文本内容
        labelList: [],// 标签
        infoSort: 1,// 权重
        infoSortExpire: '',
        createTime: '',//发布时间
      },
      sortExpireRadio: 1,
      newsInfo:  {
        module: parseInt(this.$route.query.module), // 所属模块
        companyId: '', // 公司id
        authorId: '',// 创作者id
        countryList: [],// 市场
        channelCateList: [],// 业务
        originLang: '',//语言
        title: '',// 标题
        draftTitle: '',// 草稿标题
        cover: '', // 封面
        content: '',// 富文本内容
        labelList: [],// 标签
        infoSort: 1,// 权重
        infoSortExpire: '',
        createTime: '',//发布时间
      },
      urlList: [],
      rules: {
        authorId: [
          { required: true, message: '请选择创作者', trigger: 'blur' },
        ],
        originLang: [
          { required: true, message: '请选择语言', trigger: 'change' },
        ],
        // labelList: [
        //   { type: 'array', required: true, message: '请至少添加一个标签', trigger: 'change' }
        // ],
        infoSort: [
          { required: true, message: '请输入权重排序值', trigger: 'blur' },
        ],
      },
      confShow: false,
      selectTags: [], // 选中的标签列表
      casProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
        emitPath: false
      },
      subLoading: false,// 提交loading
    }
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    }
  },
  created () {
    this.InfoId = this.$route.query.id
    if(!this.InfoId){
      this.getsnowID()
    }else{
      this.snowId = this.InfoId
    }
    this.getTerritory()
    this.getCountryDisct()
    this.getLanguage()
  },
  mounted () {
    this.initUpdate()
    this.$nextTick( () => {
      this.setleftWidth()
    })
    window.addEventListener('resize', this.setleftWidth);
  },
  methods: {
    async getsnowID(){
      let res = await systemSnowId().catch( error => { 
        this.$message('获取唯一id失败，请刷新页面获取')
       });
      this.snowId = res.data.data
    },
    pickerOptions(){
      return {
        disabledDate(time) {
          const date = new Date();
          const oneday = date.getTime();
          return time.getTime() < oneday - 86400000;
        },
        selectableRange: ((time) => {
          let date1 = new Date(this.newsInfo.infoSortExpire)
          let data = new Date();
          if(date1.getFullYear() === data.getFullYear() &&
            date1.getMonth() === data.getMonth() &&
            date1.getDate() === data.getDate()){
            let hour = data.getHours();
            let minute = data.getMinutes();
            let second = data.getSeconds();
            return [`${hour}:${minute}:${second} - 23:59:59`]
          }else{
            return [`00:00:00 - 23:59:59`]
          }
        })(),
      }
    },
    // 设置模型高度
    setleftWidth(){
      const righBac = document.querySelectorAll(".creat_model_com")[0];
      this.leftWidth = righBac.offsetWidth || 400
      this.leftWidth += 40
    },
    timeReturn(value){
      if(value){
        return true
      }else{
        return false
      }
    },
    // 获取目标市场
    getCountryDisct(){
      if(this.countryDisct.length == 0){
        systemTerritory({isLoadCate: true}).then(( data ) => {
          this.countryDisct = data.data.country
          this.countryDisct.unshift({id: '0', name: '全球'})
        })
      }
    },
    // 获取目标业务枚举列表1
    getTerritory(){
      if(this.channelCateDisct.length == 0){
        systemTerritoryTree({}).then(( data ) => {
          this.channelIdList = []
          this.channelCateDisct = []
          this.channelCateDisct = this.chuliChnnel(data.data)
        })
      }
    },
    // 处理channelCateDisct数据
    chuliChnnel(list){
      list.forEach( item => {
        if(item.dataType == 1){
          this.channelIdList.push(item.id)
        }
        if(item.children && item.children.length>0){
          this.chuliChnnel(item.children)
        }else{
          delete item.children
        }
      })
      return list
    },
    getLanguage(){
      if(this.langDisct.length == 0){
        systemLanguage({pageNumber: 1, pageSize: 500}).then(( data ) => {
          this.langDisct = data.data
        })
      }
    },
    // 选择作者改变时关联独角兽
    userChange(item){
      console.log("看看",item)
      // 有关联的独角兽
      if(item.infoCompanyId){
        let data = {
          pageNumber: 1,
          pageSize: 10,
          id: item.infoCompanyId,
        }
        userCompany(data).then(( res ) => {
          let list = res.data
          if(list.length > 0){
            this.newsInfo.companyName = list[0].name
            this.newsInfo.companyId = item.infoCompanyId
          }else{
            this.$message.error('没找到对应关联独角兽')
            this.newsInfo.companyId = item.infoCompanyId
          }
        }).catch((error) => {
          this.$message.error('获取关联独角兽名称失败'+ error)
        })
      }else{
        this.newsInfo.companyId = ''
        this.newsInfo.companyName = ''
      }
    },
    // 初始化
    initUpdate () {
      this.urlList = []
      if(this.InfoId){ // 草稿
        let info = { 
          id: this.InfoId, 
          module: this.$route.query.module, 
          isDraft: true, 
          isRelation: true 
        }
        this.getDetails(info)
      }else{
        this.newsInfo = this.newsInfoCopy
        // this.newsInfo.content = `<pre><em> <img src=\"https://imagenesyogonet.b-cdn.net/data/imagenes/2024/05/16/64326/1715883022-terre-haute-casino-indiana-altitude-bar.jpg\" alt=\"\" width=\"1920\" height=\"1080\"></img></em><br>The property's new Altitude Bar</br></pre>\n`
      } 
    },
    // 获取文章详情
    getDetails(info){
      this.$openLoading()
      auditDetails( info ).then( res => {
        this.$closeLoading()
        this.newsInfo = res.data
        console.log("资讯===",this.newsInfo)
        if(this.newsInfo.module == 1){
          if(res.data.countryList && res.data.countryList.length > 0 ){ //市场
            let ids = []
            res.data.countryList.forEach( item => {
              ids.push(item.id)
            }); 
            this.newsInfo.countryList = ids
            this.contryIds = ids
          }else{
            this.newsInfo.countryList = []
            this.contryIds = []
          }
          this.newsInfo.channelCateList = []
          this.channelCateIds = []
          if(res.data.cateList && res.data.cateList.length > 0 ){ // 分类
            res.data.cateList.forEach( item => {
              this.newsInfo.channelCateList.push(item.id)
              this.channelCateIds.push(item.id)
            }); 
          }
          if(res.data.channelList && res.data.channelList.length > 0 ){ // 业务
            res.data.channelList.forEach( item => {
              this.newsInfo.channelCateList.push(item.id)
              this.channelCateIds.push(item.id)
            }); 
          }

          if(!res.data.labelList){ // 标签
            this.newsInfo.labelList = []
          }
          if(this.newsInfo.infoSortExpire) { //是否有设置权重时间
            this.newsInfo.infoSortExpire = utcUpdatedDate(this.newsInfo.infoSortExpire)
            this.sortExpireRadio = 1
          }else{
            this.sortExpireRadio = 2
          }
        } 
        if(this.newsInfo.createTime){
          this.newsInfo.createTime = utcUpdatedDate(this.newsInfo.createTime)
        }
        if(this.newsInfo.module != 1){
          if(this.newsInfo.cover) { 
            this.urlList = this.newsInfo.cover.split(',')
          }else{
            this.urlList = []
          }
        }
        if(this.newsInfo.authorId == 0) this.newsInfo.authorId = ''
        if(this.newsInfo.companyId == 0) {
          this.newsInfo.companyId = ''
        }else{
          this.newsInfo.companyId == res.data.companyId
          this.newsInfo.companyName == res.data.companyName
        }
        console.log("资讯",this.newsInfo.companyId)
      }).catch((error) => {
        this.$closeLoading()
        this.$message("获取详情失败"+ error)
      })
    },
    // 验证参数
    verifyFun(){
      if(!this.newsInfo.authorId && this.newsInfo.module == 1) {
        this.$message("请选择作者！")
        return false
      }
      if(this.newsInfo.module == 1){
        if(this.newsInfo.countryList.length==0) return false
        let num = this.newsInfo.channelCateList.length
        if(num > 6 || num < 1) { 
          this.$message("请选中1-6个业务或分类！")
          return false
        }
        if(this.sortExpireRadio==1 && !this.newsInfo.infoSortExpire) { 
          this.$message("请选择权重有效时间！")
          return false
        }
      }
      if(!this.newsInfo.title)  {
        this.$message("请填写标题！")
        return false
      }
      if(!this.newsInfo.content || this.newsInfo.content == '<p><br></p>') {
        this.$message("请填写内容！")
        return false
      }
      return true
    },
    chuLiData(){
      let copyInfo = JSON.parse(JSON.stringify(this.newsInfo))
      // 没修改就不返回给后端
      // if(this.InfoId){
      //   if( JSON.stringify(this.channelCateIds) === JSON.stringify(this.newsInfo.channelCateList)){
      //     delete this.newsInfo.countryList
      //   }
      // }
      if(copyInfo.countryList && copyInfo.countryList.length > 0){
        let ids = []
        copyInfo.countryList.forEach( id => {
          ids.push({id: parseInt(id)})
        })
        copyInfo.countryList = ids
      }
      if(copyInfo.channelCateList && copyInfo.channelCateList.length > 0){
        copyInfo.cateList = []// 分类
        copyInfo.channelList = []
        copyInfo.channelCateList.forEach( id => {
          if(this.channelIdList.includes(id) ){
            copyInfo.cateList.push({id: id})
          }else{
            copyInfo.channelList.push({id: id})
          }
        })
      }
      if(copyInfo.module == 2){
        delete copyInfo.companyId
        delete copyInfo.countryList
        delete copyInfo.cateList
        delete copyInfo.channelList
        delete copyInfo.labelList
        delete copyInfo.infoSort
        delete copyInfo.authorId
        if(this.urlList.length > 0){
          copyInfo.cover = this.urlList.join(',')
        }else{
          copyInfo.cover = ''
        }
      }
      copyInfo.createTime = dateUpdateUtc(new Date(Date.now()))
      if(copyInfo.labelList && copyInfo.labelList.length==0){
        copyInfo.labelList = [{id: -1}]
      }
      if(this.sortExpireRadio==1){
        copyInfo.infoSortExpire = dateUpdateUtc(new Date(copyInfo.infoSortExpire))
      }else{
        copyInfo.infoSortExpire = ""
      }
      delete copyInfo.channelCateList
      return copyInfo
    },
    // 提交1
    submitFun(type){
      this.$refs['newsInfo'].validate((valid) => {
        if (valid) {
          this.confShow = true
          if(!this.verifyFun()) return
          this.confShow = false
          let info = this.chuLiData(this.newsInfo)
          delete info.draftTitle
          info.isDraft = false  // 是否保存为草稿
          if(info.id){
            info.isDraftPublish = true // 是否草稿转为发布
          }
          if(info.module == 1){
            info.id = this.InfoId ? this.InfoId : this.snowId
          }
          this.$confirm('请确认最终提交的信息，避免误操作！', '提示', {
            confirmButtonText: '确定无误',
            cancelButtonText: '继续填写',
            type: 'warning'
          }).then(() => {
            this.$openLoading()
            this.subLoading = true
            auditEdit( info ).then( res => {
              this.$closeLoading()
              this.subLoading = false
              this.$message.success("创建成功")
              if(this.InfoId){ // 草稿
                this.$router.push('/articleDraftList')
              }else{
                if(info.module == 1){
                  this.$router.push('/newsList')
                }else{
                  this.$router.push('/flashNewsList')
                }
              } 
            }).catch((error) => {
              this.$closeLoading()
              this.subLoading = false
              this.$message("创建失败"+ error)
            })
          }).catch(() => { });
        } else {
          return false;
        }
      })
    },
    // 保存为草稿
    saveFun(){
      this.confShow = true
      this.$prompt('请输入草稿标题', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '草稿标题',
        inputValue: this.newsInfo.draftTitle,
        inputValidator: function (value){
          return value ? true : false
        },
        inputErrorMessage: '标题不允许为空'
      }).then(({ value }) => {
        let info = this.chuLiData(this.newsInfo)
        info.isDraft = true
        info.draftTitle = value
        this.confShow = false
        this.$openLoading()
        this.subLoading = true
        info.id = this.InfoId ? this.InfoId : this.snowId
        auditEdit( info ).then( res => {
          this.$closeLoading()
          this.subLoading = false
          // this.initUpdate()
          // this.$router.back()
          this.$router.push('/articleDraftList')
          this.$message.success("保存草稿成功")
        }).catch( (error) => {
          this.$closeLoading()
          this.subLoading = false
          this.$message.error("保存草稿失败"+error )
        })
      }).catch(() => { });
    },
    // 取消
    cancelFun(){
      this.$confirm('退出后，已编辑的内容将被清除！', '提示', {
        confirmButtonText: '坚持退出',
        cancelButtonText: '继续填写',
        type: 'warning'
      }).then(() => {
        console.log("退出")
        if(this.InfoId){
          this.$router.back()
        }else{
          this.$refs['newsInfo'].resetFields();
          this.confShow = false
        }
      }).catch(() => { 
        console.log("继续")
      });
    },
    // 模块改变时
    moduleChange(){
      if(this.newsInfo.content == '<p><br></p>'){
        this.newsInfo.content = ''
        console.log("修改")
      }
    }
  },
  destroyed() {
    console.log("销毁")
    window.removeEventListener('resize', this.setleftWidth);
  },
}
</script>
<style lang="scss" scoped>
.page_bac_div{
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  background: #FFF;
}
.form_div_bac{
  flex: 1;
  padding: 20px 20px 40px 20px;
  padding-bottom: 80px;
  .head_back{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    width: 80px;
  }
}
.phone_righ_bac{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}
.fen_ge_xian{
  width: 100%;
  height: 2px;
  background: #9A9FA6;
  border-radius: 2px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
}
.mes_text_div{
  font-size: 13px;
  margin-left: 10px;
}
.mes_error_text{
  font-size: 11px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 9px);
  left: 0;
}
</style>
