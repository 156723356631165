import { isRouterMenu } from '@/utils'
import { systemDictNative } from '@/api/utilsManage'
import routes from '@/router/routers';

export default {
  state: {
    lastRoute: {},
    cssStyle: '',
  },
  mutations: {
    setLastRoute (state, obj) {
      state.lastRoute = obj
    },
    setCssStyle (state, css) {
      state.cssStyle = css
    },
  },
  getters: {
    lastRouteGet(state){
      return state.lastRoute
    }
  },
  actions: {
    //保存最后的路由导航
    updateLastRoute ({ state, commit }, item ) {
        let list = isRouterMenu(item.fullPath, item.menuList, [])
        if(list.length > 0){
          commit('setLastRoute', list[0])
        }
    },

  }
}
