import axiosHttps from '@/config/axios'

/**
 * 配置管理相关 
 */

/**
 * 资讯视频管理New (用于查询添加资讯视频)
 */
// 资讯视频搜索
export const newsSearchList = info => {
  return axiosHttps.request({
    url: `/v1/news/search/list`,
    data: info,
    method: 'post'
  })
}
// 展会下的资讯视频列表
export const newsExhibitionList = info => {
  return axiosHttps.request({
    url: `/v1/news/exhibition/list`,
    data: info,
    method: 'post'
  })
}
// 合集下的资讯视频列表
export const newsCollectionList = info => {
  return axiosHttps.request({
    url: `/v1/news/collection/list`,
    data: info,
    method: 'post'
  })
}


/**
 * 合集&专题
 */
// 合集&专题列表
export const collectionList = info => {
  return axiosHttps.request({
    url: `/v1/collection/list`,
    data: info,
    method: 'post'
  })
}

// 合集&专题新增保存合集
export const collectionSave = info => {
  return axiosHttps.request({
    url: `/v1/collection/save`,
    data: info,
    method: 'post'
  })
}

// 合集&专题删除
export const collectionDelete = info => {
  return axiosHttps.request({
    url: `/v1/collection/delete`,
    params: info,
    method: 'get'
  })
}

// 合集&专题资讯添加到合集
export const collectionAddNews = info => {
  return axiosHttps.request({
    url: `/v1/collection/add/news/v2`,
    data: info,
    method: 'post'
  })
}

// 修改资讯在合集里的权重
export const newsEditSort = info => {
  return axiosHttps.request({
    url: `/v1/collection/news/edit-sort`,
    params: info,
    method: 'get'
  })
}

// 把资讯从合集里删除
export const collectionNewsDelete = info => {
  return axiosHttps.request({
    url: `/v1/collection/news/delete`,
    params: info,
    method: 'get'
  })
}



/**
 * 每日精选
 */

// 精选列表
export const dailyList = info => {
  return axiosHttps.request({
    url: `/v1/daily/list`,
    data: info,
    method: 'post'
  })
}

// 查询指定日期下的精选
export const dailyListByDate = info => {
  return axiosHttps.request({
    url: `/v1/daily/list/by_date`,
    params: info,
    method: 'get'
  })
}

// 设置精选
export const dailySetListByDate = info => {
  return axiosHttps.request({
    url: `/v1/daily/set_list/by_date`,
    data: info,
    method: 'post'
  })
}

// 精选删除单条精选
export const dailyDelete = info => {
  return axiosHttps.request({
    url: `/v1/daily/delete`,
    params: info,
    method: 'get'
  })
}



/**
 * 主推文章
 */

// 精选列表
export const mainRecommendList = info => {
  return axiosHttps.request({
    url: `/v1/main_recommend/list`,
    data: info,
    method: 'post'
  })
}

// 添加主推文章
export const mainRecommendAdd = info => {
  return axiosHttps.request({
    url: `/v1/main_recommend/add`,
    data: info,
    method: 'post'
  })
}

// 删除主推文章
export const mainRecommendDelete = info => {
  return axiosHttps.request({
    url: `/v1/main_recommend/delete`,
    params: info,
    method: 'get'
  })
}


/**
 * 热议话题
 */

// 热议话题列表
export const hotTopicLise = info => {
  return axiosHttps.request({
    url: `/v1/community/hot_topic/lise`,
    data: info,
    method: 'post'
  })
}

// 创建-编辑 热议话题
export const hotTopicSave = info => {
  return axiosHttps.request({
    url: `/v1/community/hot_topic/save`,
    data: info,
    method: 'post'
  })
}

// 热议话题删除
export const hotTopicDelete = info => {
  return axiosHttps.request({
    url: `/v1/community/hot_topic/delete`,
    params: info,
    method: 'get'
  })
}

// 话题添加帖子
export const hotTopicPostAdd = info => {
  return axiosHttps.request({
    url: `/v1/community/hot_topic/post/add`,
    data: info,
    method: 'post'
  })
}

// 话题删除帖子
export const hotTopicPostDelete = info => {
  return axiosHttps.request({
    url: `/v1/community/hot_topic/post/delete`,
    params: info,
    method: 'get'
  })
}


/**
 * 国家专区
 */

// 国家专区列表
export const countyAreaList = info => {
  return axiosHttps.request({
    url: `/v1/county_area/list`,
    data: info,
    method: 'post'
  })
}
// 编辑国家
export const countyAreaEdit = info => {
  return axiosHttps.request({
    url: `/v1/county_area/edit`,
    data: info,
    method: 'post'
  })
}

/**
 * 展会相关
 */

// 展会列表
export const exhibitionList = info => {
  return axiosHttps.request({
    url: `/v1/exhibition/list`,
    data: info,
    method: 'post'
  })
}
// 编辑展会
export const exhibitionSave = info => {
  return axiosHttps.request({
    url: `/v1/exhibition/save`,
    data: info,
    method: 'post'
  })
}
// 展会添加资讯
export const exhibitionNewsAdd = info => {
  return axiosHttps.request({
    url: `/v1/exhibition/news/add`,
    data: info,
    method: 'post'
  })
}


// 删除展会
export const exhibitionDelete = info => {
  return axiosHttps.request({
    url: `/v1/exhibition/delete`,
    params: info,
    method: 'get'
  })
}
// 删除展会下的视频或资讯
export const exhibitionNewsDelete = info => {
  return axiosHttps.request({
    url: `/v1/exhibition/news/delete`,
    params: info,
    method: 'get'
  })
}
