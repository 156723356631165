<!-- 简单上传视频1 -->
<template>
  <div style="display: flex;align-items: center;">
    <el-upload action="#" :show-file-list="false" :auto-upload="true" :on-change="upLoadFileFun" :limit="1"
      accept="video/*" :style="sytleData" drag v-if="!upLoading">
      <div :style="sytleData" class="upload_bac"  >
        <i slot="default" class="el-icon-plus"  style="font-size:30px"></i>
      </div>
    </el-upload>
    
    <div :style="sytleData" class="upload_bac" style="border: 1px dashed #4290f7" v-else>
      <el-progress v-if="videoUploadPercent && videoUploadPercent > 0" type="circle" size="mini" :percentage="videoUploadPercent"
        :width="width / 2"></el-progress>
      <template v-else>
        <div style="width: 100%;margin-bottom: 12px;text-align: center;color:#4290f7" v-if="loadingText">{{ loadingText }}</div>
        <div style="width: 100%;text-align: center;">
          <i class="el-icon-loading" style="color:#4290f7;" :style="{fontSize: width / 8 +'px'}"/>
        </div>
      </template>
    </div>
    <template  v-if="videoUrl">
      <div class="img_bacdiv" :style="sytleData">
        <video-two-com :videoSrc="$pathPrefix+videoUrl" :subtitles-src="subtitleUrl"
          :showPlay="true" :videocover="$pathPrefix+coverImg" :width="width+'px'" :height="height+'px'"/>
        <div class="img_icon_bac">
          <i class="el-icon-error close_img_icon" @click="deleteImg()"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ossUpload, getOssClient, ossUploadBase } from "@/utils/ossUpload";
import { systemSnowId, bySystem } from '@/api/utilsManage'
import { getUUIDName } from '@/utils/index.js'
export default {
  name: 'uploadVideoEasy',
  props: {
    cover: {  // 封面
      type: String,
      default: '',
    },
    url: {  //  视频路径
      type: String,
      default: '',
    },
    size: { //  视频大小
      type: String,
      default: '',
    },
    duration: { //  视频时长
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    newsId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      infoId: this.$props.newsId,
      upLoading: false,// loading
      loadingText: '',// loading提示文字
      videoUrl: this.$props.url,// 视频路径
      videoSize: this.$props.size,// 视频大小（B）
      videoDuration: this.$props.duration,// 视频时长（秒）
      coverImg: this.$props.cover, // 封面
      subtitleUrl: '',// set字幕文件
      subtitleLang: '',// 当前字幕语言
      videoUploadPercent: 0,
      sytleData: {
        width: this.$props.width + 'px !important',
        height: this.$props.height + 'px !important',
      }
    };
  },
  watch : {
		'url' : function(val){
      this.videoUrl = val
		},
		'videoUrl' : function(val){
      this.$emit('update:url', val)
		},
		'coverImg' : function(val){
      this.$emit('update:cover', val)
		},
		'videoSize' : function(val){
      this.$emit('update:size', val)
		},
		'videoDuration' : function(val){
      this.$emit('update:duration', val)
		},
		'newsId' : function(val){
      this.infoId = val
		},
		'infoId' : function(val){
      this.$emit('update:newsId', val)
		},
	},
  created() {
  },
  mounted() {
    
  },
  methods: {
    //cos上传视频
    upLoadFileFun (file) {
      console.log("文件信息====",file)
      if (file.status !== 'ready') return;
      if (file.raw) {
        // 创建视频标签
        const video = document.createElement('video');
        video.preload = 'metadata';
        // 设置视频源为选择的文件
        video.src = URL.createObjectURL(file.raw);
        // 视频元数据加载完毕
        video.onloadedmetadata = async () => {
          // 设置视频当前时间为0，即视频的开始
          video.currentTime = 1;
          this.videoDuration = Math.floor(video.duration)+''; // 获取时长
          this.videoSize  = Math.floor(file.raw.size)+'';  //获取大小
          this.upLoading = true
          this.loadingText = '上传视频中...'
          // 上传oss部分
          if(!this.infoId){
            let res = await systemSnowId().catch( error => { 
              this.$message.error('获取id出错了',error)
            });
            if(!res) return
            this.infoId = res.data.data
          }
          let userMdHou5 = this.$Md5(this.infoId).slice(-5);
          let urlCenter = `b/${userMdHou5}/information/static/${this.infoId}/`
          let size = Number(this.videoSize) / 1024 / 1024 // B转为MB
          console.log("size==",size)
          // this.easyFUn(file.raw, urlCenter)
          if( size >= 100){ // 大于100MB使用分片上传
            this.multipartFun(file.raw, urlCenter)
          }else{
            this.easyFUn(file.raw, urlCenter)
          }
        };
      }
    },
    // 简单上传
    async easyFUn(file, urlCenter){
      let urlCopy = await ossUpload(file, urlCenter).catch((error) => {
        console.log("上传失败",error)  
        this.$message.error('上传失败',error)
        this.upLoading = false
        this.loadingText = ''
      })
      if(!urlCopy) return
      this.loadingText = ''
      this.upLoading = false
      //获取最后一个/的位置
      var site = urlCopy.lastIndexOf("\/");
      //截取最后一个/后的值
      const url = urlCopy.substring(site + 1, urlCopy.length);
      this.videoUrl = urlCenter+url
    },
    // 分片上传
    async multipartFun(file, urlCenter){
      let lastName = file.name.slice(file.name.lastIndexOf(".") + 1)
      const fileName = urlCenter + getUUIDName() +'.'+ lastName
      let client = await getOssClient().catch((error) => {
        this.$message.error('获取上传参数出错了',error)
        this.upLoading = false
        this.loadingText = ''
      })
      if(!client) return 
      let that = this
      client.multipartUpload(fileName, file, {
        progress: function(p) {
          that.videoUploadPercent = Number((Number(p) * 100).toFixed(1));
        },
        // 设置并发上传的分片数量。
        // parallel: 9999,
        // // 设置分片大小。默认值为1 MB，最小值为100 KB。
        // partSize: 2 * 1024 * 1024,
      }).then((result) => {
        this.upLoading = false
        this.loadingText = ''
        that.videoUploadPercent = 0
        if (result.name) {
          this.videoUrl = result.name
        } else {
          this.$message.error("上传视频失败，请重试");
        }
      }).catch((err) => {
        this.upLoading = false
        this.loadingText = ''
        that.videoUploadPercent = 0
        if (err.name == 'cancel') {
          this.$message('上传取消');
        } else {
          this.$message.error("上传视频失败，请重试",err);
        }
      });
    },
    // 获取本地视频封面
    getCover (file) { 
      return new Promise((resolve, reject) => {
        // 创建视频标签
        const video = document.createElement('video');
        video.preload = 'metadata';
        // 设置视频源为选择的文件
        video.src = URL.createObjectURL(file);
        // 视频元数据加载完毕
        video.onloadedmetadata = () => {
          // 设置视频当前时间为0，即视频的开始
            video.currentTime = 1;
            this.videoDuration = Math.floor(video.duration)+''; // 获取时长
            this.videoSize  = Math.floor(file.size)+'';  //获取大小
            // 在视频的第一帧绘制canvas
            video.onseeked = async () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
              // 将canvas转换为Base64图片
              let videoFrame = canvas.toDataURL('image/png');
              // 上传图片oss部分
              if(!this.infoId){
                let res = await systemSnowId().catch( error => { 
                  reject(error)
                });
                this.infoId = res.data.data
              }
              let userMdHou5 = this.$Md5(this.infoId).slice(-5);
              let urlCenter = `b/${userMdHou5}/information/static/${this.infoId}/`
              console.log('urlCenter==========',urlCenter)
              this.loadingText = '上传封面中...'
              let urlCopy = await ossUploadBase(videoFrame, urlCenter).catch((error) => {
                reject(error)
              })
              let site = urlCopy.lastIndexOf("\/");
              //截取最后一个/后的值
              const url = urlCopy.substring(site + 1, urlCopy.length);
              this.coverImg = urlCenter+url

              // 释放URL对象
              URL.revokeObjectURL(video.src);
              resolve()
            };
        };
      })
    },
    //删除
    deleteImg(){
      this.videoUrl = ''
      this.coverImg = ''
    },
    // set字幕
    setSubtitle(url){
      if(!this.videoUrl){
        return
      }
      if(!url){
        this.subtitleUrl = ''
        return
      }
      this.subtitleUrl = url
    }
  },
};
</script>
<style scoped>
>>> .el-upload--text {
  border: 0px dashed transparent !important;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
}
>>> .el-upload-dragger{
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss" scoped>

.upload_bac{
  background: #FBFDFF;
  // border: 1px dashed #C0CCDA;
  border-radius: 4px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.img_bacdiv{
  margin-left: 16px;
  position: relative;
  background: rgba(0, 0, 0, 1);
}
.img_icon_bac{
  position: absolute;
  right: 6px;
  top: 6px;
  background: white;
  border-radius: 100%;
}
.close_img_icon{
  cursor: pointer;
  color: #000;
  font-size: 16px;
  display: block;
}
</style>
