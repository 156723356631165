var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c(
        "el-menu",
        {
          staticClass: "sidebar-el-menu",
          attrs: {
            "default-active": _vm.activeMenu,
            collapse: _vm.collapse,
            "background-color": "#FFF",
            "text-color": "rgb(51, 54, 57)",
            "active-text-color": "#206CFF",
            "unique-opened": "",
            "collapse-transition": "",
            router: ""
          }
        },
        [
          _vm._l(_vm.items, function(item) {
            return [
              item.children
                ? [
                    _c(
                      "el-submenu",
                      { key: item.path, attrs: { index: item.path } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("i", {
                            staticClass: "icon_class_default",
                            class: item.meta.icon
                          }),
                          _c(
                            "span",
                            { attrs: { slot: "title" }, slot: "title" },
                            [_vm._v(_vm._s(item.meta.title))]
                          )
                        ]),
                        _vm._l(item.children, function(subItem) {
                          return [
                            subItem.children
                              ? _c(
                                  "el-submenu",
                                  {
                                    key: subItem.path,
                                    attrs: { index: subItem.path }
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c("i", {
                                        staticClass: "icon_class_default",
                                        class: subItem.meta.icon
                                      }),
                                      _vm._v(_vm._s(subItem.meta.title) + " ")
                                    ]),
                                    _vm._l(subItem.children, function(
                                      threeItem,
                                      i
                                    ) {
                                      return _c(
                                        "el-menu-item",
                                        {
                                          key: threeItem.path,
                                          attrs: { index: threeItem.path }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon_class_default",
                                            class: threeItem.meta.icon
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(threeItem.meta.title) +
                                              " "
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _c(
                                  "el-menu-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !_vm.isRqrzAccount ||
                                          subItem.meta.title != "举报列表",
                                        expression:
                                          "!isRqrzAccount || subItem.meta.title != '举报列表'"
                                      }
                                    ],
                                    key: subItem.path,
                                    attrs: { index: subItem.path }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "icon_class_default",
                                      class: subItem.meta.icon
                                    }),
                                    _vm._v(
                                      " " + _vm._s(subItem.meta.title) + " "
                                    )
                                  ]
                                )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                : [
                    _c(
                      "el-menu-item",
                      { key: item.path, attrs: { index: item.path } },
                      [
                        _c("i", {
                          staticClass: "icon_class_default",
                          class: item.meta.icon
                        }),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(item.meta.title))]
                        )
                      ]
                    )
                  ]
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }