<!-- 转发tg弹窗 -->
<template>
  <el-dialog title="转发"
    width='900px'
    :close-on-click-modal="false"
    :visible.sync="show">
    <div class="con_title_div">转发TG</div>
    <el-form :model="auditKuai" label-width="100px" class="demo-ruleForm">
      <el-form-item label="发布时间">
        <div style="display: flex;align-items: center;">
          <el-radio-group v-model="auditKuai.radio2">
            <el-radio :label="1">立即发布</el-radio>
            <el-radio :label="2">定时发布</el-radio>
          </el-radio-group>
          <el-date-picker 
          style="margin-left: 16px;"
            :disabled="auditKuai.radio2 == 1"
            v-model="auditKuai.preTime"
            type="datetime"
            placeholder="选择定时发布时间"
            default-time="12:00:00"
            :picker-options="pickerOptions()">
          </el-date-picker>
        </div>
        <div class="mes_error_text" v-if="auditKuai.radio2==2 && !auditKuai.preTime && tgClick">请选择定时发布时间</div>
      </el-form-item>
      <el-form-item label="转载至tg群">
        <multipleTgCom :list.sync="tgGroupList" :listDisct="telegramDisct"  @focus="getTelegramGroups"/>
        <div class="mes_error_text" v-if="tgGroupList.length == 0 && tgClick">请选择需要转发的TG群</div>
      </el-form-item>
    </el-form>
    <div class="but_bac_div">
      <el-button type="primary" @click="submitKuaiForm('auditKuai')">提交</el-button></div>
    <!-- <div class="fen_ge_xian"></div> -->
    <!-- <div  class="con_title_div">转发Twitter</div>
    <el-form :model="tData" label-width="100px" class="demo-ruleForm">
      <el-form-item label="发布时间">
        <div style="display: flex;align-items: center;">
          <el-radio-group v-model="tData.radio">
            <el-radio :label="1">立即发布</el-radio>
            <el-radio :label="2">定时发布</el-radio>
          </el-radio-group>
          <el-date-picker 
          style="margin-left: 16px;"
            :disabled="tData.radio == 1"
            v-model="tData.preTime"
            type="datetime"
            placeholder="选择定时发布时间"
            default-time="12:00:00"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptionsTwo()">
          </el-date-picker>
        </div>
        <div class="mes_error_text" v-if="tData.radio==2 && !tData.preTime && twitterClick">请选择定时发布时间</div>
      </el-form-item>
      <el-form-item label="转发Twitter">
        <multipleTgCom :list.sync="twitterList" :listDisct="twitterDisct" @focus="getTwitterDisct"/>
        <div class="mes_error_text" v-if="twitterList.length == 0 && twitterClick">请选择需要转发的twitter</div>
      </el-form-item>
      <el-form-item label="转发文案">
        <el-input 
          v-model="tData.twitterContent" 
          placeholder="请输入" 
          type="textarea"
          :rows="3"></el-input>
        <div class="mes_error_text" v-if="!tData.twitterContent && twitterClick">请输入转发文案！</div>
      </el-form-item>
      <el-form-item label="标签">
        <div class="tag_list_bac">
          <div style="display: flex;">
          <el-input placeholder="请输入标签添加" v-model="tagtext"/>
          <el-button  type="primary" @click="addTagFun()"
            :disabled="tData.tagList.includes(tagtext.trim()) || !tagtext">添加</el-button>
          </div>
          <div style="position: relative;" v-for="(name, index) in tData.tagList" :key="index">
            <div class="tag_bac_div" ># {{ name }}</div>
            <i class="el-icon-error delete_tag_icon" @click="deleteTagFun(name)"></i> 
          </div>
        </div>
      </el-form-item>
      <el-form-item label="图片">
        <uploadImgUtils :image.sync="tData.image" width="80px" height="80px"/>
      </el-form-item>
    </el-form>
    <div class="but_bac_div">
      <el-button type="primary" @click="submitTwitterForm()">提交</el-button></div> -->
    <!-- <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="submitKuaiForm('auditKuai')">提交</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import { auditAudit, auditDetails, auditForwardTg, auditForwardTwitter } from '@/api/newsManage'
import { telegramGroups, twitterAccounts } from '@/api/utilsManage'
import { dateUpdateUtc, utcUpdatedDate } from '@/utils'

export default {
  name: 'transpondTgCom',
  data () {
    return {
      newsId: '',
      show: false,
      currentModule: '',
      tgGroupList: [],
      telegramDisct: [], // tg群列表
      tgClick: false,
      //快速审核参数
      auditKuai: {
        tgList: [],
        radio2: 1,
        preTime: '',
      },
      twitterClick: false,
      twitterList: [],
      twitterDisct: [], // twitter列表
      tagtext: '',
      tData: {
        radio: 1,
        preTime: '',
        twitterContent: '',
        tagList: [],
        image:'',
      },
    }
  },
  methods: {
    pickerOptions(){
      return {
        disabledDate(time) {
          const date = new Date();
          const oneday = date.getTime();
          return time.getTime() < oneday - 86400000;
        },
        selectableRange: (() => {
          let date1 = new Date(this.auditKuai.preTime)
          let data = new Date();
          if(date1.getFullYear() === data.getFullYear() &&
            date1.getMonth() === data.getMonth() &&
            date1.getDate() === data.getDate()){
            let hour = data.getHours();
            let minute = data.getMinutes();
            let second = data.getSeconds();
            return [`${hour}:${minute}:${second} - 23:59:59`]
          }else{
            return [`00:00:00 - 23:59:59`]
          }
        })(),
      }
    },
    pickerOptionsTwo(){
      return {
        disabledDate(time) {
          const date = new Date();
          const oneday = date.getTime();
          return time.getTime() < oneday - 86400000;
        },
        selectableRange: (() => {
          let date1 = new Date(this.tData.preTime)
          let data = new Date();
          if(date1.getFullYear() === data.getFullYear() &&
            date1.getMonth() === data.getMonth() &&
            date1.getDate() === data.getDate()){
            let hour = data.getHours();
            let minute = data.getMinutes();
            let second = data.getSeconds();
            return [`${hour}:${minute}:${second} - 23:59:59`]
          }else{
            return [`00:00:00 - 23:59:59`]
          }
        })(),
      }
    },
    // 打开
    open(id, currentModule){ 
      this.newsId = id
      this.currentModule = currentModule
      this.tgGroupList = []
      let info = {  
        module: this.currentModule, 
        isRelation: true,
        id: this.newsId
      }
      this.getAuditDetails(info)
      this.getTelegramGroups()
      this.getTwitterDisct()
    },
    // 获取tg列表
    getTelegramGroups(){
      if(this.telegramDisct.length <= 0){
        telegramGroups({}).then(( res ) => {
          this.telegramDisct = res.data
        })
      }
    },
    // 获取twitter列表
    getTwitterDisct(){
      if(this.twitterDisct.length <= 0){
        twitterAccounts({}).then(( res ) => {
          this.twitterDisct = res.data
        })
      }
    },
    // 获取资讯详情
    getAuditDetails(info){
      this.$openLoading()
      this.auditKuai.preTime = ''
      auditDetails( info ).then( res => {
        this.$closeLoading()
        this.newsInfo = res.data

        if(res.data.preTime){
          this.auditKuai.preTime = utcUpdatedDate(res.data.preTime)
          this.auditKuai.radio2 = 2
          this.tData.preTime = utcUpdatedDate(res.data.preTime)
          this.tData.radio = 2
        }else{
          this.auditKuai.radio2 = 1
          this.tData.radio = 1
        }

        this.tData.twitterContent = res.data.title
        this.tData.image = res.data.cover
        this.show = true
      }).catch((error) => {
        this.$closeLoading()
        this.$message("获取详情失败"+ error)
      })
    },
    // 转发TG
    submitKuaiForm(){
      this.tgClick = true
      if(this.tgGroupList.length == 0){
        this.$message('请选择需要转载的TG群！')
        return
      }
      if(this.auditKuai.radio2==2 && !this.auditKuai.preTime) {
        this.$message('转发TG已选择定时发布，请选择定时时间！')
        return
      }
      this.$confirm('请确认最终提交的信息，避免误操作！', '提示', {
        confirmButtonText: '确定无误',
        cancelButtonText: '继续填写',
        type: 'warning'
      }).then(() => {
        this.tgClick = false
        let info = {
          module: this.currentModule,
          id: this.newsId,
        }
        if(this.auditKuai.radio2 == 2){
          info.preTime  =  dateUpdateUtc(new Date(this.auditKuai.preTime))
        }else{
          info.preTime  = null
        }
        info.tgIds = []
        this.tgGroupList.forEach( item => {
          info.tgIds.push(item.groupId)
        })
        this.$openLoading()
        auditForwardTg( info ).then( res => {
          this.$closeLoading()
          this.$message.success("转发TG成功")
          this.$emit('returnGetData')
          this.tgGroupList = []
          this.auditKuai.preTime = ""
          this.auditKuai.radio2 = 1
          // this.show = false
        }).catch((error) => {
          this.$closeLoading()
          this.$message("转发失败"+ error)
        })
      }).catch(() => { });
    },
    // 添加标签
    addTagFun(){
      if(this.tData.tagList.includes(this.tagtext.trim()) || !this.tagtext) return
      this.tData.tagList.push(this.tagtext.trim())
      this.tagtext = ''
    },
    // 删除标签
    deleteTagFun(name){
      this.tData.tagList = this.tData.tagList.filter(nameStr => nameStr !== name);
    },
    // 转发Tiwtter
    submitTwitterForm(){
      this.twitterClick = true
      if(this.tData.radio==2 && !this.tData.preTime) {
        this.$message('转发Teitter已选择定时发布，请选择定时时间！')
        return
      }
      if(this.twitterList.length == 0){
        this.$message('请选择需要转发的twitter！')
        return
      }
      if(!this.tData.twitterContent){
        this.$message('请输入转发文案！')
        return
      }
      this.$confirm('请确认最终提交的信息，避免误操作！', '提示', {
        confirmButtonText: '确定无误',
        cancelButtonText: '继续填写',
        type: 'warning'
      }).then(() => {
        this.twitterClick = false
        let info = {
          targetType: this.currentModule,
          targetId: this.newsId,
          twitterContent: this.tData.twitterContent,
        }
        if(this.tData.radio == 2){
          info.preTime  =  dateUpdateUtc(new Date(this.tData.preTime))
        }
        if(this.tData.image){
          info.image = this.$pathPrefix + this.tData.image
        }
        info.twitterIds = []
        this.twitterList.forEach( item => {
          info.twitterIds.push(item.id)
        })
        if(this.tData.tagList.length > 0){ 
          info.twitterTags = this.tData.tagList
        }
        this.$openLoading()
        auditForwardTwitter( info ).then( res => {
          this.$closeLoading()
          this.tData.radio == 1
          this.tData.preTime = ''
          this.twitterList = []
          this.tData.tagList = []
          this.$message.success("转发twitter成功")
          this.$emit('returnGetData')
        }).catch((error) => {
          this.$closeLoading()
          this.$message.error("转发失败"+ error)
        })
      }).catch(() => { });
    },


    
  }
}
</script>
<style scoped>
>>> .el-dialog__body {
  padding-top: 0 !important;
}
</style>
<style lang="scss" scoped>
.mes_error_text{
  font-size: 12px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
}
.con_title_div{
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 16px;
}
.fen_ge_xian{
  width: 100%;
  height: 2px;
  background: #9A9FA6;
  border-radius: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
.but_bac_div{
  text-align: right;
}


.tag_list_bac{
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
}
.tag_bac_div{
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgba(32, 108, 255, 0.08);
  color: #206CFF;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
}
.delete_tag_icon{
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 16px;
  cursor: pointer;
}
</style>
