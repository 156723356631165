import axios from 'axios'
import {getToken, setToken} from '@/config/util'
import config from '@/config'
import {Message} from 'element-ui';

class HttpRequest {
    constructor(baseUrl = baseURL) {
        this.baseUrl = baseUrl
        this.queue = {}
    }

    getInsideConfig(optContentType) {
        return {
            baseURL: this.baseUrl,
            withCredentials: false,
            headers: {
                'authorization': getToken(),
                'Content-Type': optContentType ? optContentType : "application/json; charset=UTF-8",
                'tk': getToken(),
            }
        }
    }

    destroy(url) {
        delete this.queue[url]
        if (!Object.keys(this.queue).length) {
            // Spin.hide()
        }
    }

    interceptors(instance, url) {
        // 请求拦截
        instance.interceptors.request.use(config => {
            this.queue[url] = true
            return config
        }, error => {
            return Promise.reject(error)
        })
        // 响应拦截
        instance.interceptors.response.use(res => {
            // this.destroy(url)
            // console.log("响应====",res)
            const {data, status} = res
            console.log("status", data.status)

            if (data.success) {
                return data
            } else if (data.success == false) {
                return Promise.reject(data.errMessage)
            } else {
                if (data.pass) {
                    return data
                } else if (data.reason) {
                    Message.error({
                        title: '错误',
                        message: data.reason
                    })
                    return Promise.reject(data.reason)
                } else {
                    if (data) {
                        return data
                    } else {
                        Message.error({
                            title: '错误',
                            message: data.errMessage
                        })
                        return Promise.reject(data.errMessage)
                    }
                }
            }

        }, error => {
            this.destroy(url)
            let errorInfo = error.response
            console.log("errorInfo", errorInfo)
            if (errorInfo.status === 401) {
                Message.error({
                    title: '错误',
                    message: '请重新登录'
                })
                setToken('')
                setTimeout(() => {
                    window.location.href = '/login'
                }, 1500);
            } else {
                Message.error({
                    title: '错误',
                    message: errorInfo.data.errMessage
                })
                return Promise.reject(errorInfo)
            }
        })
    }

    request(options) {
        const instance = axios.create()
        options = Object.assign(this.getInsideConfig(options.contentType), options)
        this.interceptors(instance, options.url)
        return instance(options)
    }
}

console.log("NODE_ENV: ", process.env.NODE_ENV)
const baseUrl = function () {
    switch (process.env.NODE_ENV) {
        case 'backend_development': // 后端开发环境
            return config.baseUrl.backend_dev
        case 'development': // 前端开发环境
            return config.baseUrl.dev
        case 'test': // 测试环境
            return config.baseUrl.test
        case 'prod': // 生产环境
            return config.baseUrl.prod
        default: // 默认生产环境
            return config.baseUrl.prod
    }
}()
console.log("baseUrl: ", baseUrl)

const axiosHttps = new HttpRequest(baseUrl)

export default axiosHttps
