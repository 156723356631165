<!-- 新增与修改 -->
<template>
  <el-dialog :title="infoId ? '编辑' : '新增'"
    width='900px'
    :close-on-click-modal="false"
    :visible.sync="visible">
    <div v-if="!infoId" style="margin-left: 20px;margin-bottom: 20px;color: #000000;">
      说明：新建账号成功后，使用手机号或邮箱地址接收验证码即可进行等登录
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="头像" prop="headPic" v-if="infoId">
        <uploadImg :image.sync="ruleForm.headPic" :userId="infoId" v-if="visible" width='60px' height='60px'/>
      </el-form-item>
      <el-form-item label="用户昵称" prop="nickName">
        <el-input v-model="ruleForm.nickName"  maxlength="20" show-word-limit/>
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-select clearable v-model="ruleForm.gender" placeholder="请选择">
          <el-option :label="item.name" :value="item.value"
            v-for="item in sexDisct" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出生日期">
        <el-col :span="11">
          <el-form-item prop="bir">
            <el-date-picker 
              type="date" 
              placeholder="选择日期" 
              v-model="ruleForm.bir" 
              style="width: 100%;"
              :picker-options="pickerOptions0">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="IP归属地">
        <el-select v-model="ruleForm.location" placeholder="请选择" filterable clearable @focus="getCountryDisct()">
          <el-option v-for="item in countryDisct" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="常居国家">
        <el-select v-model="ruleForm.countryId" placeholder="请选择" filterable clearable @focus="getCountryDisct()">
          <el-option v-for="item in countryDisct" :key="item.id" :label="item.name" :value="item.id"
            :disabled="item.id==9998 || item.id==9999">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="行业">
        <el-select v-model="ruleForm.systemIndustryId" placeholder="请选择" filterable clearable @focus="getIndustryDisct()">
          <el-option v-for="item in idustryDisct" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="个人简介" prop="content">
        <el-input type="textarea" 
          v-model="ruleForm.content"
          maxlength="200"
          rows='4'
          show-word-limit>
        </el-input>
      </el-form-item>
      <!-- <el-form-item label="长居国家" prop="region">
        <el-select filterable multiple collapse-tags clearable v-model="ruleForm.region" placeholder="请选择">
          <el-option :label="item.country_name" :value="item.id"
            v-for="item in countryList" :key="item.id"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="用户手机号" prop="phone">
        <div style="display: flex;align-items:center">
          <el-select v-model="ruleForm.countryCode" placeholder="请选择" style="width:120px;margin-right:10px">
            <el-option :value="item.country_code" :label="item.country_code"
              v-for="(item, index) in countryList" :key="index">+{{ item.country_code }}</el-option>
          </el-select>
          <el-input v-model="ruleForm.phone" v-limitInput:positiveInteger/>
        </div>
        <div class="mes_error_text" v-if="!ruleForm.email && !ruleForm.phone">请输入手机号</div>
      </el-form-item>
      <el-form-item label="用户邮箱" prop="email">
        <el-input v-model="ruleForm.email"/>
        <div class="mes_error_text" v-if="!ruleForm.email && !ruleForm.phone">请输入邮箱</div>
      </el-form-item>
      <el-form-item label="推荐状态" prop="recommendState">
        <div style="display: flex;align-items: center;">
          <el-radio-group v-model="ruleForm.recommendState">
            <el-radio :label="false">未推荐</el-radio>
            <el-radio :label="true">已推荐</el-radio>
          </el-radio-group><br/>
          <el-input v-model="ruleForm.recommendNum" placeholder="请输入" v-limitInput:digit 
                style="width: 100px;text-align: center;margin-left: 10px;"/>
        </div>
        <div style="color: gray;font-size: 10px;line-height: 15px;">
          注：1：已设置权重的，则权重排序，权重越大，排序越前，<br/>
              2：没设置权重的，则按用户注册成功时间的倒叙进行排序
        </div>
      </el-form-item>
      <el-form-item label="资讯发布权限" prop="qualState">
        <el-radio-group v-model="ruleForm.qualState" @change="setRemarkCh()">
          <el-radio :label="item.value" v-for="item in qualStateDisct">{{ item.name }}</el-radio>
        </el-radio-group><br/>
        <div style="color: gray;font-size: 10px;line-height: 15px;">
          开启后，该用户可通过客户端或pc端发布资讯文章
        </div>
      </el-form-item>
      <template v-if="ruleForm.qualState!=0">
        <el-form-item label="认证说明">
          <div style="display: flex;align-items: center;">
            （中文）
            <el-input v-model="ruleForm.authRemarkCh" style="width: 300px;"/>
            （英文）
            <el-input v-model="ruleForm.authRemarkEn" style="width: 300px;"/>
          </div>
        </el-form-item>
        <el-form-item label="绑定资讯企业" prop="infoCompanyId"  v-if="ruleForm.qualState==1">
          <selectCompany 
            v-if="visible"
            placement="top"
            filterrype='1'
            inputCompanyId = '1'
            :id.sync="ruleForm.infoCompanyId" 
            :name="ruleForm.infoCompanyName" 
            style="width: 400px;"/>
          <div class="mes_error_text" v-if="!ruleForm.infoCompanyId && ruleForm.qualState ==1 && EditIsClick">请选择企业</div>
        </el-form-item>
      </template>
      
      
      <!-- <el-form-item label="认证状态" prop="authState" v-if="ruleForm.qualState==2">
        <el-radio-group v-model="ruleForm.authState">
          <el-radio :label="item.value" v-for="item in authStateDisct">{{ item.name }}</el-radio>
        </el-radio-group><br/>
        <div style="color: gray;font-size: 10px;line-height: 15px;">
          注：仅支持个人作者可以认证，企业作者不支持认证
        </div>
      </el-form-item> -->
      <el-form-item label="招聘发布权限" prop="qualState">
        <el-radio-group v-model="ruleForm.recruitState">
          <el-radio :label="item.value" v-for="item in zhaopinStateDisct">{{ item.name }}</el-radio>
        </el-radio-group><br/>
        <div style="color: gray;font-size: 10px;line-height: 15px;">
          开启后，该用户可通过客户端或pc端发布招聘文章
        </div>
      </el-form-item>
      <el-form-item label="绑定招聘企业" prop="recruitCompanyId" v-if="ruleForm.recruitState==1">
        <selectCompany 
          v-if="visible"
          placement="top"
          filterrype='1'
          inputCompanyId = '2'
          :id.sync="ruleForm.recruitCompanyId" 
          :name="ruleForm.recruitCompanyName" 
          style="width: 400px;"/>          
          <div class="mes_error_text" v-if="!ruleForm.recruitCompanyId && ruleForm.recruitState==1 && EditIsClick">请选择企业</div>
      </el-form-item>
      
    </el-form>
    
    <span slot="footer" class="dialog-footer">
      <el-button @click="resetForm('ruleForm')">重置</el-button>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { utcUpdatedDate, dateUpdateUtc } from '@/utils'
import { userDetails, userAddEdit, industryList  } from '@/api/userManage'
import { countryCodeList } from '@/utils/countryCode.js'
import { qualStateDisctE, authStateDisctE } from '@/utils/disct.js'
import { systemTerritory } from '@/api/utilsManage'
import { Loading } from 'element-ui'
export default {
  name: 'addOrUpdate',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      infoId: '',
      visible: false,
      countryList: countryCodeList,
      qualStateDisct: qualStateDisctE,
      authStateDisct: authStateDisctE,
      zhaopinStateDisct: [
        {value: 0, name: '关闭'},
        {value: 1, name: '开启'},
      ],
      ruleForm: {},
      ruleFormCopy: {
        headPic: "", // 头像
        nickName: "", // 昵称
        gender: 0, // 性别 0女1男
        bir: "",  // 生日
        location: '',// IP
        countryId: '',//长居国家
        systemIndustryId:'', // 行业
        content: "", //简介
        countryCode: countryCodeList[0].country_code,// 区号
        phone: "",  // 手机
        email: "",  // 邮箱
        qualState: 0, // 资讯发布权限（0：不能发布；1：企业；2：个人）
        authState: 1, //	认证状态（0：未认证；1：已认证）
        authRemarkCh: '',// 中文认证说明
        authRemarkEn: '',// 英文认证说明
        recommendState: false, // 推荐状态
        recommendNum: 0, // 推荐权重

        infoCompanyId: '',// 资讯企业id
        oldInfoCompanyId: '',// 	咨询企业id-原来的值
        infoCompanyName: '',// 咨询企业名称
        recruitState: 0,// 发布招聘权限 0不能 1可以
        recruitCompanyId: '',// 招聘企业id
        oldRecruitCompanyId: '',// 	招聘企业id-原来的值
        recruitCompanyName: '',// 招聘企业名称
      },
      sexDisct: [   //性别字典
        {value: 0, name: '女'},
        {value: 1, name: '男'},
      ],
      countryDisct: [],
      idustryDisct: [],
      EditIsClick: false,
      rules: {
        // headPic: [
        //   { required: true, message: '请选择头像', trigger: 'blur' },
        // ],
        nickName: [
          { required: true, message: '请输入用户昵称', trigger: 'blur' },
          { min: 0, max: 20, message: '长度在20个字符以内', trigger: 'blur' }
        ],
        countryCode: [
          { required: true, message: '请填写区号', trigger: 'blur' },
        ],
        // phone: [
        //   { required: true, message: '请填写手机号', trigger: 'blur' },
        // ],
      },
      pickerOptions0: { 
         disabledDate(time) {
            return time.getTime() + 8.64e7 > Date.now() ;//  如果没有后面的-8.64e7就是不可以选择今天的 
         }
  	  },

    }
  },
  watch : {
		'visible' : function(val){
      if(!val){
        this.$emit('update:show', false)
      }
		}
	},
  methods: {
    // 获取目标业务枚举列表 
    getCountryDisct(){
      if(this.countryDisct.length == 0){
        systemTerritory({}).then(( data ) => {
          this.countryDisct = data.data.country
        })
      }
    },
    // 获取目标业务枚举列表 
    getIndustryDisct(){
      if(this.idustryDisct.length == 0){
        industryList({}).then(( data ) => {
          this.idustryDisct = data.data
        })
      }
    },
    
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    }, 
    init (id) {
      this.ruleForm = {}
      this.getCountryDisct()
      this.getIndustryDisct()
      if(id){
        this.infoId = id + ''
        this.$openLoading()
        userDetails( {userId: this.infoId } ).then( res => {
          this.ruleForm =  {
            id:  res.data.id,
            headPic: res.data.headPic || this.$systemAvatar,
            nickName: res.data.nickName,
            gender: res.data.gender == -1 ? '' : res.data.gender,
            bir: utcUpdatedDate(res.data.bir),
            location: res.data.location,
            countryId: res.data.countryId == 9999 ? '' : res.data.countryId,
            systemIndustryId: res.data.systemIndustryId == 0 ? '' : res.data.systemIndustryId,
            content: res.data.content,
            countryCode: res.data.countryCode ? parseInt(res.data.countryCode) : '',
            phone: res.data.phone,
            email: res.data.email,
            qualState: res.data.qualState,
            authRemarkCh: res.data.authRemarkCh,// 中文认证说明
            authRemarkEn: res.data.authRemarkEn,// 英文认证说明
            authState: res.data.authState,
            recommendState: res.data.recommendState,
            recommendNum: parseInt(res.data.recommendNum),
            infoCompanyId: res.data.infoCompanyId,// 咨询企业id
            oldInfoCompanyId: res.data.infoCompanyId || '',// 	咨询企业id-原来的值
            infoCompanyName: res.data.infoCompanyName,// 招聘企业命
            recruitState: parseInt(res.data.recruitState),// 发布招聘权限 0不能 1可以
            recruitCompanyId: res.data.recruitCompanyId,// 招聘企业id
            oldRecruitCompanyId: res.data.recruitCompanyId || '',// 	招聘企业id-原来的值
            recruitCompanyName: res.data.recruitCompanyName,// 招聘企业命
          }
          this.EditIsClick = false
          this.setRemarkCh()
          this.$closeLoading()
          this.visible = true
        }).catch( (error) => {
          this.$closeLoading()
          this.$message.error( '获取详情失败'+error );
        })
      }else{
        this.visible = true
        this.ruleForm = this.ruleFormCopy
      }
    },
    // 设置默认值
    setRemarkCh(){
      if(this.ruleForm.qualState == 1){
        if(!this.ruleForm.authRemarkCh || this.ruleForm.authRemarkCh=='PASA个人创作者'){
          this.ruleForm.authRemarkCh = 'PASA企业创作者'
        }
        if(!this.ruleForm.authRemarkEn || this.ruleForm.authRemarkEn == 'PASA  personal content creator'){
          this.ruleForm.authRemarkEn = 'PASA  enterprise content creator'
        }
      }
      if(this.ruleForm.qualState == 2){
        if(!this.ruleForm.authRemarkCh || this.ruleForm.authRemarkCh=='PASA企业创作者'){
          this.ruleForm.authRemarkCh = 'PASA个人创作者'
        }
        if(!this.ruleForm.authRemarkEn || this.ruleForm.authRemarkEn == 'PASA  enterprise content creator'){
          this.ruleForm.authRemarkEn = 'PASA  personal content creator'
        }
      }
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.EditIsClick = true
          let infoData = JSON.parse(JSON.stringify(this.ruleForm))
          if(!infoData.phone && !infoData.email){
            this.$message("请输入手机或邮箱!")
            return 
          }
          if(this.ruleForm.recruitState == 1 && !this.ruleForm.recruitCompanyId){
            return
          }
          if(this.ruleForm.qualState == 1 && !this.ruleForm.infoCompanyId){
            return
          }
          if(!infoData.email){
            delete infoData.email
          }
          if(!infoData.phone){
            delete infoData.phone
          }
          if(infoData.bir){
            infoData.bir = dateUpdateUtc(new Date(infoData.bir))
          }
          if( infoData.headPic == this.$systemAvatar){
            infoData.headPic = ''
          }
          if(infoData.qualState==2){
            infoData.authState = 1 
          }else{
            // delete infoData.authState
            infoData.authState = 0
          }
          if(!infoData.location){
            infoData.location = 0
          }else{
            infoData.location = Number(infoData.location)
          }
          if(infoData.qualState == 0){
            delete infoData.authRemarkCh
            delete infoData.authRemarkEn
          }



          if(infoData.qualState != 1){
            delete infoData.infoCompanyId
            delete infoData.oldInfoCompanyId
          }
          if(!infoData.oldInfoCompanyId){
            delete infoData.oldInfoCompanyId
          }
          if(!infoData.infoCompanyId){
            infoData.infoCompanyId = -1
          }
          if(infoData.oldInfoCompanyId && infoData.infoCompanyId != -1 && infoData.oldInfoCompanyId == infoData.infoCompanyId){
            delete infoData.infoCompanyId
            delete infoData.oldInfoCompanyId
          }

          if(infoData.recruitState != 1){
            delete infoData.recruitCompanyId
            delete infoData.oldRecruitCompanyId
          }
          if(!infoData.oldRecruitCompanyId){
            delete infoData.oldRecruitCompanyId
          }
          if(!infoData.recruitCompanyId){
            infoData.recruitCompanyId = -1
          }
          if(infoData.oldRecruitCompanyId && infoData.recruitCompanyId != -1 && infoData.oldRecruitCompanyId == infoData.recruitCompanyId){
            delete infoData.recruitCompanyId
            delete infoData.oldRecruitCompanyId
          }

          delete infoData.infoCompanyName
          delete infoData.recruitCompanyName
          this.setRemarkCh()
          this.$openLoading()
          userAddEdit( infoData ).then( res => {
            this.EditIsClick = false
            this.$closeLoading()
            this.visible = false
            this.$message( {message: '修改成功',  type: 'success'} );
            this.$emit('refreshDataList')
          }).catch((error) => {
            this.$closeLoading()
            this.$message.error(error );
          })
        } else {
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style lang="scss" scoped>
.mes_error_text{
  font-size: 12px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
}
</style>

