<template>
  <div class="container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" style="padding: 10px 0;">
      <el-form-item label="用户ID">
        <el-input v-model="dataForm.id" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="用户昵称">
        <el-input v-model="dataForm.nickName" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="dataForm.phone" size="mini" placeholder="请输入" style="width: 140px;" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="目标市场">
        <el-select v-model="dataForm.countryIds" placeholder="请选择" 
          filterable multiple collapse-tags clearable  @change="getDataList()">
          <el-option v-for="item in countryDisct" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-date-picker v-model="dataForm.createTime" type="datetimerange" @change="getDataList()"  size="mini"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="最近登录">
        <el-date-picker v-model="dataForm.recentLoginDate" type="datetimerange" @change="getDataList()"  size="mini"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="openAddOrUpdate()">新增</el-button>
        <el-button type="primary" size="mini"  plain 
          :disabled="multipleSelection.length == 0" @click="renZhengFun()" >个人作者认证</el-button>
        <el-button type="primary" size="mini"  plain 
          :disabled="multipleSelection.length == 0" @click="openRecommend" >推荐关注</el-button>
        <!--后端接口查询时间长导致失败，先注释-->
        <!-- <el-button type="primary" plain icon="el-icon-download" size="small" @click="downLoadFile" >导出数据</el-button> -->
      </el-form-item>
    </el-form>

    <!--用户列表   height="500" v-adaptive="{bottomOffset: 50,iModal: false}"-->
    <el-table
      :data="dataList" border v-loading="dataListLoading"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;"
      @selection-change="handleSelectionChange">
      <!-- :selectable="checkSelectable" -->
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="用户ID"></el-table-column>
      <el-table-column prop="nickName" label="用户昵称"> </el-table-column>
      <el-table-column prop="headPic" label="头像">
        <template slot-scope="scope">
          <template v-if="scope.row.headPic">
            <viewer>
              <img :src="$pathPrefix+scope.row.headPic" 
                style="width: 60px; height: 60px; object-fit: contain;"/>
            </viewer>
          </template>
          <template v-else>
            <viewer>
              <img :src="$pathPrefix+$systemAvatar" 
                style="width: 60px; height: 60px; object-fit: contain;"/>
            </viewer>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="用户手机号"></el-table-column>
      <el-table-column prop="email" label="用户邮箱"></el-table-column>
      <el-table-column prop="createTime" label="注册时间">
        <template slot-scope="scope">
          {{scope.row.createTime | showDate()}}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="最近启动时间" >
        <template slot-scope="scope">
          {{scope.row.updateTime | showDate()}}
        </template>
      </el-table-column>
      <el-table-column prop="qualState" label="发布权限">
        <template slot-scope="scope">
          {{scope.row.qualState | stateFilter(qualStateDisct)}}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="authState" label="认证状态">
        <template slot-scope="scope">
          {{scope.row.authState | stateFilter(authStateDisct)}}
        </template>
      </el-table-column> -->
      <el-table-column prop="recommendState" label="推荐状态">
        <template slot-scope="scope">
          {{ scope.row.recommendState ? '已推荐' : '未推荐' }}
        </template>
      </el-table-column>
      <el-table-column prop="targetCountryStr" label="目标市场" width="180">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p style=" width: 180px;">{{ scope.row.targetCountryStr }}</p>
            <div slot="reference" class="table_li_text">{{ scope.row.targetCountryStr }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="160" label="操作">
        <template slot-scope="scope">
          <div style="line-height:40px;justify-content: space-between;">
            <el-button type="success" plain size="small" @click="addOrUpdateHandle(scope.row.id)">详情</el-button>
            <el-button type="primary" plain size="small" @click="openAddOrUpdate(scope.row.id)">编辑</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- @size-change="sizeChangeHandle" layout="total, sizes, prev, pager, next, jumper"> -->
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 推荐关注 -->

    <el-dialog title="编辑推荐关注用户"
      width='500px'
      :close-on-click-modal="false"
      :visible.sync="visibleTui">
        <div style="display: flex;justify-content: center;flex-wrap: wrap;">
          <div v-for="item in recommendList" :key="item.id" class="tui_col_div">
            <el-checkbox v-model="item.recommendState">
              <div class="id_name_div">{{ item.id }} - {{ item.nickName }}</div>
            </el-checkbox>
            <el-input v-model="item.recommendNum" placeholder="请输入" v-limitInput:digit 
              style="width: 100px;text-align: center;"/>
          </div>
        </div>
    
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleTui = false">取消</el-button>
        <el-button type="primary" @click="recommendFun()" v-preventReClick>提交</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗, 详情-->
    <userDetailds ref="userDetailds" @refreshDataList="getDataList(pageIndex)"></userDetailds>

    <!-- 弹窗, 新增 / 修改 -->
    <addOrUpdate ref="addOrUpdateRef" @refreshDataList="getDataList(pageIndex)" 
      v-if="addOrUpdateShow" :show.sync="addOrUpdateShow"></addOrUpdate>
    
  </div>
</template>

<script>
import { formatDateTime,utcUpdatedDate } from '@/utils'
import { getUserList, userAuthority, userRecommend } from '@/api/userManage'
import { systemTerritory } from '@/api/utilsManage'
import UserDetailds from './userDetailds'
import addOrUpdate from './addOrUpdate'
import { qualStateDisctE, authStateDisctE } from '@/utils/disct.js'
export default {
  name: 'userManage',
  data () {
    return {
      countryDisct: [], // 目标市场枚举
      onLineDisct: [   //在线状态字典
        {value: 0, name: '离线'},
        {value: 1, name: '当前在线'},
      ],
      qualStateDisct: qualStateDisctE,
      authStateDisct: authStateDisctE,
      dataForm: {
        id: null, //ID
        nickName: "",//昵称
        phone: "",//手机号
        countryIds: [],// 目标市场
        createTime: [],//注册时间
        recentLoginDate: [], //最近登录时间
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateShow: false,
      multipleSelection: [],
      visibleTui: false,
      recommendList: []
    }
  },
  components: {
    UserDetailds,
    addOrUpdate
  },
  created () {
    this.getTerritory()
  },
  mounted () {
    this.getDataList()
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
    showCollege (value) {
      if (value && value.length>0) {
        let valueStr = ""
          for (const key in value) {
            valueStr = valueStr + value[key] + " ";
          }
        return valueStr
      }
    },
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 条件禁用列
    checkSelectable(row){
      if (row.qualState == 0) {
        return true
      } else {
        return false
      }
    },
    // 勾选改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取目标市场和目标业务枚举列表
    getTerritory(){
      systemTerritory({}).then(( data ) => {
        this.countryDisct = data.data.country
      })
    },
    // 获取数据列表
    getDataList (pageNum) {
      this.pageIndex = pageNum ? pageNum : 1
      this.dataListLoading = true
      let obj = {
        pageNumber: this.pageIndex,
        pageSize: this.pageSize,
      }
      if(this.dataForm.id) obj.id = this.dataForm.id.trim()
      if(this.dataForm.nickName) obj.nickName = this.dataForm.nickName.trim()
      if(this.dataForm.phone) obj.phone = this.dataForm.phone.trim()
      if(this.dataForm.countryIds.length>0) obj.countryIds = this.dataForm.countryIds
      //注册时间
      if(this.dataForm.createTime && this.dataForm.createTime.length > 0){
        obj.startRegisterDate = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[0]) : null
        obj.endRegisterDate = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[1]) : null
      }
      console.log("时间", obj.startRegisterDate)
      //最近登录时间
      if(this.dataForm.recentLoginDate &&  this.dataForm.recentLoginDate.length > 0){
        obj.startLastLoginDate = this.dataForm.recentLoginDate.length != 0 ? formatDateTime(this.dataForm.recentLoginDate[0]) : null
        obj.endLastLoginDate = this.dataForm.recentLoginDate.length != 0 ? formatDateTime(this.dataForm.recentLoginDate[1]) : null
      }
      getUserList(obj).then(( data ) => {
        console.log("参数",data.data)
        this.dataList = data.data
        if(this.dataList.length > 0){
          this.dataList.forEach( item => {
            item.targetCountryStr = ''  // 市场
            if(item.targetCountrylist.length > 0){
              item.targetCountrylist.forEach( (country, index) => {
                item.targetCountryStr += item.targetCountrylist.length != index+1 ? country.name+' / ' : country.name
              })
            }
          })
        }
        this.totalPage = data.totalCount
        this.dataListLoading = false
      }).catch((error) => {
        this.dataListLoading = false
        this.$message.error(error);
      })
    },

    //get 用户列表导出
    downLoadFile() {
      // this.dataListLoading = true
      // console.log("时间",this.dataForm)
      // let obj = {
      //   pageNum: this.pageIndex,
      //   pageSize: this.pageSize,
      //   userId:this.dataForm.userId?this.dataForm.userId:null,
      //   nickName:this.dataForm.nickName?this.dataForm.nickName:null,
      //   phoneNumber:this.dataForm.phoneNumber?this.dataForm.phoneNumber:null,
      //   gender:this.dataForm.gender,
      //   vipType:this.dataForm.vipType?this.dataForm.vipType: null,
      //   popularityLevel:this.dataForm.popularityLevel?this.dataForm.popularityLevel: 0,
      //   imAlive: this.dataForm.imAlive?this.dataForm.imAlive:null,
      //   state: this.dataForm.state,
      //   isInvite: this.dataForm.isInvite?parseInt(this.dataForm.isInvite):null
      // }
      // //最近登录时间
      // if(this.dataForm.recentLoginDate != null){
      //   obj.loginStartTime = this.dataForm.recentLoginDate.length != 0 ? formatDateTime(this.dataForm.recentLoginDate[0]) : null
      //   obj.loginEndTime = this.dataForm.recentLoginDate.length != 0 ? formatDateTime(this.dataForm.recentLoginDate[1]) : null
      // }
      // //注册时间
      // if(this.dataForm.createTime != null){
      //   obj.registerStartTime = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[0]) : null
      //   obj.registerEndTime = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[1]) : null
      // }
      // //人气认证通过时间
      // if(this.dataForm.popularityLevelTime != null){
      //   obj.popularityLevelStartTime = this.dataForm.popularityLevelTime.length != 0 ? formatDateTime(this.dataForm.popularityLevelTime[0]) : null
      //   obj.popularityLevelEndTime = this.dataForm.popularityLevelTime.length != 0 ? formatDateTime(this.dataForm.popularityLevelTime[1]) : null
      // }

      // memberRecordExport( obj ).then( ref => {
      //   if (Object.keys(ref)[0] === "401") {  //获取后端状态码为401时表示登录超时
      //         this.$message.success("登录超时!");
      //   } else {
      //     goDownloadExcel(ref, '用户明细');  //接口返回文件流
      //     return this.$message.success("下载完成!");
      //   }
      // }).catch(error => {
      //     this.$message.error("下载失败");
      // });
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },

    // 详情
    addOrUpdateHandle (id) {
      this.$nextTick(() => {
        this.$refs.userDetailds.init(id)
      })
    },

    // 新增 / 修改
    openAddOrUpdate (id='') {
        this.addOrUpdateShow = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateRef.init(id)
      })
    },
    // 认证
    renZhengFun(){
      let listCopy = []
      this.multipleSelection.forEach( item => {
        if (item.authState == 0) {
          listCopy.push(item)
        }
      })
      if(listCopy.length == 0) {
        this.$message("勾选中没有可认证的！")
        return
      }
      let names = ''
      let ids = []
      listCopy.forEach( item => {
        names += '（'+item.nickName+"）"
        ids.push(item.id)
      })
      this.$confirm(names+'即将成为认证作者，请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定认证',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = {
          ids: ids,
          state: 2,
          infoCompanyId: -1
        }
        userAuthority( info ).then( res => {
          this.getDataList(this.pageIndex)  
          this.$message.success("修改成功")
        }).catch((error) => {
          this.$message("修改失败", error)
        })
      }).catch(() => { });
    },
    // 打开推荐
    openRecommend(){
      this.recommendList = this.multipleSelection
      this.visibleTui = true
    },
    // 推荐关注
    recommendFun(){
      let list = []
      this.recommendList.forEach( item => {
        list.push({
          userId: item.id,
          sortNum: item.recommendNum,
          status: item.recommendState
        })
      })
      userRecommend( list ).then( res => {
        this.getDataList(this.pageIndex)  
        this.visibleTui = false
        this.$message.success("修改成功")
      }).catch((error) => {
        this.$message("修改失败", error)
      })
    }

  }
}
</script>
<style scoped>
.tui_col_div{
  width: 100%;
  text-align: center;
  margin-bottom: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.id_name_div{
  text-align: left;
  line-height: 10px;
  width: 180px; /* 设置div的宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>