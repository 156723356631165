// 本地统一存储字典
export const qualStateDisctE = [   // 用户认证状态字典
  {value: 0, name: '无'},
  {value: 1, name: '企业'},
  {value: 2, name: '个人'},
]

export const authStateDisctE = [   // 用户认证状态字典
  {value: 0, name: '未认证'},
  {value: 1, name: '已认证'},
]

export const companyTypeE = [   // 企业类型字典
  {value: 1, name: '普通企业'},
  {value: 2, name: '独角兽'},
]

export const companyStateDisctE = [   // 企业上线状态字典
  {value: 1, name: '上架'},
  {value: 2, name: '下架'},
]


export const moduleDisctE = [   // 模块字典
  {value: 1, name: '资讯'},
  {value: 2, name: '快讯'},
  {value: 3, name: '社区'},
  {value: 7, name: '招聘'},
  {value: 9, name: '视频'},
]

export const sourceTypeDisctE = [   // 资讯文章来源
  {value: 3, name: '采集'},
  {value: 1, name: '创作者发布'},
  {value: 2, name: '后台发布'},
  {value: 4, name: '独角兽代写'},
]

export const flashSourceTypeDisctE = [   // 快讯资讯文章来源
  {value: 2, name: '后台发布'},
  {value: 3, name: '采集'},
]

export const communitySourceTypeDisctE = [   // 动态文章来源
  {value: 2, name: '后台发布'},
  {value: 3, name: '采集'},
]

export const videoSourceTypeDisctE = [   //视频资讯来源
  {value: 1, name: '创作者发布'},
  {value: 2, name: '后台发布'},
  {value: 4, name: '独角兽代写'},
]
export const flashCrawlerChannelDisctE = [   //快讯采集来源渠道
  {value: 1, name: '金色财经'},
  {value: 2, name: '金10数据'},
]

export const rqDisctE = [   // 发布端口渠道
  {value: 0, name: '其他'},
  {value: 1, name: 'PC'}, 
  {value: 2, name: '客户端'},
  {value: 3, name: 'web端'},
]

export const crawlerChannelDisctE = [   //资讯采集来源渠道
  {value: 100, name: '金色财经'},
  {value: 101, name: 'WORLD CASINO DIRECTORY'},
  {value: 102, name: 'GgrAsia'},
  {value: 103, name: '灰度'},
  {value: 104, name: 'asgam'},
  {value: 105, name: '金10数据-资讯'},
]

export const newsStateDisct = [   // 资讯审核状态
  {value: 1000, name: '等待审核'},
  {value: 1001, name: '审核通过'},
  {value: 1003, name: '已被编辑过'},
  {value: 1004, name: '拒绝'},
  {value: 1005, name: '回收站'},
]

export const timeTypeDisctE = [   // 资讯时间态状态字典
  {value: 1, name: '等待时间发布中'},
  {value: 2, name: '已发布'},
  {value: 3, name: '待审核'},
]

export const advertisingTypeE = [   // 广告类型字典
  {value: 2, name: 'APP'},
  {value: 1, name: 'Web'},
]

export const adAppMmduleE = [   // 广告app模块字典
  {value: 1, name: '资讯'},
  {value: 2, name: '社区'},
  {value: 3, name: '开屏页'},
]

export const adWebMmduleE = [   // 广告web模块字典
  {value: 1, name: '首页'},
  {value: 2, name: '资讯'},
  {value: 3, name: '快讯'},
  {value: 4, name: '独角兽'},
  {value: 5, name: '招聘'},
  {value: 6, name: '社区'},
]

// export { 
//   qualStateDisctE,
//   authStateDisctE,
//   companyTypeE,
//   companyStateDisctE,
//   moduleDisctE,
//   sourceTypeDisctE,
//   videoSourceTypeDisctE,
//   crawlerChannelDisctE,
//   newsStateDisct,
//   timeTypeDisctE,
//   flashSourceTypeDisctE,
//   flashCrawlerChannelDisctE,
//   rqDisctE
// };