<template>
  <!-- 用户详情 -->
  <el-dialog
    title="用户详情"
    :close-on-click-modal="false"
    :visible.sync="visible" width="980px">
    <div style="padding: 20px; padding-top: 0px;">
      <el-form :model="dataForm" label-width="auto">
        <h2>基本信息</h2>
        <div class="form_div_bac">
          <div >
            <el-form-item label="头像">
              <viewer v-if="dataForm.headPic" >
                <img :src="$pathPrefix+dataForm.headPic" style="width: 100px; height: 100px; object-fit: contain;"/>
              </viewer>
            </el-form-item>
            <el-form-item label="用户ID"> <div class="mes_div">{{ dataForm.id }}</div></el-form-item>
            <el-form-item label="昵称"> <div class="mes_div">{{ dataForm.nickName }}</div></el-form-item>
            <el-form-item label="性别"> 
              <div class="mes_div">{{dataForm.gender | stateFilter(sexDisct)}}</div>
            </el-form-item>
            <el-form-item label="出生日期">
              <div class="mes_div">{{ dataForm.bir | showDate()}}</div>
            </el-form-item>
            <el-form-item label="手机号"> 
              <div style="display: flex;">
                <div class="mes_div" style="width: 40px;margin-right: 10px;">{{ dataForm.countryCode }}</div>
                <div class="mes_div" style="width: 216px;">{{ dataForm.phone }}</div>
              </div>
            </el-form-item>
            <el-form-item label="邮箱地址"> <div class="mes_div">{{ dataForm.email }}</div></el-form-item>
            <el-form-item label="个人简介"> <div class="mes_div">{{ dataForm.content }}</div></el-form-item>
          </div>
          <div >
            <el-form-item label="行业"> <div class="mes_div">{{ dataForm.systemIndustryName }}</div></el-form-item>
            <el-form-item label="常居国家"> <div class="mes_div">{{ dataForm.countryName }}</div></el-form-item>
            <el-form-item label="注册时间">
              <div class="mes_div">{{ dataForm.createTime | showDate()}}</div>
            </el-form-item>
            <el-form-item label="最近登录">
              <div class="mes_div">{{ dataForm.updateTime | showDate()}}</div>
            </el-form-item>
            <el-form-item label="目标市场">
              <div class="mes_div">{{ dataForm.targetCountryStr}}</div>
            </el-form-item>
            <!-- <el-form-item label="目标业务">
              <div class="mes_div">{{ dataForm.targetChannelStr }}</div>
            </el-form-item> -->
            <el-form-item label="资讯发布权限"> 
              <div class="mes_div">{{dataForm.qualState | stateFilter(qualStateDisct)}}</div>
            </el-form-item>
            <el-form-item label="资讯发布企业"> 
              <div class="mes_div">{{dataForm.infoCompanyName}}</div>
            </el-form-item>
            <el-form-item label="招聘发布权限"> 
              <div class="mes_div">{{dataForm.recruitState | stateFilter(zhaopinStateDisct)}}</div>
            </el-form-item>
            <el-form-item label="招聘发布企业"> 
              <div class="mes_div">{{dataForm.recruitCompanyName}}</div>
            </el-form-item>
            <!-- <el-form-item label="认证状态"> 
              <div class="mes_div">{{dataForm.authState | stateFilter(authStateDisct)}}</div>
            </el-form-item> -->
            <el-form-item label="推荐状态"> 
              <div class="mes_div">{{dataForm.recommendState ? '已推荐' : '未推荐' }}</div>
            </el-form-item>
            <el-form-item label="推荐权重"> <div class="mes_div">{{ dataForm.recommendNum }}</div></el-form-item>
          </div>
        </div>
        <div class="fen_ge_xian"></div>
        <h2>创作方向</h2>
        <div class="form_div_bac">
          <el-form-item label="推广市场">
            <div class="mes_div"></div>
          </el-form-item>
          <el-form-item label="推广业务">
            <div class="mes_div"></div>
          </el-form-item>
        </div>
        <div class="fen_ge_xian"></div>
        <h2>互动</h2>
        <div class="form_div_bac">
          <div>
            <el-form-item label="粉丝数量">
              <div class="mes_div">{{ dataForm.fansNumber }}</div>
            </el-form-item>
            <el-form-item label="关注数量">
              <div class="mes_div">{{ dataForm.focusNumber }}</div>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="获赞数量">
              <div class="mes_div">{{ dataForm.likeNumber }}</div>
            </el-form-item>
            <el-form-item label="评论数量">
              <div class="mes_div">{{ dataForm.discussNumber }}</div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
  
  </el-dialog>
</template>

<script>
import { userDetails } from '@/api/userManage'
import { utcUpdatedDate } from '@/utils'
import { countryCodeList } from '@/utils/countryCode.js'
import { qualStateDisctE, authStateDisctE } from '@/utils/disct.js'
export default {
  data () {
    return {
      countryList: countryCodeList,
      qualStateDisct: qualStateDisctE,
      authStateDisct: authStateDisctE,
      zhaopinStateDisct: [
        {value: 0, name: '关闭'},
        {value: 1, name: '开启'},
      ],
      visible: false,
      userId:null,
      dataForm: {
        id: '',
        headPic: "",
        nickName: "",
        phone: "",
        email: "",
        createTime: "",
        updateTime: "",
        targetCountryStr: '',
        targetChannelStr: '',
        targetCountrylist: [],
        targetChannellist: [],
        gender: 0,
        content: "",
        qualState: 0,
        fansNumber: 0,
        focusNumber: 0,
        likeNumber: 0,
        discussNumber: 0,
        qualState: 0, // 资讯发布权限（0：不能发布；1：企业；2：个人）
        authState: 0, //	认证状态（0：未认证；1：已认证）
        recommendState: false, // 推荐状态
        recommendNum: 0, // 推荐权重
      },
      sexDisct: [   //性别字典
        {value: '0', name: '女'},
        {value: '1', name: '男'},
      ],
    }
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value == state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
    countryFilter(id) {
      let item = countryCodeList.find( item => item.id == id)
      return item ? '+'+item.name : '';
    },
  },
  mounted () {
  },
  methods: {
    // 初始化
    init (id) {
      this.userId = id
      this.dataForm = {}
      this.$openLoading()
      userDetails( {userId: this.userId} ).then((res) => {
        this.visible = true
        this.$closeLoading()
        this.dataForm = res.data
        this.dataForm.targetCountryStr = ''  // 市场
        // this.dataForm.targetChannelStr = '' // 业务
        if(this.dataForm.targetCountrylist.length > 0){
          this.dataForm.targetCountrylist.forEach( (country, index) => {
            this.dataForm.targetCountryStr += this.dataForm.targetCountrylist.length != index+1 ? country.name+' / ' : country.name
          })
        }
        // if(this.dataForm.targetChannellist.length > 0){
        //   this.dataForm.targetChannellist.forEach( (channel, index) => {
        //     this.dataForm.targetChannelStr += this.dataForm.targetChannellist.length != index+1 ? channel.name+' / ' : channel.name
        //   })
        // }
        // 没有头像使用是同默认头像
        this.dataForm.headPic = this.dataForm.headPic ? this.dataForm.headPic : this.$systemAvatar
      }).catch((error) => {
        this.$closeLoading()
        this.$message.error(error);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form_div_bac{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mes_div{
  width: 300px;
  min-height: 32px;
  line-height: 32px;
  border: 1px solid #DCDFE6;
  background-color: #FFF;
  padding: 0 15px;
  border-radius: 4px;
  color: #84909D;
}
.mes_tags_div{
  min-height: 32px;
  line-height: 32px;
  padding: 0 15px;
  background-color: #FFF;
  border: 1px solid #DCDFE6;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.fen_ge_xian{
  width: 100%;
  height: 2px;
  background: #9A9FA6;
  border-radius: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
}
h2{
  margin-bottom: 34px;
  color: #000000;
}
.el-form-item {
  margin-bottom: 10px !important;
}
</style>
