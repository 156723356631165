<template>
  <div>
    <!-- 资讯列表 -->
    <el-dialog
      title="话题动态"
      :close-on-click-modal="false"
      :visible.sync="visible" width="60vw">
      <div style="min-height: 50vh;">
        <selectNewsCom @refreshDataList="getDataList(page.pageNumber)"
          v-if="visible" :infoId="infoId" inputId="2" style="margin-bottom: 20px;"/>
        <!-- height="500" v-adaptive="{bottomOffset: 120,iModal: true}" -->
        <el-table
          :data="page.list" border v-loading="dataListLoading"
          :cell-style="tableStyle" :header-cell-style="tableStyle" >
          <el-table-column label="创建时间" width="150">
            <template slot-scope="scope">
              {{scope.row.createTime | showDate()}}
            </template>
          </el-table-column>
          <el-table-column label="文章ID" prop="id" width="150"/>
          <el-table-column label="内容">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p  style=" width: 300px;" class="content_div_class">{{ scope.row.content }}</p>
                <div slot="reference"  style="display: flex;align-items: center;justify-content: center;">
                  <div class="content_div_class">{{ scope.row.content }}</div>
                  <div v-if="scope.row.urlList != 0"  style="margin-left: 12px;">
                    <img v-for="(img, imgIndex) in scope.row.urlList" :key="imgIndex"
                      :src="$pathPrefix+img" style="width: 32px;height: 32px;"/>
                  </div>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="240" label="操作">
            <template slot-scope="scope">
              <el-button type="success" plain size="small" @click="goAuditOrEdit(scope.row.id)">浏览</el-button>
              <el-button type="primary" size="mini" plain @click="interactFun(scope.row.id)">评论/点赞</el-button>
              <el-button type="warning" plain size="mini" @click="deleteFun(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination 
          background
          @current-change="getDataList"
          :current-page="page.pageNumber"
          :page-size="page.pageSize"
          :total="parseInt(page.total)"
          layout="total,prev, pager, next, jumper">
        </el-pagination>
      </div>

    
    </el-dialog>
    <!-- 预览弹窗 -->
    <previewModel ref="previewModelRef"/>
    <!-- 评论点赞弹窗 -->
    <InteractDiaCom ref="InteractDiaComRef"/>
  </div>
</template>

<script>
import { utcUpdatedDate } from '@/utils'
import {  auditList } from '@/api/newsManage'
import selectNewsCom from './selectNewsCom'
import InteractDiaCom from '../com/InteractDiaCom.vue'
import { hotTopicPostDelete } from '@/api/configManage'
export default {
  name: 'hotTopicNews',
  components: { selectNewsCom, InteractDiaCom },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch : {
		'visible' : function(val){
      if(!val){
        this.$emit('update:show', false)
      }
		}
	},
  data () {
    return {
      pageModule: 3,
      visible: this.$props.show,
      infoId: null,
      module: 1,
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        list: []
      },
      dataListLoading: false,

    }
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value == state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
  },
  mounted () {
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 初始化
    init (row) {
      this.infoId = row.id
      this.getDataList(1)
    },
    
    // 获取数据列表
    getDataList (pageNumber) {
      this.page.pageNumber = pageNumber ? pageNumber : 1
      this.dataListLoading = true
      let obj = {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        hotTopicId: this.infoId,
        module: this.pageModule,
      }
      this.$openLoading()
      auditList( obj ).then( res => {
        this.$closeLoading()
        this.visible = true
        let list = res.data
        if(list && list.length != 0){ // 循环是否有图片
          list.forEach( item => {
            item.urlList = []
            if(item.cover){
              item.urlList = item.cover.split(',')
            }
          });
        }
        this.page.list = list
        this.page.total = res.totalCount;
        this.dataListLoading = false
      }).catch((error) => {
        this.$closeLoading()
        this.$message.error(error)
        this.dataListLoading = false
      })
    },
    // 预览
    goAuditOrEdit(id){
      let info = {
        id: id,
        module: this.pageModule,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      this.$refs.previewModelRef.open(info)
    },
    // 删除
    deleteFun(id){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = {
          postId: id,
          hotTopicId: this.infoId,
        }
        hotTopicPostDelete( info ).then( res => {
          this.getDataList(this.page.pageNumber)  
          this.$message.success("删除成功")
        }).catch((error) => {
          this.$message("删除失败"+error)
        })
      }).catch(() => { });
    },
    
    //评论/点赞
    interactFun(id){
      let info = {
        id: id,
        module: this.pageModule,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      this.$refs.InteractDiaComRef.open(info)
    },

  }
}
</script>
<style scoped>
>>> .el-dialog__body{
  padding: 10px 20px !important;
}
</style>
<style lang="scss" scoped>
.content_div_class{
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
