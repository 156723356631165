<!-- 后台创建文章相关 -->
<template>
  <div class="container">
    <el-radio-group v-model="dataForm.module" @change="getDataList()" size="mini">
      <el-radio-button :label="item.value" v-for="item in moduleDisct" :key="item.value">{{ item.name }}</el-radio-button>
    </el-radio-group>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" style="padding: 10px 0;">
      <el-form-item label="草稿标题">
        <el-input v-model="dataForm.search" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="保存时间">
        <el-date-picker v-model="dataForm.createTime" type="datetimerange" @change="getDataList()"  @clear="getDataList()"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getDataList()">查询</el-button>
        <el-button type="warning" size="small" plain autofocus  
          :disabled="multipleSelection == 0" @click="deleteFun()">批量删除</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <el-table height="100" v-adaptive="{bottomOffset: 50,iModal: false}"
      :data="this.page.list" border v-loading="dataListLoading"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"/>
      <el-table-column prop="draftTitle" label="草稿标题"> </el-table-column>
      <el-table-column prop="createTime" label="保存时间">
        <template slot-scope="scope">
          {{ scope.row.createTime | matDateTime(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="160" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" plain @click="updateFun(scope.row)">编辑</el-button>
          <el-button type="warning" size="small" plain @click="deleteFun(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
      
    </el-table>
    <div class="pagination">
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="page.pageNumber"
        :page-size="page.pageSize"
        :total="parseInt(page.total)"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { formatDateTime, utcUpdatedDate } from '@/utils'
import {  draftList, deleteDraft } from '@/api/newsManage'

export default {
  name: 'articleDraftList',
  data () {
    return {
      multipleSelection: [],
      moduleDisct: [   //购买方式
        {value: 1, name: '资讯'},
        {value: 2, name: '快讯'},
        {value: 3, name: '社区'},
        {value: 9, name: '视频'},
      ],
      dataForm: { 
        search: '',
        createTime: [],
        module: 1,
      },
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        list: []
      },
      dataListLoading: false,
      addOrUpdateVisible: false,
      deleteType: 0,
    }
  },
  mounted () {
    
  },
  activated() {
    // 需要重新请求的写在这里
    this.getDataList(this.page.pageNumber)
  },
  filters:{
    // 将日期对象转成指定格式的日期时间字符串
    matDateTime (date) { 
      return utcUpdatedDate(date)
    }
  },
  methods: {
    //列表内容居中
    tableStyle(){
      return "text-align:center"
    },
    // 获取数据列表
    getDataList (pageNumber) {
      this.page.pageNumber = pageNumber ? pageNumber : 1
      this.dataListLoading = true
      let obj = {
          pageNumber: this.page.pageNumber,
          pageSize: this.page.pageSize,
          module: this.dataForm.module,
      }
      if(this.dataForm.search) obj.title = this.dataForm.search.trim()
      if(this.dataForm.createTime && this.dataForm.createTime.length > 0){
        obj.startCreateDate = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[0]) : null
        obj.endCreateDate = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[1]) : null
      }
      draftList( obj ).then( res => {
        this.page.list = res.data
        this.page.total = res.totalCount;
        this.dataListLoading = false
      }).catch((error) => {
        this.page.list = []
        this.dataListLoading = false
        this.$message.error(error);
      })
    },
    // 勾选改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //删除
    deleteFun(id){
      let ids = []
      if(id){
        ids.push(id)
      }else{
        this.multipleSelection.forEach( item => {
          ids.push(item.id)
        })
      }
      this.$confirm('确认删除！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = {
          module: this.dataForm.module,
          ids: ids
        }
        deleteDraft( info ).then( res => {
          this.$message.success("删除成功")
          this.getDataList(this.pageNumber)
        }).catch((error) => {
          this.$message.error("删除失败"+ error)
        })
      }).catch(() => { });
    },
    // 编辑跳转编辑页
    updateFun(item){
      if(this.dataForm.module == 3){
        this.$router.push({
          path: "/createCommunity",
          query: { id: item.id, module:  this.dataForm.module},
        });
      }else if(this.dataForm.module == 9){
        this.$router.push({
          path: "/createVideoNews",
          query: { id: item.id, module:  this.dataForm.module},
        });
      }else{
        this.$router.push({
          path: "/createArticle",
          query: { id: item.id, module:  this.dataForm.module},
        });
      }
    }
  }
}
</script>
