export default {
  data() {
    return {
      windowSize: {
        height: document.body.clientHeight,
        width: document.body.clientWidth,
      },
    };
  },
  mounted() {
    this.__resizeHandler = () => {
      this.windowSize.height = document.body.clientHeight;
      this.windowSize.width = document.body.clientWidth;
    };
    window.addEventListener('resize', this.__resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.__resizeHandler);
  },
};
