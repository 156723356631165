<!-- 上传图片 -->
<template>
  <div style="display: flex;align-items: center;width:100%;height: 100%">
    <template  v-if="imageArr.length > 0">
      <div class="img_bacdiv" :style="sytleData" v-for="url in imageArr" :key="url">
        <viewer :images="imageArr">
          <img :src="$pathPrefix+url" :style="sytleData" style="object-fit: cover;"/>
        </viewer>
        <div class="img_icon_bac">
          <i class="el-icon-error close_img_icon" @click="deleteImg(url)"/>
        </div>
      </div>
    </template>
    <template v-if="!uploaing">
      <el-upload action="#" :show-file-list="false" :auto-upload="true" :on-change="upLoadFileFun" :limit="1" :style="sytleData"
        accept="image/*" v-if="imageArr.length < limit">
        <div class="upload_bac" :style="sytleData">
          <i slot="default" class="el-icon-plus"  style="font-size:20px"></i>
        </div>
      </el-upload>
    </template>
    <div :style="sytleData" v-else class="upload_loading_div">
      <i class="el-icon-loading" style="color:#000;font-size:18px"/>
    </div>
  </div>
  <!-- 使用  <uploadImg :images.sync="testList" :limit="3" v-if="crud.status.cu > 0" width="60px" height="60px"></uploadImg> -->
</template>

<script>
import { ossUploadZiName } from "@/utils/ossUpload";
import { systemSnowId } from '@/api/utilsManage'
import { getUUIDName } from '@/utils/index.js'
export default {
  name: 'uploadImgCollection',
  props: {
    image: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      default(){
        return []
      },
    },
    width: {
      type: String,
      default: '120px',
    },
    height: {
      type: String,
      default: '120px',
    },
    limit: {
      type: Number,
      default: 1,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      infoId: this.$props.newsId,
      uploaing: false,
      imageArr: [],
      sytleData: {
        width: this.$props.width,
        height: this.$props.height,
      }
    };
  },
  watch : {
		image : function(val){
      if(this.$props.limit == 1){
        this.imageArr = []
        if(val){
          this.imageArr.push(val)
        }
      }else{
        this.imageArr = val
      }
		},
		'images.length' : function(val){
      if(val > 0){
        this.imageArr = this.$props.images
      }else{
        this.imageArr = []
      }
		},
		'userId' : function(val){
      this.infoId = val
		},
		'infoId' : function(val){
      this.$emit('update:userId', val)
		},
    
	},
  created() {
  },
  mounted() {
    if(this.$props.limit == 1){
      this.imageArr = []
      if(this.$props.image){
        this.imageArr.push(this.$props.image)
      }
    }else{
      this.imageArr = this.$props.images
    }
  },
  methods: {
    //cos上传图片
    async upLoadFileFun (file) {
      if (file.status !== 'ready') return;
      let _this = this;
      if (file.raw) {
        this.uploaing = true
        if(!this.infoId){
          let res = await systemSnowId().catch( error => { 
            this.$message('上传图片失败！')
          });
          this.infoId = res.data.data
        }
        let uuid = getUUIDName()
        let urlCenter = `b/collection/${this.infoId}/${uuid}`
        let urlCopy = await ossUploadZiName(file.raw, urlCenter).catch((error) => {
          this.uploaing = false
          this.$message.error(error);
        })
        if(!urlCopy) return
        _this.imageArr.push(urlCenter)
        this.uploaing = false
        if(_this.$props.limit > 1){
          _this.$emit('update:images',  _this.imageArr)
        }else{
          _this.$emit('update:image', _this.imageArr[0])
        }
      }
    },
    //删除图片
    deleteImg(url){
      this.imageArr = this.imageArr.filter( img => img != url)
      if(this.$props.limit > 1){
        this.$emit('update:images',  this.imageArr)
      }else{
        this.$emit('update:image', '')
      }
    }
  },
  destroyed() {
    this.imageArr = []
  },
};
</script>
<style scoped>
>>> .el-upload--text {
  border: 0px dashed transparent !important;
  width: calc(100% + 3px);
  height: calc(100% + 3px);
}
</style>
<style lang="scss" scoped>

.upload_bac{
  background: #FBFDFF;
  border: 1.5px dashed #C0CCDA;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_bacdiv{
  margin-right: 10px;
  position: relative;
}
.img_icon_bac{
  position: absolute;
  right: 3%;
  top: 3%;
  background: white;
  border-radius: 100%;
}
.close_img_icon{
  cursor: pointer;
  color: #000;
  font-size: 16px;
  display: block;
}
.upload_loading_div{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px dashed #C0CCDA;
  border-radius: 4px;
}
</style>
