<template>
  <!-- 富文本组件 simple default -->
  <div style="border: 1px solid #ccc;" class="com_wang_editor">
    <Toolbar style="border-bottom: 1px solid #ccc"
             :editor="editor"
             :defaultConfig="toolbarConfig"
             mode="simple"
    />
    <!-- @customPaste="handlePaste" -->
    <Editor class="com_editer_class"
            v-if="isLoanding"
            v-model="htmlContent"
            :style="{ height: height }"
            :defaultConfig="editorConfig"
            mode="simple"
            @customPaste="handlePaste"
            @onCreated="onCreated"/>
    <div class="wang_edit_loading" v-if="uploaing">
      <i class="el-icon-loading" style="color:#424548;font-size:36px"/>
    </div>
  </div>
</template>
<script>
import {ossUpload, ossUploadStream} from "@/utils/ossUpload";
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {systemSnowId} from '@/api/utilsManage'
import {setStyleFun, removeStyleFun} from '@/utils'
const randomStr = Math.random().toString().replace('.', '');
export default {
  name: 'comWangEditor',
  components: {Editor, Toolbar},
  props: {
    isLoanding: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '300px'
    },
    module: { // 模块（1资讯2快讯3社区）
      type: Number,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      styleHref: '',
      uploaing: false,
      htmlContent: '',
      editor: null,
      toolbarConfig: {
        /* 需要的工具 */
        // toolbarKeys: [ 
        //   'header1', 
        // ],
        /* 隐藏哪些菜单 */
        excludeKeys: [
          // "headerSelect",// 标题
          // "blockquote", // 引用
          // "bold", // 加粗
          // "underline", // 下划线
          // "italic", // 斜体
          // "group-more-style", // 删除线、清除格式等
          // "color", // 文字颜色
          // "bgColor", // 背景色
          "fontSize", // 字号
          "fontFamily", // 字体
          "lineHeight", // 行高
          // "bulletedList", // 无序列表
          // "numberedList", // 有序列表
          // "todo", // 代办
          // "group-justify", // 对齐
          // "group-indent", // 缩进
          // "emotion",// 表情
          // "insertLink",// 插入链接
          // "group-image",// 上传图片
          "insertVideo", // 插入视频
          "group-video",// 上传视频
          // "insertTable",// 插入表格
          "codeBlock", // 代码块
          // "divider", // 分割线
          // "undo", // 撤销
          // "redo", // 重做
          // "fullScreen" // 全屏
        ],
      },
      upLoading: false,
      editorConfig: {
        placeholder: '',
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          //配置上传图片
          uploadImage: {
            //自定义上-oss
            customUpload: async (file, insertFn) => {
              this.uploaing = true
              if (this.$props.module) { // 资讯相关
                let infoId = ''
                if (this.$props.id) {
                  infoId = this.$props.id
                } else {
                  let res = await systemSnowId().catch(error => {
                    this.$message('上传图片失败！')
                  });
                  infoId = res.data.data
                }
                let mdHou5 = this.$Md5(infoId).slice(-5);
                let namePrefix = `/b/${mdHou5}/information/${this.$props.module}-${infoId}/`
                const resultUrl = await ossUpload(file, namePrefix).catch((error) => {
                  this.uploaing = false
                })
                this.uploaing = false
                console.log("上传成功", resultUrl)
                insertFn(resultUrl)

              } else {
                const resultUrl = await ossUpload(file, this.$companyIcon).catch((error) => {
                  this.uploaing = false
                })
                this.uploaing = false
                console.log("上传成功", resultUrl)
                insertFn(resultUrl)
              }
            }
          },
          // 配置上传视频（同上传图片）
          uploadVideo: {},
          keepSpaceBarIntoP: false, // 允许在段落标签内自动换行
          typora: {
            keepSpaceBarIntoP: false, // 允许在段落标签内自动换行
          },
        },
        onPaste: (event) => {
          console.log("粘贴")
        }
      },
    }
  },
  watch: {
    htmlContent: function (val) {
      val = val.replace(/ <img/g, '<img')
      this.$emit('update:content', val)
    },
    'content': function (val) {
      this.htmlContent = this.returnContent(val)
     // this.editor.setHtml(this.returnContent(val))
    },
  },
  mounted() {
    this.htmlContent = this.returnContent(this.$props.content)
    // 注入样式
    this.styleHref = setStyleFun('.w-e-text-container', this.$props.module)
  },
  methods: {
    refreshContent(content) {
      this.htmlContent = this.returnContent(content)
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      this.editor.setHtml(this.returnContent(this.$props.content))
    },
    returnContent(content) {
      if (content) {
        content = content.replace('<html>\n <head></head>\n <body>\n  <div>\n', '')
        content = content.replace('</div>\n </body>\n</html>', '')
        content = content.replace('<html>\n <head></head>\n <body>\n  ', '')
        content = content.replace('\n </body>\n</html>', '')
        content = content.replace('<html>', '')
        content = content.replace('<head>', '')
        content = content.replace('<body>', '')
        content = content.replace(/<div>/g, '')
        content = content.replace(/<section>/g, '')
        content = content.replace('</html>', '')
        content = content.replace('</head>', '')
        content = content.replace('</body>', '')
        content = content.replace(/<\/div>/g, '')
        content = content.replace(/<\/section>/g, '')

        content = content.replace(/<img/g, ' <img')
        content = content.replace(/<pre>/g, ' <p>')
        content = content.replace(/<\/pre>/g, '</p>')
        content = content.replace(/<figcaption>/g, '')
        content = content.replace(/<\/figcaption>/g, '')
        content = content.replace(/<figure>/g, '')
        content = content.replace(/<\/figure>/g, '')
        
        // content = content.replace(/<span>/g, '')
        // content = content.replace(/<\/span>/g, '')
        // content = content.replace(/<strong>/g, '')
        // content = content.replace(/<\/strong>/g, '')
      }
      return content
    },
    // 粘贴事件处理内容
    async handlePaste(pasteData, event, callback) {
      if (!event.clipboardData) {
        return;
      }

      const types = event.clipboardData.types;
      if (types.length == 1 && types[0].startsWith('image/')) {
        // 图片资源走默认行为就行
        return;
      }

      event.preventDefault();

      // 所有数据当做文本处理
      const html = event.clipboardData.getData('text/html');
      const doc = new DOMParser().parseFromString(html, 'text/html');
      const div = document.createElement('div');
      try {
        this.uploaing = true;
        await this.eachNode([...doc.body.childNodes], div);
      } catch (err) {
      } finally {
        this.uploaing = false;
      }
      pasteData.dangerouslyInsertHtml(div.innerHTML.replace(new RegExp(randomStr, 'ig'), '<br />'));
      // pasteData.dangerouslyInsertHtml(div.innerHTML);
    },
    async eachNode(nodes, rootEl, bool = true, tag = 'p') {
      let boxEl = rootEl;
      if (!bool) {
        boxEl = document.createElement(tag);
      }
      let flag = true;
      for (const child of nodes) {
        if (child.nodeType == 3) {
          // 文本节点
          this.appendTextNode(boxEl, child.textContent.trim());
          continue;
        }

        if (!child.tagName) {
          continue;
        }

        if (child.tagName == 'IMG') {
          // 图片处理
          let src = child.getAttribute('src');
          if (!src) {
            src = child.getAttribute('data-src');
          }
          if (!src) {
            src = child.getAttribute('srcset');
          }

          if (src) {
            // 静默处理
            try {
              // 加个代理，防止图片下载失败
              const url = await this.uploadImg(`https://website-test.pasa365.com/api/utils/download?url=${encodeURIComponent(src)}`);
              const img = document.createElement('img');
              img.src = url;
              rootEl.appendChild(img);
            } catch (err) {
            }
          }
          continue;
        }

        if (
            ['a', 'span', 'strong', 'em', 'br', 'label', 'input', 'button', 'select', 'textarea', 'sub', 'sup', 'i', 'b', 'u', 'code']
                .includes(child.tagName.toLowerCase())
        ) {
          if (
            !child.getElementsByTagName('p').length
          ) {
            const html = child.innerHTML;
            child.innerHTML = html.replace(/<br \/>|<br >|<br>|<br\/>/ig, randomStr);
            this.appendTextNode(boxEl, child.textContent.trim());
            continue;
          }
        }

        if (!bool) {
          flag = false;
          rootEl.appendChild(boxEl);
        }
        await this.eachNode(
            [...child.childNodes],
            rootEl,
            false,
            /h\d/i.test(child.tagName) ? child.tagName.toLowerCase() : undefined,
        );
      }

      if (!bool && flag) {
        rootEl.appendChild(boxEl);
      }
    },
    appendTextNode(boxEl, text) {
      if (boxEl.lastChild && boxEl.lastChild.nodeType == 3) {
        boxEl.lastChild.textContent += text;
        return;
      }

      boxEl.appendChild(document.createTextNode(text));
    },
    // 粘贴事件上传图片
    async uploadImg(file) {
      if (this.$props.module) { // 资讯相关
        let infoId = ''
        if (this.$props.id) {
          infoId = this.$props.id
        } else {
          let res = await systemSnowId();
          infoId = res.data.data
        }
        let mdHou5 = this.$Md5(infoId).slice(-5);
        let namePrefix = `/b/${mdHou5}/information/${this.$props.module}-${infoId}/`
        const resultUrl = await ossUploadStream(file, namePrefix, true);
        return resultUrl;
      }

      const resultUrl = await ossUploadStream(file, this.$companyIcon, true);
      return resultUrl;
    },
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
    removeStyleFun(this.styleHref)
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.com_wang_editor {
  width: 100%;
  z-index: 10;
}

.com_editer_class {
  width: 100%;
  overflow-y: hidden;
}

.wang_edit_loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px dashed #C0CCDA;
  border-radius: 4px;
}
</style>
<style scoped>
>>> .w-e-bar-item .active {
  background: rgba(100, 100, 100, 0.4) !important;
}

>>> .w-e-bar-item button:hover {
  background: rgba(100, 100, 100, 0.4) !important;
}

>>> .w-e-image-container {
  width: 100% !important;
}

>>> .w-e-hover-bar {
  display: none !important;
}

>>> .w-e-text-container blockquote {
  background-color: transparent !important;
}

>>> .w-e-text-container blockquote span {
  color: inherit !important;
  font-size: inherit !important;
}
>>> code{
  background-color: transparent !important;
}
</style>