<template>
  <!-- 国家专区 -->
  <div class="container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" style="padding: 10px 0;">
      <el-form-item label="国家名称">
        <el-input v-model="dataForm.search1" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="监管机构">
        <el-input v-model="dataForm.search2" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="博彩合法性">
        <el-select v-model="dataForm.select2" size="mini" placeholder="请选择" clearable  
          style="width: 120px;" @change="getDataList()">
          <el-option v-for="item in gamingStateDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="登录页热门国家">
        <el-select v-model="dataForm.select1" size="mini" placeholder="请选择" clearable  @change="getDataList()" allow-create=""
          style="width: 120px;">
          <el-option v-for="item in hotStateDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="首页展示状态">
        <el-select v-model="dataForm.select3" size="mini" placeholder="请选择" clearable  @change="getDataList()" allow-create=""
          style="width: 120px;">
          <el-option v-for="item in indexShowStateDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      
      <el-form-item>
        <el-button type="primary" @click="getDataList()">查询</el-button>
        <!-- <el-button type="primary" @click="openAddOrUpdate()">新增</el-button> -->
      </el-form-item>
    </el-form>

    <!--用户列表   height="500" v-adaptive="{bottomOffset: 50,iModal: false}"-->
    <el-table
      :data="dataList" border v-loading="dataListLoading"
      height="100" v-adaptive="{bottomOffset: 50,iModal: false}"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;">
      <el-table-column prop="countryId" label="国家ID"></el-table-column>
      <el-table-column prop="countryName" label="国家名称"> </el-table-column>
      <!-- <el-table-column prop="cover" label="图片">
        <template slot-scope="scope">
          <viewer>
            <img :src="$pathPrefix+scope.row.cover" v-if="scope.row.cover"
              style="width: 60px; height: 60px; object-fit: contain;"/>
          </viewer>
        </template>
      </el-table-column> -->
      <el-table-column prop="rellayBrowNum" label="真实浏览量"> </el-table-column>
      <el-table-column prop="fixBrowNum" label="固定浏览量"> </el-table-column>
      <el-table-column prop="introduction" label="介绍">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 300px;" class="content_p_class_clamp">{{ scope.row.introduction }}</p>
            <div slot="reference"  style="display: flex;align-items: center;justify-content: center;">
              <div class="content_div_class_clamp">{{ scope.row.introduction }}</div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="博彩合法性">
        <template slot-scope="scope">
          {{scope.row.gamingState | stateFilter(gamingStateDisct)}}
        </template>
      </el-table-column>
      <el-table-column label="申请牌照攻略">
        <template slot-scope="scope">
          <div @click="goUrlFun(scope.row.licensePlateUrl)" class="go_url_div content_div_class_clamp">
            {{ scope.row.licensePlateUrl }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="机构合法性">
        <template slot-scope="scope">
          {{scope.row.regulatorsState | stateFilter(regulatorsStateDisct)}}
        </template>
      </el-table-column>
      <el-table-column prop="regulatorsName" label="监管机构"> </el-table-column>
      <el-table-column label="机构官网">
        <template slot-scope="scope">
          <div @click="goUrlFun(scope.row.regulatorsOfficialUrl)" class="go_url_div content_div_class_clamp">
            {{ scope.row.regulatorsOfficialUrl }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="权重"> </el-table-column>
      <el-table-column fixed="right" width="340" label="操作">
        <template slot-scope="scope">
          <div style="line-height:40px;justify-content: space-between;">
            <el-button type="primary" plain size="mini" @click="openAddOrUpdate(scope.row)">编辑</el-button>
            <el-button type="warning" size="mini" plain @click="updateHotState(scope.row)" 
              v-if="scope.row.hotState == 1">取消登录页热门</el-button>
            <el-button type="primary" size="mini" plain @click="updateHotState(scope.row)"  v-else>设置登录页热门</el-button>

            
            <el-button type="warning" size="mini" plain @click="updateIndexShowStatee(scope.row)" 
              v-if="scope.row.indexShowState == 1">取消首页热门</el-button>
            <el-button type="primary" size="mini" plain @click="updateIndexShowStatee(scope.row)"  v-else>设置首页热门</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>

    
    <!-- 修改 -->
    <el-dialog title="编辑" width='900px'
      :close-on-click-modal="false"
      :visible.sync="EditVisible">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
        <el-form-item label="国家名称">{{ ruleForm.countryName }}</el-form-item>
        <el-form-item label="国家ID">{{ ruleForm.countryId }}</el-form-item>
        <el-form-item label="总浏览量">
          <div style="display: flex;align-items: center;">
            {{ ruleForm.rellayBrowNum }}<span style="margin: 0 12px"> +</span>
            <el-input v-model="ruleForm.fixBrowNum" style="width: 215px;" maxlength="10" v-limitInput:positiveInteger/>
          </div>
        </el-form-item>
        <el-form-item label="国家介绍" prop="introduction">
          <el-input type="textarea" 
            v-model="ruleForm.introduction"
            maxlength="300"
            rows='4'
            show-word-limit>>
          </el-input>
        </el-form-item>
        <el-form-item label="博彩合法性" prop="gamingState">
          <el-radio-group v-model="ruleForm.gamingState">
            <el-radio :label="item.value" v-for="item in gamingStateDisct" :key="item.value">{{ item.name }}</el-radio>
          </el-radio-group> 
        </el-form-item>
        <el-form-item label="申请牌照攻略" v-if="ruleForm.gamingState==2">
          <el-input v-model="ruleForm.licensePlateUrl"/>
        </el-form-item>
        <el-form-item label="机构合法性" prop="regulatorsState" >
          <el-radio-group v-model="ruleForm.regulatorsState">
            <el-radio :label="item.value" v-for="item in regulatorsStateDisct" :key="item.value">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="ruleForm.regulatorsState==2">
          <el-form-item label="监管机构名称" prop="regulatorsName">
            <el-input v-model="ruleForm.regulatorsName"/>
          </el-form-item>
          <el-form-item label="监管机构官网">
            <el-input v-model="ruleForm.regulatorsOfficialUrl"/>
          </el-form-item>
        </template>
        <el-form-item label="权重" prop="sort">
          <el-input v-model="ruleForm.sort" style="width: 215px;" maxlength="10" v-limitInput:positiveInteger/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="EditVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>提交</el-button>
      </span>
    </el-dialog>
    
  </div>
</template>

<script>
import { formatDateTime,utcUpdatedDate } from '@/utils'
import { countyAreaList, countyAreaEdit } from '@/api/configManage'
export default {
  name: 'countyList',
  data () {
    let urlCheck = (rule, value, callback) => {
      const regex = /^https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*/;
      if(!value){
        callback();
      }else if (!regex.test(value)) {
        callback(new Error('不是合法的链接地址'));
      } else {
        callback();
      }
    };
    return {
      gamingStateDisct: [   //博彩合法性状态 1-不合法 2-合法 3-暂无相关信息
        {value: 3, name: '暂无相关信息'},
        {value: 1, name: '不合法'},
        {value: 2, name: '合法'},
      ],
      regulatorsStateDisct: [   //监管机构合法性状态 1-不合法 2-合法 3-暂无相关信息
        {value: 3, name: '暂无相关信息'},
        {value: 1, name: '不合法'},
        {value: 2, name: '合法'},
      ],
      hotStateDisct: [   //热门国家状态 1-热门 2-其它国家
        {value: 1, name: '热门'},
        {value: 2, name: '其它国家'},
      ],
      indexShowStateDisct: [   //首页热门国家状态 1-热门 2-其它国家
        {value: 0, name: '非热门'},
        {value: 1, name: '热门'},
      ],
      
      dataForm: {
        search1: '', 
        search2: '', 
        select1: '',
        select2: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      EditVisible: false,
      ruleForm: {},
      rules: {
        officialUrl: [
          { validator: urlCheck, trigger: 'blur' } // 自定义校验
        ],
        // introduction: [
        //   { required: true, message: '请输入', trigger: 'blur' },
        // ],
        // cover: [
        //   { required: true, message: '请选择图片', trigger: 'blur' },
        // ],
        regulatorsName: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        licensePlateUrl: [
          { validator: urlCheck, trigger: 'blur' } // 自定义校验
        ],
        regulatorsOfficialUrl: [
          { validator: urlCheck, trigger: 'blur' } // 自定义校验
        ],
      },
    }
  },
  created () {
    
  },
  mounted () {
    this.getDataList()
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 条件禁用列
    checkSelectable(row){
      if (row.qualState == 0) {
        return true
      } else {
        return false
      }
    },
    // 获取数据列表
    getDataList (pageNum) {
      this.pageIndex = pageNum ? pageNum : 1
      this.dataListLoading = true
      let obj = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
      }
      if(this.dataForm.search1) obj.countryName = this.dataForm.search1.trim()
      if(this.dataForm.search2) obj.regulatorsName = this.dataForm.search2.trim()
      if(this.dataForm.select1) obj.hotState = this.dataForm.select1
      if(this.dataForm.select2) obj.gamingState = this.dataForm.select2
      if(this.dataForm.select3 !== "") obj.indexShowState = this.dataForm.select3
      countyAreaList(obj).then(( data ) => {
        console.log("参数",data.data)
        this.dataList = data.data
        this.totalPage = data.totalCount
        this.dataListLoading = false
      }).catch((error) => {
        this.dataListLoading = false
        this.$message.error(error);
      })
    },

    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },

    // 打开编辑
    openAddOrUpdate(row) {
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.EditVisible = true
    },
    // 提交编辑
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let infoData = JSON.parse(JSON.stringify(this.ruleForm))
          if(!infoData.fixBrowNum) infoData.fixBrowNum = 0
          if( infoData.gamingState != 2){
            infoData.licensePlateUrl = ''
          }
          if( infoData.regulatorsState != 2){
            infoData.regulatorsName = ''
            infoData.regulatorsOfficialUrl = ''
          }
          this.$openLoading()
          countyAreaEdit( infoData ).then( res => {
            this.$closeLoading()
            this.EditVisible = false
            this.$message( {message: '操作成功',  type: 'success'} );
            this.getDataList(this.pageIndex)  
          }).catch((error) => {
            this.$closeLoading()
            this.$message.error(error );
          })
        } else {
          return false;
        }
      });
    },
    // 修改登录页热门国家
    updateHotState(row){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let infoData = JSON.parse(JSON.stringify(row))
        let stateNum = infoData.hotState == 1 ? 2 : 1
        infoData.hotState = stateNum
        this.$openLoading()
        countyAreaEdit( infoData ).then( res => {
          this.$closeLoading()
          this.$message( {message: '操作成功',  type: 'success'} );
          this.getDataList(this.pageIndex)  
        }).catch((error) => {
          this.$closeLoading()
          this.$message.error(error );
        })
      }).catch(() => { });
    },
    // 修改首页热门国家
    updateIndexShowStatee(row){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let infoData = JSON.parse(JSON.stringify(row))
        let stateNum = infoData.indexShowState == 1 ? 0 : 1
        infoData.indexShowState = stateNum
        this.$openLoading()
        countyAreaEdit( infoData ).then( res => {
          this.$closeLoading()
          this.$message( {message: '操作成功',  type: 'success'} );
          this.getDataList(this.pageIndex)  
        }).catch((error) => {
          this.$closeLoading()
          this.$message.error(error );
        })
      }).catch(() => { });
    },
    // 跳转网页
    goUrlFun(url){
      window.open(url, '_blank')
    }

  }
}
</script>
<style scoped>
.go_url_div{
  color: #1890FF;
  text-decoration: underline;
  cursor: pointer;
}
</style>