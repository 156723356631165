var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("v-head"),
      _c("v-sidebar"),
      _c(
        "div",
        {
          staticClass: "content-box",
          class: { "content-collapse": _vm.collapse }
        },
        [
          _c("v-tags"),
          _c(
            "div",
            { staticClass: "content home_router_view" },
            [
              _c(
                "keep-alive",
                [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
                1
              ),
              !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
              _c("el-backtop", { attrs: { target: ".content" } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }