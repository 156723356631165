var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-video",
      class: { "u-video-hover": !_vm.vplay },
      style: "width: " + _vm.width + "; height: " + _vm.height + ";"
    },
    [
      _c(
        "video",
        _vm._b(
          {
            ref: "video",
            attrs: {
              src: _vm.videoSrc,
              poster: _vm.poster,
              width: _vm.width,
              height: _vm.height,
              autoplay: _vm.autoplay,
              controls: !_vm.originPlay && _vm.controls,
              loop: _vm.loop,
              preload: _vm.preload,
              controlsList: "nodownload noplaybackrate",
              disablepictureinpicture: ""
            },
            domProps: { muted: _vm.autoplay || _vm.muted },
            on: {
              "~click": function($event) {
                $event.preventDefault()
                return _vm.onPlay.apply(null, arguments)
              }
            }
          },
          "video",
          _vm.$attrs,
          false
        ),
        [
          _vm._v(" 您的浏览器不支持video标签。 "),
          _c("source", { attrs: { src: _vm.videoSrc, type: "video/ogg" } }),
          _c("source", { attrs: { src: _vm.videoSrc, type: "video/mp4" } }),
          _c("source", { attrs: { src: _vm.videoSrc, type: "video/webm" } }),
          _c(
            "object",
            {
              attrs: {
                data: _vm.videoSrc,
                width: _vm.width,
                height: _vm.height
              }
            },
            [
              _c("embed", {
                attrs: {
                  src: _vm.videoSrc,
                  width: _vm.width,
                  height: _vm.height
                }
              })
            ]
          )
        ]
      ),
      _c(
        "svg",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.originPlay || _vm.showPlay,
              expression: "originPlay || showPlay"
            }
          ],
          staticClass: "u-play",
          class: { hidden: _vm.vplay },
          style:
            "width: " + _vm.playWidth + "px; height: " + _vm.playWidth + "px;",
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 48 48",
            fill: "none"
          },
          on: { click: _vm.onPlay }
        },
        [
          _c("g", { attrs: { filter: "url(#filter0_b_10621_1289)" } }, [
            _c("path", {
              attrs: {
                d:
                  "M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z",
                fill: "white",
                "fill-opacity": "0.9"
              }
            })
          ]),
          _c("path", {
            attrs: {
              d:
                "M20 24.0005V17.0723L26 20.5364L32 24.0005L26 27.4646L20 30.9287V24.0005Z",
              fill: "#206CFF"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }