<template>
  <div class="header_div">
    <!-- 折叠按钮 -->
    <div class="collapse-btn" @click="collapseChage">
      <i v-if="!collapse" class="el-icon-s-fold icon_class_default"></i>
      <i v-else class="el-icon-s-unfold icon_class_default"></i>
    </div>
    <div class="logo">
      <img class="logo_img" src='../../assets/img/logo.png'/>PASA后台
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 全屏显示 -->
        <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip :content="fullscreen?`取消全屏`:`全屏`" effect="dark" placement="bottom">
            <i class="el-icon-rank icon_class_default"></i>
          </el-tooltip>
        </div>
        <!-- 消息中心 -->
        <!-- <div class="btn-bell">
            <el-tooltip
                effect="dark"
                :content="message?`有${message}条未读消息`:`消息中心`"
                placement="bottom"
            >
                <router-link to="/tabs">
                    <i class="el-icon-bell"></i>
                </router-link>
            </el-tooltip>
            <span class="btn-bell-badge" v-if="message"></span>
        </div> -->
        <!-- 用户头像 -->
        <div class="user-avator">
          <img src="../../assets/img/img.jpg"/>
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{ nickname }}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="loginout" divided>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import bus from '../common/bus';
import {mapActions} from 'vuex'
import {getUserInfo} from '@/config/util'

export default {
  data() {
    return {
      collapse: false,
      fullscreen: false,
      message: 2,
    };
  },
  computed: {
    nickname() {
      let recordVal = JSON.parse(getUserInfo());
      return recordVal.nickname;
      // return this.$store.state.user.userinfo ? this.$store.state.user.userinfo.nickname : '';
    }
  },
  methods: {
    ...mapActions([
      'handleLogOut'
    ]),
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == 'loginout') {
        this.handleLogOut().then(() => {
          this.$router.push("login")
        })
      }
    },
    // 侧边栏折叠
    collapseChage() {
      this.collapse = !this.collapse;
      bus.$emit('collapse', this.collapse);
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
  },
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  }
};
</script>
<style lang='scss' scoped>
$baiTextColor: rgb(51, 54, 57);
$heiTextColor: #e5e7eb;
$baiBacColor: #FFF;
$heiBacColor: #18181C;
$activeBacColor: rgba(100, 100, 100, 0.3);
.icon_class_default {
  color: $baiTextColor;
  margin-top: 6px;
}

.header_div {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  font-size: 22px;
  color: $baiTextColor;
  background: $baiBacColor;
}

.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 54px;
}

.collapse-btn:hover {
  background: $activeBacColor !important;
}

.header_div .logo {
  float: left;
  width: 250px;
  height: 54px;
  font-size: 18px;
  color: $baiTextColor;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo_img {
  width: 24px;
  height: 24px;
  display: block;
  margin-right: 8px;
}

.header-right {
  float: right;
  padding-right: 50px;
}

.header-user-con {
  display: flex;
  height: 54px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: $baiTextColor;
}

.btn-bell .el-icon-bell {
  color: $baiTextColor;
}

.user-avator {
  margin: 0 10px 0 20px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: $baiTextColor;
  cursor: pointer;
}

.el-dropdown-menu__item {
  text-align: center;
}
</style>
