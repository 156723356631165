<template>
  <div class="container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" style="padding: 10px 0;">
      <el-form-item label="企业ID">
        <el-input v-model="dataForm.search1" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="企业名称">
        <el-input v-model="dataForm.search2" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="企业英文名称">
        <el-input v-model="dataForm.search3" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="企业类型">
        <el-select v-model="dataForm.type" size="mini" placeholder="请选择" clearable  @change="getDataList()">
          <el-option v-for="item in companyType" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="企业状态">
        <el-select v-model="dataForm.state" size="mini" placeholder="请选择" clearable  @change="getDataList()">
          <el-option v-for="item in companyStateDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      
      <el-form-item label="主营业务" prop="gender">
        <el-cascader 
          size="mini"
          @change="getDataList()"
          filterable clearable collapse-tags
          :props="casProps"
          :options="channelCateDisct"
          v-model="dataForm.channelIds"
          :show-all-levels="false"
          :change-on-select="true">
        </el-cascader>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-date-picker v-model="dataForm.createTime" type="datetimerange" @change="getDataList()"  size="mini"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="openAddOrUpdate()">新增企业</el-button>
      </el-form-item>
    </el-form>

    <!--用户列表   height="500" v-adaptive="{bottomOffset: 50,iModal: false}"-->
    <el-table
      :data="dataList" border v-loading="dataListLoading"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;">
      <!-- :selectable="checkSelectable" -->
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="企业ID"></el-table-column>
      <el-table-column prop="name" label="企业名称"> </el-table-column>
      <el-table-column prop="nameEn" label="企业英文名称"> </el-table-column>
      <el-table-column prop="typeName" label="企业类型"> </el-table-column>
      <!-- <el-table-column prop="type" label="企业类型">
        <template slot-scope="scope">
          {{scope.row.type | stateFilter(companyType)}}
        </template>
      </el-table-column> -->
      <el-table-column prop="sort" label="权重排序"></el-table-column>
      <el-table-column prop="channelStr" label="主营业务" width="180">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p style=" width: 180px;">{{ scope.row.channelStr }}</p>
            <div slot="reference" class="table_li_text">{{ scope.row.channelStr }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="图片">
        <template slot-scope="scope">
            <viewer>
              <img :src="$pathPrefix+$companyIcon+scope.row.icon" 
                style="width: 60px; height: 60px; object-fit: contain;"/>
            </viewer>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="成立时间">
        <template slot-scope="scope">
          {{scope.row.createTime | showDate()}}
        </template>
      </el-table-column>
      <el-table-column prop="state" label="状态">
        <template slot-scope="scope">
          {{scope.row.state | stateFilter(companyStateDisct)}}
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="类型"/>
      <el-table-column fixed="right" width="240" label="操作">
        <template slot-scope="scope">
          <div style="line-height:40px;justify-content: space-between;">
            <el-button type="success" plain size="mini" @click="addOrUpdateHandle(scope.row.id)">详情</el-button>
            <el-button type="primary" plain size="mini" @click="openAddOrUpdate(scope.row.id)">编辑</el-button>
            <el-button type="primary" plain size="mini" @click="openNews(scope.row.id)">相关资讯</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- @size-change="sizeChangeHandle" layout="total, sizes, prev, pager, next, jumper"> -->
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>
    
    <!-- 弹窗, 详情-->
    <companyDetailds ref="companyDetailds" @refreshDataList="getDataList(pageIndex)"></companyDetailds>
    <!-- 弹窗, 公司相关资讯-->
    <companyNews ref="companyNewsRef"/>
    <!-- 弹窗, 新增 / 修改 -->
    <addOrUpdate ref="addOrUpdateRef" @refreshDataList="getDataList(pageIndex)" 
      v-if="addOrUpdateShow" :show.sync="addOrUpdateShow"></addOrUpdate>
      
  </div>
</template>

<script>
import { formatDateTime,utcUpdatedDate } from '@/utils'
import { getCompanyList } from '@/api/userManage'
import { systemTerritory, systemTerritoryTree } from '@/api/utilsManage'
import companyDetailds from './companyDetailds'
import addOrUpdate from './addOrUpdate'
import companyNews from './companyNews'
import { companyStateDisctE, companyTypeE } from '@/utils/disct.js'
export default {
  name: 'userManage',
  data () {
    return {
      onLineDisct: [   //在线状态字典
        {value: 0, name: '离线'},
        {value: 1, name: '当前在线'},
      ],
      companyStateDisct: companyStateDisctE,
      companyType: companyTypeE,
      channelCateDisct: [],
      casProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
        emitPath: false
      },

      dataForm: {
        search1: '',
        search2: '',
        search3: '',
        type: '',
        state: '',
        channelIds: [],
        createTime: [],//注册时间
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateShow: false,
      multipleSelection: [],
    }
  },
  components: {
    companyDetailds,
    addOrUpdate,
    companyNews
  },
  created () {
    this.getTerritory()
  },
  mounted () {
    this.getDataList()
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
    showCollege (value) {
      if (value && value.length>0) {
        let valueStr = ""
          for (const key in value) {
            valueStr = valueStr + value[key] + " ";
          }
        return valueStr
      }
    },
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 条件禁用列
    checkSelectable(row){
      if (row.qualState == 0) {
        return true
      } else {
        return false
      }
    },
    // 获取目标业务枚举列表 
    getTerritory(){
      systemTerritoryTree({type: 2, isIncludeCate: false}).then(( data ) => {
        this.channelCateDisct = []
        this.channelCateDisct = this.chuliChnnel(data.data)
      })
    },
    // 处理channelCateDisct数据
    chuliChnnel(list){
      list.forEach( item => {
        if(item.dataType == 1){
          item.disabled = true
        }else{
          item.disabled = false
        }
        if(item.children && item.children.length>0){
          this.chuliChnnel(item.children)
        }else{
          delete item.children
        }
      })
      return list
    },
    // 获取数据列表
    getDataList (pageNum) {
      this.pageIndex = pageNum ? pageNum : 1
      this.dataListLoading = true
      let obj = {
        pageNumber: this.pageIndex,
        pageSize: this.pageSize,
      }
      if(this.dataForm.search1) obj.id = this.dataForm.search1.trim()
      if(this.dataForm.search2) obj.name = this.dataForm.search2.trim()
      if(this.dataForm.search3) obj.nameEn = this.dataForm.search3.trim()
      if(this.dataForm.type) obj.type = this.dataForm.type
      if(this.dataForm.state) obj.state = this.dataForm.state
      if(this.dataForm.channelIds.length>0) obj.channelIds = this.dataForm.channelIds
      //注册时间
      if(this.dataForm.createTime && this.dataForm.createTime.length > 0){
        obj.startCreateTime = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[0]) : null
        obj.endCreateTime = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[1]) : null
      }
      getCompanyList(obj).then(( data ) => {
        this.dataList = data.data
        if(this.dataList.length > 0){
          this.dataList.forEach( item => {
            item.channelStr = ''  // 主营业务
            if(item.channelList.length > 0){
              item.channelList.forEach( (country, index) => {
                item.channelStr += item.channelList.length != index+1 ? country.name+' / ' : country.name
              })
            }
          })
        }
        this.totalPage = data.totalCount
        this.dataListLoading = false
      }).catch((error) => {
        this.dataListLoading = false
        this.$message.error(error);
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },

    // 详情
    addOrUpdateHandle (id) {
      this.$nextTick(() => {
        this.$refs.companyDetailds.init(id)
      })
    },

    // 新增 / 修改
    openAddOrUpdate (id='') {
        this.addOrUpdateShow = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateRef.init(id)
      })
    },

    // 打开相关资讯
    openNews (id='') {
      this.$nextTick(() => {
        this.$refs.companyNewsRef.init(id)
      })
    },
    

  }
}
</script>
<style scoped>
.tui_col_div{
  width: 100%;
  text-align: center;
  margin-bottom: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.id_name_div{
  text-align: left;
  line-height: 10px;
  width: 180px; /* 设置div的宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>