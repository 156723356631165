<template>
  <!-- 编辑与审核资讯和招聘文章1 -->
  <div class="page_bac_div">
    <!-- 左边表单区 -->
    <div class="form_div_bac" :style="{paddingRight: leftWidth +'px'}">
      <div style="display:flex;align-items: center;justify-content: space-between;">
        <div class="head_back" @click="$router.back()">
          <i class="el-icon-arrow-left" style="font-size:18px;font-weight: 600;"></i>
          <h4>返回</h4>
        </div> 
        <!-- <h2 v-if="InfoId">编辑</h2> -->
        <h2 v-if="InfoId">审核</h2>
        <h2 v-else>审核 {{ pageNumber }} /  {{ total }}</h2>
      </div>
      <!-- 审核信息 -->
      <h4 style="margin-bottom: 20px;">审核信息</h4>
      <el-form :model="auditInfo" label-width="auto" class="demo-newsInfo">
        <el-form-item label="审核状态" prop="content">
          <el-radio-group v-model="auditInfo.state">
            <el-radio :label="1000">待审核</el-radio>
            <el-radio :label="1001">通过</el-radio>
            <el-radio :label="1004">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="拒绝理由" prop="content"  v-if="auditInfo.state == 1004">
          <el-input type="textarea" 
            placeholder="请输入拒绝理由"              
            v-model="auditInfo.refusalCause"
            maxlength="100"
            rows='4'>
          </el-input>
          <div class="mes_error_text" v-if="!auditInfo.refusalCause && auditIsClick">请输入拒绝内容</div>
        </el-form-item>
        <el-form-item label="所属创作者" v-if="[1,3,9].includes(currentModule) && auditInfo.state  == 1001">
          <div style="display: flex;align-items: center;">
            <div>
              <div >
                <selectUser 
                  :id.sync="auditInfo.authorId"  
                  :nickName.sync="auditInfo.nickName" 
                  :headPic.sync="auditInfo.headPic" 
                  @userChange="userChange"
                  style="width: 300px"/>
              </div>
              <div class="mes_error_text" v-if="!auditInfo.authorId && auditIsClick">请选择作者</div>
            </div>
            <div v-if="[1,9].includes(currentModule)" style="margin-left: 20px;">
              <div style="display: flex;align-items: center;">
                <span>企业：</span>
                <selectCompany 
                  :disabled="!auditInfo.authorId"
                  placeholder="关联独角兽公司"
                  :id.sync="auditInfo.companyId" 
                  :name.sync="auditInfo.companyName" 
                  style="width: 350px;"/>
              </div>
              <div class="mes_error_text" v-if="auditInfo.sourceType==4 && !auditInfo.companyId && auditIsClick">请选择公司</div>
            </div>
          </div>
        </el-form-item>
        <!-- <el-form-item label="转载至tg群" v-if="[1,9].includes(currentModule) && auditInfo.state  == 1001">
          <multipleTgCom :list.sync="tgGroupList" :listDisct="telegramDisct"/>
        </el-form-item> -->
        <el-form-item label="发布时间" prop="createTime" v-if="auditInfo.state == 1001">
          <div style="display: flex;align-items: center;">
            <el-radio-group v-model="auditInfo.radio" :disabled="olState == auditInfo.state">
              <el-radio :label="1">立即发布</el-radio>
              <el-radio :label="2">定时发布</el-radio>
            </el-radio-group>
            <el-date-picker 
            style="margin-left: 16px;"
              :disabled="auditInfo.radio == 1 || olState == auditInfo.state"
              v-model="auditInfo.preTime"
              type="datetime"
              placeholder="选择定时发布时间"
              default-time="12:00:00"
              :picker-options="pickerOptions()">
            </el-date-picker>
          </div>
          <div class="mes_error_text" v-if="auditInfo.radio==2 && !auditInfo.preTime  && auditIsClick">请选择定时发布时间</div>
        </el-form-item>
        <template v-if="[1,9].includes(currentModule) && auditInfo.state==1001">
          <el-form-item label="关联展会" prop="content">
            <selectExCom v-if="isLoanding" :exList.sync="auditInfo.exhibitionsSet" inputId="12"/>
          </el-form-item>
          <el-form-item label="关联专题&合集" prop="content">
            <selectSpCom v-if="isLoanding" :infoId="newsInfo.id" :spList.sync="auditInfo.collectionsSet" inputId="13"/>
          </el-form-item>
        </template>
        <el-form-item label="备注" prop="content">
          <el-input type="textarea" 
            v-model="auditInfo.remark"
            maxlength="100"
            rows='4'
            show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="audit_footer_div">
        <div>
          <el-button type="primary" @click="submitAuditForm()"  v-preventReClick
            :disabled="olState == auditInfo.state && auditInfo.state != 1001 && auditInfo.state != 1000">保存</el-button>
          <el-button type="success" @click="openEdit()">编辑</el-button>
        </div>
        <div v-if="!InfoId">
          <el-button type="primary" :disabled="pageNumber==1" 
            @click="goPageFun(pageNumber-1)" v-preventReClick>上一篇</el-button>
          <el-button type="primary" :disabled="pageNumber>=total"  
            @click="goPageFun(pageNumber+1)" v-preventReClick>下一篇</el-button>
        </div>
      </div>
      <div class="fen_ge_xian"></div>
      <!-- 基本信息 -->
      <h4 style="margin-bottom: 20px;">基本信息</h4>
      <el-form :model="newsInfo"  ref="newsInfo" label-width="auto" class="demo-newsInfo">
        <el-form-item label="所属模块">{{ currentModule | stateFilter(moduleDisct) }}</el-form-item>
        <el-form-item label="采集来源" v-if="[1,3].includes(currentModule) && auditInfo.sourceType==3">
          {{ newsInfo.crawlerChannel | stateSystemFilter(crawlerDisct) }}
        </el-form-item>
        <el-form-item label="采集时间"  v-if="auditInfo.sourceType==3 && currentModule==1">
          {{ newsInfo.collectTime | showDate() }}
        </el-form-item>
        <el-form-item label="创建时间">{{ newsInfo.createTime | showDate() }}</el-form-item>
        <el-form-item label="定时时间" v-if="newsInfo.preTime">
          {{ newsInfo.preTime | showDate() }}
        </el-form-item>
        <el-form-item label="发布时间" v-if="newsInfo.lastTime">
          {{ newsInfo.lastTime | showDate() }}
        </el-form-item>
        <el-form-item label="所属创作者" v-if="currentModule != 2">{{ newsInfo.nickName }}</el-form-item>
        <el-form-item label="发布端口" v-if="[1,4].includes(auditInfo.sourceType)">
          {{ newsInfo.rq | stateFilter(rqDisct) }}
        </el-form-item>
        <el-form-item label="招聘企业" v-if="currentModule == 7">{{ newsInfo.companyName }}</el-form-item>

        <template v-if="[1,3,9].includes(currentModule)">
          <el-form-item label="所属目标市场">
            <div class="tag_list_bac">
              <div class="tag_bac_div" v-for="(item, index) in newsInfo.countryList" :key="item.index">
                {{ item.name }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="所属业务或分类" >
            <div class="tag_list_bac">
              <div class="tag_bac_div" v-for="(item, index) in newsInfo.cateList" :key="item.index">
                {{ item.name }}
              </div>
              <div class="tag_bac_div" v-for="(item, index) in newsInfo.channelList" :key="item.index">
                {{ item.name }}
              </div>
            </div>
          </el-form-item>
        </template>
        <el-form-item label="标题" prop="title" v-if="currentModule != 3">
          {{ newsInfo.title }}
        </el-form-item>
        <el-form-item label="封面" v-if="currentModule == 1">
          <viewer :images="[$pathPrefix+newsInfo.cover]">
            <img :src="$pathPrefix+newsInfo.cover"  style="width: 80px;height: 80px;display: block;object-fit: cover;"
            v-if="newsInfo.cover"/>
          </viewer>
        </el-form-item>
        <el-form-item label="正文内容" v-if="currentModule != 9">
          <div v-html="newsInfo.content" class="con_div_bac"></div>
        </el-form-item>
        <el-form-item label="正文图片"  v-if="currentModule == 3">
          <div style="display: flex;">
            <img :src="$pathPrefix+url" v-for="url in urlList" :key="url"
              style="width: 80px;height: 80px;display: block;object-fit: cover; margin-right: 10px"/>
          </div>
        </el-form-item>
        <template v-if="currentModule == 9">
          <el-form-item label="简介">{{ newsInfo.content }}</el-form-item>
          <el-form-item label="封面">
            <viewer :images="[$pathPrefix+newsInfo.cover]">
              <img :src="$pathPrefix+newsInfo.cover"  style="width: 80px;height: 80px;display: block;object-fit: cover;"
              v-if="newsInfo.cover"/>
            </viewer>
          </el-form-item>
          <template v-if="newsInfo.videoInfo">
            <el-form-item label="中文字幕">
              <div v-if="newsInfo.videoInfo.subtitleZh">
                {{ $pathPrefix+newsInfo.videoInfo.subtitleZh }}
                <span class="subtitle_but"  @click="returnUrl($pathPrefix+newsInfo.videoInfo.subtitleZh)">预览</span>
                <span class="subtitle_but" @click="returnUrl('')">取消</span>
              </div>
            </el-form-item>
            <el-form-item label="英文字幕">
              <div v-if="newsInfo.videoInfo.subtitleEn">
                {{ $pathPrefix+newsInfo.videoInfo.subtitleEn }}
                <span class="subtitle_but"  @click="returnUrl($pathPrefix+newsInfo.videoInfo.subtitleEn)">预览</span>
                <span class="subtitle_but" @click="returnUrl('')">取消</span>
              </div>
            </el-form-item>
          </template>
        </template>
        
        <el-form-item label="选择标签" v-if="[1,3,9].includes(currentModule)">
          <div class="tag_list_bac">
            <div class="tag_bac_div" v-for="(item, index) in newsInfo.labelList" :key="item.index">
              {{ item.name }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="权重排序" prop="infoSort" v-if="currentModule != 2" >
          {{ newsInfo.infoSort }}
        </el-form-item>
        <el-form-item label="权重有效时间" v-if="currentModule != 2">
          {{ newsInfo.infoSortExpire | showDate() }}
        </el-form-item>
      </el-form>
      
    </div>
    <!-- 手机模型 -->
    <div class="phone_righ_bac">
      <articleVideoModel 
        v-if="currentModule==9"
        ref="articleVideoModelRef"
        class="edit_model_com"
        :height="Math.min(windowSize.height * .8, 667)"
        :videoUrl.sync="newsInfo.videoInfo.videoUrl" 
        :html.sync="newsInfo.content" 
        :title.sync="newsInfo.title" 
        :tags.sync="newsInfo.labelList"
        :currentModule.sync="currentModule"
        :nickname.sync="newsInfo.nickName"
        :headPic.sync="newsInfo.headPic"
        :date.sync="newsInfo.date" 
        :newsId="InfoId"/>
      <articleModel
        v-else
        class="edit_model_com"
        :newsId="InfoId"
        :height="Math.min(windowSize.height * .8, 667)"
        :title.sync="newsInfo.title" 
        :nickname.sync="auditInfo.nickName"
        :date.sync="newsInfo.date" 
        :html.sync="newsInfo.content"
        :currentModule.sync="currentModule"
        :urlList.sync="urlList"
        :headPic="auditInfo.headPic"
        :tags.sync="newsInfo.labelList"
        :company="company"/>
    </div>
    <!-- 非视频编辑弹窗 -->
    <editDiaCom ref="editDiaComRef" @returnGetData="initUpdate()" v-if="currentModule!=9 && showEdit" :show.sync="showEdit"/>
    <!-- 视频编辑弹窗 -->
    <videoEditDiaCom ref="videoEditDiaComRef" @returnGetData="initUpdate()" v-if="currentModule==9 && showEdit" :show.sync="showEdit"/>
  </div>
</template>

<script>
import windowSize from '@/mixin/windowSize'
import htmlHandler from '@/mixin/htmlHandler';
import { auditDetails, auditForwardTg, auditAudit, recruitDetails, recruitEdit, recruitAudit } from '@/api/newsManage'
import { 
  moduleDisctE, 
  rqDisctE
} from '@/utils/disct.js'
import { 
  systemDict, 
  telegramGroups, 
  userCompany 
} from '@/api/utilsManage'
import { dateUpdateUtc, utcUpdatedDate, setStyleFun, removeStyleFun } from '@/utils'
import editDiaCom from './editDiaCom.vue'
import videoEditDiaCom from './videoEditDiaCom.vue'
import selectExCom from './selectExCom.vue'
import selectSpCom from './selectSpCom.vue'
export default {
  name: 'auditOrEdit',
  components: { editDiaCom, videoEditDiaCom, selectExCom, selectSpCom },
  mixins: [windowSize, htmlHandler],
  data () {
    return {
      InfoId: this.$route.query.id,
      currentModule: parseInt(this.$route.query.module),
      crawlerChannel: this.$route.query.crawlerChannel ,
      leftWidth: 400,
      styleHref: '',

      moduleDisct: moduleDisctE,// 模块
      crawlerDisct: [], // 采集来源渠道
      telegramDisct: [], // tg群列表
      tgGroupList: [],// 选中的tg群列表
      rqDisct: rqDisctE, // 发布端口
      // 审核参数
      auditInfo: {
        state: 1000,
        refusalCause: '',
        companyId: '',
        companyName: '',
        sourceType: 3,
        authorId: '',
        nickName: '',
        remark: '',
        radio: 1,
        preTime: '',
        exhibitionsSet: [],// 展会
        collectionsSet: [],// 专题&合集
      },
      // 文章信息参数
      newsInfo: {
        countryList: [],// 市场
        channelList: [],// 业务
        cateList: [], //分类
        channelCateList: [],// 业务分类
        labelList: [],// 标签
        videoInfo: {
          videoUrl: '',
          subtitleZh: '',
          subtitleEn: '',
        }
      },
      // 招聘企业信息
      company: {
        icon: "",
        id: "",
        name: "",
        title: ""
      },
      pageNumber: 1,
      total: 0,
      auditIsClick: false,
      isLoanding: false,
      urlList: [],
      olState: 0,
      showEdit: false,
    }
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    stateSystemFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.code === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }else{
        return ''
      }
    },
  },
  created () {
    if([1,2,9].includes(this.currentModule)){
      this.getSystemDict()
      this.getTelegramGroups()
    }
    // if(this.currentModule != 7){
    //   this.getTerritory()
    // }
  },
  mounted () {
    this.isLoanding = false,
    this.initUpdate()
    // 注入样式
    this.styleHref = setStyleFun('.con_div_bac', this.currentModule)
    this.$nextTick( () => {
      this.setleftWidth()
    })
    window.addEventListener('resize', this.setleftWidth);
  },
  beforeDestroy() {
    removeStyleFun(this.styleHref)
  },
  methods: {
    // 设置模型高度
    setleftWidth(){
      const righBac = document.querySelectorAll(".edit_model_com")[0];
      this.leftWidth = righBac.offsetWidth || 400
      this.leftWidth += 40
    },
    pickerOptions(){
      return {
        disabledDate(time) {
          const date = new Date();
          const oneday = date.getTime();
          return time.getTime() < oneday - 86400000;
        },
        selectableRange: (() => {
          let date1 = new Date(this.auditInfo.preTime)
          let data = new Date();
          if(date1.getFullYear() === data.getFullYear() &&
            date1.getMonth() === data.getMonth() &&
            date1.getDate() === data.getDate()){
            let hour = data.getHours();
            let minute = data.getMinutes();
            let second = data.getSeconds();
            return [`${hour}:${minute}:${second} - 23:59:59`]
          }else{
            return [`00:00:00 - 23:59:59`]
          }
        })(),
      }
    },
    getSystemDict(){
      // 获取来源
      let key = ""
      switch (this.currentModule) {
        case 1: // 资讯
          key =  'NEWS_CRAWLER_CHANNEL'
        case 'test': // 快讯
          key =  'QUICK_CRAWLER_CHANNEL'
        case 'prod': // 社区
          key =  'COMMUNITY_CRAWLER_CHANNEL'
        default: // 资讯
          key =  'NEWS_CRAWLER_CHANNEL'
      }
      systemDict({key: key}).then(( res ) => {
        this.crawlerDisct = res.data
      })
    },
    // 获取tg列表
    getTelegramGroups(){
      if(this.telegramDisct.length == 0){
        telegramGroups({}).then(( res ) => {
          this.telegramDisct = res.data
        })
      }
    },
    goPageFun(num){
      this.pageNumber = num
      this.initUpdate()
    },
    // 选择作者改变时关联独角兽
    userChange(item){
      // 有关联的独角兽
      if(item.infoCompanyId){
        let data = {
          pageNumber: 1,
          pageSize: 10,
          id: item.infoCompanyId,
        }
        userCompany(data).then(( res ) => {
          let list = res.data
          if(list.length > 0){
            this.auditInfo.companyName = list[0].name
            this.auditInfo.companyId = item.infoCompanyId
          }else{
            this.$message.error('没找到对应关联独角兽')
            this.auditInfo.companyId = item.infoCompanyId
          }
        }).catch((error) => {
          this.$message.error('获取关联独角兽名称失败'+ error)
        })
      }else{
        this.auditInfo.companyId = ''
        this.auditInfo.companyName = ''
      }
    },
    // 初始化
    initUpdate () {
      let info = {  module: this.currentModule, isRelation: true }
      if(this.InfoId){ // 编辑
        info.id = this.InfoId
      }else{ // 审核
        this.auditIsClick = false
        if(this.crawlerChannel){
          info.crawlerChannel = this.crawlerChannel
        }
        info.pageNumber = this.pageNumber
      }
      this.getDetails(info)
    },
    // 获取文章详情
    getDetails(info){
      this.$openLoading()
      if(this.currentModule == 7){
        this.getRecruitDetails(info)
      }else{
        this.getAuditDetails(info)
      }
    },
    // 获取资讯详情
    getAuditDetails(info){
      auditDetails( info ).then( res => {
        this.$closeLoading()
        res.data.content = this.__replaceHtml(res.data.content);
        this.newsInfo = res.data
        this.isLoanding = true

        let date = ''
        if(res.data.state == 1001){
          date = res.data.lastTime
        }else{
          if(res.data.preTime){
            date = res.data.preTime
          }else{
            date = res.data.createTime
          }
        }
        this.newsInfo.date = date
        if(this.newsInfo.module != 1){
          if(this.newsInfo.cover) { 
            this.urlList = this.newsInfo.cover.split(',')
          }else{
            this.urlList = []
          }
        }

        this.olState = res.data.state
        this.auditInfo.state = res.data.state
        this.auditInfo.refusalCause = res.data.refusalCause
        this.auditInfo.remark = res.data.remark
        this.auditInfo.authorId = res.data.authorId
        this.auditInfo.nickName = res.data.nickName
        this.auditInfo.headPic = res.data.headPic
        this.auditInfo.sourceType = res.data.sourceType
        this.auditInfo.companyId = res.data.companyId
        this.auditInfo.companyName = res.data.companyName || ''
        if(this.auditInfo.authorId == 0) this.auditInfo.authorId = ''
        if(this.auditInfo.companyId == 0) this.auditInfo.companyId = ''
        this.total = parseInt(res.data.total)

        if(res.data.preTime){
          this.auditInfo.preTime = utcUpdatedDate(res.data.preTime)
          this.auditInfo.radio = 2
        }else{
          this.auditInfo.radio = 1
        }
        if([1,9].includes(this.currentModule) ){
          this.auditInfo.exhibitionsSet = []
          //资讯和视频资讯会有展会
          if(res.data.exhibitions && res.data.exhibitions.length > 0){
            res.data.exhibitions.forEach( item => {
              this.auditInfo.exhibitionsSet.push({id: item.exhibitionId, title: item.exhibitionName})
            })
          }
          //资讯和视频资讯可以加入专题&合集
          this.auditInfo.collectionsSet = []
          if(res.data.collections && res.data.collections.length > 0){
            res.data.collections.forEach( item => {
              this.auditInfo.collectionsSet.push(
                {id: item.collectionId, title: item.collectionName, sort: item.collectionSort, type: item.type}
              )
            })
          }
        }
        console.log("-======--------=+++",this.auditInfo)
      }).catch((error) => {
        this.$closeLoading()
        this.isLoanding = true
        this.$message.error("获取详情失败"+ error)
      })
    },
    // 获取招聘详情
    getRecruitDetails(info){
      recruitDetails( info ).then( res => {
        this.$closeLoading()
        this.newsInfo = res.data
        this.isLoanding = true
        this.auditInfo.nickName = res.data.nickName

        this.olState = res.data.state
        this.auditInfo.state = res.data.state
        this.auditInfo.refusalCause = res.data.refusalCause
        this.auditInfo.remark = res.data.remark
        this.total = parseInt(res.data.total)
        if(res.data.preTime){
          this.auditInfo.preTime = utcUpdatedDate(res.data.preTime)
          this.auditInfo.radio = 2
        }else{
          this.auditInfo.radio = 1
        }
        let date = ''
        if(res.data.state == 1001){
          date = res.data.lastTime
        }else{
          if(res.data.preTime){
            date = res.data.preTime
          }else{
            date = res.data.createTime
          }
        }
        this.newsInfo.date = date
        this.company = {
          icon: res.data.companyIcon,
          id: res.data.companyId,
          name: res.data.companyName,
          title: res.data.companyTitle
        }
      }).catch((error) => {
        this.$closeLoading()
        this.isLoanding = true
        this.$message.error("获取详情失败"+ error)
      })
    },


    // 提交审核
    submitAuditForm(){
      this.auditIsClick = true
      if(this.auditInfo.state == 1004 && !this.auditInfo.refusalCause) return
      if([1,3,9].includes(this.currentModule) && !this.auditInfo.authorId && this.auditInfo.state  == 1001) return
      if(this.currentModule != 7 && this.auditInfo.sourceType==4 && !this.auditInfo.companyId) return
      if(this.auditInfo.authorId == 0 || !this.auditInfo.authorId) this.auditInfo.authorId = ''
      if(this.auditInfo.companyId == 0) this.auditInfo.companyId = ''
      if(this.olState != 1001){
        if(this.auditInfo.radio == 2 && !this.auditInfo.preTime) return
      }
      this.auditIsClick = false
      this.$confirm('请确认最终提交的信息，避免误操作！', '提示', {
        confirmButtonText: '确定无误',
        cancelButtonText: '继续填写',
        type: 'warning'
      }).then( async () => {
        let info = {
          module: this.currentModule,
          remark: this.auditInfo.remark,
          ids: [this.newsInfo.id],
          state: this.auditInfo.state,
          companyId: this.auditInfo.companyId
        }
        if([1,3,9].includes(this.currentModule)){
          if(this.auditInfo.state  == 1001){
            info.authorIds = [this.auditInfo.authorId]
          }
        }
        if(this.olState != 1001 && this.auditInfo.radio == 2){
          info.preTime  =  dateUpdateUtc(new Date(this.auditInfo.preTime))
        }
        if(this.auditInfo.radio == 1){
          info.preTime  = null
        }
        if([1,9].includes(this.currentModule) && this.auditInfo.state==1001){
          //资讯和视频资讯可以加入展会
          if(this.auditInfo.exhibitionsSet && this.auditInfo.exhibitionsSet.length > 0){
            let exArr = []
            this.auditInfo.exhibitionsSet.forEach( item => {
              exArr.push({exhibitionId: item.id, exhibitionName: item.title})
            })
            info.exhibitions = exArr
          }
          console.log("到这1")
          //资讯和视频资讯可以加入专题&合集
          if(this.auditInfo.collectionsSet && this.auditInfo.collectionsSet.length > 0){
            let spArr = []
            this.auditInfo.collectionsSet.forEach( item => {
              spArr.push(
                {collectionId: item.id, collectionName: item.title, collectionSort: item.sort, type: item.type}
              )
            })
            info.collections = spArr
          }
        }
        console.log("到这",this.auditInfo)
        if(this.auditInfo.state == 1004) info.refusalCause = this.auditInfo.refusalCause
        this.$openLoading()
        if(this.currentModule == 7){
          recruitAudit( info ).then( res => {
            this.$closeLoading()
            this.initUpdate()
            this.$message.success("审核成功")
          }).catch((error) => {
            this.$closeLoading()
            this.$message.error("审核失败"+ error)
          })
        }else{
          auditAudit( info ).then( res => {
            this.$closeLoading()
            this.initUpdate()
            this.$message.success("审核成功")
          }).catch((error) => {
            this.$closeLoading()
            this.$message.error("审核失败"+ error)
          })
        }        
      }).catch(() => { });
    },
    // 转发TG
    submitTG(){
      return new Promise(  (resolve, reject) => {
        try{
          let info = {
            module: this.currentModule,
            id: this.newsInfo.id,
          }
          if(this.auditInfo.radio == 2){
            info.preTime  =  dateUpdateUtc(new Date(this.auditInfo.preTime))
          }else{
            info.preTime  = null
          }
          info.tgIds = []
          this.tgGroupList.forEach( item => {
            info.tgIds.push(item.groupId)
          })
          auditForwardTg( info ).then( res => {
            return resolve(res)
          }).catch((error) => {
            return reject(error)
          })
        } catch (error) {
          reject(error)
        }
      })
    },


    // 打开编辑窗口
    openEdit(){
      if(this.newsInfo.id){
        let info = JSON.parse(JSON.stringify(this.newsInfo))
        info.module = this.currentModule
        this.showEdit = true
        this.$nextTick( () => {
          if(info.module == 9){
            this.$refs.videoEditDiaComRef.open(info)
          }else{
            this.$refs.editDiaComRef.open(info)
          }
        })
      }else{
        this.$message("没有获取到文章信息！")
      }
    },
    // set字幕
    returnUrl(url){
      this.$refs.articleVideoModelRef.setSubtitle(url)
    },

  },
  destroyed() {
    console.log("销毁")
    window.removeEventListener('resize', this.setleftWidth);
  },
}
</script>
<style lang="scss" scoped>
.page_bac_div{
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  background: #FFF;
}
.form_div_bac{
  flex: 1;
  padding: 20px 20px 80px 20px;
  .head_back{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    width: 80px;
  }
}
.audit_footer_div{
  padding-left: 100px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.phone_righ_bac{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}
.fen_ge_xian{
  width: 100%;
  height: 2px;
  background: #9A9FA6;
  border-radius: 2px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
}
.mes_text_div{
  font-size: 13px;
  margin-left: 10px;
}
.mes_error_text{
  font-size: 12px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
}
.con_div_bac{
  border: 1px dashed #CCCCCC; 
  word-wrap:break-word;
 	word-break:normal;
  max-height: 400px;
  overflow: auto;
  padding: 0 16px;
  width: calc(100% - 32px);
  white-space:pre-wrap;
}
.tag_list_bac{
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 16px;
  border: 1px dashed #CCCCCC;
  border-radius: 6px;
  .tag_bac_div{
    padding: 8px 16px;
    border-radius: 4px;
    background-color: rgba(32, 108, 255, 0.08);
    color: #206CFF;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
  }
}
.subtitle_but{
  margin-left: 10px;
  cursor: pointer;
  text-decoration: underline;
  color: #206CFF;
}
</style>
<style scoped>
.con_div_bac >>> img{
  max-width: 100% !important;
}
</style>
