var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: !!_vm.src,
        width: "820px",
        "close-on-click-modal": false,
        "append-to-body": "",
        "destroy-on-close": ""
      },
      on: { close: _vm.closeHandler }
    },
    [
      _c("div", [
        _c("img", {
          ref: "image",
          class: _vm.$style.image,
          attrs: { src: _vm.src }
        })
      ]),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.closeHandler } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.cropHandler } },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }