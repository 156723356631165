<!-- 用户多选选择组件 -->
<template>
  <div class="com_mes_bac" >
    <el-input :id="'inputUserDuoId'+$props.inputId" @focus="focusFun"  @blur="blurFun"
      :placeholder="selectlist.length>0 ? selectlist[0].nickName+'   +'+selectlist.length : '请选择'"  
      :disabled="disabledInput"
      v-model="comInput" @input="comRemoteFun()">
      <div  slot="suffix" style="display: flex;">
        <div v-if="comInput && selectlist.length>0 ">
          {{ selectlist[0].nickName }}   +{{ selectlist.length }}
        </div>
        <i class="el-input__icon el-icon-error zi_input_icon" id="closeIconId"
          v-show="selectlist.length>0" @click="inputClearFun()"></i>
      </div>
    </el-input>
    <div class="com_relevance_bac" v-if="showRele" v-outside="closePopup" id="comListId">
      <template v-if="comData.list.length > 0">
        <div v-for="item in comData.list" :key="item.id" class="com_relevance_card"
          @mouseover="mouseoverId=item.id" @mouseout="mouseoverId=''"
          @click="opClickFun(item)"
          :style="{
            'background': mouseoverId==item.id || infoIds.includes(item.id) ? '#F2F3F5' : '#FFF',
            'color': infoIds.includes(item.id) ? 'blueviolet' : ''
          }">
          <span>{{ item.id }}</span>
          <span>{{ item.nickName }}</span>
        </div>
        <div v-if="comData.pageNum >= comData.total && !getLoading"  class="bot_mes_text">没有更多了...</div>
      </template>
      <div class="bot_mes_text" v-if="comData.list.length == 0 && !getLoading">无数据</div>
      <div class="bot_mes_text" v-if="getLoading">
        <i class="el-icon-loading"></i>  加载中
      </div>
    </div>
  </div>
</template>

<script>
import { userSimples } from '@/api/utilsManage'
export default {
  name: 'selectUserDuo',
  props: {
    maxLength: { // 最多选择多少，不传就无限
      type: Number,
      default: 0,
    },
    inputId: { // 单个页面多次使用该组件传入inputid值区分
      type: String,
      default: '',
    },
    ids: {
      type: Array,
      default(){
        return []
      }
    },
    disabledInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getLoading: false,
      mouseoverId: '',
      comInput: '',
      infoIds: this.$props.ids, // 默认id
      selectlist: [], //选中的对象数据
      showRele: false,// 标签列表框
      // 公司查询相关参数
      comData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        list: [],
      },
      
    };
  },
  directives: {
    outside: {
      bind(el, binding, vnode) {
        function documentClick(e) {
          let _that = vnode.context
          e.stopPropagation();
          const inputEl = document.getElementById('inputUserDuoId'+_that.inputId)
          const closeEl = document.getElementById('closeIconId')
          // !el.contains(e.target) && 
          if (!inputEl.contains(e.target) && !el.contains(e.target) && !closeEl.contains(e.target)) {
            binding.value(); // 调用组件中定义的方法
          }
        }
        el.__documentClick__ = documentClick;
        document.addEventListener('click', documentClick);
      },
      unbind(el) {
        document.removeEventListener('click', el.__documentClick__);
        delete el.__documentClick__;
      },
    },
  },
  watch : {
		'selectlist.length' : function(val){
      this.$emit('setList', this.selectlist)
      this.infoIds = []
      if(val > 0){
        this.selectlist.forEach( item => {
          this.infoIds.push(item.id)
        })
      }
      this.$emit('update:ids', this.infoIds)
		},
		'ids.length' : function(val){
      if(this.$props.ids.length == 0){
        this.$nextTick( () => {
          this.selectlist = []
        })
      }
		}
    
	},
  created() {
  },
  mounted() {
    
  },
  methods: {
    closePopup(){
      this.showRele = false
    },
    // 失去焦点
    blurFun(){
      if(this.selectlist.length > 0){
        // this.$nextTick( () =>{
        //   this.comInput = this.selectlist[0].nickName + '  +'+this.selectlist.length
        // })
      }
    },
    // 获取焦点
    focusFun(){
      if(this.showRele) return 
      // this.comInput = ""
      this.comRemoteFun()
    },
    // 初始化获取列表
    comRemoteFun() {
      this.showRele = true
      this.comData = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        list: [],
      }
      let data = {
        pageNumber: this.comData.pageNum,
        pageSize: this.comData.pageSize,
        nickName: this.comInput,
      }
      this.getLoading = true
      userSimples(data).then(( res ) => {
        this.getLoading = false
        this.comData.list = res.data
        this.comData.total = res.totalPages
        this.$nextTick( () => {
          document.getElementById("comListId").addEventListener('scroll', this.windowScrollListener);
        })
      }).catch((error) => {
        this.getLoading = false
      })
    },
    // 分页获取列表
    comPageFun() {
      if(this.comData.pageNum >= this.comData.total) return  // 没有更多数据了
      this.comData.pageNum += 1
      let data = {
        pageNumber: this.comData.pageNum,
        pageSize: this.comData.pageSize,
        name: this.comInput,
      }
      this.getLoading = true
      userSimples(data).then(( res ) => {
        this.getLoading = false
        this.comData.list = this.comData.list.concat(res.data)
        this.comData.total = res.totalPages
      }).catch((error) => {
        this.getLoading = false
      })
    },
    // 列表滚动事件
    windowScrollListener(e){
      if(e.srcElement.clientHeight + parseInt(e.srcElement.scrollTop) === e.srcElement.scrollHeight){
        // console.log("触底了")
        this.comPageFun()
      }
    },
    opClickFun(item){
      if(!this.infoIds.includes(item.id)){
        if(this.$props.maxLength == 0 || (this.$props.maxLength != 0 && this.selectlist.length < this.$props.maxLength)){
          this.selectlist.unshift(item)  // 添加标签
        }
      }else{
        
        this.tagClearFun(item)
      }
    },
    // 删除指定标签
    tagClearFun(item){
      var index = this.infoIds.indexOf(item.id)
      if (index !== -1) {
        this.selectlist.splice(index, 1);
      }
    },
    // 删除标签
    inputClearFun(){
      this.tagClearFun(this.selectlist[0])
    },
    
  },
  destroyed() {
    
  },
};
</script>
<style lang="scss" scoped>
.com_mes_bac{
  width: 100%;
  position: relative;
  min-height: 18px;
  .com_relevance_bac::-webkit-scrollbar {
    width: 5px;
  }
  .com_relevance_bac{
    width: 100%;
    max-height: 220px;
    overflow: auto;
    position: absolute;
    left: 0;
    top: 36px;
    padding: 8px 0;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    background: #FFF;
    border-radius: 2px;
    z-index: 999;
    .com_relevance_card{
      padding: 7px 12px;
      color: #1D2129;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .zi_input_icon{
    cursor: pointer;
  }
}
.bot_mes_text{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8e8e8e;
  margin: 8px 0;
  i{
    font-size: 18px;
    color: #8e8e8e;
    margin-right: 3px;
  }
}
</style>
