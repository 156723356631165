var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "com_mes_bac" },
    [
      _c(
        "el-input",
        {
          attrs: {
            id: "inputUserDuoId" + _vm.$props.inputId,
            placeholder:
              _vm.selectlist.length > 0
                ? _vm.selectlist[0].nickName + "   +" + _vm.selectlist.length
                : "请选择",
            disabled: _vm.disabledInput
          },
          on: {
            focus: _vm.focusFun,
            blur: _vm.blurFun,
            input: function($event) {
              return _vm.comRemoteFun()
            }
          },
          model: {
            value: _vm.comInput,
            callback: function($$v) {
              _vm.comInput = $$v
            },
            expression: "comInput"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { display: "flex" },
              attrs: { slot: "suffix" },
              slot: "suffix"
            },
            [
              _vm.comInput && _vm.selectlist.length > 0
                ? _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.selectlist[0].nickName) +
                        " +" +
                        _vm._s(_vm.selectlist.length) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectlist.length > 0,
                    expression: "selectlist.length>0"
                  }
                ],
                staticClass: "el-input__icon el-icon-error zi_input_icon",
                attrs: { id: "closeIconId" },
                on: {
                  click: function($event) {
                    return _vm.inputClearFun()
                  }
                }
              })
            ]
          )
        ]
      ),
      _vm.showRele
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "outside",
                  rawName: "v-outside",
                  value: _vm.closePopup,
                  expression: "closePopup"
                }
              ],
              staticClass: "com_relevance_bac",
              attrs: { id: "comListId" }
            },
            [
              _vm.comData.list.length > 0
                ? [
                    _vm._l(_vm.comData.list, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "com_relevance_card",
                          style: {
                            background:
                              _vm.mouseoverId == item.id ||
                              _vm.infoIds.includes(item.id)
                                ? "#F2F3F5"
                                : "#FFF",
                            color: _vm.infoIds.includes(item.id)
                              ? "blueviolet"
                              : ""
                          },
                          on: {
                            mouseover: function($event) {
                              _vm.mouseoverId = item.id
                            },
                            mouseout: function($event) {
                              _vm.mouseoverId = ""
                            },
                            click: function($event) {
                              return _vm.opClickFun(item)
                            }
                          }
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item.id))]),
                          _c("span", [_vm._v(_vm._s(item.nickName))])
                        ]
                      )
                    }),
                    _vm.comData.pageNum >= _vm.comData.total && !_vm.getLoading
                      ? _c("div", { staticClass: "bot_mes_text" }, [
                          _vm._v("没有更多了...")
                        ])
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.comData.list.length == 0 && !_vm.getLoading
                ? _c("div", { staticClass: "bot_mes_text" }, [_vm._v("无数据")])
                : _vm._e(),
              _vm.getLoading
                ? _c("div", { staticClass: "bot_mes_text" }, [
                    _c("i", { staticClass: "el-icon-loading" }),
                    _vm._v(" 加载中 ")
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }