import axiosHttps from '@/config/axios'

/**
 * 首页Tab相关 
 */

// 首页Tab列表
export const homeTabsList = info => {
  return axiosHttps.request({
    url: `/v1/tabs/list`,
    data: info,
    method: 'post'
  })
}