var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag_list_bac" },
    [
      _c(
        "div",
        { staticClass: "tag_mes_bac" },
        [
          _vm.showRele && _vm.position == "top"
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "outside",
                      rawName: "v-outside",
                      value: _vm.closePopup,
                      expression: "closePopup"
                    }
                  ],
                  staticClass: "tag_relevance_bac relevance_top",
                  attrs: { id: "tagListId" }
                },
                [
                  _vm.comData.list.length > 0
                    ? [
                        _vm._l(_vm.comData.list, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "tag_relevance_card",
                              class:
                                _vm.mouseoverId == item.id ||
                                _vm.tagIds.includes(item.id)
                                  ? "select_class"
                                  : "",
                              on: {
                                mouseover: function($event) {
                                  _vm.mouseoverId = item.id
                                },
                                mouseout: function($event) {
                                  _vm.mouseoverId = ""
                                },
                                click: function($event) {
                                  return _vm.addTagFun(item)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "2px 16px",
                                    display: "flex",
                                    "justify-content": "space-between"
                                  }
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.id))]),
                                  _c("span", [_vm._v(_vm._s(item.nickName))])
                                ]
                              )
                            ]
                          )
                        }),
                        _vm.comData.pageNum >= _vm.comData.total &&
                        !_vm.getLoading
                          ? _c("div", { staticClass: "bot_mes_text" }, [
                              _vm._v("没有更多了...")
                            ])
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm.comData.list.length == 0 && !_vm.getLoading
                    ? _c("div", { staticClass: "bot_mes_text" }, [
                        _vm._v("无数据")
                      ])
                    : _vm._e(),
                  _vm.getLoading
                    ? _c("div", { staticClass: "bot_mes_text" }, [
                        _c("i", { staticClass: "el-icon-loading" }),
                        _vm._v(" 加载中 ")
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _c("el-input", {
            attrs: {
              id: "inputTagId" + _vm.$props.inputId,
              placeholder: "请输入选择"
            },
            on: {
              focus: _vm.focusFun,
              input: function($event) {
                return _vm.tagRemoteFun()
              }
            },
            model: {
              value: _vm.tagInput,
              callback: function($$v) {
                _vm.tagInput = $$v
              },
              expression: "tagInput"
            }
          }),
          _vm.showRele && _vm.position == "bottom"
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "outside",
                      rawName: "v-outside",
                      value: _vm.closePopup,
                      expression: "closePopup"
                    }
                  ],
                  staticClass: "tag_relevance_bac relevance_bot",
                  attrs: { id: "tagListId" }
                },
                [
                  _vm.comData.list.length > 0
                    ? [
                        _vm._l(_vm.comData.list, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "tag_relevance_card",
                              class:
                                _vm.mouseoverId == item.id ||
                                _vm.tagIds.includes(item.id)
                                  ? "select_class"
                                  : "",
                              on: {
                                mouseover: function($event) {
                                  _vm.mouseoverId = item.id
                                },
                                mouseout: function($event) {
                                  _vm.mouseoverId = ""
                                },
                                click: function($event) {
                                  return _vm.addTagFun(item)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "2px 16px",
                                    display: "flex",
                                    "justify-content": "space-between"
                                  }
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(item.id))]),
                                  _c("span", [_vm._v(_vm._s(item.nickName))])
                                ]
                              )
                            ]
                          )
                        }),
                        _vm.comData.pageNum >= _vm.comData.total &&
                        !_vm.getLoading
                          ? _c("div", { staticClass: "bot_mes_text" }, [
                              _vm._v("没有更多了.....")
                            ])
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm.comData.list.length == 0 && !_vm.getLoading
                    ? _c("div", { staticClass: "bot_mes_text" }, [
                        _vm._v("无数据")
                      ])
                    : _vm._e(),
                  _vm.getLoading
                    ? _c("div", { staticClass: "bot_mes_text" }, [
                        _c("i", { staticClass: "el-icon-loading" }),
                        _vm._v(" 加载中 ")
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm._l(_vm.selectTags, function(item, index) {
        return _c(
          "div",
          { key: index, staticStyle: { position: "relative" } },
          [
            _c("div", { staticClass: "tag_bac_div" }, [
              _vm._v(_vm._s(item.nickName))
            ]),
            _c("i", {
              staticClass: "el-icon-error delete_tag_icon",
              on: {
                click: function($event) {
                  return _vm.deleteTagFun(item.id)
                }
              }
            })
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }