export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: 'PASA Admin',
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,
  /**
   * @description 是否使用国际化，默认为false
   *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
   *              用来在菜单中显示文字
   */
  useI18n: false,
  /**
   * @description 后台api请求基础路径
   */
  baseUrl: {
    backend_dev: 'http://127.0.0.1:8888/', //后端开发
    dev: 'https://admin-test.api.pasa88.com/', //开发 
    test: 'https://admin-test.api.pasa88.com/', //测试 
    prod: 'https://admin-production.api.pasa88.com/', // 正式
  },
  // app客户端api接口路径
  appBaseUrl: function () {
    switch (process.env.NODE_ENV) {
      case 'development': // 前端开发环境
        return 'https://api-test.pasa88.com/'
      case 'test': // 测试环境
        return 'https://api-test.pasa88.com/'
      case 'prod': // 生产环境
        return 'https://api.pasa88.com/'
      default: // 默认生产环境
        return 'https://api.pasa88.com/'
    }
  }(),
  // 服务器资源路径前缀
  pathPrefix: function () {
    switch (process.env.NODE_ENV) {
      case 'development': // 前端开发环境
        return 'http://media.pasa365.com/'
      case 'test': // 测试环境
        return 'http://media.pasa365.com/'
      case 'prod': // 生产环境
        return 'https://media-p.pasa365.com/'
      default: // 默认生产环境
        return 'https://media-p.pasa365.com/'
    }
  }(),
  // 上传oss域名更换路径
  appOssBaseUrl: function () {
    switch (process.env.NODE_ENV) {
      case 'development': // 前端开发环境
        return 'pasa-test.oss-accelerate.aliyuncs.com'
      case 'test': // 测试环境
        return 'pasa-test.oss-accelerate.aliyuncs.com'
      case 'prod': // 生产环境
        return 'pasa-app.oss-accelerate.aliyuncs.com'
      default: // 默认生产环境
        return 'pasa-app.oss-accelerate.aliyuncs.com'
    }
  }(),
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: 'home',
  /**
   * @description 需要加载的插件
   */
  plugin: {
    'error-store': {
      showInHeader: true, // 设为false后不会在顶部显示错误日志徽标
      developmentOff: true // 设为true后在开发环境不会收集错误信息，方便开发中排查错误
    }
  }
}
