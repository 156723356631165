<template>
  <div class="container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" style="padding: 10px 0;">
      <el-form-item label="专题名">
        <el-input v-model="dataForm.search1" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="dataForm.select1" size="mini" placeholder="请选择" clearable  @change="getDataList()" allow-create=""
          style="width: 120px;">
          <el-option v-for="item in typeDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="首页推荐">
        <el-select v-model="dataForm.select2" size="mini" placeholder="请选择" clearable  @change="getDataList()">
          <el-option v-for="item in recommendDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="dataForm.select3" size="mini" placeholder="请选择" clearable  @change="getDataList()">
          <el-option v-for="item in stateDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="dataForm.createTime" type="datetimerange" @change="getDataList()"  size="mini"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="openAddOrUpdate()">新增</el-button>
        <!--后端接口查询时间长导致失败，先注释-->
        <!-- <el-button type="primary" plain icon="el-icon-download" size="small" @click="downLoadFile" >导出数据</el-button> -->
      </el-form-item>
    </el-form>

    <!--用户列表   height="500" v-adaptive="{bottomOffset: 50,iModal: false}"-->
    <el-table
      :data="dataList" border v-loading="dataListLoading"
      height="100" v-adaptive="{bottomOffset: 50,iModal: false}"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;">
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          {{scope.row.createTime | showDate()}}
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="title" label="标题"> 
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 300px;" class="content_p_class_clamp">{{ scope.row.title }}</p>
            <div slot="reference"  style="display: flex;align-items: center;justify-content: center;">
              <div class="content_div_class_clamp">{{ scope.row.title }}</div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="cover" label="图片">
        <template slot-scope="scope">
          <viewer>
            <img :src="$pathPrefix+scope.row.cover" v-if="scope.row.cover"
              style="width: 60px; height: 60px; object-fit: contain;"/>
          </viewer>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型">
        <template slot-scope="scope">
          {{scope.row.type | stateFilter(typeDisct)}}
        </template>
      </el-table-column>
      <el-table-column prop="indexShowState" label="首页推荐">
        <template slot-scope="scope">
          {{scope.row.indexShowState | stateFilter(recommendDisct)}}
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="权重"></el-table-column>
      <el-table-column prop="realyBrowNum" label="实际浏览量"></el-table-column>
      <el-table-column prop="fixBrowNum" label="配置浏览量"></el-table-column>
      <el-table-column prop="indexShowState" label="状态">
        <template slot-scope="scope">
          {{scope.row.state | stateFilter(stateDisct)}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="240" label="操作">
        <template slot-scope="scope">
          <div style="line-height:40px;justify-content: space-between;">
            <el-button type="success" plain size="mini" @click="openNews(scope.row.id)">文章管理</el-button>
            <el-button type="primary" plain size="mini" @click="openAddOrUpdate(scope.row)">编辑</el-button>
            <el-button type="warning" plain size="mini" @click="deleteFun(scope.row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- @size-change="sizeChangeHandle" layout="total, sizes, prev, pager, next, jumper"> -->
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 弹窗, 文章管理-->
    <newsManage ref="newsManageRef" @refreshDataList="getDataList(pageIndex)"></newsManage>
    
    <!-- 弹窗, 新增 / 修改 -->
    <addOrUpdate ref="addOrUpdateRef" @refreshDataList="getDataList(pageIndex)" 
      v-if="addOrUpdateShow" :show.sync="addOrUpdateShow"></addOrUpdate>
    
  </div>
</template>

<script>
import { formatDateTime,utcUpdatedDate } from '@/utils'
import { collectionList, collectionDelete } from '@/api/configManage'
import newsManage from './newsManage'
import addOrUpdate from './addOrUpdate'
export default {
  name: 'specialList',
  data () {
    return {
      stateDisct: [   //在线状态字典
        {value: 1, name: '开启'},
        {value: 2, name: '关闭'},
      ],
      typeDisct: [   //在线状态字典
        {value: 1, name: '专题'},
        {value: 2, name: '合集'},
      ],
      recommendDisct: [   //在线状态字典
        {value: 0, name: '否'},
        {value: 1, name: '是'},
      ],
      dataForm: {
        search1: '', //
        select1: '',
        select2: '',
        createTime: [],//注册时间
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateShow: false,
      visibleTui: false,
      recommendList: [],
      infoId: 1,
    }
  },
  components: {
    newsManage,
    addOrUpdate,
  },
  created () {
    
  },
  mounted () {
    this.getDataList()
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 条件禁用列
    checkSelectable(row){
      if (row.qualState == 0) {
        return true
      } else {
        return false
      }
    },
    // 获取数据列表
    getDataList (pageNum) {
      this.pageIndex = pageNum ? pageNum : 1
      this.dataListLoading = true
      let obj = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
      }
      if(this.dataForm.search1) obj.title = this.dataForm.search1.trim()
      if(this.dataForm.select1) obj.type = this.dataForm.select1
      if(this.dataForm.select2 !== "") obj.indexShowState = this.dataForm.select2
      if(this.dataForm.select3) obj.state = this.dataForm.select3
      //注册时间
      if(this.dataForm.createTime && this.dataForm.createTime.length > 0){
        obj.startDatetime = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[0]) : null
        obj.endDatetime = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[1]) : null
      }
      collectionList(obj).then(( data ) => {
        console.log("参数",data.data)
        this.dataList = data.data
        this.totalPage = data.totalCount
        this.dataListLoading = false
      }).catch((error) => {
        this.dataListLoading = false
        this.$message.error(error);
      })
    },

    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },

    // 打开文章管理
    openNews (id) {
      this.$nextTick(() => {
        this.$refs.newsManageRef.init(id)
      })
    },

    // 新增 / 修改
    openAddOrUpdate(row={}) {
      this.addOrUpdateShow = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateRef.init(row)
      })
    },
    // 删除
    deleteFun(id){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        collectionDelete( { id: id } ).then( res => {
          this.getDataList(this.pageIndex)  
          this.$message.success("删除成功")
        }).catch((error) => {
          this.$message("删除失败"+error)
        })
      }).catch(() => { });
    }

  }
}
</script>
<style scoped>
.tui_col_div{
  width: 100%;
  text-align: center;
  margin-bottom: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.id_name_div{
  text-align: left;
  line-height: 10px;
  width: 180px; /* 设置div的宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>