
import config from '@/config'
import { Message } from 'element-ui';
import { systemSnowId } from '@/api/utilsManage'
import { ossUploadBase } from "@/utils/ossUpload";
import Md5 from 'js-md5'
import store from '@/store'
import { systemDictNative } from '@/api/utilsManage'
/**
 * 获取uuid
 */
export function getUUID () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
}
/**
 * 获取uuid不要-的作为名称使用
 */
export function getUUIDName () {
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
    uuid = uuid.replace(/-/g, '')
    return uuid
}
/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
export function adornUrl (actionName) {
    const baseUrl =  process.env.NODE_ENV === 'development' ? config.baseUrl.dev : process.env.NODE_ENV === 'test' ? config.baseUrl.test : config.baseUrl.prod
    // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
    return baseUrl + actionName
}

/**
 * @param {Array} list 通过路由列表得到菜单列表
 * @returns {Array}
 */
export const getMenuByRouter = (list, access) => {
    let res = []
    forEach(list, item => {
      if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
        let obj = {
          icon: (item.meta && item.meta.icon) || '',
          name: item.name,
          path: item.path,
          meta: item.meta
        }
        if ((hasChild(item) || (item.meta && item.meta.showAlways)) && showThisMenuEle(item, access)) {
          obj.children = getMenuByRouter(item.children, access)
        }
        if (item.meta && item.meta.href) obj.href = item.meta.href
        if (showThisMenuEle(item, access)) res.push(obj)
      }
    })
    return res
}
const forEach = (arr, fn) => {
    if (!arr.length || !fn) return
    let i = -1
    let len = arr.length
    while (++i < len) {
      let item = arr[i]
      fn(item, i, arr)
    }
}
const showThisMenuEle = (item, access) => {
  if (item.meta && item.meta.access && item.meta.access.length) {
    if (hasOneOf(item.meta.access, access)) return true
    else return false
  } else return true
}
export const hasChild = (item) => {
  return item.children && item.children.length !== 0
}

//递归判断获取当前路由是否在路由导航上
export function  isRouterMenu(path, arr, list = []) {
  for(var i = 0; i<arr.length; i++){
      if (arr[i].children && arr[i].children.length) {
          isRouterMenu(path, arr[i].children, list);
      }else{
          if (arr[i].path == path) {
            list.push(arr[i])
          }
      }
  }
  return list
}


/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}


/**
 * 递归深拷贝
 */
export const diGuiDeepCopy = (obj) => {
  let objClone = Array.isArray(obj) ? [] : {}//获取是数组还是对象赋空值
  if(obj && typeof obj === "object"){//不为空且是对象（含数组和对象）
      for(let key in obj){
      //判断ojb子元素是否为对象，如果是，递归复制
      if(obj[key] && typeof obj[key] === "object") {
          objClone[key] = diGuiDeepCopy(obj[key])
      }else{
          //如果不是，简单复制
          objClone[key] = obj[key]
      }
      }
  }
  return objClone
}
// 将日期对象转成指定格式的日期时间字符串
export const formatDateTime = function (dateTimr) { 
  var date = new Date(dateTimr);
	var y = date.getFullYear(); 
	var m = date.getMonth() + 1;  
	m = m < 10 ? ('0' + m) : m;  
	var d = date.getDate();  
	d = d < 10 ? ('0' + d) : d;  
	 var h = date.getHours();  
	 h=h < 10 ? ('0' + h) : h;  
	var minute = date.getMinutes();  
	minute = minute < 10 ? ('0' + minute) : minute;  
	var second=date.getSeconds();  
	 second=second < 10 ? ('0' + second) : second;  
	return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second; 
}

// 根据传入的时间当前时区转为世界时间并转为日期时间字符串
export const dateUpdateUtc = function (dateTimr) { 
  // const dateTimrDate = new Date(dateTimr)
  // const utcDate = formatDateTime(new Date(dateTimr.getTime() + dateTimr.getTimezoneOffset() * 60000))
  console.log("时间====",dateTimr)
  var date = new Date(dateTimr.getTime() + dateTimr.getTimezoneOffset() * 60000);
	var y = date.getFullYear(); 
	var m = date.getMonth() + 1;  
	m = m < 10 ? ('0' + m) : m;  
	var d = date.getDate();  
	d = d < 10 ? ('0' + d) : d;  
	 var h = date.getHours();  
	 h=h < 10 ? ('0' + h) : h;  
	var minute = date.getMinutes();  
	minute = minute < 10 ? ('0' + minute) : minute;  
	var second=date.getSeconds();  
	 second=second < 10 ? ('0' + second) : second;  
	return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second; 
}


// 根据传入的世界时间转换未当前时区时间
export const  utcUpdatedDate = (dateTimr) => {
  if(!dateTimr) return
  const dateTimrStr = dateTimr.replace(/\-/g, '/') // 兼容ios '2019/11/09 10:13:21'
  const toDate = new Date(dateTimrStr);
  var utc = toDate.getTime()

  var offset = 0 - new Date().getTimezoneOffset(); //时区区
  var korean = utc + 60000 * offset;

  var date = new Date(korean);
  // console.log("date2时间=====",new Date().getTimezoneOffset())
  var y = date.getFullYear(); 
  var m = date.getMonth() + 1;  
  m = m < 10 ? ('0' + m) : m;  
  var d = date.getDate();  
  d = d < 10 ? ('0' + d) : d;  
  var h = date.getHours();  
  h=h < 10 ? ('0' + h) : h;  
  var minute = date.getMinutes();  
  minute = minute < 10 ? ('0' + minute) : minute;  
  var second=date.getSeconds();  
  second=second < 10 ? ('0' + second) : second;  
  return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second
}

// 根据时间转换字符串转义
export const getCurrentZoneTimeStr = (dateTimr) => {
  if(!dateTimr) return ''
  // 将0时区转成本地时间
  const dateTimrStr = utcUpdatedDate(dateTimr).replace(/\-/g, '/')// 兼容ios '2019/11/09 10:13:21'
  const date = new Date(dateTimrStr) 
  const now = new Date();
  const diffNow = now - date;

  const seconds = 1000;
  const minutes = seconds * 60;
  const hours = minutes * 60;
  const days = hours * 24;
  const weeks = days * 7;
  const months = days * 30; // 假设每月都是30天
  const years = days * 365;

  const diff = date.getTime()
  
  var y = date.getFullYear(); 
  var m = date.getMonth() + 1;  
  m = m < 10 ? ('0' + m) : m;  
  var d = date.getDate();  
  d = d < 10 ? ('0' + d) : d;  
  var h = date.getHours();  
  h=h < 10 ? ('0' + h) : h;  
  var minute = date.getMinutes();  
  minute = minute < 10 ? ('0' + minute) : minute;  
  var second=date.getSeconds();  
  second=second < 10 ? ('0' + second) : second;  
  
  let time
  if (diff <= 0) {
    time = '';
  } else if (diff < 1 * 60 * 60 * 1000) {
    time = `${Math.floor(diff / 1000 / 60)}分钟前`;
  } else if (diff < 24 * 60 * 60 * 1000) {
    time = `${Math.floor(diff / 1000 / 60 / 60)}小时前`;
  } else if (diff < 48 * 60 * 60 * 1000) {
    time = `昨天 ${h}:minute`;
  } else if (diff < 72 * 60 * 60 * 1000) {
    time = `前天 ${h}:minute`;
  } else if (diff < 7 * 24 * 60 * 60 * 1000) {
    time = `${Math.floor(diff / 1000 / 60 / 60 / 24)}天前`;
  } else if (diff < 14 * 24 * 60 * 60 * 1000) {
    time = '一周前';
  } else if (diffNow < years) {
    time = `${m}-${d}`
  } else {
    time = `${y}-${m}-${d}`
  }
  return time
}


/**
 * 复制内容带换行\r或\n换行
 */
export const copyTextarea = (codeData) => {
  const textarea = document.createElement('textarea'); //构建textarea实现换行
  textarea.value = codeData; // \r\n 是 换行 符号」
  document.body.appendChild(textarea); // 添加临时实例
  textarea.select(); // 选择实例内容
  document.execCommand('Copy'); // 执行复制
  document.body.removeChild(textarea); // 删除临时实例
  //成功提示信息
  Message.success({
    message: "复制成功"
  })
}

//获取当前路径对key值参数，比如getQueryVariable('token')获取token
export const getQueryVariable = (variable) => {
  var query = window.location.href.split("?")[1]; // 根据“?”切割数组，截取“?”之后的字符串
  console.log("路径返回",query)
  if(query){
    var vars = query.split("&"); // 根据“&”切割成数组
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      // ['appid','1234567890']
      if(pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
    }
  }
  return(false);
}

// 获取使用注入样式
export const removeStyleFun = (hrefStr) =>{
  if(!hrefStr) return
  var links = document.head.getElementsByTagName('link');
  for (var i = 0; i < links.length; i++) {
      if (links[i].href === hrefStr) {
          document.head.removeChild(links[i]);
      }
  }
}

// 获取使用注入样式
export const setStyleFun = (className, module) =>{
  let moduleStr = 'news'
  if(module){
    if(module == 1) moduleStr = 'news'
    if(module == 2) moduleStr = 'flash-news'
    if(module == 3) moduleStr = 'community'
    if(module == 7) moduleStr = 'recruit'
    if(module == 15) moduleStr = 'expo'
  }
  // 判断正式测试web路径前缀获取样式
  const webBaseUrl = process.env.NODE_ENV === 'development' ? 'https://website-test.pasa365.com' : 
    process.env.NODE_ENV === 'test' ? 'https://website-test.pasa365.com' : 'https://www.pasa365.com'

  let hrefStr = `${webBaseUrl}/api/utils/style?f=true&s=${className}&t=${moduleStr}&rq=1&tl=zh`;
  let is = document.querySelector(`link[href="${hrefStr}"]`) !== null
  if(is){
    return ''
  }else{
    // 调用link引入
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = hrefStr
    document.getElementsByTagName('head')[0].appendChild(link); 
    return hrefStr // 返回路径方便不需要时删除
  }
}


// 视频截取封面
export const videoGetPoster = (videoUrl, infoId) => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video') // 也可以自己创建video
    video.src = videoUrl // url地址 url跟 视频流是一样的
    video.crossOrigin = 'anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
    video.currentTime = 1 // 第一帧
    video.oncanplay = async () => {
      let canvas = document.createElement('canvas') // 获取 canvas 对象
      const ctx = canvas.getContext('2d') // 绘制2d
      canvas.width = video.videoWidth ? video.videoWidth : 320 // 获取视频宽度
      canvas.height = video.videoHeight ? video.videoHeight : 320 //获取视频高度
      // 利用canvas对象方法绘图
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
      // 转换成base64形式
      const videoFirstImgsrc = canvas.toDataURL('image/png') // 截取后的视频封面
      // console.log("封面",videoFirstImgsrc)
      
      video.remove()
      canvas.remove()
      // return videoFirstImgsrc
      
      if(!infoId){
        let res = await systemSnowId().catch( error => { 
          reject(error)
        });
        infoId = res.data.data
      }
      let userMdHou5 = Md5(infoId).slice(-5);
      let urlCenter = `b/${userMdHou5}/information/static/${infoId}/`
      let urlCopy = await ossUploadBase(videoFirstImgsrc, urlCenter).catch((error) => {
        reject(error)
      })
      console.log("返回",urlCopy)
      let site = urlCopy.lastIndexOf("\/");
      //截取最后一个/后的值
      const url = urlCopy.substring(site + 1, urlCopy.length);
      resolve(urlCenter+url)
    }
  })
}
