<!-- 选择多个用户组件 -->
<template>
  <div style="display: flex;align-items: flex-start;justify-content: space-between;">
    <div class="tag_list_bac">
      <div class="tag_mes_bac" >
        <div class="tag_relevance_bac relevance_top" v-if="showRele && position=='top'" v-outside="closePopup" id="tagListId">
          <template v-if="comData.list.length > 0">
            <div v-for="item in comData.list" :key="item.id" class="tag_relevance_card"
              @mouseover="mouseoverId=item.id" @mouseout="mouseoverId=''" @click="addTagFun(item)"
              :class=" mouseoverId==item.id || tagIds.includes(item.id) ? 'select_class' : ''">
              <div class="list_item">
                <!-- <div class="list_item_id">{{ item.id }}</div> -->
                <div class="list_item_title">{{ item.title }}</div>
              </div>
            </div>
            <div class="bot_mes_text" v-if="comData.pageNum >= comData.total && !getLoading">没有更多了...</div>
          </template>
          <div class="bot_mes_text" v-if="comData.list.length == 0 && !getLoading">无数据</div>
          <div class="bot_mes_text" v-if="getLoading">
            <i class="el-icon-loading"></i>  加载中
          </div>
        </div>
        <div style="display: flex;">
          <el-input @focus="focusFun" :id="'inputNewsId'+$props.inputId"
            placeholder="请输入标题选择" v-model="tagInput" @input="tagRemoteFun()"/>
          <el-button style="margin: 0px;" size="mini" type="primary" 
            :disabled="selectTags.length==0" @click="addNewsSub()"  v-preventReClick>提交</el-button>
        </div>
        <div class="tag_relevance_bac relevance_bot" v-if="showRele && position=='bottom'" v-outside="closePopup" id="tagListId">
          <template v-if="comData.list.length > 0">
            <div v-for="item in comData.list" :key="item.id" class="tag_relevance_card" 
              @mouseover="mouseoverId=item.id" @mouseout="mouseoverId=''" @click="addTagFun(item)"
              :class=" mouseoverId==item.id || tagIds.includes(item.id) ? 'select_class' : ''">
              <div class="list_item">
                <!-- <div class="list_item_id">{{ item.id }}</div> -->
                <div class="list_item_title">
                  <template v-if="item.isVideo">（视频）</template>
                  <template v-else>（资讯）</template>
                  {{ item.title }}</div>
              </div>
            </div>
            <div class="bot_mes_text" v-if="comData.pageNum >= comData.total && !getLoading">没有更多了.....</div>
          </template>
          <div class="bot_mes_text" v-if="comData.list.length == 0 && !getLoading">无数据</div>
          <div class="bot_mes_text" v-if="getLoading">
            <i class="el-icon-loading"></i>  加载中
          </div>
        </div>
      </div>
      <!-- <div style="position: relative;" v-for="(item, index) in selectTags" :key="index">
        <div class="tag_bac_div" >{{ item.title }}</div>
        <i class="el-icon-error delete_tag_icon" @click="deleteTagFun(item.id)"></i> 
      </div> -->
      <div class="tag_bac_div" v-for="(item, index) in selectTags" :key="index">
        <template v-if="item.isVideo">视频{{ index+1 }}：</template>
        <template v-else>资讯{{ index+1 }}：</template>
        <div class="tag_bac_title" >{{ item.title }}</div>
        <div>
          <el-button type="warning" plain size="mini" @click="deleteTagFun(item.id)">删除</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {  newsSearchList } from '@/api/configManage'
import { exhibitionNewsAdd } from '@/api/configManage'
export default {
  name: 'selectUserMultipleTwo',
  props: {
    infoId: {
      type: String,
      default: '',
    },
    inputId: { // 单个页面多次使用该组件传入inputid值区分
      type: String,
      default: '',
    },
    position: { // 咱开位置
      type: String,
      default: 'bottom',
    },
    limit: { // 限制数量
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tagInput: '',
      showRele: false,// 标签列表框
      getLoading: false,
      // 公司查询相关参数
      comData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        list: [],
      },
      selectTags: [],
      tagIds: [],
      mouseoverId: '',
    };
  },
  directives: {
    outside: {
      bind(el, binding, vnode) {
        function documentClick(e) {
          let _that = vnode.context
          e.stopPropagation();
          // const pop = el.getElementsByClassName('pop-container')[0]; // 弹窗的容器 
          // && pop && !pop.contains(e.target)
          const inputEl = document.getElementById('inputNewsId'+_that.inputId)
          if (!el.contains(e.target) && !inputEl.contains(e.target)) {
            binding.value(); // 调用组件中定义的方法
          }
        }
        el.__documentClick__ = documentClick;
        document.addEventListener('click', documentClick);
      },
      unbind(el) {
        document.removeEventListener('click', el.__documentClick__);
        delete el.__documentClick__;
      },
    },
  },
  watch : {
		'selectTags.length' : function(val){
      let ids = this.selectTags.map( item => item.id )
      this.tagIds = ids
		}
	},
  created() {
  },
  mounted() {
    
  },
  methods: {
    closePopup(){
      this.showRele = false
    },
    focusFun(){
      if(this.showRele) return 
      this.tagRemoteFun()
    },
    // 初始化获取标签列表
    tagRemoteFun() {
      this.showRele = true
      this.comData = {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        list: [],
      }
      let data = {
        pageNum: this.comData.pageNum,
        pageSize: this.comData.pageSize,
        title: this.tagInput,
        filterExhibitionId: this.$props.infoId,
      }
      this.getLoading = true
      newsSearchList(data).then(( res ) => {
        this.getLoading = false
        this.comData.list = res.data
        this.comData.total = res.totalPages
        this.$nextTick( () => {
          document.getElementById("tagListId").addEventListener('scroll', this.windowScrollListener);
        })
      }).catch((error) => {
        this.getLoading = false
      })
    },
    // 分页获取标签列表
    tagPageFun() {
      if((this.comData.pageNum >= this.comData.total) || this.getLoading) return  // 没有更多数据了
      let data = {
        pageNum: this.comData.pageNum + 1,
        pageSize: this.comData.pageSize,
        title: this.tagInput,
        filterExhibitionId: this.$props.infoId,
      }
      this.getLoading = true
      newsSearchList(data).then(( res ) => {
        this.getLoading = false
        this.comData.list = this.comData.list.concat(res.data)
        this.comData.total = res.totalPages
        this.comData.pageNum = res.pageIndex
      }).catch((error) => {
        this.getLoading = false
      })
    },
    // 列表滚动事件
    windowScrollListener(e){
      //e.srcElement.scrollTop 容器滚动条距离顶部的距离
      if(e.srcElement.clientHeight + parseInt(e.srcElement.scrollTop) === e.srcElement.scrollHeight){
        this.tagPageFun()
      }
    },
    // 添加标签
    addTagFun(item){
      if(!this.selectTags.some( obj => obj.id == item.id )){
        if(this.$props.limit > 0 && this.selectTags.length >= this.$props.limit){
          this.$message(`用户最多可选${this.$props.limit}个！`)
          return
        }
        let obj = JSON.parse(JSON.stringify(item))
        this.selectTags.push(obj)
      }else{
        this.deleteTagFun(item.id)
      }
    },
    // 删除标签
    deleteTagFun(id){
      this.selectTags = this.selectTags.filter(obj => obj.id !== id);
    },
    // 展会添加资讯
    addNewsSub(){
      let info = {
        newsIds: [],
        exhibitionId: this.$props.infoId
      }
      this.selectTags.forEach( item => {
        info.newsIds.push(item.id)
      })
      this.$openLoading()
      exhibitionNewsAdd( info ).then( res => {
        this.$closeLoading()
        this.$message.success("添加成功")
        this.$emit('refreshDataList')
        this.selectTags = []
      }).catch((error) => {
        this.$closeLoading()
        this.$message("添加失败"+error)
      })
    }
    
  },
  destroyed() {
    
  },
};
</script>
<style lang="scss" scoped>
.tag_list_bac{
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 16px;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
}
// .tag_bac_div{
//   padding: 8px 16px;
//   border-radius: 4px;
//   background-color: rgba(32, 108, 255, 0.08);
//   // color: #206CFF;
//   color: #000;
//   font-family: "PingFang SC";
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 100%; /* 12px */
// }
.tag_bac_div{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}
.tag_bac_Conten{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tag_bac_title{
  width: 1px;
  flex: 1;
  padding: 8px 16px;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%; /* 12px */
  margin-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: rgba(32, 108, 255, 0.08);
}
.delete_tag_icon{
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 16px;
  cursor: pointer;
}
.tag_mes_bac{
  width: 400px;
  position: relative;
  min-height: 18px;
  .tag_relevance_bac::-webkit-scrollbar {
    width: 5px;
  }
  .tag_relevance_bac{
    width: 100%;
    max-height: 220px;
    overflow: auto;
    padding: 12px 0;
    background: #FFF;
    border-radius: 2px;
    z-index: 999;
    .tag_relevance_card{
      color: #606266;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      cursor: pointer;
      border-bottom: 1px solid #F2F3F5;
    }
    .select_class{
      background: #F2F3F5;
      // color: #206CFF;
      color: #409EFF;
      font-weight: 600;
    }
  }
  .relevance_top{
    position: absolute;
    left: 0;
    bottom: 40px;
    box-shadow: 0px  0px 12px 4px  rgba(0, 0, 0, 0.12);
  }
  .relevance_bot{
    position: absolute;
    left: 0;
    top: 40px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  }
}
.list_item{
  padding: 6px 2px 6px 16px;
  display: flex;
  justify-content: space-between;
  .list_item_id{
    margin-right: 20px;
  }
  .list_item_title{
    flex: 1;
    width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.tag_op_top{
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 17px;
  .op_top_left{
    flex: 1;
  }
  .op_top_right{
    color:blueviolet;
    width: 32px;
    cursor: pointer;
  }
}
.tag_op_bot{
  font-size: 12px;
  color:#C1C1C1;
  line-height: 12px;
}
.bot_mes_text{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8e8e8e;
  margin: 8px 0;
  i{
    font-size: 18px;
    color: #8e8e8e;
    margin-right: 3px;
  }
}
</style>
