var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "用户详情",
        "close-on-click-modal": false,
        visible: _vm.visible,
        width: "980px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "20px", "padding-top": "0px" } },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.dataForm, "label-width": "auto" } },
            [
              _c("h2", [_vm._v("基本信息")]),
              _c("div", { staticClass: "form_div_bac" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "企业图片" } },
                      [
                        _vm.$companyIcon + _vm.dataForm.icon
                          ? _c("viewer", [
                              _c("img", {
                                staticStyle: {
                                  width: "60px",
                                  height: "60px",
                                  "object-fit": "cover"
                                },
                                attrs: {
                                  src:
                                    _vm.$pathPrefix +
                                    _vm.$companyIcon +
                                    _vm.dataForm.icon
                                }
                              })
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("el-form-item", { attrs: { label: "企业ID" } }, [
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(_vm._s(_vm.dataForm.id))
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "企业名称" } }, [
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(_vm._s(_vm.dataForm.name))
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "企业英文名称" } }, [
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(_vm._s(_vm.dataForm.nameEn))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("el-form-item", { attrs: { label: "企业类型" } }, [
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("stateFilter")(
                              _vm.dataForm.type,
                              _vm.companyType
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "成立时间" } }, [
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(
                          _vm._s(_vm._f("showDate")(_vm.dataForm.createTime))
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "状态" } }, [
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("stateFilter")(
                              _vm.dataForm.state,
                              _vm.companyStateDisct
                            )
                          )
                        )
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "主营业务" } }, [
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(_vm._s(_vm.dataForm.channelStr))
                      ])
                    ]),
                    _c("el-form-item", { attrs: { label: "权重" } }, [
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(_vm._s(_vm.dataForm.sort))
                      ])
                    ])
                  ],
                  1
                )
              ]),
              _c("el-form-item", { attrs: { label: "官网地址" } }, [
                _c(
                  "span",
                  {
                    staticClass: "official_url",
                    on: {
                      click: function($event) {
                        return _vm.openUrl(_vm.dataForm.officialUrl)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.dataForm.officialUrl))]
                )
              ]),
              _c("el-form-item", { attrs: { label: "公司简介" } }, [
                _c("div", {
                  staticClass: "company_content",
                  domProps: { innerHTML: _vm._s(_vm.dataForm.content) }
                })
              ]),
              _c("div", { staticClass: "fen_ge_xian" }),
              _c("h2", [_vm._v("联系方式")]),
              _c("div", { staticClass: "form_div_bac" }, [
                _c("div", { staticClass: "lian_xi_bac" }, [
                  _c(
                    "span",
                    { staticStyle: { "font-weight": "600", width: "80px" } },
                    [_vm._v("Telegram")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/img/tg_logo_img.png") }
                      }),
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(_vm._s(_vm.dataForm.tgAccount))
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "form_div_bac" }, [
                _c("div", { staticClass: "lian_xi_bac" }, [
                  _c(
                    "span",
                    { staticStyle: { "font-weight": "600", width: "80px" } },
                    [_vm._v("WhatsApp")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/phone_logo_img.png")
                        }
                      }),
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(_vm._s(_vm.dataForm.whatsAccount))
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "form_div_bac" }, [
                _c("div", { staticClass: "lian_xi_bac" }, [
                  _c(
                    "span",
                    { staticStyle: { "font-weight": "600", width: "80px" } },
                    [_vm._v("Line")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/line_logo_img.png")
                        }
                      }),
                      _c("div", { staticClass: "mes_div" }, [
                        _vm._v(_vm._s(_vm.dataForm.lineAccount))
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "fen_ge_xian" }),
              _c("h2", [_vm._v("绑定资讯账号")]),
              _c(
                "div",
                {
                  staticClass: "form_footer_bac",
                  staticStyle: { "padding-bottom": "30px" }
                },
                _vm._l(_vm.dataForm.userAccoutlist, function(userId, index) {
                  return _c("div", { key: index }, [
                    _c("div", { staticClass: "tag_bac_div" }, [
                      _vm._v(_vm._s(userId))
                    ])
                  ])
                }),
                0
              ),
              _c("h2", [_vm._v("绑定招聘账号")]),
              _c(
                "div",
                { staticClass: "form_footer_bac" },
                _vm._l(_vm.dataForm.recruitAccoutlist, function(userId, index) {
                  return _c("div", { key: index }, [
                    _c("div", { staticClass: "tag_bac_div" }, [
                      _vm._v(_vm._s(userId))
                    ])
                  ])
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }