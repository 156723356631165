import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import directive from '@/directive'
import config from '@/config'
import components from '@/components/index.js'
import ElementUI from 'element-ui';
import { Loading } from 'element-ui';
import Viewer from 'v-viewer' // 引入图片预览js
import './icons' // icon
import Md5 from 'js-md5'
import VueI18n from 'vue-i18n';
import { messages } from './components/common/i18n';
import 'viewerjs/dist/viewer.css' // 引入css
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
// import './assets/css/theme-green/index.css'; // 浅绿色主题
import './assets/css/icon.css';
import './components/common/directives';
// import 'babel-polyfill'; 
Vue.config.productionTip = false;
Vue.prototype.$Md5 = Md5

Vue.prototype.$pathPrefix = config.pathPrefix //图片前缀路径 
Vue.prototype.$companyIcon = 'index/unicorn/company/icon/' //企业logo前缀路径
Vue.prototype.$systemAvatar = 'system/user/avatar.png' //用户系统头像
Vue.prototype.$openLoading = (target) => { 
  // 如果已经有加载提示在显示，则先关闭
  if (Vue.prototype.$loading.instance) {
    Vue.prototype.$loading.instance.close();
  }
  // 创建新的加载提示
  const loading = Loading.service({
    target: target ? '.content-box' : target,
    text: '努力加载中...',
    background: 'rgba(0, 0, 0, 0.1)',
  });
  setTimeout( () => { // 超时关闭
    if (Vue.prototype.$loading.instance) {
      Vue.prototype.$loading.instance.close();
    }
  }, 8000)
  // 将实例保存，以便可以在需要的时候关闭它
  Vue.prototype.$loading.instance = loading;
};  
// 关闭全局加载提示
Vue.prototype.$closeLoading = () => {
  if (Vue.prototype.$loading.instance) {
    Vue.prototype.$loading.instance.close();
  }
};

Viewer.setDefaults({
  Options: {
    'zIndex': 99999,
    'inline': true, // 启用 inline 模式
    'button': true, // 显示右上角关闭按钮
    'navbar': true, // 显示缩略图导航
    'title': true, // 显示当前图片的标题
    'toolbar': true, // 显示工具栏
    'tooltip': true, // 显示缩放百分比
    'movable': true, // 图片是否可移动
    'zoomable': true, // 图片是否可缩放
    'rotatable': true, // 图片是否可旋转
    'scalable': true, // 图片是否可翻转
    'transition': true, // 使用 CSS3 过度
    'fullscreen': true, // 播放时是否全屏
    'keyboard': true, // 是否支持键盘
    'url': 'data-source' // 设置大图片的 url
  }
})


Vue.use(Viewer)
//全局自定义组件
Vue.use(components)
Vue.use(VueI18n);
Vue.use(ElementUI, {
    size: 'small'
});
/**
 * 注册指令
 */
directive(Vue)
const i18n = new VueI18n({
    locale: 'zh',
    messages
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
