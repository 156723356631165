<!-- 批量快速审核弹窗 -->
<template>
  <el-dialog title="批量快速审核"
    width='750px'
    :close-on-click-modal="false"
    :visible.sync="show">
    <el-form :model="auditKuai" label-width="100px" class="demo-ruleForm" style="height: 350px;">
      <el-form-item label="审核状态" prop="content">
        <el-radio-group v-model="auditKuai.radio1">
          <el-radio :label="1000">待审核</el-radio>
          <el-radio :label="1001">通过</el-radio>
          <el-radio :label="1004">拒绝</el-radio>
        </el-radio-group>
        <div style="margin-top: 20px;">
          <el-input type="textarea" 
            v-if="auditKuai.radio1 == 1004"
            placeholder="请输入拒绝内容"              
            v-model="auditKuai.inputValue1"
            maxlength="100"
            rows='4'>
          </el-input>
        </div>
        <div class="mes_error_text" v-if="auditKuai.radio1 == 1004 && !auditKuai.inputValue1 && isClick">请输入拒绝内容</div>
      </el-form-item>
      <el-form-item label="所属创作者" v-if="currentModule != 7 && auditKuai.radio1 != 1004">
        <div style="display: flex;align-items: center;">
          <selectUserDuo 
            v-if="show"
            :maxLength='idList.length'
            :ids.sync="auditKuai.authorIds" 
            style="width: 400px;margin-right: 20px;"/>
        </div>
        <div style="color: #84909D;line-height: 16px;margin-top: 6px;width: 70%;">
          可通过搜索创作者手机号、邮箱地址、昵称。
          当前已选择<span style="color:red">{{ idList.length }}</span>篇文章，
          最多可选择<span style="color:red">{{ idList.length }}</span>个作者，选择作者后按顺序分配
          已选择的创作者数量少于文章数量，将按顺序重复分配创作者。
        </div>
        <div class="mes_error_text" v-if="auditKuai.authorIds.length == 0 && isClick">请选择作者</div>
      </el-form-item>
      <el-form-item label="发布时间" v-if="auditKuai.radio1 == 1001">
        <div style="display: flex;align-items: center;">
          <el-radio-group v-model="auditKuai.radio2">
            <el-radio :label="1">立即发布</el-radio>
            <el-radio :label="2">定时发布</el-radio>
          </el-radio-group>
          <el-date-picker 
          style="margin-left: 16px;"
            :disabled="auditKuai.radio2 == 1"
            v-model="auditKuai.preTime"
            type="datetime"
            placeholder="选择定时发布时间"
            default-time="12:00:00"
            :picker-options="pickerOptions()">
          </el-date-picker>
        </div>
        <div class="mes_error_text" v-if="auditKuai.radio2==2 && !auditKuai.preTime">请选择定时发布时间</div>
      </el-form-item>
      <el-form-item label="备注" prop="content">
        <el-input type="textarea" 
          v-model="auditKuai.inputValue2"
          maxlength="100"
          rows='4'
          show-word-limit>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="submitKuaiForm('auditKuai')" :disabled="auditKuai.radio1==1000"  v-preventReClick>提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { auditAudit, recruitAudit } from '@/api/newsManage'
import { dateUpdateUtc } from '@/utils'

export default {
  name: 'quickAuditCom',
  data () {
    return {
      idList: [],
      show: false,
      currentModule: '',
      isClick: false,
      //快速审核参数
      auditKuai: {
        radio1: 1000,
        inputValue1: '',
        authorIds: [],
        inputValue2: '',
        radio2: 1,
        preTime: '',
      },
    }
  },
  methods: {
    pickerOptions(){
      return {
        disabledDate(time) {
          const date = new Date();
          const oneday = date.getTime();
          return time.getTime() < oneday - 86400000;
        },
        selectableRange: (() => {
          let date1 = new Date(this.auditKuai.preTime)
          let data = new Date();
          if(date1.getFullYear() === data.getFullYear() &&
            date1.getMonth() === data.getMonth() &&
            date1.getDate() === data.getDate()){
            let hour = data.getHours();
            let minute = data.getMinutes();
            let second = data.getSeconds();
            return [`${hour}:${minute}:${second} - 23:59:59`]
          }else{
            return [`00:00:00 - 23:59:59`]
          }
        })(),
      }
    },
    // 打开批量审核弹窗
    open(ids, currentModule){ 
      this.idList = ids
      this.isClick = false
      this.currentModule = currentModule
      this.show = true
      this.auditKuai = {
        radio1: 1000,
        inputValue1: '',
        authorIds: [],
        inputValue2: '',
        radio2: 1,
        preTime: '',
      } 
    },
    // 提交快速审核
    submitKuaiForm(ids){
      this.isClick = true
      if(this.auditKuai.radio1 == 1004 && !this.auditKuai.inputValue1) return
      if(this.auditKuai.radio2 == 2 && !this.auditKuai.preTime) return
      if(this.currentModule != 7 && this.auditKuai.radio1 != 1004){
        if(this.auditKuai.authorIds.length == 0) return
      }
      this.isClick = false
      this.$confirm('请确认最终提交的信息，避免误操作！', '提示', {
        confirmButtonText: '确定无误',
        cancelButtonText: '继续填写',
        type: 'warning'
      }).then(() => {
        let info = {
          module: this.currentModule,
          remark: this.auditKuai.inputValue2,
          ids: this.idList,
          state: this.auditKuai.radio1,
        }
        if(this.auditKuai.radio2 == 2){
          info.preTime  =  dateUpdateUtc(new Date(this.auditKuai.preTime))
        }else{
          info.preTime  = null
        }
        if(this.auditKuai.radio1 == 1004) info.refusalCause = this.auditKuai.inputValue1
        this.$openLoading()
        if(this.currentModule == 7){
          recruitAudit( info ).then( res => {
            this.$closeLoading()
            this.$message.success("审核成功")
            this.$emit('returnGetData')
            this.show = false
          }).catch((error) => {
            this.$closeLoading()
            this.$message("审核失败"+ error)
          })
        }else{
          if(this.currentModule != 7 && this.auditKuai.radio1 != 1004){
            if(this.auditKuai.authorIds.length != 0){
              info.authorIds = this.auditKuai.authorIds
            }
          }
          auditAudit( info ).then( res => {
            this.$closeLoading()
            this.$message.success("审核成功")
            this.$emit('returnGetData')
            this.show = false
          }).catch((error) => {
            this.$closeLoading()
            this.$message("审核失败"+ error)
          })
        }
      }).catch(() => { });
    },

  }
}
</script>
<style lang="scss" scoped>
.mes_error_text{
  font-size: 12px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
}
</style>
