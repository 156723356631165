<!-- 上传字幕文件1 -->
<template>
  <div class="com_div_bac">
    <template  v-if="!uploaing">
      <el-upload action="#" :show-file-list="false" :auto-upload="true" :on-change="uploadBefore" :limit="1"
        accept=".vtt, .srt">
        <div class="upload_bac">
          <!-- <i slot="default" class="el-icon-plus"  style="font-size:20px"></i> -->
            点击上传
        </div>
      </el-upload>
    </template>
    <div v-else class="upload_loading_div" >
      <i class="el-icon-loading" style="color:#000;font-size:18px;color: #FFF;"/>
    </div>
    <div class="url_bac" v-if="urlStr">
      <div class="url_div">{{$pathPrefix}}{{ urlStr }}</div>
      <i class="el-icon-error close_img_icon" @click="deleteUrl()"/>
    </div>
  </div>
</template>

<script>
import { ossUploadSubtitle } from "@/utils/ossUpload";
import { systemSnowId } from '@/api/utilsManage'
export default {
  name: 'uploadSubtitle',
  props: {
    url: {
      type: String,
      default: '',
    },
    lang: {
      type: String,
      default: 'zh',
    },
    newsId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      infoId: this.$props.newsId,
      uploaing: false,
      urlStr: this.$props.url,
    };
  },
  watch : {
		'url' : function(val){
      this.urlStr = val
		},
		'urlStr' : function(val){
      this.$emit('update:url', val)
		},
		'newsId' : function(val){
      this.infoId = val
		},
		'infoId' : function(val){
      this.$emit('update:newsId', val)
		},

	},
  created() {
  },
  mounted() {
    
  },
  methods: {
    async uploadBefore(file) {
      if (file.status !== 'ready' || !file.raw) {
        return;
      }
      this.uploaing = true
      if(!this.infoId){
        let res = await systemSnowId().catch( error => { 
          reject(error)
        });
        this.infoId = res.data.data
      }
      let userMdHou5 = this.$Md5(this.infoId).slice(-5);
      // translation/{targetType}-{targetld//captionS-{languageCode}
      let urlCenter = `translation/1-${this.infoId}/captions-${this.$props.lang}`
      let res = await ossUploadSubtitle(file.raw, urlCenter).catch((error) => {
        this.uploaing = false
        this.$message.error(error);
      })
      if(!res) return
      this.urlStr = urlCenter
      this.uploaing = false
    },
    //删除
    deleteUrl(){
      this.urlStr = ''
      this.$emit("returnUrl", '')
    },
  },
  destroyed() {
    
  },
};
</script>
<style scoped>
>>> .el-upload--text {
  border: 0px dashed transparent !important;
  width: calc(100% + 3px);
  height: calc(100% + 3px);
}
</style>
<style lang="scss" scoped>
.com_div_bac{
  display: flex;
  align-items: flex-start;
  width:100%;
  height: 100%
}
.upload_bac{
  height: 32px;
  padding: 0px 12px;
  background: #409EFF;
  color: #FFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.upload_loading_div{
  width: 83px;
  height: 32px;
  background: #409EFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.url_bac{
  flex: 1;
  // height: 32px;
  word-wrap: break-word;
  position: relative;
  margin-left: 20px;
  border: 1px solid #DCDFE6;
  background-color: #FFF;
  padding-left: 15px;
  border-radius: 4px 0 0 4px;
  color: #84909D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .url_div{
    flex: 1;
    line-height: 28px;
    overflow: auto;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    margin-right: 6px;
  }
}
.close_img_icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  color: #000;
  font-size: 16px;
  display: block;
}
</style>
