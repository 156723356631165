<!-- 编辑弹窗弹窗 -->
<template>
  <el-dialog title="编辑"
    width='80vw'
    :close-on-click-modal="false"
    :visible.sync="showDia">
    <div class="page_bac_div">
      <!-- 左边表单区 -->
      <div class="form_div_bac"
        :style="{paddingRight: leftWidth +'px'}">
        <el-form :model="newsInfo" :rules="rules" ref="newsInfo" label-width="auto" class="demo-newsInfo">
          <el-form-item label="所属模块">视频</el-form-item>
          <el-form-item label="所属目标市场" prop="countryList">
            <el-select v-model="newsInfo.countryList" placeholder="请选择" @focus="getCountryDisct()"
              filterable multiple clearable style="width: 100%;">
              <el-option v-for="item in countryDisct" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <div class="mes_error_text" v-if="newsInfo.countryList.length==0 && EditIsClick">请选择目标市场</div>
          </el-form-item>
          <el-form-item label="所属业务或分类" prop="channelList">
            <el-cascader
              style="width: 100%;"
              filterable clearable
              :props="casProps"
              :options="channelCateDisct"
              v-model="newsInfo.channelCateList"
              :show-all-levels="false"
              :change-on-select="true"
              @visible-change="getTerritory()">
            </el-cascader>
            <div class="mes_text_div"> 选中1-6个业务或分类</div>
            <div class="mes_error_text" 
              v-if="newsInfo.channelCateList.length >6 || newsInfo.channelCateList.length < 1 && EditIsClick">
              请选中1-6个业务或分类
            </div>
          </el-form-item>
          <el-form-item label="标题" prop="title" required>
            <el-input v-model="newsInfo.title" maxlength="150"/>
            <div class="mes_error_text" v-if="!newsInfo.title && EditIsClick">请输入标题</div>
          </el-form-item>
          <el-form-item label="视频简介">
            <el-input v-model="newsInfo.content" maxlength="200"/>
          </el-form-item>
          <el-form-item label="封面" prop="content">
            <uploadImgUtils :image.sync="newsInfo.cover" width="80px" height="80px"/>
          </el-form-item>
          <el-form-item label="上传视频">
            <div style="display: flex;margin-bottom: 8px;">
              <uploadVideoEasy 
                :newsId.sync="newsInfo.id"
                :url.sync="newsInfo.videoInfo.videoUrl" 
                :cover.sync="newsInfo.cover"
                :size.sync="newsInfo.videoInfo.size"
                :duration.sync="newsInfo.videoInfo.duration"
                ref="uploadVideoEasyRef"/>
              <div class="but_bac" v-if="newsInfo.videoInfo.videoUrl">
                <el-button type="primary" @click="getImg($pathPrefix+newsInfo.videoInfo.videoUrl)"
                  v-preventReClick>截取封面</el-button>
                <el-button type="primary" style="margin: 0px" @click="returnUrl('')" v-preventReClick
                  :disabled="!subtitleLang">取消字幕</el-button>
                <el-button type="primary" :disabled="!newsInfo.videoInfo.subtitleZh || subtitleLang == 'zh'" style="margin: 0px"
                  @click="returnUrl($pathPrefix+newsInfo.videoInfo.subtitleZh)" v-preventReClick>中文字幕</el-button>
                <el-button type="primary"  :disabled="!newsInfo.videoInfo.subtitleEn  || subtitleLang == 'en'" style="margin: 0px"
                  @click="returnUrl($pathPrefix+newsInfo.videoInfo.subtitleEn)" v-preventReClick>英文字幕</el-button>
              </div>
            </div>
            <div class="mes_error_text" v-if="!newsInfo.videoInfo.videoUrl && EditIsClick">请上传视频</div>
          </el-form-item>
          <el-form-item label="中文字幕">
            <uploadSubtitle 
              :newsId.sync="newsInfo.id"
              :url.sync="newsInfo.videoInfo.subtitleZh" lang="zh" 
              :currentModule="newsInfo.module"
              @returnUrl="returnUrl"/>
          </el-form-item>
          <el-form-item label="英文字幕">
            <uploadSubtitle 
              :newsId.sync="newsInfo.id"
              :url.sync="newsInfo.videoInfo.subtitleEn" lang="en" 
              :currentModule="newsInfo.module"
              @returnUrl="returnUrl"/>
          </el-form-item>


          
          <el-form-item label="选择标签" prop="labelList">
            <selectTag :labelList.sync="newsInfo.labelList"/>
            最多可添加20个标签
          </el-form-item>
          <el-form-item label="权重排序" prop="infoSort">
            <div style="display: flex;align-items: center;">
              <el-input v-model="newsInfo.infoSort" style="width: 215px;" maxlength="10" v-limitInput:positiveInteger/>
              <span class="mes_text_div">数值越大，排序越靠前</span>
            </div>
          </el-form-item>
          <el-form-item label="权重有效时间" prop="infoSort">
            <div style="display: flex;align-items: center;">
              <el-radio-group v-model="sortExpireRadio"> 
                <el-radio :label="1">设置有效时间</el-radio>
                <el-radio :label="2">不设置有效时间</el-radio>
              </el-radio-group>
              <el-date-picker 
                style="margin-left: 16px;"
                :disabled="sortExpireRadio == 2"
                v-model="newsInfo.infoSortExpire"
                type="datetime"
                placeholder="选择时间"
                default-time="23:59:59"
                :picker-options="pickerOptions()">
              </el-date-picker>
            </div>
            <div class="mes_error_text" v-if="sortExpireRadio==1 && !newsInfo.infoSortExpire  && EditIsClick">请选择定时发布时间</div>
          </el-form-item>
          
        </el-form>

      </div>
      <!-- 手机模型 -->
      <div class="phone_righ_bac">
        <articleVideoModel 
          :newsId="newsInfo.id"
          ref="articleVideoModelRef"
          class="model_com"
          :height="modelHeight"
          :videoUrl.sync="newsInfo.videoInfo.videoUrl" 
          :html.sync="newsInfo.content" 
          :title.sync="newsInfo.title" 
          :tags.sync="newsInfo.labelList"
          :currentModule.sync="newsInfo.module"
          :nickname.sync="newsInfo.nickName"
          :headPic.sync="newsInfo.headPic"
          :date.sync="newsInfo.date"/>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showDia = false">取消</el-button>
      <el-button type="primary" @click="submitForm('newsInfo')" v-preventReClick>提交</el-button>
    </span>
  </el-dialog>
</template>

<script>

import { 
  moduleDisctE, 
  rqDisctE
} from '@/utils/disct.js'
import { 
  systemTerritory, 
  systemTerritoryTree, 
  systemLanguage, 
} from '@/api/utilsManage'
import { auditEdit } from '@/api/newsManage'
import { dateUpdateUtc,utcUpdatedDate, videoGetPoster } from '@/utils'
export default {
  name: 'videoEditDiaCom',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch : {
    'show' : function(val){
      this.showDia = val
    },
    'showDia' : function(val){
      this.$emit('update:show', val)
    },
  },
  data () {
    return {
      showDia: this.$props.show,
      modelHeight: 667,
      leftWidth: 400,
      newsInfo: {// 文章详情
        countryList: [],// 市场
        channelList: [],// 业务
        cateList: [], //分类
        channelCateList: [],// 业务分类
        labelList: [],// 标签
        videoInfo: {
          videoUrl: '',
          subtitleZh: '',
          subtitleEn: '',
        }
      }, 
      sortExpireRadio: 1,
      subtitleLang: '',
      crawlerChannel: this.$route.query.crawlerChannel ,
      countryDisct: [], // 目标市场枚举
      channelCateDisct: [], // 目标业务分类枚举
      channelIdList: [], // 目标type1分类枚举
      moduleDisct: moduleDisctE,// 模块
      rqDisct: rqDisctE, // 发布端口
      EditIsClick: false,
      
      tgGroupList: [],// 选中的tg群列表
      tagInput: '',// 标签输入
      tagList: [],// 查询到的标签列表
      contryIds: [], // 保存的市场id
      channelIds: [], // 保存的业务id
      cateIds: [], // 保存的分类id
      casProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
        emitPath: false
      },

      rules: {
        // originLang: [
        //   { required: true, message: '请选择语言', trigger: 'change' },
        // ],
        // labelList: [
        //   { type: 'array', required: true, message: '请至少添加一个标签', trigger: 'change' }
        // ],
        infoSort: [
          { required: true, message: '请输入权重排序值', trigger: 'blur' },
        ],
      },
    }
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }else{
        return ''
      }
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.setModelHeight);
  },

  methods: {
    pickerOptions(){
      return {
        disabledDate(time) {
          const date = new Date();
          const oneday = date.getTime();
          return time.getTime() < oneday - 86400000;
        },
        selectableRange: (() => {
          let date1 = new Date(this.newsInfo.infoSortExpire)
          let data = new Date();
          if(date1.getFullYear() === data.getFullYear() &&
            date1.getMonth() === data.getMonth() &&
            date1.getDate() === data.getDate()){
            let hour = data.getHours();
            let minute = data.getMinutes();
            let second = data.getSeconds();
            return [`${hour}:${minute}:${second} - 23:59:59`]
          }else{
            return [`00:00:00 - 23:59:59`]
          }
        })(),
      }
    },
    // 获取目标市场
    getCountryDisct(){
      if(this.countryDisct.length == 0){
        systemTerritory({isLoadCate: true}).then(( data ) => {
          this.countryDisct = data.data.country
          this.countryDisct.unshift({id: '0', name: '全球'})
        })
      }
    },
    // 获取目标业务枚举列表
    getTerritory(){
      if(this.channelCateDisct.length == 0){
        systemTerritoryTree({}).then(( data ) => {
          this.channelIdList = []
          this.channelCateDisct = []
          this.channelCateDisct = this.chuliChnnel(data.data)
        })
      }
    },
    // 处理channelCateDisct数据
    chuliChnnel(list){
      list.forEach( item => {
        if(item.dataType == 1){
          this.channelIdList.push(item.id)
        }
        if(item.children && item.children.length>0){
          this.chuliChnnel(item.children)
        }else{
          delete item.children
        }
      })
      return list
    },
    // 打开弹窗
    open(newsInfo){ 
      this.newsInfo = newsInfo
      console.log("文章信息",this.newsInfo)
      this.chuLiGetData()
      this.$nextTick( () => {
        this.setModelHeight()
      })
      window.addEventListener('resize', this.setModelHeight);
      this.getCountryDisct()
      this.getTerritory()
    },
    // 设置模型高度
    setModelHeight(){
      const diaBody = document.querySelectorAll(".el-dialog__body")[0];
      this.modelHeight = diaBody.offsetHeight || 667
      this.modelHeight -= 40
      this.$nextTick( () => {
        const righBac = document.querySelectorAll(".model_com")[0];
        this.leftWidth = righBac.offsetWidth || 400
        this.leftWidth += 40
      })
    },
    // 处理获取到的文章数据
    chuLiGetData(){
      if(this.newsInfo.countryList && this.newsInfo.countryList.length > 0 ){ //市场
        let ids = []
        this.newsInfo.countryList.forEach( item => {
          ids.push(item.id)
        }); 
        this.newsInfo.countryList = ids
        this.contryIds = ids
      }else{
        this.newsInfo.countryList = []
        this.contryIds = []
      }
      this.newsInfo.channelCateList = []
      this.cateIds = []
      this.channelIds = []
      if(this.newsInfo.cateList && this.newsInfo.cateList.length > 0 ){ // 分类
        this.newsInfo.cateList.forEach( item => {
          this.newsInfo.channelCateList.push(item.id)
          this.cateIds.push({id: item.id})
        }); 
      }
      if(this.newsInfo.channelList && this.newsInfo.channelList.length > 0 ){ // 业务
        this.newsInfo.channelList.forEach( item => {
          this.newsInfo.channelCateList.push(item.id)
          this.channelIds.push({id: item.id})
        }); 
      }
      if(this.newsInfo.infoSortExpire) { //是否有设置权重时间
        this.newsInfo.infoSortExpire = utcUpdatedDate(this.newsInfo.infoSortExpire)
        this.sortExpireRadio = 1
      }else{
        this.sortExpireRadio = 2
      }

      if(!this.newsInfo.labelList){ // 标签
        this.newsInfo.labelList = []
      }
    },

    // 验证参数
    verifyFun(){
      if(this.newsInfo.countryList.length==0)  { 
        this.$message("请选中目标市场")
        return false
      }
      let num = this.newsInfo.channelCateList.length
      if(num > 6 || num < 1)  { 
        this.$message("请选中1-6个业务或分类！")
        return false
      }
      if(!this.newsInfo.title) {
        this.$message("请填写标题！")
        return false
      }
      if(!this.newsInfo.videoInfo.videoUrl)  {
        this.$message("请上传视频！")
        return false
      }
      if(this.sortExpireRadio==1 && !this.newsInfo.infoSortExpire) { 
        this.$message("请选择权重有效时间！")
        return false
      }
      return true
    },
    // 处理数据
    chuLiData(){
      let copyInfo = JSON.parse(JSON.stringify(this.newsInfo))
      if(copyInfo.channelCateList && copyInfo.channelCateList.length > 0){
        copyInfo.cateList = []// 分类
        copyInfo.channelList = []
        copyInfo.channelCateList.forEach( id => {
          if(this.channelIdList.includes(id) ){
            copyInfo.cateList.push({id: id})
          }else{
            copyInfo.channelList.push({id: id})
          }
        })
      }
      // 没修改就不返回给后端
      if( JSON.stringify(this.contryIds) === JSON.stringify(copyInfo.countryList)){
        delete copyInfo.countryList
      }
      if( JSON.stringify(this.channelIds) === JSON.stringify(copyInfo.channelList)){
        delete copyInfo.channelList
      }
      if( JSON.stringify(this.cateIds) === JSON.stringify(copyInfo.cateList)){
        delete copyInfo.cateList
      }
      if(copyInfo.countryList){
        let ids = []
        copyInfo.countryList.forEach( id => {
          ids.push({id: parseInt(id)})
        })
        copyInfo.countryList = ids
      }
      if(copyInfo.cateList && copyInfo.cateList.length == 0){
        copyInfo.cateList = [{id: -1}]
      }
      if(copyInfo.channelList && copyInfo.channelList.length == 0){
        copyInfo.channelList = [{id: -1}]
      }
      
      if(copyInfo.module == 2){
        delete copyInfo.countryList
        delete copyInfo.channelList
        delete copyInfo.cateList
        delete copyInfo.labelList
        delete copyInfo.infoSort
      }
      if(copyInfo.labelList && copyInfo.labelList.length==0){
        copyInfo.labelList = [{id: -1}]
      }
      if(this.sortExpireRadio==1){
        copyInfo.infoSortExpire = dateUpdateUtc(new Date(copyInfo.infoSortExpire))
      }else{
        copyInfo.infoSortExpire = ""
      }
      // if(copyInfo.module != 3 && copyInfo.content){
      //   copyInfo.content = '<html>\n <head></head>\n <body>\n  <div>\n'+ copyInfo.content + '</div>\n </body>\n</html>'
      // }
      delete copyInfo.crawlerChannel
      delete copyInfo.nickName
      delete copyInfo.authorId
      delete copyInfo.companyId
      delete copyInfo.isDraft
      delete copyInfo.isDraftPublish
      delete copyInfo.date
      console.log("提交",copyInfo )
      
      delete copyInfo.channelCateList
      return copyInfo
    },
    // 提交编辑
    submitForm(){
      this.$refs['newsInfo'].validate((valid) => {
        if (valid) {
          this.EditIsClick = true
          if(!this.verifyFun()) return
          this.EditIsClick = false
          this.$confirm('请确认最终提交的信息，避免误操作！', '提示', {
            confirmButtonText: '确定无误',
            cancelButtonText: '继续填写',
            type: 'warning'
          }).then( async () => {
            let info = this.chuLiData(this.newsInfo)
            this.$openLoading()
            if(!info.cover) {// 没有封面自动截取
              info.cover = await videoGetPoster(this.$pathPrefix+info.videoInfo.videoUrl, info.id).catch((error) => {
                this.$closeLoading()
                this.$message.error("获取封面失败"+ error)
              })
            }
            if(!info.cover) return
            auditEdit( info ).then( res => {
              this.$closeLoading()
              this.showDia = false
              this.$emit('returnGetData')
              this.$message.success("编辑成功")
            }).catch((error) => {
              this.$closeLoading()
              this.$message.error("编辑失败"+ error)
            })
          }).catch(() => { });
        } else {
          return false;
        }
      })
    },
    // set字幕
    returnUrl(url){
      if(url){
        if(this.subtitleLang == url.slice(-2)){
          return
        }
        this.subtitleLang = url.slice(-2)
      }else{
        if(!this.subtitleLang){
          return
        }
        this.subtitleLang = ''
      }
      this.$refs.uploadVideoEasyRef.setSubtitle(url)
      this.$refs.articleVideoModelRef.setSubtitle(url)
    },
    async getImg(url){
      this.$openLoading()
      this.newsInfo.cover = await videoGetPoster(url, this.newsInfo.id)
      this.$closeLoading()
    }
    
  }
}
</script>
<style lang="scss" scoped>
.page_bac_div{
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  background: #FFF;
}
.form_div_bac{
  flex: 1;
  padding: 20px;
}
.phone_righ_bac{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}
.mes_error_text{
  font-size: 11px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 9px);
  left: 0;
}
.but_bac{
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  gap: 12px;
  margin-left: 12px;
}
</style>
