<!-- 预览资讯模型 -->
<template>
  <!-- 预览弹窗 -->
  <div class="preview_div" v-if="show" @click="show=false">
    <div @click.stop>
      <articleModel
        @click.stop
        :height="Math.min(windowSize.height * .8, 667)"
        :title.sync="previewInfo.title" 
        :nickname.sync="previewInfo.nickName"
        :date.sync="previewInfo.date" 
        :html.sync="previewInfo.html"
        :currentModule.sync="currentModule"
        :urlList.sync="urlList"
        :headPic="previewInfo.headPic"
        :tags.sync="previewInfo.labelList"
        :company="company"
        :newsId.sync="newsId"/>
    </div>
  </div>
</template>

<script>
import windowSize from '@/mixin/windowSize'
import { auditDetails, recruitDetails } from '@/api/newsManage'
export default {
  name: 'previewModel',
  mixins: [windowSize],
  data() {
    return { 
      newsId: '',
      show: false,
      currentModule: 1,
      previewInfo: {
        title: '',
        nickName: '',
        date: '',
        html: '',
        labelList: [],
        headPic: '',
      },
      urlList: [],
      company: {
        icon: "",
        id: "",
        name: "",
        title: ""
      }
    };
  },
  methods: {
    closeModal(event) {
      // 阻止事件冒泡，以免点击内容也触发外层div的关闭
      event.stopPropagation();
      this.show = false;
    },
    async open( info ){
      if(!info.id || !info.module) {
        this.$message("获取参数失败")
        console.log("请求文章参数===",info)
        return
      }
      this.newsId = info.id
      this.currentModule = info.module
      let res = {}
      if(this.currentModule == 7){ // 招聘
        this.$openLoading()
        recruitDetails( info ).then( res => {
          this.$closeLoading()
          let date = ''
          if(res.data.state == 1001){
            date = res.data.lastTime
          }else{
            if(res.data.preTime){
              date = res.data.preTime
            }else{
              date = res.data.createTime
            }
          }
          this.previewInfo = {
            title: res.data.title,
            nickName: res.data.nickName,
            date: date,
            html: res.data.content,
            headPic: res.data.headPic
          }
          this.company = {
            icon: res.data.companyIcon,
            id: res.data.companyId,
            name: res.data.companyName,
            title: res.data.companyTitle
          }
          this.show = true
        }).catch((error) => {
          this.$closeLoading()
          this.$message("获取详情失败", error)
        })
      }else{// 资讯
        this.$openLoading()
        auditDetails( info ).then( res => {
          this.$closeLoading()
          let date = ''
          if(res.data.state == 1001){
            date = res.data.lastTime
          }else{
            if(res.data.preTime){
              date = res.data.preTime
            }else{
              date = res.data.createTime
            }
          }
          this.previewInfo = {
            title: res.data.title || '',
            nickName: res.data.nickName,
            date: date,
            html: res.data.content,
            labelList: res.data.labelList,
            headPic: res.data.headPic
          }
          if(res.data.cover) { 
            this.urlList = res.data.cover.split(',')
          }else{
            this.urlList = []
          }
          this.show = true
        }).catch((error) => {
          this.$closeLoading()
          this.$message("获取详情失败", error)
        })
      }


    }
  },
}
</script>
<style lang="scss" scoped>
.preview_div{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
