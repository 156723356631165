import axiosHttps from '@/config/axios'

//后台系统登录用户
export const login = info => {
    return axiosHttps.request({
        url: `/admin/security/do_login`,
        data: info,
        method: 'post'
    })


}


//验证码校验
export const captchaVerify = data => {
    return axiosHttps.request({
        url: `/admin/security/captcha_verify`,
        data: {params: data},
        method: 'post'
    })
}





