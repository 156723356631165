<!-- 上传视频1 -->
<template>
  <div style="display: flex;align-items: center;">
    <el-upload action="#" :show-file-list="false" :auto-upload="true" :on-change="upLoadFileFun" :limit="1"
      accept="video/*" :style="sytleData" drag v-if="!loadingText">
      <div :style="sytleData" class="upload_bac"  >
        <i slot="default" class="el-icon-plus"  style="font-size:30px"></i>
      </div>
    </el-upload>
    
    <div :style="sytleData" class="upload_bac" style="border: 1px dashed #4290f7" v-else>
      <template v-if="videoUploadPercent && videoUploadPercent > 0">
        <el-progress  type="circle" size="mini" :percentage="videoUploadPercent"
          :width="width / 2"></el-progress>
        <el-button type="info" plain @click="cancelUpload()" size="mini" style="width: 60%;margin-top: 20px">取消上传</el-button>
      </template>
      <template v-else>
        <div style="width: 100%;margin-bottom: 12px;text-align: center;color:#4290f7">{{ loadingText }}</div>
        <div style="width: 100%;text-align: center;">
          <i class="el-icon-loading" style="color:#4290f7;" :style="{fontSize: width / 8 +'px'}"/>
        </div>
      </template>
    </div>
    <template  v-if="videoUrl">
      <div class="img_bacdiv" :style="sytleData">
        <video-two-com :videoSrc="$pathPrefix+videoUrl" :subtitles-src="subtitleUrl"
          :showPlay="true" :videocover="$pathPrefix+cover" :width="width+'px'" :height="height+'px'"/>
        <div class="img_icon_bac">
          <i class="el-icon-error close_img_icon" @click="deleteImg()"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ossUpload, getOssClient, ossUploadBase } from "@/utils/ossUpload";
import { systemSnowId, bySystem } from '@/api/utilsManage'
import { getUUIDName } from '@/utils/index.js'
export default {
  name: 'uploadVideoPoint',
  props: {
    cover: {  // 封面
      type: String,
      default: '',
    },
    url: {  //  视频路径
      type: String,
      default: '',
    },
    size: { //  视频大小
      type: String,
      default: '',
    },
    duration: { //  视频时长
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    newsId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      infoId: this.$props.newsId,
      videoUrl: this.$props.url,// 视频路径
      videoSize: this.$props.size,// 视频大小（B）
      videoDuration: this.$props.duration,// 视频时长（秒）
      subtitleUrl: '',// set字幕文件
      subtitleLang: '',// 当前字幕语言
      sytleData: {
        width: this.$props.width + 'px !important',
        height: this.$props.height + 'px !important',
      },

      loadingText: '',// loading提示文字
      videoUploadPercent: 0,
      client: null,
      uploadData: null,
    };
  },
  watch : {
		'url' : function(val){
      this.videoUrl = val
		},
		'videoUrl' : function(val){
      this.$emit('update:url', val)
		},
		'videoSize' : function(val){
      this.$emit('update:size', val)
		},
		'videoDuration' : function(val){
      this.$emit('update:duration', val)
		},
		'newsId' : function(val){
      this.infoId = val
		},
		'infoId' : function(val){
      this.$emit('update:newsId', val)
		},
	},
  created() {
  },
  mounted() {
    
  },
  methods: {
    //cos上传视频
    upLoadFileFun (file) {
      console.log("文件信息====",file)
      if (file.status !== 'ready') return;
      if (file.raw) {
        // 创建视频标签
        const video = document.createElement('video');
        video.preload = 'metadata';
        // 设置视频源为选择的文件
        video.src = URL.createObjectURL(file.raw);
        // 视频元数据加载完毕
        video.onloadedmetadata = async () => {
          // 设置视频当前时间为0，即视频的开始
          video.currentTime = 1;
          this.videoDuration = Math.floor(video.duration)+''; // 获取时长
          this.videoSize  = Math.floor(file.raw.size)+'';  //获取大小
          this.loadingText = '上传视频中...'
          // 上传oss部分
          if(!this.infoId){
            let res = await systemSnowId().catch( error => { 
              this.$message.error('获取id出错了',error)
            });
            if(!res) return
            this.infoId = res.data.data
          }
          let userMdHou5 = this.$Md5(this.infoId).slice(-5);
          let urlCenter = `b/${userMdHou5}/information/static/${this.infoId}/`
          this.multipartFun(file.raw, urlCenter)
          // // this.easyFUn(file.raw, urlCenter)
          // if( size >= 100){ // 大于100MB使用分片上传
          //   this.multipartFun(file.raw, urlCenter)
          // }else{
          //   this.easyFUn(file.raw, urlCenter)
          // }
        };
      }
    },
    // 简单上传
    async easyFUn(file, urlCenter){
      let urlCopy = await ossUpload(file, urlCenter).catch((error) => {
        console.log("上传失败",error)  
        this.$message.error('上传失败',error)
        this.loadingText = ''
      })
      if(!urlCopy) return
      this.loadingText = ''
      //获取最后一个/的位置
      var site = urlCopy.lastIndexOf("\/");
      //截取最后一个/后的值
      const url = urlCopy.substring(site + 1, urlCopy.length);
      this.videoUrl = urlCenter+url
    },
    // 分片上传
    async multipartFun(file, urlCenter){
      let lastName = file.name.slice(file.name.lastIndexOf(".") + 1)
      const fileName = urlCenter + getUUIDName() +'.'+ lastName
      if(!this.client) {
        this.client = await getOssClient().catch((error) => {
          this.$message.error('获取上传参数出错了',error)
          this.loadingText = ''
        })
      }
      if(!this.client) return  
      const that = this
      async function multipartUpload () {
        //正常来说data的参数应该后台返回，但是这里为了偷懒直接写死
        // 支持断点续传，只尝试600次，
        for (let i = 0; i < 5; i++) {
          try {
            const result = await that.client.multipartUpload(fileName, file, {
              checkpoint: that.uploadData ,
              async  progress (p, cpt) {
                // p进度条的值
                that.uploadData  = cpt
                that.uploadData = cpt
                that.videoUploadPercent = Number((Number(p) * 100).toFixed(1));
              }
            })
            that.loadingText = ''
            that.uploadData = null
            that.videoUploadPercent = 0
            if (result.name) {
              that.videoUrl = result.name
            } else {
              that.$message.error("上传视频失败，请重试");
            }
            break
          } catch (e) {
            that.loadingText = ''
            that.uploadData = null
            that.videoUploadPercent = 0
            console.log("e======",e)
            if (e.name == 'cancel') {
              that.$message('上传取消');
              break
            } else {
              // that.$message.error("上传视频失败，请重试",e);
            }
          }
        }
      }
      multipartUpload()
    },
    // 取消上传
    cancelUpload() {
      const {name, uploadId} = this.uploadData
      this.client.abortMultipartUpload(name, uploadId );
    },
    //删除
    deleteImg(){
      this.videoUrl = ''
    },
    // set字幕
    setSubtitle(url){
      if(!this.videoUrl){
        return
      }
      if(!url){
        this.subtitleUrl = ''
        return
      }
      this.subtitleUrl = url
    }
  },
};
</script>
<style scoped>
>>> .el-upload--text {
  border: 0px dashed transparent !important;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
}
>>> .el-upload-dragger{
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss" scoped>

.upload_bac{
  background: #FBFDFF;
  // border: 1px dashed #C0CCDA;
  border-radius: 4px;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.img_bacdiv{
  margin-left: 16px;
  position: relative;
  background: rgba(0, 0, 0, 1);
}
.img_icon_bac{
  position: absolute;
  right: 6px;
  top: 6px;
  background: white;
  border-radius: 100%;
}
.close_img_icon{
  cursor: pointer;
  color: #000;
  font-size: 16px;
  display: block;
}
</style>
