<template>
  <div>
    <!-- 资讯列表 -->
    <el-dialog
      title="文章管理"
      :close-on-click-modal="false"
      :visible.sync="visible" width="60vw">
      <div style="min-height: 50vh;">
        <selectNewsCom @refreshDataList="getDataList(page.pageNumber)"
          v-if="visible" :infoId="infoId" inputId="2" style="margin-bottom: 20px;"/>
        <!-- height="500" v-adaptive="{bottomOffset: 120,iModal: true}" -->
        <el-table
          :data="page.list" border v-loading="dataListLoading"
          :cell-style="tableStyle" :header-cell-style="tableStyle" >
          <el-table-column label="类型" width="100">
            <template slot-scope="scope">
              {{scope.row.isVideo ? '视频' : '资讯'}}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="150">
            <template slot-scope="scope">
              {{scope.row.createTime | showDate()}}
            </template>
          </el-table-column>
          <el-table-column label="文章ID" prop="id" width="150"/>
          <el-table-column label="标题">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top">
                <p  style=" width: 180px;">{{ scope.row.title }}</p>
                <div slot="reference" class="table_li_text">{{ scope.row.title }}</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="240" label="操作">
            <template slot-scope="scope">
              <el-button type="success" plain size="small" @click="goAuditOrEdit(scope.row)">浏览</el-button>
              <el-button type="primary" size="mini" plain @click="interactFun(scope.row)">评论</el-button>
              <el-button type="warning" plain size="mini" @click="deleteFun(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination 
          background
          @current-change="getDataList"
          :current-page="page.pageNumber"
          :page-size="page.pageSize"
          :total="parseInt(page.total)"
          layout="total,prev, pager, next, jumper">
        </el-pagination>
      </div>

    
    </el-dialog>
    <!-- 预览弹窗 -->
    <previewModel ref="previewModelRef"/>
    <!-- 预览弹窗 -->
    <previewVideoModel ref="previewVideoModellRef"/>
    <!-- 评论点赞弹窗 -->
    <InteractDiaCom ref="InteractDiaComRef"/>
  </div>
</template>

<script>
import { utcUpdatedDate } from '@/utils'
import selectNewsCom from './selectNewsCom'
import InteractDiaCom from '../com/InteractDiaCom.vue'
import { exhibitionNewsDelete, newsExhibitionList } from '@/api/configManage'
export default {
  name: 'newsManage',
  components: { selectNewsCom, InteractDiaCom },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch : {
		'visible' : function(val){
      if(!val){
        this.$emit('update:show', false)
      }
		}
	},
  data () {
    return {
      pageModule: 1,
      visible: this.$props.show,
      infoId: null,
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        list: []
      },
      dataListLoading: false,

    }
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value == state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
  },
  mounted () {
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 初始化
    init (id) {
      this.infoId = id
      this.getDataList(1)
    },
    
    // 获取数据列表
    getDataList (pageNumber) {
      this.page.pageNumber = pageNumber ? pageNumber : 1
      this.dataListLoading = true
      let obj = {
        exhibitionId: this.infoId,//id
        pageNum: this.page.pageNumber,
        pageSize: this.page.pageSize,
      }
      this.$openLoading()
      newsExhibitionList( obj ).then( res => {
        this.$closeLoading()
        this.visible = true
        this.page.list = res.data
        this.page.total = res.totalCount;
        this.dataListLoading = false
      }).catch((error) => {
        this.$closeLoading()
        this.$message.error(error)
        this.dataListLoading = false
      })
    },
    // 预览
    goAuditOrEdit(row){
      let info = {
        id: row.id,
        module: row.isVideo ? 9 : 1,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      if(row.isVideo){
        this.$refs.previewVideoModellRef.open(info)
      }else{
        this.$refs.previewModelRef.open(info)
      }
    },
    // 删除
    deleteFun(id){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let info = {
          newsId: id,
          exhibitionId: this.infoId,
        }
        this.$openLoading()
        exhibitionNewsDelete( info ).then( res => {
          this.$closeLoading()
          this.getDataList(this.page.pageNumber)  
          this.$message.success("删除成功")
        }).catch((error) => {
          this.$closeLoading()
          this.$message("删除失败"+error)
        })
      }).catch(() => { });
    },
    //评论/点赞
    interactFun(row){
      let info = {
        id: row.id,
        module: row.isVideo ? 9 : 1,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      this.$refs.InteractDiaComRef.open(info)
    },

  }
}
</script>
<style scoped>
>>> .el-dialog__body{
  padding: 10px 20px !important;
}
</style>
<style lang="scss" scoped>
</style>
