import axiosHttps from '@/config/axios'
import axios from 'axios'
import config from '@/config'

/**
 * 公共接口  
 */
// oss请求
export const bySystem = info => {
  return axios({
    url: `${config.appBaseUrl}service-system/system/oss/sts/get-token/by-system`,
    data: info,
    method: 'post',
    headers: { 'Content-Type':'application/json'},
  })
}

// 获取全局唯一的雪花id
export const systemSnowId = info => {
  return axios({
    url: `${config.appBaseUrl}service-system/system/snow/id`,
    data: info,
    method: 'post',
    headers: { 'Content-Type':'application/json'},
  })
}

// 获取资讯评论列表
export const discussListDiscuss = info => {
  return axios({
    url: `${config.appBaseUrl}service-interactive/news/info/discuss/listDiscuss`,
    data: info,
    method: 'post',
    headers: { 'Content-Type':'application/json', 'tl': 'zh'},
  })
}
// 获取资讯二级评论列表
export const discussListDiscussTwo = info => {
  return axios({
    url: `${config.appBaseUrl}service-interactive/news/info/discuss/sec/list`,
    data: info,
    method: 'post',
    headers: { 'Content-Type':'application/json', 'tl': 'zh'},
  })
}

// 领域下拉列表（目标市场和目标业务和分类）
export const systemTerritory = info => {
  return axiosHttps.request({
    url: `/v1/system/territory`,
    params: info,
    method: 'get'
  })
}

// 领域下拉列表（分类和业务关联树状关系）
export const systemTerritoryTree = info => {
  return axiosHttps.request({
    url: `/v1/system/territory/tree`,
    params: info,
    method: 'get'
  })
}

// 获取字典表数据 传key
// 资讯来源传 NEWS_CRAWLER_CHANNEL
// 快讯来源传 QUICK_CRAWLER_CHANNEL
export const systemDict = info => {
  return axiosHttps.request({
    url: `/v1/system/dict`,
    params: info,
    method: 'get'
  })
}

// 获取字典表数据2 传key
// 获取样式传 PLATFROM_WEB_CONTNET_SHOW_STYLE
export const systemDictNative = info => {
  return axiosHttps.request({
    url: `/v1/system/dict/native`,
    data: info,
    method: 'post'
  })
}


// 语言列表
export const systemLanguage = info => {
  return axiosHttps.request({
    url: `/v1/system/language`,
    params: info,
    method: 'get'
  })
}

// 获取用户信息列表（返回简单字段/作者列表）
export const userSimples = info => {
  return axiosHttps.request({
    url: `/v1/user/simples`,
    data: info,
    method: 'post'
  })
}

// 公司列表
export const userCompany = info => {
  return axiosHttps.request({
    url: `/v1/user/company`,
    params: info,
    method: 'get'
  })
}

// 标签列表
export const systemLabel = info => {
  return axiosHttps.request({
    url: `/v1/system/label`,
    params: info,
    method: 'get'
  })
}

// 获取telegram群组列表
export const telegramGroups = info => {
  return axiosHttps.request({
    url: `/v1/system/telegram/groups`,
    params: info,
    method: 'get'
  })
}

// 获取twitter列表
export const twitterAccounts = info => {
  return axiosHttps.request({
    url: `/v1/system/twitter/accounts`,
    params: info,
    method: 'get'
  })
}