<template>
  <div>
    <!-- 公司相关资讯列表 -->
    <el-dialog
      title="相关资讯列表"
      :close-on-click-modal="false"
      :visible.sync="visible" width="80vw">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item label="标题">
          <el-input v-model="dataForm.search1" placeholder="请输入" clearable size="mini" @clear="getDataList()"></el-input>
        </el-form-item>
        <el-form-item label="创作者ID">
          <el-input v-model="dataForm.search2" placeholder="请输入" clearable size="mini" @clear="getDataList()"></el-input>
        </el-form-item>
        <el-form-item label="创作者昵称">
          <el-input v-model="dataForm.search3" placeholder="请输入" clearable size="mini" @clear="getDataList()"></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker v-model="dataForm.createTime" type="datetimerange" @change="getDataList()" size="mini"
            range-separator="-" start-placeholder="开始" end-placeholder="结束">
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getDataList()">查询</el-button>
          <!--后端接口查询时间长导致失败，先注释-->
          <!-- <el-button type="primary" plain icon="el-icon-download" size="small" @click="downLoadFile" >导出数据</el-button> -->
        </el-form-item>
      </el-form>
      <!--资讯列表   height="500" v-adaptive="{bottomOffset: 50,iModal: false}"-->
      <el-table
        :data="page.list" border v-loading="dataListLoading"
        :cell-style="tableStyle" :header-cell-style="tableStyle" 
        height="60vh">
        <el-table-column label="文章ID" prop="id" width="150"/>
        <el-table-column label="标题" width="120">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p  style=" width: 180px;">{{ scope.row.title }}</p>
              <div slot="reference" class="table_li_text">{{ scope.row.title }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="创作者昵称" prop="authorNickName"/>
        <el-table-column label="所属市场" width="180">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p style=" width: 180px;">{{ scope.row.countryStr }}</p>
              <div slot="reference" class="table_li_text">{{ scope.row.countryStr }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="所属业务" width="180">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p  style=" width: 180px;">{{ scope.row.channelStr }}</p>
              <div slot="reference" class="table_li_text">{{ scope.row.channelStr }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="所属分类" width="180">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p  style=" width: 180px;">{{ scope.row.cateStr }}</p>
              <div slot="reference" class="table_li_text">{{ scope.row.cateStr }}</div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="浏览数" prop="infoSort"/>
        <el-table-column label="评论数" prop="infoSort"/>
        <el-table-column label="点赞数" prop="infoSort"/>
        <el-table-column label="分享数" prop="infoSort"/>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{scope.row.createTime | showDate()}}
          </template>
        </el-table-column>
        <el-table-column label="发布时间">
          <template slot-scope="scope">
            {{scope.row.lastTime | showDate()}}
          </template>
        </el-table-column>
        <el-table-column label="发布状态">
          <template slot-scope="scope">
            {{scope.row.reallyTime}}
          </template>
        </el-table-column> 
        <el-table-column fixed="right" width="80" label="操作">
          <template slot-scope="scope">
            <el-button type="success" plain size="small" @click="goAuditOrEdit(scope.row.id)">浏览</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination 
          background
          @current-change="getDataList"
          :current-page="page.pageNumber"
          :page-size="page.pageSize"
          :total="parseInt(page.total)"
          layout="total,prev, pager, next, jumper">
        </el-pagination>
      </div>

    
    </el-dialog>
    <!-- 预览弹窗 -->
    <previewModel ref="previewModelRef"/>
  </div>
</template>

<script>
import { userDetails } from '@/api/userManage'
import { utcUpdatedDate } from '@/utils'
import {  auditList, auditDetails } from '@/api/newsManage'
export default {
  name: 'companyNews',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch : {
		'visible' : function(val){
      if(!val){
        this.$emit('update:show', false)
      }
		}
	},
  data () {
    return {
      visible: this.$props.show,
      infoId: null,
      module: 1,
      dataForm: {
        search1: null, // 标题
        search2: "",//作者ID
        search3: "",//作者昵称
        createTime: [],// 发布时间
      },
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        list: []
      },
      dataListLoading: false,

    }
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value == state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
  },
  mounted () {
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 初始化
    init (id) {
      this.infoId = id
      this.visible = true
      console.log("打开")
      this.getDataList(1)
    },
    
    // 获取数据列表
    getDataList (pageNumber) {
      this.page.pageNumber = pageNumber ? pageNumber : 1
      this.dataListLoading = true
      let obj = {
        companyId: this.infoId,
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        module: this.module,// 1：咨询，2：快讯, 3 社区 
      }
      if(this.dataForm.search1) obj.title = this.dataForm.search1
      if(this.dataForm.search2) obj.authorId = this.dataForm.search2
      if(this.dataForm.search3) obj.authorNickName = this.dataForm.search3
      //发布时间
      if(this.dataForm.createTime && this.dataForm.createTime.length > 0){
        obj.startCreateDate = formatDateTime(this.dataForm.createTime[0])
        obj.endCreateDate =formatDateTime(this.dataForm.createTime[1])
      }
      auditList( obj ).then( res => {
        this.page.list = res.data
        if(this.page.list.length > 0){
          this.page.list .forEach( item => {
            item.countryStr = ''  // 市场
            item.channelStr = '' // 业务
            item.cateStr = ''  // 分类
            if(item.countryList.length > 0){
              item.countryList.forEach( (country, index) => {
                item.countryStr += item.countryList.length != index+1 ? country.name+' / ' : country.name
              })
            }
            if(item.channelList.length > 0){
              item.channelList.forEach( (channel, index) => {
                item.channelStr += item.channelList.length != index+1 ? channel.name+' / ' : channel.name
              })
            }
            if(item.cateList.length > 0){
              item.cateList.forEach( (channel, index) => {
                item.cateStr += item.cateList.length != index+1 ? channel.name+' / ' : channel.name
              })
            }
          })
        }
        this.page.total = res.totalCount;
        this.dataListLoading = false
      }).catch((error) => {
        this.$message.error(error)
        this.dataListLoading = false
      })
    },
    goAuditOrEdit(id){
      // let query = {
      //   id: id ? id : '',
      //   module: this.module,
      // }
      // this.$router.push({
      //   path: '/auditOrEdit',
      //   query: query
      // })
      let info = {
        id: id,
        module: 1,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      this.$refs.previewModelRef.open(info)
    },

  }
}
</script>
<style lang="scss" scoped>
.form_div_bac{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.mes_div{
  width: 300px;
  min-height: 32px;
  line-height: 32px;
  border: 1px solid #DCDFE6;
  background-color: #FFF;
  padding: 0 15px;
  border-radius: 4px;
  color: #84909D;
}
.mes_tags_div{
  min-height: 32px;
  line-height: 32px;
  padding: 0 15px;
  background-color: #FFF;
  border: 1px solid #DCDFE6;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.fen_ge_xian{
  width: 100%;
  height: 2px;
  background: #9A9FA6;
  border-radius: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
}
h2{
  margin-bottom: 34px;
  color: #000000;
}
.el-form-item {
  margin-bottom: 10px !important;
}
</style>
