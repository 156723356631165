<!-- 评论/点赞弹窗 -->
<template>
  <el-dialog title="评论/点赞"
    width='70vw'
    :close-on-click-modal="false"
    :visible.sync="show">
    <div class="page_bac_div">
      <!-- 左边表单区 -->
      <div class="form_div_bac" :style="{paddingRight: leftWidth +'px'}">
        <h4 style="margin-bottom: 20px;">操作评论</h4>
        <el-form :model="formData1" label-width="100px" class="demo-ruleForm">
          <el-form-item label="评论内容" prop="content">
              <!-- maxlength="100"
              show-word-limit -->
            <el-input type="textarea" 
              v-model="formData1.inputValue1"
              rows='4'>
            </el-input>
          </el-form-item>
          <el-form-item label="选择作者">
            <div style="display: flex;align-items: center;">
              <selectUser 
                v-if="show"
                inputId="2"
                :id.sync="formData1.authorId" />
              <el-button type="primary" @click="submitPingFun" 
                :disabled="!formData1.authorId || !formData1.inputValue1" v-preventReClick>发表评论</el-button>
            </div>
          </el-form-item>
          <el-form-item label="发布时间">
            <div style="display: flex;align-items: center;">
              <el-radio-group v-model="formData1.radio">
                <el-radio :label="1">立即发布</el-radio>
                <el-radio :label="2">定时发布</el-radio>
              </el-radio-group>
              <el-date-picker
              style="margin-left: 16px;"
                :disabled="formData1.radio==1" 
                v-model="formData1.createTime"
                type="datetime"
                placeholder="选择定时发布时间"
                default-time="12:00:00"
                @change="timeChange"
                :picker-options="pickerOptions()">
              </el-date-picker>
            </div>
            <div class="mes_error_text" v-if="formData1.radio==2 && !formData1.createTime">请选择定时发布时间</div>
          </el-form-item>
        </el-form>
        <div class="fen_ge_xian"></div>
        <h4 style="margin-bottom: 20px;">操作点赞</h4>
        <el-form label-width="100px" class="demo-ruleForm">
          <el-form-item label="选择作者">
            <div style="display: flex;align-items: flex-start;"> 
                <selectUserMultipleTwo position="top"
                :ids.sync="authorIds" inputId="1" v-if="show" style="flex: 1;"/> 
              <el-button type="primary" @click="submitDianFun" :disabled="authorIds.length==0" 
                style="width: 80px;"  v-preventReClick>点赞</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- 手机模型 -->
      <div class="phone_righ_bac" v-if="show">
        <!-- :date.sync="newsInfo.sourceType==3 && newsInfo.module==1 ? newsInfo.collectTime : newsInfo.createTime"  -->
        <articleVideoModel 
          v-if="currentModule==9"
          ref="articleVideoModelRef"
          class="model_com"
          :height="modelHeight"
          :videoUrl.sync="newsInfo.videoUrl" 
          :html.sync="newsInfo.content" 
          :title.sync="newsInfo.title" 
          :tags.sync="newsInfo.labelList"
          :currentModule.sync="currentModule"
          :nickname.sync="newsInfo.nickName"
          :headPic.sync="newsInfo.headPic"
          :date.sync="newsInfo.date" 
          :newsId="InfoId"/>
        <articleModel 
          v-else
          ref="articleModelRef"
          class="model_com"
          :height="modelHeight"
          :title.sync="newsInfo.title" 
          :nickname.sync="newsInfo.nickName"
          :html.sync="newsInfo.content"
          :currentModule.sync="currentModule"
          :date.sync="newsInfo.date" 
          :urlList.sync="urlList"
          :headPic="newsInfo.headPic"
          :tags="newsInfo.labelList"
          :company="company"
          :newsId="InfoId"/>
      </div>
    </div>
    <!-- <span slot="footer" class="dialog-footer" style="padding: 0px;">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" @click="submitForm('newsInfo')">提交</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import { auditDetails, recruitDetails, auditBehavior } from '@/api/newsManage'
import { utcUpdatedDate, dateUpdateUtc  } from '@/utils'
export default {
  name: 'InteractDiaCom',
  data () {
    return {
      show: false,
      InfoId: '',
      currentModule: 1,
      modelHeight: 667,
      leftWidth: 400,
      newsInfo: {// 文章详情
        countryList: [],// 市场
        channelList: [],// 业务
        cateList: [], //分类
        channelCateList: [],// 业务分类
        labelList: [],// 标签
      }, 
      company: {
        icon: "",
        id: "",
        name: "",
        title: ""
      },
      urlList: [],
      // 评论参数
      formData1: {
        inputValue1: '',
        authorId: '',
        radio: 1,
        createTime: '',
      },
      // 点赞参数
      authorIds: [],
      dateTime: '2024-07-31 18:00:00',
      
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.setModelHeight);
  },

  methods: {
    pickerOptions(){
      if(!this.newsInfo.date) {
        return {}
      }
      let _that = this
      return {
        disabledDate(time) {
          const date = new Date(utcUpdatedDate(_that.newsInfo.date));
          const oneday = date.getTime();
          return time.getTime() < oneday - 86400000;
        },
        selectableRange: (() => {
          let data = new Date(utcUpdatedDate(_that.newsInfo.date));
          let date1 = new Date(_that.formData1.createTime)
          if(date1.getFullYear() === data.getFullYear() &&
            date1.getMonth() === data.getMonth() &&
            date1.getDate() === data.getDate()){
            let hour = data.getHours();
            let minute = data.getMinutes();
            let second = data.getSeconds();
            return [`${hour}:${minute}:${second} - 23:59:59`]
          }else{
            return [`00:00:00 - 23:59:59`]
          }
        })(),
      }
    },
    timeChange(){
      const date = new Date(utcUpdatedDate(this.newsInfo.date));
      if(new Date(this.formData1.createTime).getTime() < date.getTime() ){
        this.formData1.createTime = ''
        this.$message("不能选择小于文章时间")
      }
    },
    // 打开弹窗
    async open( info ){
      if(!info.id || !info.module) {
        this.$message("获取参数失败")
        console.log("请求文章参数===",info)
        return
      }
      
      this.formData1 = {
        inputValue1: '',
        authorId: '',
        radio: 1,
        createTime: '',
      }
      // 点赞参数
      this.authorIds = []
      this.InfoId = info.id
      this.currentModule = info.module
      this.$openLoading()
      let res = {}
      if(this.currentModule == 7){ // 招聘
        res = await recruitDetails( info ).catch((error) => {
          this.$closeLoading()
          this.$message("获取详情失败", error)
        })
        let date = ''
        if(res.data.state == 1001){
          date = res.data.lastTime
        }else{
          if(res.data.preTime){
            date = res.data.preTime
          }else{
            date = res.data.createTime
          }
        }
        this.newsInfo = {
          title: res.data.title,
          nickName: res.data.nickName,
          date: date,
          content: res.data.content,
          headPic: res.data.headPic,
        }
        this.company = {
          icon: res.data.companyIcon,
          id: res.data.companyId,
          name: res.data.companyName,
          title: res.data.companyTitle
        }
      }else{// 资讯
        res = await auditDetails( info ).catch((error) => {
          this.$closeLoading()
          this.$message("获取详情失败", error)
        })
        let date = ''
        if(res.data.state == 1001){
          date = res.data.lastTime
        }else{
          if(res.data.preTime){
            date = res.data.preTime
          }else{
            date = res.data.createTime
          }
        }
        if(res.data.module == 9){
          this.newsInfo = {
            title: res.data.title || '',
            nickName: res.data.nickName,
            date: date,
            content: res.data.content,
            labelList: res.data.labelList,
            headPic: res.data.headPic,
            videoUrl: res.data.videoInfo.videoUrl,
            subtitleZh: res.data.videoInfo.subtitleZh,
            subtitleEn: res.data.videoInfo.subtitleEn
          }
        }else{
          // date: res.data.sourceType == 3 ? res.data.collectTime : res.data.createTime,
          this.newsInfo = {
            title: res.data.title || '',
            nickName: res.data.nickName,
            date: date,
            content: res.data.content,
            labelList: res.data.labelList,
            headPic: res.data.headPic,
          }
          if(res.data.cover) { 
            this.urlList = res.data.cover.split(',')
          }else{
            this.urlList = []
          }
        }
      }
      this.$closeLoading()
      this.show = true
      this.$nextTick( () => {
        this.setModelHeight()
      })
      window.addEventListener('resize', this.setModelHeight);
    },
    // 设置模型高度
    setModelHeight(){
      const diaBody = document.querySelectorAll(".el-dialog__body")[0];
      this.modelHeight = diaBody.offsetHeight || 667
      this.modelHeight -= 40
      this.$nextTick( () => {
        const righBac = document.querySelectorAll(".model_com")[0];
        this.leftWidth = righBac.offsetWidth || 400
        this.leftWidth += 40
      })
    },
    // 发表评论
    submitPingFun(){
      // if(!this.formData1.inputValue1) return 
      // if(!this.formData1.authorId) return 
      if(this.formData1.radio == 2 && !this.formData1.createTime) return
      this.$confirm('请确认最终提交的信息，避免误操作！', '提示', {
        confirmButtonText: '确定无误',
        cancelButtonText: '继续填写',
        type: 'warning'
      }).then(() => {
        // module	所属模块（1：咨询，2：快讯，3：社区, 7招聘）
        let info = {
          type: 1,
          id: this.InfoId,
          review: this.formData1.inputValue1,
          userIds: [this.formData1.authorId],
          module: parseInt(this.currentModule),
        }
        if(this.formData1.radio == 2){
          info.createTime =  dateUpdateUtc(new Date(this.formData1.createTime))
        }
        this.$openLoading()
        auditBehavior( info ).then( res => {
          this.$closeLoading()
          this.formData1 = {
            inputValue1: '',
            authorId: '',
            radio: 1,
            createTime: '',
          }
          if(this.currentModule == 9){
            this.$refs.articleVideoModelRef.comRemoteFun()
          }else{
            this.$refs.articleModelRef.comRemoteFun()
          }
          this.$message.success("发表成功")
        }).catch((error) => {
          this.$closeLoading()
          this.$message("发表失败"+ error)
        })
      }).catch(() => { });
    },
    // 发表点赞
    submitDianFun(){
      this.$confirm('请确认最终提交的信息，避免误操作！', '提示', {
        confirmButtonText: '确定无误',
        cancelButtonText: '继续填写',
        type: 'warning'
      }).then(() => {
        let info = {
          type: 2,
          id: this.InfoId,
          userIds: this.authorIds,
          module: parseInt(this.currentModule)
        }
        this.$openLoading()
        auditBehavior( info ).then( res => {
          this.$closeLoading()
          this.$message.success("点赞成功")
          this.authorIds = []
        }).catch((error) => {
          this.$closeLoading()
          this.$message("点赞失败"+ error)
        })
      }).catch(() => { });
    }
    
    
  }
}
</script>
<style lang="scss" scoped>

.page_bac_div{
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  background: #FFF;
}
.form_div_bac{
  flex: 1;
  padding: 20px;
  // padding-right: 400px;
}
.phone_righ_bac{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}
.mes_error_text{
  font-size: 11px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 9px);
  left: 0;
}
</style>
