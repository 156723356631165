//自定义指令
import Vue from 'vue'
 // 按钮防抖重复点击方法指令（按钮使用 v-preventReClick 就行了）
const preventReClick = Vue.directive('preventReClick', {
    inserted: function (el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true
                setTimeout(() => {
                    el.disabled = false
                }, binding.value || 1500)
            }
        })
    }
});
//自定义指令（通用防抖 v-throttle 就行了，也可以自定义时间v-throttle="1500"）
const throttle = Vue.directive('throttle', {
    bind: (el, binding) => {
        el.style.cursor = 'pointer'
        let throttleTime = binding.value; // 防抖时间
        if (!throttleTime) { // 用户若不设置防抖时间，则默认2s
          throttleTime = 1500;
        }
        let cbFun;
        el.addEventListener('click', event => {
          if (!cbFun) { // 第一次执行
            el.style.cursor = 'no-drop'
            cbFun = setTimeout(() => {
                el.style.cursor = 'pointer'
              cbFun = null;
            }, throttleTime);
          } else {
            event && event.stopImmediatePropagation();
          }
        }, true);
    },
});
 
export { preventReClick, throttle }