var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag_list_bac" },
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "300px" },
          attrs: {
            placeholder: "请选择",
            filterable: "",
            multiple: "",
            "collapse-tags": "",
            clearable: ""
          },
          model: {
            value: _vm.countryids,
            callback: function($$v) {
              _vm.countryids = $$v
            },
            expression: "countryids"
          }
        },
        _vm._l(_vm.$props.listDisct, function(item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.name, value: item.id }
          })
        }),
        1
      ),
      _vm._l(_vm.countryList, function(item, index) {
        return _c("div", { key: item.index, staticClass: "select_tag_div" }, [
          _vm._v(" " + _vm._s(item.name) + " "),
          _c("i", {
            staticClass: "el-icon-error delete_tag_icon",
            on: {
              click: function($event) {
                return _vm.deleteTagFun(item)
              }
            }
          })
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }