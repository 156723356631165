<template>
  <div class="container">
    <div style="margin-bottom: 14px;">
      <el-radio-group v-model="tuiType" @change="getTabList()" size="mini">
        <el-radio-button :label="item.value" v-for="item in tuiTypeDisct" :key="item.value">{{ item.name }}</el-radio-button>
      </el-radio-group>
    </div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" style="padding: 10px 0;">
      <el-form-item label="ID">
        <el-input v-model="dataForm.search1" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="dataForm.search2" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="推荐位置">
        <el-select v-model="dataForm.select1" size="mini" placeholder="请选择" clearable 
          style="width: 120px;" @change="getDataList()">
          <el-option v-for="item in homeTabList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推荐时间">
        <el-date-picker v-model="dataForm.dateTime" type="datetimerange" 
          style="width: 310px;" @change="getDataList()"  size="mini"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="openAdd()">新增主推文章</el-button>
        <!--后端接口查询时间长导致失败，先注释-->
        <!-- <el-button type="primary" plain icon="el-icon-download" size="small" @click="downLoadFile" >导出数据</el-button> -->
      </el-form-item>
    </el-form>

    <!--用户列表   height="500" v-adaptive="{bottomOffset: 50,iModal: false}"-->
    <el-table
      :data="dataList" border v-loading="dataListLoading"
      height="100" v-adaptive="{bottomOffset: 50,iModal: false}"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;">
      <el-table-column prop="dateTime" label="开始时间">
        <template slot-scope="scope">
          <template v-if="scope.row.startTime || scope.row.endTime">
            {{scope.row.startTime | showDate()}}
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="dateTime" label="结束时间">
        <template slot-scope="scope">
          <template v-if="scope.row.startTime || scope.row.endTime">
            {{scope.row.endTime | showDate()}}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="推荐位置">
        <template slot-scope="scope">
          <template v-if="scope.row.tab">
            {{ scope.row.tab.name }}
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="newsId" label="文章ID"></el-table-column>
      <el-table-column label="文章标题"> 
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 300px;" class="content_p_class_clamp">{{ scope.row.newsTitle }}</p>
            <div slot="reference"  style="display: flex;align-items: center;justify-content: center;">
              <div class="content_div_class_clamp">{{ scope.row.newsTitle }}</div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="权重"></el-table-column>
      <el-table-column fixed="right" width="240" label="操作">
        <template slot-scope="scope">
          <div style="line-height:40px;justify-content: space-between;">
              <el-button type="success" plain size="small" @click="goAuditOrEdit(scope.row.newsId)">浏览</el-button>
              <el-button type="primary" size="mini" plain @click="interactFun(scope.row.newsId)">评论</el-button>
            <el-button type="warning" plain size="mini" @click="deleteFun(scope.row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- @size-change="sizeChangeHandle" layout="total, sizes, prev, pager, next, jumper"> -->
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 新增 -->
    <el-dialog
      title="新增"
      :close-on-click-modal="false"
      :visible.sync="newsVisible" width="700px">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm" style="min-height: 300px;">
        <el-form-item label="推荐位置" prop="select1">
          <el-select v-model="ruleForm.select1" size="mini" placeholder="请选择" clearable>
            <el-option v-for="item in homeTabList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择文章" prop="newsId">
          <selectNewsCom @refreshDataList="getDataList(page.pageNumber)"  inputId="2"
          v-if="newsVisible" :newsId.sync="ruleForm.newsId"/>
        </el-form-item>
        <el-form-item label="权重" prop="sort">
          <el-input v-model="ruleForm.sort" style="width: 215px;" maxlength="10" v-limitInput:positiveInteger/>
        </el-form-item>
        <el-form-item label="推荐时间" prop="preTime">
          <el-date-picker v-model="ruleForm.preTime" type="datetimerange"  size="mini"
            range-separator="-" start-placeholder="开始" end-placeholder="结束">
          </el-date-picker>
        </el-form-item>
      </el-form> 
      <span slot="footer" class="dialog-footer">
        <el-button @click="newsVisible=false">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>提交</el-button>
      </span>
    </el-dialog>
    <!-- 预览弹窗 -->
    <previewModel ref="previewModelRef"/>
    <!-- 评论点赞弹窗 -->
    <InteractDiaCom ref="InteractDiaComRef"/>
    
    
  </div>
</template>

<script>
import { formatDateTime,utcUpdatedDate, dateUpdateUtc } from '@/utils'
import { mainRecommendList, mainRecommendAdd, mainRecommendDelete } from '@/api/configManage'
import { homeTabsList } from '@/api/homeTab'
import InteractDiaCom from '../com/InteractDiaCom.vue'
import selectNewsCom from './selectNewsCom'
export default {
  name: 'popularizeNews',
  data () {
    return {
      pageModule: 1,
      tuiType: 1,
      tuiTypeDisct: [   // 广告类型字典
        {value: 1, name: 'web'},
        {value: 2, name: 'Android'},
        {value: 3, name: 'Ios'},
      ],
      homeTabList: [], // 表单tab
      webTabList: [], // webTab
      androidTabList: [], // androidTab
      iosTabList: [], // iosTab


      dataForm: {
        search1: '', //
        search2: '', //
        search3: '', //
        select1: '',
        dateTime: [],
        groupByDate: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      visibleTui: false,
      recommendList: [],
      infoId: 1,

      newsVisible: false,
      ruleForm: {
        select1: '',
        newsId: '',
        sort: 1,
        preTime: [],
      },
      rules: {
        select1: [
          { required: true, message: '请选择推荐位置', trigger: 'change' },
        ],
        newsId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        sort: [
          { required: true, message: '请输入权重', trigger: 'blur' },
        ],
        preTime: [
          { type: 'array', required: true, message: '请选择时间', trigger: 'blur' }
        ],
      },
    }
  },
  components: {
    selectNewsCom,InteractDiaCom
  },
  created () {
    
  },
  mounted () {
    this.getTabList()
    // this.getDataList()
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
    showCollege (value) {
      if (value && value.length>0) {
        let valueStr = ""
          for (const key in value) {
            valueStr = valueStr + value[key] + " ";
          }
        return valueStr
      }
    },
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 条件禁用列
    checkSelectable(row){
      if (row.qualState == 0) {
        return true
      } else {
        return false
      }
    },
    // 获取数据列表
    getDataList (pageNum) {
      this.pageIndex = pageNum ? pageNum : 1
      this.dataListLoading = true
      let obj = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
        type: this.tuiType
      }
      if(this.dataForm.search1) obj.newsId = this.dataForm.search1.trim()
      if(this.dataForm.search2) obj.newsTitle = this.dataForm.search2.trim()
      if(this.dataForm.select1) obj.indexTabIds = [this.dataForm.select1]
      //时间 
      console.log("区间",(this.dataForm.groupByDate + ' 00:00:00').replace(/\-/g, '/'))
      if(this.dataForm.dateTime && this.dataForm.dateTime.length > 0){
        obj.startTime = this.dataForm.dateTime.length != 0 ? dateUpdateUtc(this.dataForm.dateTime[0]) : null
        obj.endTime = this.dataForm.dateTime.length != 0 ? dateUpdateUtc(this.dataForm.dateTime[1]) : null
      }
      // 日期
      if(this.dataForm.groupByDate) obj.groupByDate = dateUpdateUtc(this.dataForm.groupByDate)
      mainRecommendList(obj).then(( data ) => {
        this.dataList = data.data
        this.totalPage = data.totalCount
        this.dataListLoading = false
      }).catch((error) => {
        this.dataListLoading = false
        this.$message.error(error);
      })
    },

    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 获取
    getTabList(){
      this.homeTabList = []
      if(this.tuiType == 1){//web
        this.homeTabList = this.webTabList
      }else if(this.tuiType == 2){//android
        this.homeTabList = this.androidTabList
      }else if(this.tuiType == 3){//ios
        this.homeTabList = this.iosTabList
      }
      this.getDataList()
      if(this.homeTabList.length == 0){
        let obj = {
          pageNum: this.pageIndex,
          pageSize: this.pageSize,
          type: this.tuiType
        }
        homeTabsList( obj ).then( res => {
          this.$closeLoading()
          this.homeTabList = res.data
          if(this.tuiType == 1){//web
            this.webTabList = res.data
          }else if(this.tuiType == 2){//android
            this.androidTabList = res.data
          }else if(this.tuiType == 3){//ios
            this.iosTabList = res.data
          }
        }).catch((error) => {
          this.$closeLoading()
          this.$message(error)
        })
      }
    },

    // 打开新增
    openAdd(){
      let obj = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
        type: this.tuiType
      }
      this.$openLoading()
      homeTabsList( obj ).then( res => {
        this.$closeLoading()
        this.newsVisible = true 
        this.homeTabList = res.data
        this.ruleForm = {
          select1: '',
          newsId: '',
          sort: 1,
          preTime: [],
        }
      }).catch((error) => {
        this.$closeLoading()
        this.$message(error)
      })
    },
    // 新增
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            indexTabId: this.ruleForm.select1,
            newsId: this.ruleForm.newsId,
            sort: this.ruleForm.sort,
            startTime: dateUpdateUtc(this.ruleForm.preTime[0]),
            endTime: dateUpdateUtc(this.ruleForm.preTime[1]),
          }
          console.log("参数",data)
          mainRecommendAdd( data ).then( res => {
            this.getDataList(this.pageIndex)  
            this.newsVisible = false
            this.$message.success("新增成功")
          }).catch((error) => {
            this.$message.error("新增失败"+ error)
          })
        } else {
          return false;
        }
      });
    },


    // 预览
    goAuditOrEdit(id){
      let info = {
        id: id,
        module: this.pageModule,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      this.$refs.previewModelRef.open(info)
    },
    // 删除
    deleteFun(id){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        mainRecommendDelete( { id: id } ).then( res => {
          this.getDataList(this.pageIndex)  
          this.$message.success("删除成功")
        }).catch((error) => {
          this.$message.error("删除失败"+error)
        })
      }).catch(() => { });
    },
    //评论/点赞
    interactFun(id){
      let info = {
        id: id,
        module: this.pageModule,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      this.$refs.InteractDiaComRef.open(info)
    },

  }
}
</script>
<style scoped>
.tui_col_div{
  width: 100%;
  text-align: center;
  margin-bottom: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.id_name_div{
  text-align: left;
  line-height: 10px;
  width: 180px; /* 设置div的宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>