import Vue from 'vue';
import Router from 'vue-router';
import routes from './routers';

import store from '@/store'
import {Loading} from 'element-ui';
import {canTurnTo, getToken, setTitle} from '@/config/util'

Vue.use(Router)

const router = new Router({
    routes,
    mode: 'history',
})

const turnTo = (to, access, next) => {
    if (canTurnTo(to.name, access, routes)) next() // 有权限，可访问
    else next({replace: true, name: 'error_401'}) // 无权限，重定向到401页面
}
//设置内容区loading
let loadingInstance = null
//路由导航守卫前
router.beforeEach((to, from, next) => {
    loadingInstance = Loading.service(
        {
            target: document.querySelector('.home_router_view'),
            text: '努力加载中...',
            background: 'rgba(0, 0, 0, 0.1)',
        }
    )
    const token = getToken()
    if (!token && to.path !== "/login") {
        // 未登录且要跳转的页面不是登录页
        next("/login")
    } else if (!token && to.path === "/login") {
        // 未登陆且要跳转的页面是登录页
        next() // 跳转
    } else if (token && to.path === "/login") {
        // 已登录且要跳转的页面是登录页
        next(from.path)
    } else {
        next()
    }
})
//路由导航守卫后
router.afterEach(to => {
    //保存最后的路由导航
    store.dispatch('updateLastRoute', {fullPath: to.fullPath, menuList: store.getters.menuList})

    setTitle(to, router.app)
    loadingInstance.close()
    window.scrollTo(0, 0)
})

export default router