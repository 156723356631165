<template>
  <!-- 评论区 -->
   <div>
    <div class="comment_con_bac" v-for="(item, index) in commentCountList">
      <div class="comment_user_bac">
        <div class="comment_user_left">
          <div v-if="item.disAccount.userInfo.headPic">
            <img :src="$pathPrefix+item.disAccount.userInfo.headPic" 
              :class=" tierIndex >= 2? 'comment_user_avatar_two' : 'comment_user_avatar'"/>
          </div>
          <div v-else><img :src="$pathPrefix+$systemAvatar"
            :class=" tierIndex >= 2? 'comment_user_avatar_two' : 'comment_user_avatar'"/></div>
          <div class="comment_user_nickname">{{ item.disAccount.userInfo.nickname }}</div>
          <img class="author_tag_div" src="@/assets/img/author_tag_img.png" v-if="item.disAccount.authorState==1"/>
        </div>
        <!-- <img src="@/assets/img/diandiandian_icon.png" class="comment_user_right"/> -->
        <div class="comment_bot_right">
          <img src="@/assets/img/xinxin_no_icon.png" class="comment_xinno_icon" />
          {{ item.likeNum }}
        </div>
      </div>
      <div class="comment_con_div">
        <div class="comment_con_text">
          {{ item.content }}
        </div>
        <div class="comment_bot_bac">
          <div>
            {{  item.createTime | commShowDate() }} 
            <span style="font-size: 10px;"> · </span> 
            {{ item.ipCountryName }} 
          </div>
        </div>
        <commentListCom v-if="item.secList" :commentList="item.secList" :tierIndex="tierIndex+1"/>
        <!-- v-if="(item.secList && rowsCount == 0) || (item.secList < rowsCount && rowsCount != 0)" -->
        <div class="geng_duo_div" v-if="item.secCount > 0">
          <div class="geng_duo_left">
            <div  class="geng_left_border"></div>
          </div>
          <div class="geng_duo_right">
            <template v-if="getCommLoading">加载中...</template>

            <template v-else>
              <div @click="getCOmmFun(item.id, pageIndex+1, index)" 
                v-if="(item.secList && rowsCount == 0) || (item.secList < rowsCount && rowsCount != 0)">
                共{{ item.secCount }}条回复
              </div>
              <template v-else>
                <div @click="packUpFun(index)" v-if="commentArr.length == 0 && item.secList.lenngth > 3 ">收起</div>
                <div @click="unfoldFun(index)" v-else>展开更多回复</div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { discussListDiscussTwo } from '@/api/utilsManage'
import { getCurrentZoneTimeStr } from '@/utils'
import htmlHandler from '@/mixin/htmlHandler';
export default {
  name: 'commentListCom',
  mixins: [htmlHandler],
  props: {
    tierIndex: {
      type: Number,
      default: 1,
    },
    commentList:{
      type: Array,
      default (){
        return []
      }
    },
  },
  filters:{
    commShowDate (value) {
      if (value) {
        // 将时间戳转换成date对象1
        return getCurrentZoneTimeStr(value)
      }
    },
  },
  data () {
    return {
      commentCountList: this.$props.commentList,// 所有评论
      commentArr: [],//收起的评论
      getCommLoading: false,
      pageIndex: -1,
      rowsCount: 0,
    }
  },
  watch : {
		'commentList.length' : function(val){
      this.commentCountList = this.$props.commentList
		},
	},
  mounted () {
  },
  beforeDestroy() {
  },
  methods: {
    packUpFun(index){
      this.commentArr = this.commentCountList[index].secList.slice(3)
      this.commentCountList[index].secList = this.commentCountList[index].secList.slice(0, 3); // 前10条
    },
    unfoldFun(index){
      this.commentCountList[index].secList = this.commentCountList[index].secList.concat(this.commentArr.slice(0, 10)); // 展开10条
      this.commentArr.splice(0, 10)
    },
    // 获取更多子评论
    getCOmmFun(id, pageIndex, index){
      this.pageIndex = pageIndex
      let data = {
        firstDisId: id,
        pagerInfo:{
          pageIndex: pageIndex,
          pageSize: 10
        }
      }
      this.getCommLoading = true
      discussListDiscussTwo(data).then(( res ) => {
        this.getCommLoading = false
        this.commentCountList[index].secList = this.commentCountList[index].secList.concat(res.data.data.list)
        this.rowsCount = res.data.data.page.rowsCount
      }).catch((error) => {
        this.getCommLoading = false
      })
    }
  }
}
</script>
<style lang='scss' scoped>

.comment_con_bac{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.comment_user_bac{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}
.comment_user_left{
  display: flex;
  align-items: center; 
}
.comment_avatar_div{
  width: 32px;
}
.comment_user_avatar{
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 32px;
  margin-right: 10px;
}
.comment_user_avatar_two{
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 22px;
  margin-right: 10px;
}
.comment_user_nickname{
  color: #7D7D7D;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.author_tag_div{
  height: 15px;
  border-radius: 2px;
  margin-left: 4px;
}
.comment_user_right{
  width: 16px;
  height: 16px;
  display: block;
}
.comment_con_div{
  margin-left: 32px;
  width: calc(100% - 32px)
}
.comment_con_text{
  color:  #1D2129;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 4px 0px 8px 0px;
}
.comment_bot_bac{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #86909C;
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.comment_bot_right{
  text-align: center;
  // display: flex;
  // align-items: center;
  color: #86909C;
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.comment_bot_hui{
  padding: 4px 10px;
  margin-right: 12px;
  border-radius: 100px;
  background: #F5F5F5;
  color: #1D2129;
  font-family: "PingFang SC";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}
.comment_xinno_icon{
  width: 16px;
  height: 16px;
  display: block;
  margin-bottom: 4px;
}

.geng_duo_div{
  display:flex;
  align-items: center;
  margin-top: 10px;
  .geng_duo_left{
    width: 32px;
    .geng_left_border{
      background: #E5E6EB;
      height: 0.5px;
      width: 22px;
    }
  }
  .geng_duo_right{
    color: #031C5E;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    display: flex;
    align-items: center;
    gap: 12px;
    div{
      cursor: pointer;
    }
  }
}
</style>