var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "video",
    {
      ref: "video",
      attrs: {
        width: _vm.width,
        height: _vm.height,
        poster: _vm.poster,
        controls: ""
      }
    },
    [
      _c("source", { attrs: { src: _vm.src, type: "video/ogg" } }),
      _c("source", { attrs: { src: _vm.src, type: "video/mp4" } }),
      _c("source", { attrs: { src: _vm.src, type: "video/webm" } }),
      _c(
        "object",
        { attrs: { data: _vm.src, width: _vm.width, height: _vm.height } },
        [
          _c("embed", {
            attrs: { src: _vm.src, width: _vm.width, height: _vm.height }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }