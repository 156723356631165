import { Message } from 'element-ui';
import * as XLSX from 'xlsx'
// import * as XLSX_STYLE from 'xlsx-style'

//导出表格
export function  exportXlsx(headers, data, name=new Date().getTime()) {
  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data])
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, name+'.xlsx')
  Message({
    message: '导出成功',
    type: 'success',
  })
}