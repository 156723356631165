<template>
  <el-dialog title="导入" :visible.sync="visable"  width="60%">
    <div class="dao_ru_bac">
      <el-transfer
        style="text-align: left; display: inline-block"
        filterable
        v-model="selectValue"
        :titles="['用户列表', '添加用户']"
        :button-texts="['移除', '添加 ']"
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}'
        }"
        :data="list">
        <span slot-scope="{ option }" style="width:300px">{{ option.key }} - {{ option.label }}</span>
      </el-transfer>
    </div>
    <span slot="footer" class="dialog-footer">
      <div style="display: flex;align-items:center">
        <el-button size="mini" type="success" @click="exportXlsxFun" plain>下载模板</el-button>
        <input type="file" accept=".xlsx" @change="handleFileUpload" 
          style="width: 100%;height: 100%;margin-left: 20px"/>
      </div>
      <div>
        <el-button @click="visable = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm()" size="small" :disabled="selectValue.length == 0"
          v-preventReClick>确 定</el-button>
      </div>
    </span>
  </el-dialog>
</template>
<script>
import * as XLSX from 'xlsx'
import { exportXlsx } from '@/utils/xlsx'
import { getMemberSimpleList } from '@/api/userManage'
export default {
  name: 'importXlsx',
  props: {
    show:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      visable: this.show,
      list: [],
      selectValue: [],
      listLoading: false,
    };
  },
  watch : {
		visable : function(val){
			this.$emit('update:show', val)
		},
    show : function(val){
			this.visable = this.show
		},
	},
  methods: {
    //列表内容居中
    tableStyle(){
      return "text-align:center"
    },
    //导入
    handleFileUpload(event) {
      const file = event.target.files[0];
      if(!file) return
      this.listLoading = true
      const reader = new FileReader();
      let that = this
      let ids = []
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        console.log('导入的文件',jsonData);
        jsonData.forEach( (arr, indexNum) => {
          if(indexNum>0){
            if(arr[0]){
              ids.push(arr[0])
            }
          }
        })
        console.log('导入ids',ids);
        this.reutrnList(ids)
        this.listLoading = false
        // that.getUserList(ids)
      };
      reader.readAsArrayBuffer(file);
    },
    //组装数据
    reutrnList(ids){
      this.list = []
      this.selectValue = ids
      if(ids.length > 0){
        ids.forEach( id => {
          this.list.push({
            key: id,
            label: id,
            disabled: false
          })
        })
      }
    },
    //根据id获取批量用户
    getUserList(ids){
      this.listLoading = true
      getMemberSimpleList( ids ).then( ({data}) => {
          this.list = data 
          this.listLoading = false
        }).catch( (err) => {
          this.list = []
          this.listLoading = false
        })
    },
    //模板导出
    exportXlsxFun(){
      exportXlsx(["用户ID"], [""], "导入用户模板")
    },
    //确定
    confirm(){
      this.list = []
      this.selectValue = []
      this.$emit('returnList', this.selectValue)
      this.visable = false
    }
  }
}
</script>
<style scoped>
.dialog-footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dao_ru_bac{
  height: 40vh;
  text-align: center;
}
.upload_wai_bac{
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.upload_bac {
  width: 240px;
  height: 120px;
  border-radius: 8px;
  border: 2px dashed #e5e6eb;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload_icon_bac {
  position: absolute !important;
  pointer-events: none !important;
  background: white !important;
}
>>> .el-transfer-panel {
  width: 360px !important;
}
>>> .el-transfer__buttons {
  width: 60px;
  /* display: flex;
  flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
}

>>> .el-transfer__buttons button{
  margin: 10px 0px !important;
}
</style>