import axiosHttps from '@/config/axios'

// 获取翻译
export const translate = data => {
  return axiosHttps.request({
    url: `/v1/translate`,
    data: data,
    method: 'post'
  })
}

// 获取翻译
export const detectLanguage = data => {
  return axiosHttps.request({
    url: `/v1/translate/detectLanguage`,
    data: data,
    method: 'post'
  })
}

// 获取草稿列表
export const draftList = info => {
  return axiosHttps.request({
    url: `/v1/audit/draft/list`,
    data: info,
    method: 'post'
  })
}

// 删除草稿
export const deleteDraft = info => {
  return axiosHttps.request({
    url: `/v1/audit/draft`,
    data: info,
    method: 'delete'
  })
}

// 获取文章审核列表
export const auditList = info => {
  return axiosHttps.request({
    url: `/v1/audit/list`,
    data: info,
    method: 'post'
  })
}

//获取获取文章详情
export const auditDetails = info => {
  return axiosHttps.request({
    url: `/v1/audit/details`,
    params: info,
    method: 'get'
  })
}

// 新增/编辑文章
export const auditEdit = info => {
  return axiosHttps.request({
    url: `/v1/audit/edit`,
    data: info,
    method: 'post'
  })
}

// 社区动态置顶操作
export const communityPostSetTop = info => {
  return axiosHttps.request({
    url: `/v1/community/post/set-top`,
    params: info,
    method: 'get'
  })
}

// 评论和点赞文章
export const auditBehavior = info => {
  return axiosHttps.request({
    url: `/v1/audit/behavior`,
    data: info,
    method: 'post'
  })
}

// 转发TG群
export const auditForwardTg = info => {
  return axiosHttps.request({
    url: `/v1/audit/forward/tg`,
    data: info,
    method: 'post'
  })
}

// 转发Twitter
export const auditForwardTwitter = info => {
  return axiosHttps.request({
    url: `/v1/audit/forward/twitter`,
    data: info,
    method: 'post'
  })
}

// 审核
export const auditAudit = info => {
  return axiosHttps.request({
    url: `/v1/audit/audit`,
    data: info,
    method: 'post'
  })
}


// 删除文章
export const deleteAudit = info => {
  return axiosHttps.request({
    url: `/v1/audit/delete`,
    data: info,
    method: 'delete'
  })
}



// 获取招聘列表
export const recruitList = info => {
  return axiosHttps.request({
    url: `/v1/recruit/list`,
    data: info,
    method: 'post'
  })
}
//获取招聘详情
export const recruitDetails = info => {
  return axiosHttps.request({
    url: `/v1/recruit/details`,
    params: info,
    method: 'get'
  })
}

// 编辑招聘文章
export const recruitEdit = info => {
  return axiosHttps.request({
    url: `/v1/recruit/edit`,
    data: info,
    method: 'post'
  })
}

// 招聘审核
export const recruitAudit = info => {
  return axiosHttps.request({
    url: `/v1/recruit/audit`,
    data: info,
    method: 'post'
  })
}

// 删除招聘
export const deleteRecruit = info => {
  return axiosHttps.request({
    url: `/v1/recruit/delete`,
    data: info,
    method: 'delete'
  })
}