var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "编辑",
        width: "80vw",
        "close-on-click-modal": false,
        visible: _vm.showDia
      },
      on: {
        "update:visible": function($event) {
          _vm.showDia = $event
        }
      }
    },
    [
      _c("div", { staticClass: "page_bac_div" }, [
        _c(
          "div",
          {
            staticClass: "form_div_bac",
            style: { paddingRight: _vm.leftWidth + "px" }
          },
          [
            _c(
              "el-form",
              {
                ref: "newsInfo",
                staticClass: "demo-newsInfo",
                attrs: {
                  model: _vm.newsInfo,
                  rules: _vm.rules,
                  "label-width": "auto"
                }
              },
              [
                _c("el-form-item", { attrs: { label: "所属模块" } }, [
                  _vm._v("视频")
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "所属目标市场", prop: "countryList" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "请选择",
                          filterable: "",
                          multiple: "",
                          clearable: ""
                        },
                        on: {
                          focus: function($event) {
                            return _vm.getCountryDisct()
                          }
                        },
                        model: {
                          value: _vm.newsInfo.countryList,
                          callback: function($$v) {
                            _vm.$set(_vm.newsInfo, "countryList", $$v)
                          },
                          expression: "newsInfo.countryList"
                        }
                      },
                      _vm._l(_vm.countryDisct, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    ),
                    _vm.newsInfo.countryList.length == 0 && _vm.EditIsClick
                      ? _c("div", { staticClass: "mes_error_text" }, [
                          _vm._v("请选择目标市场")
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "所属业务或分类", prop: "channelList" } },
                  [
                    _c("el-cascader", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        props: _vm.casProps,
                        options: _vm.channelCateDisct,
                        "show-all-levels": false,
                        "change-on-select": true
                      },
                      on: {
                        "visible-change": function($event) {
                          return _vm.getTerritory()
                        }
                      },
                      model: {
                        value: _vm.newsInfo.channelCateList,
                        callback: function($$v) {
                          _vm.$set(_vm.newsInfo, "channelCateList", $$v)
                        },
                        expression: "newsInfo.channelCateList"
                      }
                    }),
                    _c("div", { staticClass: "mes_text_div" }, [
                      _vm._v(" 选中1-6个业务或分类")
                    ]),
                    _vm.newsInfo.channelCateList.length > 6 ||
                    (_vm.newsInfo.channelCateList.length < 1 && _vm.EditIsClick)
                      ? _c("div", { staticClass: "mes_error_text" }, [
                          _vm._v(" 请选中1-6个业务或分类 ")
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "标题", prop: "title", required: "" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "150" },
                      model: {
                        value: _vm.newsInfo.title,
                        callback: function($$v) {
                          _vm.$set(_vm.newsInfo, "title", $$v)
                        },
                        expression: "newsInfo.title"
                      }
                    }),
                    !_vm.newsInfo.title && _vm.EditIsClick
                      ? _c("div", { staticClass: "mes_error_text" }, [
                          _vm._v("请输入标题")
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "视频简介" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "200" },
                      model: {
                        value: _vm.newsInfo.content,
                        callback: function($$v) {
                          _vm.$set(_vm.newsInfo, "content", $$v)
                        },
                        expression: "newsInfo.content"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "封面", prop: "content" } },
                  [
                    _c("uploadImgUtils", {
                      attrs: {
                        image: _vm.newsInfo.cover,
                        width: "80px",
                        height: "80px"
                      },
                      on: {
                        "update:image": function($event) {
                          return _vm.$set(_vm.newsInfo, "cover", $event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: "上传视频" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "margin-bottom": "8px" }
                    },
                    [
                      _c("uploadVideoEasy", {
                        ref: "uploadVideoEasyRef",
                        attrs: {
                          newsId: _vm.newsInfo.id,
                          url: _vm.newsInfo.videoInfo.videoUrl,
                          cover: _vm.newsInfo.cover,
                          size: _vm.newsInfo.videoInfo.size,
                          duration: _vm.newsInfo.videoInfo.duration
                        },
                        on: {
                          "update:newsId": function($event) {
                            return _vm.$set(_vm.newsInfo, "id", $event)
                          },
                          "update:news-id": function($event) {
                            return _vm.$set(_vm.newsInfo, "id", $event)
                          },
                          "update:url": function($event) {
                            return _vm.$set(
                              _vm.newsInfo.videoInfo,
                              "videoUrl",
                              $event
                            )
                          },
                          "update:cover": function($event) {
                            return _vm.$set(_vm.newsInfo, "cover", $event)
                          },
                          "update:size": function($event) {
                            return _vm.$set(
                              _vm.newsInfo.videoInfo,
                              "size",
                              $event
                            )
                          },
                          "update:duration": function($event) {
                            return _vm.$set(
                              _vm.newsInfo.videoInfo,
                              "duration",
                              $event
                            )
                          }
                        }
                      }),
                      _vm.newsInfo.videoInfo.videoUrl
                        ? _c(
                            "div",
                            { staticClass: "but_bac" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "preventReClick",
                                      rawName: "v-preventReClick"
                                    }
                                  ],
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.getImg(
                                        _vm.$pathPrefix +
                                          _vm.newsInfo.videoInfo.videoUrl
                                      )
                                    }
                                  }
                                },
                                [_vm._v("截取封面")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "preventReClick",
                                      rawName: "v-preventReClick"
                                    }
                                  ],
                                  staticStyle: { margin: "0px" },
                                  attrs: {
                                    type: "primary",
                                    disabled: !_vm.subtitleLang
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.returnUrl("")
                                    }
                                  }
                                },
                                [_vm._v("取消字幕")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "preventReClick",
                                      rawName: "v-preventReClick"
                                    }
                                  ],
                                  staticStyle: { margin: "0px" },
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      !_vm.newsInfo.videoInfo.subtitleZh ||
                                      _vm.subtitleLang == "zh"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.returnUrl(
                                        _vm.$pathPrefix +
                                          _vm.newsInfo.videoInfo.subtitleZh
                                      )
                                    }
                                  }
                                },
                                [_vm._v("中文字幕")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "preventReClick",
                                      rawName: "v-preventReClick"
                                    }
                                  ],
                                  staticStyle: { margin: "0px" },
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      !_vm.newsInfo.videoInfo.subtitleEn ||
                                      _vm.subtitleLang == "en"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.returnUrl(
                                        _vm.$pathPrefix +
                                          _vm.newsInfo.videoInfo.subtitleEn
                                      )
                                    }
                                  }
                                },
                                [_vm._v("英文字幕")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  !_vm.newsInfo.videoInfo.videoUrl && _vm.EditIsClick
                    ? _c("div", { staticClass: "mes_error_text" }, [
                        _vm._v("请上传视频")
                      ])
                    : _vm._e()
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "中文字幕" } },
                  [
                    _c("uploadSubtitle", {
                      attrs: {
                        newsId: _vm.newsInfo.id,
                        url: _vm.newsInfo.videoInfo.subtitleZh,
                        lang: "zh",
                        currentModule: _vm.newsInfo.module
                      },
                      on: {
                        "update:newsId": function($event) {
                          return _vm.$set(_vm.newsInfo, "id", $event)
                        },
                        "update:news-id": function($event) {
                          return _vm.$set(_vm.newsInfo, "id", $event)
                        },
                        "update:url": function($event) {
                          return _vm.$set(
                            _vm.newsInfo.videoInfo,
                            "subtitleZh",
                            $event
                          )
                        },
                        returnUrl: _vm.returnUrl
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "英文字幕" } },
                  [
                    _c("uploadSubtitle", {
                      attrs: {
                        newsId: _vm.newsInfo.id,
                        url: _vm.newsInfo.videoInfo.subtitleEn,
                        lang: "en",
                        currentModule: _vm.newsInfo.module
                      },
                      on: {
                        "update:newsId": function($event) {
                          return _vm.$set(_vm.newsInfo, "id", $event)
                        },
                        "update:news-id": function($event) {
                          return _vm.$set(_vm.newsInfo, "id", $event)
                        },
                        "update:url": function($event) {
                          return _vm.$set(
                            _vm.newsInfo.videoInfo,
                            "subtitleEn",
                            $event
                          )
                        },
                        returnUrl: _vm.returnUrl
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "选择标签", prop: "labelList" } },
                  [
                    _c("selectTag", {
                      attrs: { labelList: _vm.newsInfo.labelList },
                      on: {
                        "update:labelList": function($event) {
                          return _vm.$set(_vm.newsInfo, "labelList", $event)
                        },
                        "update:label-list": function($event) {
                          return _vm.$set(_vm.newsInfo, "labelList", $event)
                        }
                      }
                    }),
                    _vm._v(" 最多可添加20个标签 ")
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "权重排序", prop: "infoSort" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "limitInput",
                              rawName: "v-limitInput:positiveInteger",
                              arg: "positiveInteger"
                            }
                          ],
                          staticStyle: { width: "215px" },
                          attrs: { maxlength: "10" },
                          model: {
                            value: _vm.newsInfo.infoSort,
                            callback: function($$v) {
                              _vm.$set(_vm.newsInfo, "infoSort", $$v)
                            },
                            expression: "newsInfo.infoSort"
                          }
                        }),
                        _c("span", { staticClass: "mes_text_div" }, [
                          _vm._v("数值越大，排序越靠前")
                        ])
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "权重有效时间", prop: "infoSort" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.sortExpireRadio,
                              callback: function($$v) {
                                _vm.sortExpireRadio = $$v
                              },
                              expression: "sortExpireRadio"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("设置有效时间")
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("不设置有效时间")
                            ])
                          ],
                          1
                        ),
                        _c("el-date-picker", {
                          staticStyle: { "margin-left": "16px" },
                          attrs: {
                            disabled: _vm.sortExpireRadio == 2,
                            type: "datetime",
                            placeholder: "选择时间",
                            "default-time": "23:59:59",
                            "picker-options": _vm.pickerOptions()
                          },
                          model: {
                            value: _vm.newsInfo.infoSortExpire,
                            callback: function($$v) {
                              _vm.$set(_vm.newsInfo, "infoSortExpire", $$v)
                            },
                            expression: "newsInfo.infoSortExpire"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.sortExpireRadio == 1 &&
                    !_vm.newsInfo.infoSortExpire &&
                    _vm.EditIsClick
                      ? _c("div", { staticClass: "mes_error_text" }, [
                          _vm._v("请选择定时发布时间")
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "phone_righ_bac" },
          [
            _c("articleVideoModel", {
              ref: "articleVideoModelRef",
              staticClass: "model_com",
              attrs: {
                newsId: _vm.newsInfo.id,
                height: _vm.modelHeight,
                videoUrl: _vm.newsInfo.videoInfo.videoUrl,
                html: _vm.newsInfo.content,
                title: _vm.newsInfo.title,
                tags: _vm.newsInfo.labelList,
                currentModule: _vm.newsInfo.module,
                nickname: _vm.newsInfo.nickName,
                headPic: _vm.newsInfo.headPic,
                date: _vm.newsInfo.date
              },
              on: {
                "update:videoUrl": function($event) {
                  return _vm.$set(_vm.newsInfo.videoInfo, "videoUrl", $event)
                },
                "update:video-url": function($event) {
                  return _vm.$set(_vm.newsInfo.videoInfo, "videoUrl", $event)
                },
                "update:html": function($event) {
                  return _vm.$set(_vm.newsInfo, "content", $event)
                },
                "update:title": function($event) {
                  return _vm.$set(_vm.newsInfo, "title", $event)
                },
                "update:tags": function($event) {
                  return _vm.$set(_vm.newsInfo, "labelList", $event)
                },
                "update:currentModule": function($event) {
                  return _vm.$set(_vm.newsInfo, "module", $event)
                },
                "update:current-module": function($event) {
                  return _vm.$set(_vm.newsInfo, "module", $event)
                },
                "update:nickname": function($event) {
                  return _vm.$set(_vm.newsInfo, "nickName", $event)
                },
                "update:headPic": function($event) {
                  return _vm.$set(_vm.newsInfo, "headPic", $event)
                },
                "update:head-pic": function($event) {
                  return _vm.$set(_vm.newsInfo, "headPic", $event)
                },
                "update:date": function($event) {
                  return _vm.$set(_vm.newsInfo, "date", $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.showDia = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" }
              ],
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("newsInfo")
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }