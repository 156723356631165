var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.infoId ? "编辑" : "新增",
        width: "1000px",
        "close-on-click-modal": false,
        visible: _vm.visible
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "140px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "公司类型", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.ruleForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "type", $$v)
                    },
                    expression: "ruleForm.type"
                  }
                },
                _vm._l(_vm.companyType, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.name, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主营业务", prop: "channelList" } },
            [
              _c("el-cascader", {
                staticStyle: { width: "100%" },
                attrs: {
                  filterable: "",
                  clearable: "",
                  props: _vm.casProps,
                  options: _vm.channelCateDisct,
                  "show-all-levels": false,
                  "change-on-select": true
                },
                on: {
                  "visible-change": function($event) {
                    return _vm.getTerritory()
                  }
                },
                model: {
                  value: _vm.ruleForm.channelList,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "channelList", $$v)
                  },
                  expression: "ruleForm.channelList"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态", prop: "state" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.state,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "state", $$v)
                    },
                    expression: "ruleForm.state"
                  }
                },
                _vm._l(_vm.companyStateDisct, function(item) {
                  return _c(
                    "el-radio",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
              _c("br")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司名称", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司英文名称", prop: "nameEn" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.nameEn,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "nameEn", $$v)
                  },
                  expression: "ruleForm.nameEn"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司描述", prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "公司英文描述", prop: "remarkEn" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.remarkEn,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "remarkEn", $$v)
                  },
                  expression: "ruleForm.remarkEn"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "国家", prop: "localhost" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    filterable: "",
                    clearable: ""
                  },
                  on: {
                    focus: function($event) {
                      return _vm.getCountryDisct()
                    }
                  },
                  model: {
                    value: _vm.ruleForm.localhost,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "localhost", $$v)
                    },
                    expression: "ruleForm.localhost"
                  }
                },
                _vm._l(_vm.countryDisct, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图片", prop: "icon" } },
            [
              _vm.visible
                ? _c("uploadImgCompany", {
                    attrs: {
                      image: _vm.ruleForm.icon,
                      width: "60px",
                      height: "60px"
                    },
                    on: {
                      "update:image": function($event) {
                        return _vm.$set(_vm.ruleForm, "icon", $event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "成立时间" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  "picker-options": _vm.pickerOptions0
                },
                model: {
                  value: _vm.ruleForm.createTime,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "createTime", $$v)
                  },
                  expression: "ruleForm.createTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "官网链接", prop: "officialUrl" } },
            [
              _c("el-input", {
                attrs: { r: "" },
                model: {
                  value: _vm.ruleForm.officialUrl,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "officialUrl", $$v)
                  },
                  expression: "ruleForm.officialUrl"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "权重排序", prop: "sort" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("el-input", {
                  directives: [
                    {
                      name: "limitInput",
                      rawName: "v-limitInput:positiveInteger",
                      arg: "positiveInteger"
                    }
                  ],
                  staticStyle: { width: "215px" },
                  attrs: { maxlength: "10" },
                  model: {
                    value: _vm.ruleForm.sort,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "sort", $$v)
                    },
                    expression: "ruleForm.sort"
                  }
                }),
                _c("span", { staticClass: "mes_text_div" }, [
                  _vm._v("数值越大，排序越靠前")
                ])
              ],
              1
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "公司简介", prop: "content" } },
            [
              _c("comWangEditor", {
                attrs: { height: "300px", content: _vm.ruleForm.content },
                on: {
                  "update:content": function($event) {
                    return _vm.$set(_vm.ruleForm, "content", $event)
                  }
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "联系方式", required: "" } }, [
            _c("div", { staticClass: "lian_xi_bac" }, [
              _c(
                "span",
                { staticStyle: { "font-weight": "600", width: "80px" } },
                [_vm._v("Telegram")]
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/img/tg_logo_img.png") }
                  }),
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    model: {
                      value: _vm.ruleForm.tgAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "tgAccount", $$v)
                      },
                      expression: "ruleForm.tgAccount"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lian_xi_bac" }, [
              _c(
                "span",
                { staticStyle: { "font-weight": "600", width: "80px" } },
                [_vm._v("WhatsApp")]
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/img/phone_logo_img.png") }
                  }),
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    model: {
                      value: _vm.ruleForm.whatsAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "whatsAccount", $$v)
                      },
                      expression: "ruleForm.whatsAccount"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "lian_xi_bac" }, [
              _c(
                "span",
                { staticStyle: { "font-weight": "600", width: "80px" } },
                [_vm._v("Line")]
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/img/line_logo_img.png") }
                  }),
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    model: {
                      value: _vm.ruleForm.lineAccount,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "lineAccount", $$v)
                      },
                      expression: "ruleForm.lineAccount"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "绑定企业资讯账号" } },
            [
              _c("selectUserMultiple", {
                attrs: {
                  ids: _vm.ruleForm.userAccoutlist,
                  inputId: "1",
                  filterrype: "1"
                },
                on: {
                  "update:ids": function($event) {
                    return _vm.$set(_vm.ruleForm, "userAccoutlist", $event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "绑定企业招聘账号" } },
            [
              _c("selectUserMultiple", {
                attrs: {
                  ids: _vm.ruleForm.recruitAccoutlist,
                  inputId: "2",
                  filterrype: "1"
                },
                on: {
                  "update:ids": function($event) {
                    return _vm.$set(_vm.ruleForm, "recruitAccoutlist", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.cancelFun()
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" }
              ],
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitForm("ruleForm")
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }