<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">
        <img class="logo_img" src='@/assets/img/logo.png'/> <span>PASA Admin</span>
      </div>
      <el-form ref="login" :model="param" :rules="rules" hide-required-asterisk label-width="40px"
      >
        <el-tabs v-model="loginType" stretch type="card">
          <el-tab-pane class="ms-content" name="PWD">
            <span slot="label"><i class="el-icon-key"></i>密码登录</span>

            <el-form-item label="账号" prop="account" style="margin-bottom: 40px">
              <el-input v-model="param.account" clearable placeholder="请输入用户名" @keyup.enter.native="submitForm()">
              </el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pwd">
              <el-input
                  v-model="param.pwd"
                  clearable
                  placeholder="请输入密码"
                  show-password
                  show-pwd
                  type="pwd"
                  @keyup.enter.native="submitForm()"
              >
              </el-input>
            </el-form-item>
            <div style="text-align: right;">
              <el-checkbox v-model="userChecked" style="width: 100%;">记住账号</el-checkbox>
            </div>

          </el-tab-pane>
          <el-tab-pane disabled name="SMS">
            <span slot="label"><i class="el-icon-mobile-phone"></i>手机号登录</span>

            <div style="text-align: right;">
              <el-checkbox v-model="userChecked" style="width: 100%;">记住账号</el-checkbox>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="login-btn">
          <el-button ref="loginBtn" icon="el-icon-caret-right" style="border-radius: 0;height: 50px; font-size: 18px;"
                     type="primary" @click="submitForm()">立即登录
          </el-button>

        </div>
      </el-form>

    </div>
    <button id="loginBtn" ref="fakeLoginBtn" style="display: none"></button>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data: function () {
    return {
      loading: null,
      loginType: "PWD",
      param: {
        account: '',
        pwd: '',// 密码传后端 例如密码123456   进行一次md5，然后获取md5值拼上账号，再进行一次md5
        verifyCode: '',// 验证码
        verifyCodeMark: '', // 获取验证码时给的uuid
      },
      rules: {
        account: [{required: true, message: '请输入用户名', trigger: 'blur'}],
        pwd: [{required: true, message: '请输入密码', trigger: 'blur'}],
        // verifyCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      markPath: '',
      userChecked: false,
      captcha: null,
      captchaVerifyParam: null,
    };
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.loadScript('https://o.alicdn.com/captcha-frontend/aliyunCaptcha/AliyunCaptcha.js', this.initCaptcha)


  },
  created() {
    let loginInfo = localStorage.getItem('adminLoginInfo')
    if (loginInfo) {
      loginInfo = JSON.parse(loginInfo)
      this.userChecked = loginInfo.userChecked
      if (this.userChecked) {
        this.param.account = loginInfo.account
        this.param.pwd = loginInfo.pwd
      }
    }

    // this.initCaptcha()

  },

  methods: {
    ...mapActions([
      'handleLogin',
      'captchaVerify'
    ]),
    loadScript(src, callback) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.onload = callback;
      document.head.appendChild(script);
    },

    getInstance(instance) {
      this.captcha = instance;
    },
    // 生成随机数
    generateRandomNumber(length) {
      const min = Math.pow(10, length - 1);
      const max = Math.pow(10, length) - 1;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    // 获取验证码
    initCaptcha() {
      initAliyunCaptcha({
        SceneId: 'oxxe4ps5v', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: '1ar2ica', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: '#captcha-element', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: '#loginBtn', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
        slideStyle: {
          width: 360,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
        language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
        region: 'sgp' //验证码示例所属地区，支持中国内地（cn）、新加坡（sgp）
      });

      this.loading.close();
    },

    onBizResultCallback(isSuccess) {
      if (isSuccess) {
        this.submitForm()
      }
    },
    async captchaVerifyCallback(captchaVerifyParam) {
      console.debug("captchaVerifyParam: ", captchaVerifyParam)

      let result = await this.captchaVerifyEvent(captchaVerifyParam);
      this.captchaVerifyParam = captchaVerifyParam
      // 2.构造标准返回参数
      return {
        captchaResult: result, // 验证码验证是否通过，boolean类型，必选
      };

    },
    async captchaVerifyEvent(captchaVerifyParam) {
      return ((await this.captchaVerify(captchaVerifyParam)).data === true);
    },
    async submitForm() {

      // if (!this.captcha) {
      //   this.$message.error('系统正在加载中 请稍后再试');
      //   return;
      // }

      let isValid = await new Promise((resolve) => {
        this.$refs.login.validate((valid) => {
          resolve(valid);
        });
      });

      console.debug("isValid: ", isValid)

      if (!isValid) {
        this.$message.error('请输入登录信息');
        return;
      }

      // if (!this.captchaVerifyParam) {
      //   this.captchaVerifyParam = null
      //   this.$refs.fakeLoginBtn.click()
      //   return;
      // }

      let pwdMd5 = this.$Md5(this.param.pwd.trim())
      let data = {
        loginName: this.param.account.trim(),
        password: pwdMd5,
        loginType: this.loginType,
        verifyCode: '123456'//this.captchaVerifyParam,// 验证码
      }
      this.$openLoading()
      this.handleLogin(data).then(res => {
        if (this.userChecked) {
          let info = {
            userChecked: this.userChecked,
            account: this.param.account.trim(),
            pwd: this.param.pwd.trim(),
          }
          localStorage.setItem('adminLoginInfo', JSON.stringify(info))


        } else {
          localStorage.removeItem('adminLoginInfo')
        }

        this.$closeLoading()
        this.$router.push('/')
      }).catch(err => {
        this.$closeLoading()
        console.log("错误", err)
        this.captchaVerifyParam = null
        this.$message.error(err);
      })

    }
  },
};
</script>
<style scoped>

</style>
<style lang="scss" scoped>
.login-wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  //background-image: url(../../assets/img/login_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #efefef;
  // background: #FFF;
}

.ms-title {
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(50, 50, 50, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_img {
  width: 24px;
  height: 24px;
  display: block;
  margin-right: 8px;
}

.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.1);
}

.ms-content {
  padding: 0 15px;
}

.login-btn {
  text-align: center;
  margin-top: 20px;
}

.login-btn button {
  width: 100%;
  height: 36px;
}

.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}

.login-captcha {
  overflow: hidden;

  > img {
    width: 100%;
    height: 32px;
    cursor: pointer;
  }
}
</style>