var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    attrs: {
      filterable: "",
      clearable: "",
      "collapse-tags": "",
      props: _vm.casProps,
      options: _vm.channelCateDisct,
      "show-all-levels": false,
      "change-on-select": true
    },
    model: {
      value: _vm.channelCateIds,
      callback: function($$v) {
        _vm.channelCateIds = $$v
      },
      expression: "channelCateIds"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }