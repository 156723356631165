<template>
  <!-- 展会 -->
  <div class="container">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" style="padding: 10px 0;">
      <el-form-item label="标题">
        <el-input v-model="dataForm.search1" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="渠道">
        <el-select v-model="dataForm.select3" size="mini" placeholder="请选择" clearable 
          style="width: 120px;" @change="getDataList()">
          <el-option v-for="item in crawlerTypeDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间状态">
        <el-select v-model="dataForm.select1" size="mini" placeholder="请选择" clearable 
          style="width: 120px;" @change="getDataList()">
          <el-option v-for="item in timeFilterDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="dataForm.select2" size="mini" placeholder="请选择" clearable 
          style="width: 120px;" @change="getDataList()">
          <el-option v-for="item in stateDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      
      <el-form-item label="创建时间">
        <el-date-picker v-model="dataForm.createTime" type="datetimerange"  size="mini"
          style="width: 310px;"  @change="getDataList()"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="openAddOrUpdate()">新增</el-button>
      </el-form-item>
    </el-form>
    
    <el-table
      :data="dataList" border v-loading="dataListLoading"
      height="100" v-adaptive="{bottomOffset: 50,iModal: false}"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;">
      <el-table-column prop="dateTime" label="开始时间">
        <template slot-scope="scope">
          {{scope.row.startTime | showDate()}}
        </template>
      </el-table-column>
      <el-table-column prop="dateTime" label="结束时间">
        <template slot-scope="scope">
          {{scope.row.endTime | showDate()}}
        </template>
      </el-table-column>
      <el-table-column prop="id" label="展会ID"></el-table-column>
      <el-table-column prop="title" label="展会标题"> </el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <viewer>
            <img :src="$pathPrefix+scope.row.coverUrl" v-if="scope.row.coverUrl"
              style="width: 60px; height: 60px; object-fit: contain;"/>
          </viewer>
        </template>
      </el-table-column>
      <el-table-column label="展会官网">
        <template slot-scope="scope">
          <div @click="goUrlFun(scope.row.officeUrl)" class="go_url_div content_div_class_clamp">
            {{ scope.row.officeUrl }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="展会简介">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 300px;" class="content_p_class_clamp">{{ scope.row.introduction }}</p>
            <div slot="reference"  style="display: flex;align-items: center;justify-content: center;">
              <div class="content_div_class_clamp">{{ scope.row.introduction }}</div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="展会内容">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p  style=" width: 300px;" class="content_p_class_clamp">{{ scope.row.content }}</p>
            <div slot="reference"  style="display: flex;align-items: center;justify-content: center;">
              <div class="content_div_class_clamp">{{ scope.row.content }}</div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{scope.row.state | stateFilter(stateDisct)}}
        </template>
      </el-table-column>
      <el-table-column label="web顶部推荐">
        <template slot-scope="scope">
          {{scope.row.webTopRecState | stateFilter(webTopRecStateDisct)}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="240" label="操作">
        <template slot-scope="scope">
          <div style="line-height:40px;justify-content: space-between;">
            <el-button type="success" plain size="mini" @click="openNews(scope.row.id)">文章管理</el-button>
            <el-button type="primary" plain size="mini" @click="openAddOrUpdate(scope.row)">编辑</el-button>
            <el-button type="warning" plain size="mini" @click="deleteFun(scope.row.id)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- @size-change="sizeChangeHandle" layout="total, sizes, prev, pager, next, jumper"> -->
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 弹窗, 文章管理-->
    <newsManage ref="newsManageRef" @refreshDataList="getDataList(pageIndex)"></newsManage>
    
    <!-- 弹窗, 新增 / 修改 -->
    <addOrUpdate ref="addOrUpdateRef" @refreshDataList="getDataList(pageIndex)" 
      v-if="addOrUpdateShow" :show.sync="addOrUpdateShow"></addOrUpdate>
    
  </div>
</template>

<script>
import { formatDateTime,utcUpdatedDate } from '@/utils'
import { exhibitionList, exhibitionDelete } from '@/api/configManage'
import newsManage from './newsManage'
import addOrUpdate from './addOrUpdate'
export default {
  name: 'exhibitionList',
  data () {
    return {
      pageModule: 3,
      crawlerTypeDisct: [   //渠道字典
        {value: -1, name: '全部'},
        {value: 0, name: '后台发布'},
        {value: 1, name: '灰度'},
        {value: 2, name: 'EventUS'},
      ],
      stateDisct: [   //在线状态字典
        {value: 1, name: '开启'},
        {value: 2, name: '关闭'},
      ],
      webTopRecStateDisct: [   //在线状态字典
        {value: 0, name: '关闭'},
        {value: 1, name: '开启'},
      ],
      timeFilterDisct: [   //在线状态字典
        {value: 1, name: '全部'},
        {value: 2, name: '未开始'},
        {value: 3, name: '进行中'},
        {value: 4, name: '已结束'},
      ],

      dataForm: {
        search1: '', //
        select1: '',
        select2: '',
        select3: 0,
        createTime: [],//注册时间
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateShow: false,
      visibleTui: false,
      recommendList: [],
      infoId: 1,
    }
  },
  components: {
    newsManage,
    addOrUpdate,
  },
  created () {
    
  },
  mounted () {
    this.getDataList()
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 条件禁用列
    checkSelectable(row){
      if (row.qualState == 0) {
        return true
      } else {
        return false
      }
    },
    // 获取数据列表
    getDataList (pageNum) {
      this.pageIndex = pageNum ? pageNum : 1
      this.dataListLoading = true
      let obj = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
      }
      if(this.dataForm.search1) obj.title = this.dataForm.search1.trim()
      if(this.dataForm.select1) obj.timeFilter = this.dataForm.select1 
      if(this.dataForm.select2) obj.state = this.dataForm.select2
      if(this.dataForm.select3 !== "" && this.dataForm.select3 !== -1) obj.crawlerType = this.dataForm.select3
      if(this.dataForm.createTime && this.dataForm.createTime.length > 0){
        obj.startTime = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[0]) : null
        obj.endTime = this.dataForm.createTime.length != 0 ? formatDateTime(this.dataForm.createTime[1]) : null
      }
      exhibitionList(obj).then(( data ) => {
        console.log("参数",data.data)
        this.dataList = data.data
        this.totalPage = data.totalCount
        this.dataListLoading = false
      }).catch((error) => {
        this.dataListLoading = false
        this.$message.error(error);
      })
    },

    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },

    // 打开文章管理
    openNews (id) {
      this.$nextTick(() => {
        this.$refs.newsManageRef.init(id)
      })
    },

    // 新增 / 修改
    openAddOrUpdate(row={}) {
      this.addOrUpdateShow = true
      this.$nextTick(() => {
        this.$refs.addOrUpdateRef.init(row)
      })
    },
    // 删除
    deleteFun(id){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        exhibitionDelete( { id: id } ).then( res => {
          this.getDataList(this.pageIndex)  
          this.$message.success("删除成功")
        }).catch((error) => {
          this.$message("删除失败"+error)
        })
      }).catch(() => { });
    },
    
    // 跳转网页
    goUrlFun(url){
      window.open(url, '_blank')
    }

  }
}
</script>
<style scoped>
.go_url_div{
  color: #1890FF;
  text-decoration: dashed;
  cursor: pointer;
}
</style>