<!-- 上传图片 -->
<template>
  <div style="display: flex;align-items: center;width:100%;height: 100%">
    <template  v-if="imageArr.length > 0">
      <div class="img_bacdiv" :style="sytleData" v-for="url in imageArr" :key="url">
        <viewer :images="imageArr">
          <img :src="$pathPrefix+$companyIcon+url" :style="sytleData" style="object-fit: cover;"/>
        </viewer>
        <div class="img_icon_bac">
          <i class="el-icon-error close_img_icon" @click="deleteImg(url)"/>
        </div>
      </div>
    </template>
    <template v-if="!uploaing">
      <el-upload action="#" :show-file-list="false" :auto-upload="true" :on-change="uploadBefore" :limit="1" :style="sytleData"
        accept="image/*" v-if="imageArr.length < limit">
        <div class="upload_bac" :style="sytleData">
          <i slot="default" class="el-icon-plus"  style="font-size:20px"></i>
        </div>
      </el-upload>
    </template>
    <div :style="sytleData" v-else class="upload_loading_div">
      <i class="el-icon-loading" style="color:#000;font-size:18px"/>
    </div>
    <crop-image :source="file" @cancel="file = null" @crop="upLoadFileFun"></crop-image>
  </div>
  <!-- 使用  <uploadImg :images.sync="testList" :limit="3" v-if="crud.status.cu > 0" width="60px" height="60px"></uploadImg> -->
</template>

<script>
import { ossUpload } from "@/utils/ossUpload";
import cropImage from '@/components/cropImage';
export default {
  name: 'uploadImgCompany',
  components: { cropImage },
  props: {
    image: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      default(){
        return []
      },
    },
    width: {
      type: String,
      default: '120px',
    },
    height: {
      type: String,
      default: '120px',
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      uploaing: false,
      imageArr: [],
      sytleData: {
        width: this.$props.width,
        height: this.$props.height,
      },
      file: null,
    };
  },
  watch : {
		image : function(val){
      if(this.$props.limit == 1){
        this.imageArr = []
        if(val){
          this.imageArr.push(val)
        }
      }else{
        this.imageArr = val
      }
		},
		'images.length' : function(val){
      if(val > 0){
        this.imageArr = this.$props.images
      }else{
        this.imageArr = []
      }
		},
    
	},
  created() {
  },
  mounted() {
    if(this.$props.limit == 1){
      this.imageArr = []
      if(this.$props.image){
        this.imageArr.push(this.$props.image)
      }
    }else{
      this.imageArr = this.$props.images
    }
  },
  methods: {
    uploadBefore(file) {
      if (file.status !== 'ready' || !file.raw) {
        return;
      }
      this.file = file.raw;
    },
    //cos上传图片
    async upLoadFileFun (file) {
      let _this = this;
      this.uploaing = true
      let urlCopy = await ossUpload(file, this.$companyIcon ).catch((error) => {
        this.uploaing = false
        this.$message.error(error);
      })
      console.log("上传返回",urlCopy)
      //获取最后一个/的位置
      var site = urlCopy.lastIndexOf("\/");
      //截取最后一个/后的值
      const url = urlCopy.substring(site + 1, urlCopy.length);
      _this.imageArr.push(url)
      this.uploaing = false
      if(_this.$props.limit > 1){
        _this.$emit('update:images',  _this.imageArr)
      }else{
        _this.$emit('update:image', _this.imageArr[0])
      }
    },
    //删除图片
    deleteImg(url){
      this.imageArr = this.imageArr.filter( img => img != url)
      if(this.$props.limit > 1){
        this.$emit('update:images',  this.imageArr)
      }else{
        this.$emit('update:image', '')
      }
    }
  },
  destroyed() {
    this.imageArr = []
  },
};
</script>
<style scoped>
>>> .el-upload--text {
  border: 0px dashed transparent !important;
  width: calc(100% + 3px);
  height: calc(100% + 3px);
}
</style>
<style lang="scss" scoped>

.upload_bac{
  background: #FBFDFF;
  border: 1.5px dashed #C0CCDA;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_bacdiv{
  margin-right: 10px;
  position: relative;
}
.img_icon_bac{
  position: absolute;
  right: 3%;
  top: 3%;
  background: white;
  border-radius: 100%;
}
.close_img_icon{
  cursor: pointer;
  color: #000;
  font-size: 16px;
  display: block;
}
.upload_loading_div{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px dashed #C0CCDA;
  border-radius: 4px;
}
</style>
