// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n.index_image_1PHVJ {\n  display: block;\n  max-width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"image": "index_image_1PHVJ"
};
module.exports = exports;
