export default {
  computed: {
    __html() {
      return this.__replaceHtml(this.html);
    },
  },
  methods: {
    __replaceHtml(html = '') {
      return html.replace(/(<img.*?)src="(.*?)"/gi, (...args) => {
        return `${args[1]}src="https://website-test.pasa365.com/api/utils/download?url=${encodeURIComponent(args[2])}"`;
      });
    },
    // 转换回原来路径
    __replaceContent(html = '') {
      return html.replace(/(<img.*?)src="(.*?)"/gi, (...args) => {
        const regex = /\?url=([^&]+)/;
        const match = args[2].match(regex);
        let url =  match ? decodeURIComponent(match[1]) : args[2]
        return `${args[1]}src="${url}"`;
      });
    },
  },
};
