import axiosHttps from '@/config/axios'

/**
 * 用户列表相关 
 */

//后台登录获取验证码
export const codeImg = info => {
  return axiosHttps.request({
    url: `/erupt-api/code-img`,
    params: info,
    method: 'get'
  })
}

//后台登录获取验证码
export const eruptApiUserinfo = () => {
  return axiosHttps.request({
    url: `/erupt-api/userinfo`,
    params: {},
    method: 'get'
  })
}

// 获取用户数据列表
export const getUserList = info => {
  return axiosHttps.request({
    url: `/v1/user/list`,
    data: info,
    method: 'post'
  })
}
//获取用户详情
export const userDetails = info => {
  return axiosHttps.request({
    url: `/v1/user/${info.userId}/details`,
    params: {},
    method: 'get'
  })
}

//用户新增/编辑
export const userAddEdit = info => {
  return axiosHttps.request({
    url: `/v1/user/edit`,
    data: info,
    method: 'post'
  })
}

// 用户认证
export const userAuthority = info => {
  return axiosHttps.request({
    url: `/v1/user/authority`,
    data: info,
    method: 'post'
  })
}

// 用户推荐关注
export const userRecommend = info => {
  return axiosHttps.request({
    url: `/v1/user/recommend`,
    data: info,
    method: 'post'
  })
}


// 获取企业列表
export const getCompanyList = info => {
  return axiosHttps.request({
    url: `/v1/company/list`,
    data: info,
    method: 'post'
  })
}

//企业新增/编辑
export const companyAddEdit = info => {
  return axiosHttps.request({
    url: `/v1/company/edit`,
    data: info,
    method: 'post'
  })
}

//获取企业详情
export const companyDetails = info => {
  return axiosHttps.request({
    url: `/v1/company/${info.id}/details`,
    params: {},
    method: 'get'
  })
}



// 行业列表
export const industryList = info => {
  return axiosHttps.request({
    url: `/v1/user/industry/list`,
    data: info,
    method: 'post'
  })
}
