var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "com_wang_editor",
      staticStyle: { border: "1px solid #ccc" }
    },
    [
      _c("Toolbar", {
        staticStyle: { "border-bottom": "1px solid #ccc" },
        attrs: {
          editor: _vm.editor,
          defaultConfig: _vm.toolbarConfig,
          mode: "simple"
        }
      }),
      _vm.isLoanding
        ? _c("Editor", {
            staticClass: "com_editer_class",
            style: { height: _vm.height },
            attrs: { defaultConfig: _vm.editorConfig, mode: "simple" },
            on: { customPaste: _vm.handlePaste, onCreated: _vm.onCreated },
            model: {
              value: _vm.htmlContent,
              callback: function($$v) {
                _vm.htmlContent = $$v
              },
              expression: "htmlContent"
            }
          })
        : _vm._e(),
      _vm.uploaing
        ? _c("div", { staticClass: "wang_edit_loading" }, [
            _c("i", {
              staticClass: "el-icon-loading",
              staticStyle: { color: "#424548", "font-size": "36px" }
            })
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }