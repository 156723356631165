var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "align-items": "center" } },
    [
      !_vm.upLoading
        ? _c(
            "el-upload",
            {
              style: _vm.sytleData,
              attrs: {
                action: "#",
                "show-file-list": false,
                "auto-upload": true,
                "on-change": _vm.upLoadFileFun,
                limit: 1,
                accept: "video/*",
                drag: ""
              }
            },
            [
              _c("div", { staticClass: "upload_bac", style: _vm.sytleData }, [
                _c("i", {
                  staticClass: "el-icon-plus",
                  staticStyle: { "font-size": "30px" },
                  attrs: { slot: "default" },
                  slot: "default"
                })
              ])
            ]
          )
        : _c(
            "div",
            {
              staticClass: "upload_bac",
              staticStyle: { border: "1px dashed #4290f7" },
              style: _vm.sytleData
            },
            [
              _vm.videoUploadPercent && _vm.videoUploadPercent > 0
                ? _c("el-progress", {
                    attrs: {
                      type: "circle",
                      size: "mini",
                      percentage: _vm.videoUploadPercent,
                      width: _vm.width / 2
                    }
                  })
                : [
                    _vm.loadingText
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-bottom": "12px",
                              "text-align": "center",
                              color: "#4290f7"
                            }
                          },
                          [_vm._v(_vm._s(_vm.loadingText))]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticStyle: { width: "100%", "text-align": "center" }
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-loading",
                          staticStyle: { color: "#4290f7" },
                          style: { fontSize: _vm.width / 8 + "px" }
                        })
                      ]
                    )
                  ]
            ],
            2
          ),
      _vm.videoUrl
        ? [
            _c(
              "div",
              { staticClass: "img_bacdiv", style: _vm.sytleData },
              [
                _c("video-two-com", {
                  attrs: {
                    videoSrc: _vm.$pathPrefix + _vm.videoUrl,
                    "subtitles-src": _vm.subtitleUrl,
                    showPlay: true,
                    videocover: _vm.$pathPrefix + _vm.coverImg,
                    width: _vm.width + "px",
                    height: _vm.height + "px"
                  }
                }),
                _c("div", { staticClass: "img_icon_bac" }, [
                  _c("i", {
                    staticClass: "el-icon-error close_img_icon",
                    on: {
                      click: function($event) {
                        return _vm.deleteImg()
                      }
                    }
                  })
                ])
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }