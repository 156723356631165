import Main from '@/components/common/Home.vue'//home底页

export default [
    //静态路由
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/utils/Login.vue'),
        meta: { title: '登录',hideInMenu: true }
    },
    {
        path: '*',
        redirect: '/404',
        meta: { title: '404',hideInMenu: true }
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "404" */ '@/views/utils/404.vue'),
        meta: { title: '404',hideInMenu: true }
    },
    {
        path: '/403',
        component: () => import(/* webpackChunkName: "403" */ '@/views/utils/403.vue'),
        meta: { title: '403',hideInMenu: true }
    },

    //动态路由
    {
        path: '/',
        name: '/',
        component: Main,
        meta: { 
            title: '用户管理', 
            icon: 'el-icon-user',
            roles: ['R_ADMIN']
        },
        redirect: 'userList',
        children: [
            {
                path: '/userList',
                name: 'userList',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/userManage/userList/index.vue'),
                meta: { title: '用户列表' }
            },
            {
                path: '/companyList',
                name: 'companyList',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/userManage/companyList/index.vue'),
                meta: { title: '企业列表' }
            },
        ]
    },
    //动态路由
    {
        path: '/articleManage',
        name: '/articleManage',
        component: Main,
        meta: { 
            title: '文章管理', 
            icon: 'el-icon-edit-outline',
        },
        redirect: 'createArticle',
        children: [
            {
                path: '/createArticle',
                name: 'createArticle',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/articleManage/createArticle'),
                meta: { title: '创建文章' }
            },
            // {
            //     path: '/createFlashNews',
            //     name: 'createFlashNews',
            //     component:() => import(/* webpackChunkName: "userList" */ '@/views/articleManage/createFlashNews'),
            //     meta: { 
            //         title: '发布快讯',
            //     }
            // },
            {
                path: '/createCommunity',
                name: 'createCommunity',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/articleManage/createCommunity'),
                meta: { 
                    title: '发布社区动态',
                }
            },
            {
                path: '/createVideoNews',
                name: 'createVideoNews',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/articleManage/createVideoNews'),
                meta: { 
                    title: '发布视频资讯',
                }
            },
            {
                path: '/articleDraftList',
                name: 'articleDraftList',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/articleManage/articleDraftList'),
                meta: { 
                    title: '文章草稿列表',
                    keepAlive: true // 需要被缓存 
                }
            },
        ]
    },
    //动态路由
    {
        path: '/newsManage',
        name: '/newsManage',
        component: Main,
        meta: { 
            title: '审核管理', 
            icon: 'el-icon-edit-outline',
        },
        redirect: 'newsList',
        children: [
            {
                path: '/newsList',
                name: 'newsList',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/newsManage/newsList'),
                meta: { 
                    title: '资讯列表',
                    keepAlive: true // 需要被缓存 
                }
            },
            {
                path: '/flashNewsList',
                name: 'flashNewsList',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/newsManage/flashNewsList'),
                meta: { 
                    title: '快讯列表',
                    keepAlive: true // 需要被缓存 
                }
            },
            {
                path: '/communityList',
                name: 'communityList',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/newsManage/communityList'),
                meta: { 
                    title: '社区列表',
                    keepAlive: true // 需要被缓存 
                }
            },
            {
                path: '/recruitList',
                name: 'recruitList',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/newsManage/recruitList'),
                meta: { 
                    title: '招聘列表',
                    keepAlive: true // 需要被缓存 
                }
            },
            {
                path: '/videoNewsList',
                name: 'videoNewsList',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/newsManage/videoNewsList'),
                meta: { 
                    title: '视频资讯列表',
                    keepAlive: true // 需要被缓存 
                }
            },
            {
                path: '/auditOrEdit',
                name: 'auditOrEdit',
                component:() => import(/* webpackChunkName: "userList" */ '@/views/newsManage/auditOrEdit'),
                meta: { 
                    title: '编辑与审核',
                    hideInMenu: true 
                }
            },
        ]
    },
    //动态路由
    {
        path: '/configManage',
        name: '/configManage',
        component: Main,
        meta: { 
            title: '配置管理', 
            icon: 'el-icon-menu',
        },
        redirect: 'advertisingList',
        children: [
            {
                path: '/specialList',
                name: 'specialList',
                component:() => import('@/views/configManage/specialList/index.vue'),
                meta: { title: '专题&合集列表' }
            },
            {
                path: '/highlightsNews',
                name: 'highlightsNews',
                component:() => import('@/views/configManage/highlightsNews/index.vue'),
                meta: { title: '每日精选' }
            },
            {
                path: '/popularizeNews',
                name: 'popularizeNews',
                component:() => import('@/views/configManage/popularizeNews/index.vue'),
                meta: { title: '主推文章' }
            },
            {
                path: '/hotTopic',
                name: 'hotTopic',
                component:() => import('@/views/configManage/hotTopic/index.vue'),
                meta: { title: '热议话题' }
            },
            {
                path: '/countyList',
                name: 'countyList',
                component:() => import('@/views/configManage/countyList/index.vue'),
                meta: { title: '国家专区' }
            },
            {
                path: '/exhibitionList',
                name: 'exhibitionList',
                component:() => import('@/views/configManage/exhibitionList/index.vue'),
                meta: { title: '展会列表' }
            },
            // {
            //     path: '/webRecommend',
            //     name: 'webRecommend',
            //     component:() => import('@/views/configManage/webRecommend/index.vue'),
            //     meta: { title: 'web推荐视频' }
            // },
            // {
            //     path: '/advertisingList',
            //     name: 'advertisingList',
            //     component:() => import('@/views/configManage/advertisingList/index.vue'),
            //     meta: { title: '广告列表' }
            // },
        ]
    },
    // ...(process.env.NODE_ENV === 'development' ? [
    //     {
    //         path: '/test',
    //         name: 'Test',
    //         meta: { 
    //             title: '测试', 
    //             icon: 'el-icon-edit-outline',
    //         },
    //         component:() => import('@/views/test.vue'),
    //     },
    // ] : []),
    // {
    //     path: '/systemManage',
    //     name: '/systemManage',
    //     component: Main,
    //     meta: { 
    //         title: '系统相关管理', 
    //         icon: 'el-icon-edit-outline',
    //     },
    //     redirect: 'systemUserList',
    //     children: [
    //         {
    //             path: '/systemUserList',
    //             name: 'systemUserList',
    //             component:() => import(/* webpackChunkName: "userList" */ '@/views/systemManage/systemUserList'),
    //             meta: { title: '系统用户列表' }
    //         }
    //     ]
    // },
    // 不在路由导航上的子路由需要加 hideInMenu: true
    //   {
    //     path: '/son',
    //     name: 'son',
    //     component: Main,
    //     meta: {  title: '子页面',hideInMenu: true },
    //     children: [
    //         {
    //             path: '/userDetailds',
    //             name: 'userDetailds',
    //             component:() => import(/* webpackChunkName: "editor" */ '@/views/userManage/userList/userDetailds.vue'),
    //             meta: { title: '用户详情页',hideInMenu: true }
    //         },
    //     ]
    //   },

]