<!-- 新增与修改 -->
<template>
  <el-dialog :title="infoId ? '编辑' : '新增'"
    width='1000px' 
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item label="公司类型" prop="type">
        <el-select v-model="ruleForm.type" placeholder="请选择">
          <el-option :label="item.name" :value="item.value"
            v-for="item in companyType" :key="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="主营业务" prop="channelList">
        <el-cascader
          style="width: 100%;"
          filterable clearable
          :props="casProps"
          :options="channelCateDisct"
          v-model="ruleForm.channelList"
          :show-all-levels="false"
          :change-on-select="true"
          @visible-change="getTerritory()">
        </el-cascader>
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio-group v-model="ruleForm.state">
          <el-radio :label="item.value" v-for="item in companyStateDisct" :key="item.value">{{ item.name }}</el-radio>
        </el-radio-group><br/>
      </el-form-item>
      <el-form-item label="公司名称" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>
      <el-form-item label="公司英文名称" prop="nameEn">
        <el-input v-model="ruleForm.nameEn" />
      </el-form-item>
      <el-form-item label="公司描述" prop="remark">
        <el-input v-model="ruleForm.remark" />
      </el-form-item>
      <el-form-item label="公司英文描述" prop="remarkEn">
        <el-input v-model="ruleForm.remarkEn" />
      </el-form-item>
      <el-form-item label="国家" prop="localhost">
        <el-select v-model="ruleForm.localhost" placeholder="请选择" filterable clearable @focus="getCountryDisct()">
          <el-option v-for="item in countryDisct" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="icon">
        <uploadImgCompany :image.sync="ruleForm.icon" v-if="visible" width='60px' height='60px'/>
      </el-form-item>
      <el-form-item label="成立时间">
        <el-date-picker 
          type="date" 
          placeholder="选择日期" 
          v-model="ruleForm.createTime" 
          style="width: 100%;"
          :picker-options="pickerOptions0">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="官网链接" prop="officialUrl">
        <el-input v-model="ruleForm.officialUrl"r/>
      </el-form-item>
      <el-form-item label="权重排序" prop="sort">
        <div style="display: flex;align-items: center;">
          <el-input v-model="ruleForm.sort" style="width: 215px;" maxlength="10" v-limitInput:positiveInteger/>
          <span class="mes_text_div">数值越大，排序越靠前</span>
        </div>
      </el-form-item>
      <el-form-item label="公司简介" prop="content">
        <!-- <el-input type="textarea" 
          v-model="ruleForm.content"
          rows='4'>
        </el-input>
         -->
        <comWangEditor
            height="300px"
            :content.sync="ruleForm.content"
            />
      </el-form-item>
      <el-form-item label="联系方式" required>
        <div class="lian_xi_bac">
          <span style="font-weight: 600;width: 80px;">Telegram</span>
          <div style="display: flex;align-items: center;">
            <img src="@/assets/img/tg_logo_img.png"/>
            <el-input v-model="ruleForm.tgAccount" style="width: 250px;"/>
          </div>
        </div> 
        <div class="lian_xi_bac">
          <span style="font-weight: 600;width: 80px;">WhatsApp</span>
          <div style="display: flex;align-items: center;">
            <img src="@/assets/img/phone_logo_img.png"/>
            <el-input v-model="ruleForm.whatsAccount" style="width: 250px;"/>
          </div>
        </div>
        <div class="lian_xi_bac">
          <span style="font-weight: 600;width: 80px;">Line</span>
          <div style="display: flex;align-items: center;">
            <img src="@/assets/img/line_logo_img.png"/>
            <el-input v-model="ruleForm.lineAccount" style="width: 250px;"/>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="绑定企业资讯账号">
        <selectUserMultiple :ids.sync="ruleForm.userAccoutlist" inputId="1" filterrype="1"/> 
      </el-form-item>
      <el-form-item label="绑定企业招聘账号">
        <selectUserMultiple :ids.sync="ruleForm.recruitAccoutlist" inputId="2" filterrype="1"/>
      </el-form-item>
    </el-form>
    
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      <el-button @click="cancelFun()">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { utcUpdatedDate, dateUpdateUtc } from '@/utils'
import { companyDetails, companyAddEdit } from '@/api/userManage'
import { companyStateDisctE, companyTypeE } from '@/utils/disct.js'
import { systemTerritoryTree, systemTerritory } from '@/api/utilsManage'
export default {
  name: 'addOrUpdate',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    let urlCheck = (rule, value, callback) => {
      const regex = /^https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*/;
      if(!value){
        callback();
      }else if (!regex.test(value)) {
        callback(new Error('不是合法的链接地址'));
      } else {
        callback();
      }
    };
    return {
      infoId: '',
      visible: false,
      channelCateDisct: [],
      companyStateDisct: companyStateDisctE,
      companyType: companyTypeE,
      casProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        multiple: true,
        emitPath: false
      },
      ruleForm: {},
      ruleFormCopy: {
        id: '',// 公司id
        name: "",   // 企业名称
        nameEn: "",// 公司名称-英文
        localhost: '',// IP
        remark: '',// 描述
        remarkEn: '',// 描述
        state: 1,// 状态（1：上架，2：下架）
        createTime: "",// 	成立时间
        type: 1,// 公司类型(1: 普通企业， 2：独角兽)
        channelList: [],// 	主营业务
        icon: "",// 	图片
        officialUrl: "",// 	官网链接
        sort: "1",// 权重
        content: "",// 	公司简介
        tgAccount: "",// 联系方式-Telegram
        whatsAccount: "",// 联系方式-WhatsApp
        lineAccount: '',// 联系方式-line
        userAccoutlist: [],// 	绑定企业资质账号
        recruitAccoutlist: [],// 	绑定企业招聘账号
      },
      
      rules: {
        type: [
          { required: true, message: '请选择公司类型', trigger: 'change' },
        ],
        channelList: [
          { type: 'array', required: true, message: '请至少选择一个主营企业', trigger: 'change' }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入中文名称', trigger: 'blur' },
        ],
        // nameEn: [
        //   { required: true, message: '请输入英文名称', trigger: 'blur' },
        // ],
        localhost: [
          { required: true, message: '请选择国家', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '请输入描述', trigger: 'blur' },
        ],
        // remarkEn: [
        //   { required: true, message: '请输入英文描述', trigger: 'blur' },
        // ],
        icon: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        createTime: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        officialUrl: [
          { validator: urlCheck, trigger: 'blur' } // 自定义校验
        ],
        sort: [
          { required: true, message: '请输入权重', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入公司简介', trigger: 'blur' },
        ],
      },
      pickerOptions0: { 
         disabledDate(time) {
            return time.getTime() + 8.64e7 > Date.now() ;//  如果没有后面的-8.64e7就是不可以选择今天的 
         }
  	  },
      countryDisct: [],

    }
  },
  watch : {
		'visible' : function(val){
      if(!val){
        this.$emit('update:show', false)
      }
		}
	},
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    }, 
    // 获取归属地
    getCountryDisct(){
      if(this.countryDisct.length == 0){
        systemTerritory({}).then(( data ) => {
          this.countryDisct = data.data.country
          this.countryDisct.unshift({id: '0', name: '全球'})
        })
      }
    },
    // 获取目标业务枚举列表 
    getTerritory(){
      if(this.channelCateDisct.length == 0){
        systemTerritoryTree({type: 2, isIncludeCate: false}).then(( data ) => {
          this.channelCateDisct = this.chuliChnnel(data.data)
        })
      }
    },
    // 处理channelCateDisct数据
    chuliChnnel(list){
      list.forEach( item => {
        if(item.dataType == 1){
          item.disabled = true
        }else{
          item.disabled = false
        }
        if(item.children && item.children.length>0){
          this.chuliChnnel(item.children)
        }else{
          delete item.children
        }
      })
      return list
    },
    init (id) {
      this.ruleForm = {}
      this.getTerritory()
      this.getCountryDisct()
      if(id){
        this.infoId = id + ''
        this.visible = true
        companyDetails( {id: this.infoId } ).then( res => {
          this.ruleForm =  res.data
          this.ruleForm.createTime =   utcUpdatedDate(this.ruleForm.createTime)
          if(this.ruleForm.channelList.length > 0){
            let ids = []
            this.ruleForm.channelList.forEach( item => {
              ids.push(item.id)
            })
            this.ruleForm.channelList = ids
          }
        })
      }else{
        this.visible = true
        this.ruleForm = this.ruleFormCopy
      }
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let info = JSON.parse(JSON.stringify(this.ruleForm))
          console.log("时间==",info.createTime)
          if(info.createTime){
            info.createTime = dateUpdateUtc(new Date(info.createTime))
          }
          if(!info.id){
            delete info.id
          }
          if(info.channelList.length > 0){
            let ids = []
            info.channelList.forEach( id => {
              ids.push({id: id})
            })
            info.channelList = ids
          }
          if(info.userAccoutlist.length > 0){
            let ids = []
            info.userAccoutlist.forEach( id => {
              ids.push(id)
            })
            info.userAccoutlist = ids
          }
          if(info.recruitAccoutlist.length > 0){
            let ids = []
            info.recruitAccoutlist.forEach( id => {
              ids.push(id)
            })
            info.recruitAccoutlist = ids
          }
          this.$openLoading()
          console.log("时间",info.createTime)
          companyAddEdit( info ).then( res => {
            this.$closeLoading()
            this.visible = false
            this.$message( {message: '操作成功',  type: 'success'} );
            this.$emit('refreshDataList')
          }).catch((error) => {
            this.$closeLoading()
            this.$message.error( error);
          })
        } else {
          return false;
        }
      });
    },
    // 取消
    cancelFun(){
      this.$confirm('退出后，不保存已编辑的内容！', '提示', {
        confirmButtonText: '坚持退出',
        cancelButtonText: '继续填写',
        type: 'warning'
      }).then(() => {
        console.log("退出")
        this.visible = false
      }).catch(() => { 
        console.log("继续")
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style lang="scss" scoped>
.mes_error_text{
  font-size: 12px;
  color: #F56C6C;
  position: absolute;
  top: calc(100% - 6px);
  left: 0;
}
.lian_xi_bac{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .lian_xi_check{
    width: 100px;
  }
  img{
    width: 24px;
    display: block;
    margin: 0 10px;
  }
}
</style>

