import { preventReClick, throttle } from './preventReClick/index'
import adaptive from './adaptive/index'
import limitInput from './limitInput/index'

const importDirective = Vue => {
  // 按钮防抖指令
  Vue.directive('preventReClick', preventReClick)
  // 按钮防抖指令
  Vue.directive('throttle', throttle)
  // 表格自动高度
  Vue.directive('adaptive', adaptive)
  // 输入框限制
  Vue.directive('limitInput', limitInput)
}

export default importDirective
