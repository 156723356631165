var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "preview_div",
          on: {
            click: function($event) {
              _vm.show = false
            }
          }
        },
        [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c("articleModel", {
                attrs: {
                  height: Math.min(_vm.windowSize.height * 0.8, 667),
                  title: _vm.previewInfo.title,
                  nickname: _vm.previewInfo.nickName,
                  date: _vm.previewInfo.date,
                  html: _vm.previewInfo.html,
                  currentModule: _vm.currentModule,
                  urlList: _vm.urlList,
                  headPic: _vm.previewInfo.headPic,
                  tags: _vm.previewInfo.labelList,
                  company: _vm.company,
                  newsId: _vm.newsId
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  },
                  "update:title": function($event) {
                    return _vm.$set(_vm.previewInfo, "title", $event)
                  },
                  "update:nickname": function($event) {
                    return _vm.$set(_vm.previewInfo, "nickName", $event)
                  },
                  "update:date": function($event) {
                    return _vm.$set(_vm.previewInfo, "date", $event)
                  },
                  "update:html": function($event) {
                    return _vm.$set(_vm.previewInfo, "html", $event)
                  },
                  "update:currentModule": function($event) {
                    _vm.currentModule = $event
                  },
                  "update:current-module": function($event) {
                    _vm.currentModule = $event
                  },
                  "update:urlList": function($event) {
                    _vm.urlList = $event
                  },
                  "update:url-list": function($event) {
                    _vm.urlList = $event
                  },
                  "update:tags": function($event) {
                    return _vm.$set(_vm.previewInfo, "labelList", $event)
                  },
                  "update:newsId": function($event) {
                    _vm.newsId = $event
                  },
                  "update:news-id": function($event) {
                    _vm.newsId = $event
                  }
                }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }