<template>
  <!-- 用户详情 -->
  <el-dialog
    title="用户详情"
    :close-on-click-modal="false"
    :visible.sync="visible" width="980px">
    <div style="padding: 20px; padding-top: 0px;">
      <el-form :model="dataForm" label-width="auto">
        <h2>基本信息</h2>
        <div class="form_div_bac">
          <div >
            <el-form-item label="企业图片">
              <viewer v-if="$companyIcon+dataForm.icon" >
                <img :src="$pathPrefix+$companyIcon+dataForm.icon" style="width: 60px; height: 60px; object-fit: cover;"/>
              </viewer>
            </el-form-item>
            <el-form-item label="企业ID"> <div class="mes_div">{{ dataForm.id }}</div></el-form-item>
            <el-form-item label="企业名称"> <div class="mes_div">{{ dataForm.name }}</div></el-form-item>
            <el-form-item label="企业英文名称"> <div class="mes_div">{{ dataForm.nameEn }}</div></el-form-item>
          </div>
          <div >
            <el-form-item label="企业类型">
              <div class="mes_div">{{dataForm.type | stateFilter(companyType)}}</div>
            </el-form-item>
            <el-form-item label="成立时间">
              <div class="mes_div">{{ dataForm.createTime | showDate()}}</div>
            </el-form-item>
            <el-form-item label="状态"> 
              <div class="mes_div">{{dataForm.state | stateFilter(companyStateDisct)}}</div>
            </el-form-item>
            <el-form-item label="主营业务">
              <div class="mes_div">{{ dataForm.channelStr}}</div>
            </el-form-item>
            <el-form-item label="权重"> <div class="mes_div">{{ dataForm.sort }}</div></el-form-item>
          </div>
        </div>
        <el-form-item label="官网地址"> 
          <span @click="openUrl(dataForm.officialUrl)" class="official_url">{{ dataForm.officialUrl }}</span>
        </el-form-item>
        <el-form-item label="公司简介"> 
          <div class="company_content" v-html="dataForm.content"></div>
        </el-form-item>
        <div class="fen_ge_xian"></div>
        <h2>联系方式</h2>
        <div class="form_div_bac">
          <div class="lian_xi_bac">
            <span style="font-weight: 600;width: 80px;">Telegram</span>
            <div style="display: flex;align-items: center;">
              <img src="@/assets/img/tg_logo_img.png"/>
              <div class="mes_div">{{ dataForm.tgAccount}}</div>
            </div>
          </div>
        </div>
        <div class="form_div_bac">
          <div class="lian_xi_bac">
            <span style="font-weight: 600;width: 80px;">WhatsApp</span>
            <div style="display: flex;align-items: center;">
              <img src="@/assets/img/phone_logo_img.png"/>
              <div class="mes_div">{{ dataForm.whatsAccount}}</div>
            </div>
          </div>
        </div>
        <div class="form_div_bac">
          <div class="lian_xi_bac">
            <span style="font-weight: 600;width: 80px;">Line</span>
            <div style="display: flex;align-items: center;">
              <img src="@/assets/img/line_logo_img.png"/>
              <div class="mes_div">{{ dataForm.lineAccount}}</div>
            </div>
          </div>
        </div>
        <div class="fen_ge_xian"></div>
        <h2>绑定资讯账号</h2>
        <div class="form_footer_bac" style="padding-bottom: 30px;">
          <div v-for="(userId, index) in dataForm.userAccoutlist" :key="index">
            <div class="tag_bac_div" >{{ userId }}</div>
          </div>
        </div>
        <h2>绑定招聘账号</h2>
        <div class="form_footer_bac">
          <div v-for="(userId, index) in dataForm.recruitAccoutlist" :key="index">
            <div class="tag_bac_div" >{{ userId }}</div>
          </div>
        </div>
      </el-form>
    </div>
  
  </el-dialog>
</template>

<script>
import { companyDetails } from '@/api/userManage'
import { utcUpdatedDate } from '@/utils'
import { companyStateDisctE, companyTypeE } from '@/utils/disct.js'
export default {
  data () {
    return {
      companyStateDisct: companyStateDisctE,
      companyType: companyTypeE,
      visible: false,
      companyId: null,
      dataForm: {
        id: '',// 公司id
        name: "",   // 企业名称
        nameEn: "",// 公司名称-英文
        state: "",// 状态（1：上架，2：下架）
        createTime: "",// 	成立时间
        type: "",// 公司类型(1: 普通企业， 2：独角兽)
        channelList: [],// 	主营业务
        icon: "",// 	图片
        officialUrl: "",// 	官网链接
        sort: "",// 权重
        content: "",// 	公司简介
        tgAccount: "",// 联系方式-Telegram
        whatsAccount: "",// 联系方式-WhatsApp
        lineAccount: '',// line
        userAccoutlist: [],// 	绑定企业资质账号
        recruitAccoutlist: [],// 	绑定企业招聘账号
      },
    }
  },
  filters:{
    stateFilter(state, stateDisct) {
      if(!state) return ''
      let item = stateDisct.find( item => item.value == state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
  },
  mounted () {
  },
  methods: {
    // 初始化
    init (id) {
      this.companyId = id
      this.visible = true
      this.dataForm = {}
      this.$openLoading()
      companyDetails( {id: this.companyId} ).then((res) => {
        this.$closeLoading()
        this.dataForm = res.data
        this.dataForm.channelStr = ''  // 主营业务
        if(this.dataForm.channelList.length > 0){
          this.dataForm.channelList.forEach( (channel, index) => {
            this.dataForm.channelStr += this.dataForm.channelList.length != index+1 ? channel.name+' / ' : channel.name
          })
        }
      }).catch((error) => {
        this.$closeLoading()
        this.$message.error(error);
      })
    },
    openUrl(url){
      window.open(url, '_blank');
    }
  }
}
</script>
<style lang="scss" scoped>
.form_div_bac{
  width: 100%;
  display: flex;
  justify-content: space-between;
  .lian_xi_bac{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .lian_xi_check{
      width: 100px;
    }
    img{
      width: 24px;
      display: block;
      margin: 0 10px;
    }
  }
}
.form_footer_bac{
  width: 100%;
  display: flex;
  align-items: center;
  .lian_xi_bac{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .lian_xi_check{
      width: 100px;
    }
    img{
      width: 24px;
      display: block;
      margin: 0 10px;
    }
  }
  .tag_bac_div{
    padding: 8px 16px;
    border-radius: 4px;
    background-color: rgba(32, 108, 255, 0.08);
    color: #206CFF;
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
  }
}
.mes_div{
  width: 300px;
  min-height: 32px;
  line-height: 32px;
  border: 1px solid #DCDFE6;
  background-color: #FFF;
  padding: 0 15px;
  border-radius: 4px;
  color: #84909D;
}
.mes_tags_div{
  min-height: 32px;
  line-height: 32px;
  padding: 0 15px;
  background-color: #FFF;
  border: 1px solid #DCDFE6;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
}
.fen_ge_xian{
  width: 100%;
  height: 2px;
  background: #9A9FA6;
  border-radius: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
}
h2{
  margin-bottom: 34px;
  color: #000000;
}
.el-form-item {
  margin-bottom: 10px !important;
}
.official_url{
  color: #206CFF;
  text-decoration: underline;
  cursor: pointer;
}
</style>
<style scoped>
.company_content >>> img{
  max-width: 100% !important;
}
</style>

