var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "com_mes_bac" },
    [
      _vm.showRele && _vm.placement == "top"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "outside",
                  rawName: "v-outside",
                  value: _vm.closePopup,
                  expression: "closePopup"
                }
              ],
              staticClass: "com_relevance_bac com_relevance_top",
              attrs: { id: "comListId" }
            },
            [
              _vm.comData.list.length > 0
                ? [
                    _vm._l(_vm.comData.list, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "com_relevance_card",
                          style: {
                            background:
                              _vm.mouseoverId == item.id ||
                              _vm.selectInfo.id == item.id
                                ? "#F2F3F5"
                                : "#FFF"
                          },
                          on: {
                            mouseover: function($event) {
                              _vm.mouseoverId = item.id
                            },
                            mouseout: function($event) {
                              _vm.mouseoverId = ""
                            },
                            click: function($event) {
                              return _vm.addcomFun(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    }),
                    _vm.comData.pageNum >= _vm.comData.total && !_vm.getLoading
                      ? _c("div", { staticClass: "bot_mes_text" }, [
                          _vm._v("没有更多了...")
                        ])
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.comData.list.length == 0 && !_vm.getLoading
                ? _c("div", { staticClass: "bot_mes_text" }, [_vm._v("无数据")])
                : _vm._e(),
              _vm.getLoading
                ? _c("div", { staticClass: "bot_mes_text" }, [
                    _c("i", { staticClass: "el-icon-loading" }),
                    _vm._v(" 加载中 ")
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-input",
        {
          class: _vm.selectInfo.title ? "zi_ding_input" : "",
          attrs: {
            id: "inputcomId" + _vm.$props.inputCompanyId,
            placeholder: _vm.selectInfo.title
              ? _vm.selectInfo.title
              : _vm.placeholder
          },
          on: {
            focus: _vm.focusFun,
            blur: _vm.blurFun,
            input: function($event) {
              return _vm.comRemoteFun()
            }
          },
          model: {
            value: _vm.comInput,
            callback: function($$v) {
              _vm.comInput = $$v
            },
            expression: "comInput"
          }
        },
        [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectInfo.title,
                expression: "selectInfo.title"
              }
            ],
            staticClass: "el-input__icon el-icon-error zi_input_icon",
            attrs: { slot: "suffix" },
            on: {
              click: function($event) {
                return _vm.inputClearFun()
              }
            },
            slot: "suffix"
          })
        ]
      ),
      _vm.showRele && _vm.placement == "bottom"
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "outside",
                  rawName: "v-outside",
                  value: _vm.closePopup,
                  expression: "closePopup"
                }
              ],
              staticClass: "com_relevance_bac com_relevance_bot",
              attrs: { id: "comListId" }
            },
            [
              _vm.comData.list.length > 0
                ? [
                    _vm._l(_vm.comData.list, function(item) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "com_relevance_card",
                          style: {
                            background:
                              _vm.mouseoverId == item.id ||
                              _vm.selectInfo.id == item.id
                                ? "#F2F3F5"
                                : "#FFF"
                          },
                          on: {
                            mouseover: function($event) {
                              _vm.mouseoverId = item.id
                            },
                            mouseout: function($event) {
                              _vm.mouseoverId = ""
                            },
                            click: function($event) {
                              return _vm.addcomFun(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      )
                    }),
                    _vm.comData.pageNum >= _vm.comData.total && !_vm.getLoading
                      ? _c("div", { staticClass: "bot_mes_text" }, [
                          _vm._v("没有更多了...")
                        ])
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.comData.list.length == 0 && !_vm.getLoading
                ? _c("div", { staticClass: "bot_mes_text" }, [_vm._v("无数据")])
                : _vm._e(),
              _vm.getLoading
                ? _c("div", { staticClass: "bot_mes_text" }, [
                    _c("i", { staticClass: "el-icon-loading" }),
                    _vm._v(" 加载中 ")
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }