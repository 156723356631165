import { bySystem } from '@/api/utilsManage'
import { getUUIDName } from './index.js'
import OSS from 'ali-oss'
import axios from 'axios';
import config from '@/config'

//替换域名 
function replaceDoamin(url, domain) {
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/:\n\r]+)/;
  const match = url.match(regex);
  if (match) {
    return url.replace(match[1], domain);
  }
  return url
}
// 上传本地
function ossUpload(file, namePrefix='user/avatar/') {
  return new Promise((resolve, reject) => {
    bySystem({}).then( async ( res ) => {
      if(res.data.success){
        const client = new OSS({
          region: res.data.data.Region,
          accessKeyId: res.data.data.AccessKeyId,
          accessKeySecret: res.data.data.AccessKeySecret,
          stsToken: res.data.data.SecurityToken,
          bucket: res.data.data.BucketName,
          cname: true,
          endpoint: config.appOssBaseUrl
        });
        try {
          // 这里可以根据需要自定义上传的文件名
          let lastName = file.name.slice(file.name.lastIndexOf(".") + 1)
          const fileName = namePrefix + getUUIDName() +'.'+ lastName
          // const fileName = namePrefix + file.name
          const result = await client.put(fileName, file)
          let url = replaceDoamin(result.url, config.appOssBaseUrl);
          console.log("请求oss路径======", url)
          resolve(url)
        } catch (error) {
          reject(error.message)
        }
      }else{
        reject(res.statusText)
      }
    })
  })
}
// 上传本地自定义名
function ossUploadZiName(file, namePrefix='user/avatar/') {
  return new Promise((resolve, reject) => {
    bySystem({}).then( async ( res ) => {
      if(res.data.success){
        const client = new OSS({
          region: res.data.data.Region,
          accessKeyId: res.data.data.AccessKeyId,
          accessKeySecret: res.data.data.AccessKeySecret,
          stsToken: res.data.data.SecurityToken,
          bucket: res.data.data.BucketName,
          cname: true,
          endpoint: config.appOssBaseUrl
        });
        try {
          // 这里可以根据需要自定义上传的文件名
          const fileName = namePrefix
          // const fileName = namePrefix + file.name
          const result = await client.put(fileName, file)
          let url = replaceDoamin(result.url, config.appOssBaseUrl);
          console.log("请求oss路径======", url)
          resolve(url)
        } catch (error) {
          reject(error.message)
        }
      }else{
        reject(res.statusText)
      }
    })
  })
}

// 上传网络图片
function ossUploadStream(image, namePrefix='user/avatar/', bool) {
  return new Promise((resolve, reject) => {
    bySystem({}).then( async ( res ) => {
      if(res.data.success){
        const client = new OSS({
          region: res.data.data.Region,
          accessKeyId: res.data.data.AccessKeyId,
          accessKeySecret: res.data.data.AccessKeySecret,
          stsToken: res.data.data.SecurityToken,
          bucket: res.data.data.BucketName,
          cname: true,
          endpoint: config.appOssBaseUrl
        });
        try {
          if (!bool) {
            image = getPathWithoutQuery(image)
          }
          const filename = namePrefix + getUUIDName()
          const response  = await axios.get(image, { responseType: 'blob' })
          const result = await client.put(filename, response.data);
          let url = replaceDoamin(result.url, config.appOssBaseUrl);
          console.log("请求oss路径======", url)
          resolve(url)
        } catch (error) {
          reject(error.message)
        }
      }else{
        console.log("请求oss参数失败",res.statusText)
        reject(res.statusText)
      }
    })
  })
}


// 上传base64图片
function ossUploadBase(base64Data, namePrefix='user/avatar/') {
  return new Promise((resolve, reject) => {
    bySystem({}).then( async ( res ) => {
      if(res.data.success){
        const client = new OSS({
          region: res.data.data.Region,
          accessKeyId: res.data.data.AccessKeyId,
          accessKeySecret: res.data.data.AccessKeySecret,
          stsToken: res.data.data.SecurityToken,
          bucket: res.data.data.BucketName,
          cname: true,
          endpoint: config.appOssBaseUrl
        });
        try {
          const filename = namePrefix + getUUIDName()
          let blob = base64ToBlob(base64Data);
          const result = await client.put(filename, blob)
          let url = replaceDoamin(result.url, config.appOssBaseUrl);
          console.log("请求oss路径======", url)
          resolve(url)
        } catch (error) {
          // console.error("错误名称:", error.name);
          // console.error("错误信息:", error.message);
          // console.error("堆栈跟踪:", error.stack);
          reject(error.message)
        }
      }else{
        console.log("请求oss参数失败",res.statusText)
        reject(res.statusText)
      }
    })
  })
}

// 上传本地字幕文件
function ossUploadSubtitle(file, namePrefix) {
  return new Promise((resolve, reject) => {
    bySystem({}).then( async ( res ) => {
      if(res.data.success){
        const client = new OSS({
          region: res.data.data.Region,
          accessKeyId: res.data.data.AccessKeyId,
          accessKeySecret: res.data.data.AccessKeySecret,
          stsToken: res.data.data.SecurityToken,
          bucket: res.data.data.BucketName,
          cname: true,
          endpoint: config.appOssBaseUrl
        });
        try {
          // 这里可以根据需要自定义上传的文件名
          const fileName = namePrefix
          const result = await client.put(fileName, file)
          console.log("请求oss路径======", result.url)
          resolve(result.url)
        } catch (error) {
          reject(error.message)
        }
      }else{
        reject(res.statusText)
      }
    })
  })
}


// 获取oss的client对象
function getOssClient() {
  return new Promise((resolve, reject) => {
    bySystem({}).then( async ( res ) => {
      if(res.data.success){
        const client = new OSS({
          region: res.data.data.Region,
          accessKeyId: res.data.data.AccessKeyId,
          accessKeySecret: res.data.data.AccessKeySecret,
          stsToken: res.data.data.SecurityToken,
          bucket: res.data.data.BucketName,
          cname: true,
          endpoint: config.appOssBaseUrl
        });
        resolve(client)
      }else{
        reject(res.statusText)
      }
    })
  })
}

// 去掉有些网络图片路径有?后面的参数
function getPathWithoutQuery(path) {
  // 使用split分割字符串，以'?'为分隔符，并获取第一个部分
  const parts = path.split('?');
  // 如果数组长度大于1，说明有问号，返回问号之前的内容
  if (parts.length > 1) {
    return parts[0];
  }
  // 否则，直接返回原路径
  return path;
}
//  将base64字符串转换为Blobd对象
function base64ToBlob(base64Data) {
  var arr = base64Data.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while(n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type: mime});
}

export { 
  ossUpload,
  ossUploadZiName,
  ossUploadStream,
  ossUploadBase,
  replaceDoamin,
  ossUploadSubtitle,
  getOssClient
};