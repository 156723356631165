<template>
  <!-- 视频文章模型组件 -->
  <div class="model_bac">
    <div class="model_bac_div" :style="{
      height: `${height}px`,
      width: `${height * (375 / 667)}px`,
    }">
      <div id="modelId"
        :style="{
        boxSizing: 'border-box',
        flexShrink: 0,
        width: '375px',
        height: '667px',
        paddingBottom: '60px',
        overflow: 'auto',
        transform: `scale(${height / 667})`,
      }">
        <video-two-com :videoSrc="$pathPrefix+videoUrl" :subtitles-src="subtitleUrl"
          :showPlay="true" :videocover="$pathPrefix+coverUrl" width="100%" height="200"/>
        <div style="padding: 0px 12px">
          <el-tabs v-model="activeName">
            <el-tab-pane label="简介" name="1">
              <!-- 用户头部信息 -->
              <div class="user_con_bac" >
                <div class="user_con_div">
                  <img :src="$pathPrefix+headPic" class="user_avatar" v-if="headPic"/>
                  <img :src="$pathPrefix+$systemAvatar" class="user_avatar" v-else/>
                  <div>
                    <div class="user_nickname">{{ nickname }}</div>
                    <div class="user_date">{{ date | showDate() }}</div>
                  </div>
                </div>
                <div class="attention_but_no">+ 关注</div>
              </div>
              <div class="title_div">{{ title }}</div>
              <div class="content_div">{{ html  }}</div>
              <!-- 标签 -->
              <div class="tag_bac" v-if="currentModule != 7">
                <div class="tag_div" v-for="(item, index) in tags">#  {{ item.name }}</div>
              </div>
              <!-- 招聘公司信息 -->
              <div class="company_div_bac" v-if="company.id" @click="tryToOpenAppCompany(company.id)">
                <img :src="$pathPrefix+'index/unicorn/company/icon/'+company.icon" class="company_avatar"/>
                <div class="company_con_bac">
                  <div class="company_con_name">{{ company.name }}</div>
                  <div class="company_con_des">{{ company.title }}</div>
                </div>
                <img src="@/assets/img/company_biao_icon.png" class="company_biao"/>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="'评论 '+rowsCount" name="2">
              <!-- 评论区 -->
              <template v-if="newsId">
                <div class="comment_con_bac" v-for="(item, index) in commentList">
                  <div class="comment_user_bac">
                    <div class="comment_user_left">
                      <div class="comment_avatar_div" v-if="item.disAccount.userInfo.headPic">
                        <img :src="$pathPrefix+item.disAccount.userInfo.headPic" class="comment_user_avatar"/>
                      </div>
                      <div class="comment_avatar_div" v-else><img :src="$pathPrefix+$systemAvatar" class="comment_user_avatar"/></div>
                      <div class="comment_user_nickname">{{ item.disAccount.userInfo.nickname }}</div>
                    </div>
                    <img src="@/assets/img/diandiandian_icon.png" class="comment_user_right"/>
                  </div>
                  <div class="comment_con_div">
                    <div class="comment_con_text">
                      {{ item.content }}
                    </div>
                    <div class="comment_bot_bac">
                      <div>
                        {{  item.createTime | commShowDate() }} 
                        <span style="font-size: 10px;"> · </span> 
                        {{ item.ipCountryName }} 
                      </div>
                      <div class="comment_bot_right">
                        {{ item.likeNum }}
                        <img src="@/assets/img/xinxin_no_icon.png" class="comment_xinno_icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="comm_footer_text" v-if="getLoading">加载中...</div>
                <template v-else>
                  <div class="comm_footer_text" v-if="pageIndex+1 >= totalPages">- THE END -</div>
                  <div class="comm_footer_text" style="cursor: pointer;" v-else @click="comPageFun()">
                    查看更多内容 
                    <i class="el-icon-arrow-right" style="font-size: 16px;color: #86909C;"></i>
                  </div>
                </template>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { discussListDiscuss } from '@/api/utilsManage'
import { utcUpdatedDate, getCurrentZoneTimeStr, setStyleFun, removeStyleFun } from '@/utils'
import htmlHandler from '@/mixin/htmlHandler';
export default {
  name: 'articleVideoModel',
  mixins: [htmlHandler],
  props: {
    height: { type: Number, default: 667 },
    currentModule:{
      type: Number,
      default: 1
    },
    newsId:{
      type: String,
      default: ''
    },
    videoUrl:{
      type: String,
      default: ''
    },
    coverUrl:{
      type: String,
      default: ''
    },
    title:{
      type: String,
      default: ''
    },
    html:{
      type: String,
      default: ``
    },
    headPic:{
      type: String,
      default: ''
    },
    nickname:{
      type: String,
      default: ''
    },
    date:{
      type: String,
      default: ''
    },
    // 标签列表
    tags: {
      type: Array,
      default (){
        return [ ]
      }
    },
    // 招聘公司列表
    company: {
      type: Object,
      default (){
        return {
          icon: "",
          id: "",
          name: "",
          title: ""
        }
      }
    },
  },
  filters:{
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象1
        return utcUpdatedDate(value)
      }
    },
    commShowDate(value){
      if (value) {
        // 将时间戳转换成date对象1
        return getCurrentZoneTimeStr(value)
      }
    }
  },
  data () {
    return {
      activeName: '1',
      subtitleUrl: '',// set字幕文件
      styleHref: '',
      imageArr: this.$props.urlList,
      pageIndex: 0,
      commentList: [],
      totalPages: 0,
      rowsCount: 0,
      getLoading: false,
    }
  },
  watch : {
		'urlList.length' : function(val){
      if(val > 0){
        this.imageArr = this.$props.urlList
      }else{
        this.imageArr = []
      }
		},
    
	},
  mounted () {
    // 注入样式
    this.styleHref = setStyleFun('.con_html', this.$props.currentModule)
    if(this.$props.newsId){
      this.comRemoteFun()
    }
  },
  beforeDestroy() {
    // 组件销毁前清理定时器
    removeStyleFun(this.styleHref)
  },
  methods: {

    // 初始化获取列表
    comRemoteFun() {
      this.pageIndex = 0
      this.totalPages = 0
      let data = {
        newsId: this.$props.newsId,
        pagerInfo:{
          pageIndex: this.pageIndex,
          pageSize: 10
        }
      }
      this.getLoading = true
      discussListDiscuss(data).then(( res ) => {
        this.getLoading = false
        this.commentList = res.data.data.list
        this.totalPages = res.data.data.page.totalPages
        this.rowsCount = res.data.data.page.rowsCount
      }).catch((error) => {
        this.getLoading = false
      })
    },
    // 分页获取列表
    comPageFun() {
      if(this.pageIndex+1 == this.totalPages) return  // 没有更多数据了
      this.pageIndex += 1
      let data = {
        newsId: this.$props.newsId,
        pagerInfo:{
          pageIndex: this.pageIndex,
          pageSize: 10
        }
      }
      this.getLoading = true
      discussListDiscuss(data).then(( res ) => {
        this.getLoading = false
        this.commentList = this.commentList.concat(res.data.data.list)
        this.totalPages = res.data.data.page.totalPages
        this.rowsCount = res.data.data.page.rowsCount
      }).catch((error) => {
        this.getLoading = false
      })
    },
    // set字幕
    setSubtitle(url){
      console.log("字幕路径",url)
      if(!this.$props.videoUrl){
        this.$message('还没有可预览的视频!')
        return
      }
      if(url){
        if(url.slice(-2) == 'zh'){
          this.$message.success("正在使用中文字幕")
        }
        if(url.slice(-2) == 'en'){
          this.$message.success("正在使用英文字幕")
        }
      }else{
        this.$message.success("已取消使用字幕")
      }
      if(!url){
        this.subtitleUrl = ''
        return
      }
      this.subtitleUrl = url
    }

  }
}
</script>
<style scoped>
>>> .el-tabs__nav-wrap::after{
  position: static !important;
}
</style>
<style lang='scss' scoped>
.model_bac{
  border-radius: 8px;
  border: 4px solid #d5d5d8;
  background: #FFF;
}
.model_bac_div{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 375px;
  height: 667px;
  background: #FFF;
  >div {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.title_div{
  min-height: 26px;
  color: #1D2129;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 8px;
  word-wrap: break-word;
}
.content_div{
  color: #4E5969;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 164%; 
  word-wrap: break-word;
}
.content_div_show{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.user_con_bac{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .user_con_div{
    display: flex;
    align-items: center;
  }
  .user_avatar{
    width: 32px;
    height: 32px;
    border-radius: 100px;
    margin-right: 10px;
  }
  .user_nickname{
    color: #1D2129;
    font-family: "PingFang SC";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }
  .user_date{
    color: #86909C;
    font-family: "PingFang SC";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
  }
  .attention_but_no{
    width: 50px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    text-align: center;
    border-radius: 100px;
    background: rgba(32, 108, 255, 0.08);
    border: 1px solid rgba(32, 108, 255, 0.08);
    color: #206CFF;
    font-family: "PingFang SC";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
  }
}

.con_html{
  min-height: 300px;
  font-family: "PingFang SC" !important;
  word-wrap:break-word;
 	word-break:normal;
}
.con_html ::deep img{
  max-width: 100% !important;
}

.tag_bac{
  display: flex;
  align-items: center; /* 设置垂直间距 */
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 24px;
}
.tag_div{
  border-radius: 2px;
  background: #F7F8FA;
  color: #4E5969;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 4px 8px;
  margin: 0  8px  8px 0;
}



.comment_con_bac{
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
}
.comment_user_bac{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; 
}
.comment_user_left{
  display: flex;
  align-items: center; 
}
.comment_avatar_div{
  width: 32px;
}
.comment_user_avatar{
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 22px;
}
.comment_user_nickname{
  color: #7D7D7D;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.comment_user_right{
  width: 16px;
  height: 16px;
  display: block;
}
.comment_con_div{
  margin-left: 32px;
  width: calc(100% - 32px)
}
.comment_con_text{
  color:  #1D2129;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 8px 0px;
}
.comment_bot_bac{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #86909C;
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.comment_bot_right{
  display: flex;
  align-items: center;
}
.comment_bot_hui{
  padding: 4px 10px;
  margin-right: 12px;
  border-radius: 100px;
  background: #F5F5F5;
  color: #1D2129;
  font-family: "PingFang SC";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}
.comment_xinno_icon{
  width: 16px;
  height: 16px;
  display: block;
  margin-left: 4px;
}

.cover_img_bac{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}
.cover_img_class{
  width: calc((375px - 54px) / 3);
  height: calc((375px - 54px) / 3);
  border-radius: 5px;
  object-fit: cover;
}
.company_div_bac{
  position: relative;
  width: calc(100% - 24px);
  padding: 12px;
  background: #F3F4FB;
  display: flex;
}
.company_avatar{
  width: 46px;
  height: 46px;
  display: block;
  margin-right: 12px;
}
.company_con_bac{
  padding: 3px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
.company_con_name{
  width: 80%;
  color: #1D2129;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.company_con_des{
  width: 80%;
  color: #4E5969;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
}
.company_biao{
  width: 52px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.comm_num_title{
  display: flex;
  align-items: center;
  color: #1D2129;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  margin: 24px 0;
  img{
    width: 24px;
    height: 24px;
    display: block;
  }
  span{
    margin-left: 5px;
  }
}
.comm_footer_text{
  color: #86909C;
  font-family: "PingFang SC";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line_div{
  height: 1px;
  border: 1px solid #F2F3F5;
}
</style>
<style scoped>
.con_html >>> img{
  max-width: 100% !important;
}
</style>