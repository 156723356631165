var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "svg",
      _vm._g(
        {
          class: _vm.svgClass,
          attrs: {
            "aria-hidden": "true",
            color: _vm.color,
            width: _vm.size + "px",
            height: _vm.size + "px"
          }
        },
        _vm.$listeners
      ),
      [_c("use", { attrs: { "xlink:href": _vm.iconName } })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }