var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page_bac_div" },
    [
      _c(
        "div",
        {
          staticClass: "form_div_bac",
          style: { paddingRight: _vm.leftWidth + "px" }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "space-between"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "head_back",
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "el-icon-arrow-left",
                    staticStyle: { "font-size": "18px", "font-weight": "600" }
                  }),
                  _c("h4", [_vm._v("返回")])
                ]
              ),
              _vm.InfoId
                ? _c("h2", [_vm._v("审核")])
                : _c("h2", [
                    _vm._v(
                      "审核 " +
                        _vm._s(_vm.pageNumber) +
                        " / " +
                        _vm._s(_vm.total)
                    )
                  ])
            ]
          ),
          _c("h4", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v("审核信息")
          ]),
          _c(
            "el-form",
            {
              staticClass: "demo-newsInfo",
              attrs: { model: _vm.auditInfo, "label-width": "auto" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核状态", prop: "content" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.auditInfo.state,
                        callback: function($$v) {
                          _vm.$set(_vm.auditInfo, "state", $$v)
                        },
                        expression: "auditInfo.state"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1000 } }, [
                        _vm._v("待审核")
                      ]),
                      _c("el-radio", { attrs: { label: 1001 } }, [
                        _vm._v("通过")
                      ]),
                      _c("el-radio", { attrs: { label: 1004 } }, [
                        _vm._v("拒绝")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.auditInfo.state == 1004
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拒绝理由", prop: "content" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入拒绝理由",
                          maxlength: "100",
                          rows: "4"
                        },
                        model: {
                          value: _vm.auditInfo.refusalCause,
                          callback: function($$v) {
                            _vm.$set(_vm.auditInfo, "refusalCause", $$v)
                          },
                          expression: "auditInfo.refusalCause"
                        }
                      }),
                      !_vm.auditInfo.refusalCause && _vm.auditIsClick
                        ? _c("div", { staticClass: "mes_error_text" }, [
                            _vm._v("请输入拒绝内容")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              [1, 3, 9].includes(_vm.currentModule) &&
              _vm.auditInfo.state == 1001
                ? _c("el-form-item", { attrs: { label: "所属创作者" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("selectUser", {
                                staticStyle: { width: "300px" },
                                attrs: {
                                  id: _vm.auditInfo.authorId,
                                  nickName: _vm.auditInfo.nickName,
                                  headPic: _vm.auditInfo.headPic
                                },
                                on: {
                                  "update:id": function($event) {
                                    return _vm.$set(
                                      _vm.auditInfo,
                                      "authorId",
                                      $event
                                    )
                                  },
                                  "update:nickName": function($event) {
                                    return _vm.$set(
                                      _vm.auditInfo,
                                      "nickName",
                                      $event
                                    )
                                  },
                                  "update:nick-name": function($event) {
                                    return _vm.$set(
                                      _vm.auditInfo,
                                      "nickName",
                                      $event
                                    )
                                  },
                                  "update:headPic": function($event) {
                                    return _vm.$set(
                                      _vm.auditInfo,
                                      "headPic",
                                      $event
                                    )
                                  },
                                  "update:head-pic": function($event) {
                                    return _vm.$set(
                                      _vm.auditInfo,
                                      "headPic",
                                      $event
                                    )
                                  },
                                  userChange: _vm.userChange
                                }
                              })
                            ],
                            1
                          ),
                          !_vm.auditInfo.authorId && _vm.auditIsClick
                            ? _c("div", { staticClass: "mes_error_text" }, [
                                _vm._v("请选择作者")
                              ])
                            : _vm._e()
                        ]),
                        [1, 9].includes(_vm.currentModule)
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v("企业：")]),
                                    _c("selectCompany", {
                                      staticStyle: { width: "350px" },
                                      attrs: {
                                        disabled: !_vm.auditInfo.authorId,
                                        placeholder: "关联独角兽公司",
                                        id: _vm.auditInfo.companyId,
                                        name: _vm.auditInfo.companyName
                                      },
                                      on: {
                                        "update:id": function($event) {
                                          return _vm.$set(
                                            _vm.auditInfo,
                                            "companyId",
                                            $event
                                          )
                                        },
                                        "update:name": function($event) {
                                          return _vm.$set(
                                            _vm.auditInfo,
                                            "companyName",
                                            $event
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.auditInfo.sourceType == 4 &&
                                !_vm.auditInfo.companyId &&
                                _vm.auditIsClick
                                  ? _c(
                                      "div",
                                      { staticClass: "mes_error_text" },
                                      [_vm._v("请选择公司")]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e(),
              _vm.auditInfo.state == 1001
                ? _c(
                    "el-form-item",
                    { attrs: { label: "发布时间", prop: "createTime" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled: _vm.olState == _vm.auditInfo.state
                              },
                              model: {
                                value: _vm.auditInfo.radio,
                                callback: function($$v) {
                                  _vm.$set(_vm.auditInfo, "radio", $$v)
                                },
                                expression: "auditInfo.radio"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("立即发布")
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("定时发布")
                              ])
                            ],
                            1
                          ),
                          _c("el-date-picker", {
                            staticStyle: { "margin-left": "16px" },
                            attrs: {
                              disabled:
                                _vm.auditInfo.radio == 1 ||
                                _vm.olState == _vm.auditInfo.state,
                              type: "datetime",
                              placeholder: "选择定时发布时间",
                              "default-time": "12:00:00",
                              "picker-options": _vm.pickerOptions()
                            },
                            model: {
                              value: _vm.auditInfo.preTime,
                              callback: function($$v) {
                                _vm.$set(_vm.auditInfo, "preTime", $$v)
                              },
                              expression: "auditInfo.preTime"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.auditInfo.radio == 2 &&
                      !_vm.auditInfo.preTime &&
                      _vm.auditIsClick
                        ? _c("div", { staticClass: "mes_error_text" }, [
                            _vm._v("请选择定时发布时间")
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              [1, 9].includes(_vm.currentModule) && _vm.auditInfo.state == 1001
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "关联展会", prop: "content" } },
                      [
                        _vm.isLoanding
                          ? _c("selectExCom", {
                              attrs: {
                                exList: _vm.auditInfo.exhibitionsSet,
                                inputId: "12"
                              },
                              on: {
                                "update:exList": function($event) {
                                  return _vm.$set(
                                    _vm.auditInfo,
                                    "exhibitionsSet",
                                    $event
                                  )
                                },
                                "update:ex-list": function($event) {
                                  return _vm.$set(
                                    _vm.auditInfo,
                                    "exhibitionsSet",
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "关联专题&合集", prop: "content" } },
                      [
                        _vm.isLoanding
                          ? _c("selectSpCom", {
                              attrs: {
                                infoId: _vm.newsInfo.id,
                                spList: _vm.auditInfo.collectionsSet,
                                inputId: "13"
                              },
                              on: {
                                "update:spList": function($event) {
                                  return _vm.$set(
                                    _vm.auditInfo,
                                    "collectionsSet",
                                    $event
                                  )
                                },
                                "update:sp-list": function($event) {
                                  return _vm.$set(
                                    _vm.auditInfo,
                                    "collectionsSet",
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "100",
                      rows: "4",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.auditInfo.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.auditInfo, "remark", $$v)
                      },
                      expression: "auditInfo.remark"
                    }
                  })
                ],
                1
              )
            ],
            2
          ),
          _c("div", { staticClass: "audit_footer_div" }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      { name: "preventReClick", rawName: "v-preventReClick" }
                    ],
                    attrs: {
                      type: "primary",
                      disabled:
                        _vm.olState == _vm.auditInfo.state &&
                        _vm.auditInfo.state != 1001 &&
                        _vm.auditInfo.state != 1000
                    },
                    on: {
                      click: function($event) {
                        return _vm.submitAuditForm()
                      }
                    }
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "success" },
                    on: {
                      click: function($event) {
                        return _vm.openEdit()
                      }
                    }
                  },
                  [_vm._v("编辑")]
                )
              ],
              1
            ),
            !_vm.InfoId
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "preventReClick",
                            rawName: "v-preventReClick"
                          }
                        ],
                        attrs: {
                          type: "primary",
                          disabled: _vm.pageNumber == 1
                        },
                        on: {
                          click: function($event) {
                            return _vm.goPageFun(_vm.pageNumber - 1)
                          }
                        }
                      },
                      [_vm._v("上一篇")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "preventReClick",
                            rawName: "v-preventReClick"
                          }
                        ],
                        attrs: {
                          type: "primary",
                          disabled: _vm.pageNumber >= _vm.total
                        },
                        on: {
                          click: function($event) {
                            return _vm.goPageFun(_vm.pageNumber + 1)
                          }
                        }
                      },
                      [_vm._v("下一篇")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("div", { staticClass: "fen_ge_xian" }),
          _c("h4", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v("基本信息")
          ]),
          _c(
            "el-form",
            {
              ref: "newsInfo",
              staticClass: "demo-newsInfo",
              attrs: { model: _vm.newsInfo, "label-width": "auto" }
            },
            [
              _c("el-form-item", { attrs: { label: "所属模块" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("stateFilter")(_vm.currentModule, _vm.moduleDisct)
                  )
                )
              ]),
              [1, 3].includes(_vm.currentModule) &&
              _vm.auditInfo.sourceType == 3
                ? _c("el-form-item", { attrs: { label: "采集来源" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("stateSystemFilter")(
                            _vm.newsInfo.crawlerChannel,
                            _vm.crawlerDisct
                          )
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.auditInfo.sourceType == 3 && _vm.currentModule == 1
                ? _c("el-form-item", { attrs: { label: "采集时间" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("showDate")(_vm.newsInfo.collectTime)) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("el-form-item", { attrs: { label: "创建时间" } }, [
                _vm._v(_vm._s(_vm._f("showDate")(_vm.newsInfo.createTime)))
              ]),
              _vm.newsInfo.preTime
                ? _c("el-form-item", { attrs: { label: "定时时间" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("showDate")(_vm.newsInfo.preTime)) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.newsInfo.lastTime
                ? _c("el-form-item", { attrs: { label: "发布时间" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("showDate")(_vm.newsInfo.lastTime)) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.currentModule != 2
                ? _c("el-form-item", { attrs: { label: "所属创作者" } }, [
                    _vm._v(_vm._s(_vm.newsInfo.nickName))
                  ])
                : _vm._e(),
              [1, 4].includes(_vm.auditInfo.sourceType)
                ? _c("el-form-item", { attrs: { label: "发布端口" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("stateFilter")(_vm.newsInfo.rq, _vm.rqDisct)
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.currentModule == 7
                ? _c("el-form-item", { attrs: { label: "招聘企业" } }, [
                    _vm._v(_vm._s(_vm.newsInfo.companyName))
                  ])
                : _vm._e(),
              [1, 3, 9].includes(_vm.currentModule)
                ? [
                    _c("el-form-item", { attrs: { label: "所属目标市场" } }, [
                      _c(
                        "div",
                        { staticClass: "tag_list_bac" },
                        _vm._l(_vm.newsInfo.countryList, function(item, index) {
                          return _c(
                            "div",
                            { key: item.index, staticClass: "tag_bac_div" },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        0
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "所属业务或分类" } }, [
                      _c(
                        "div",
                        { staticClass: "tag_list_bac" },
                        [
                          _vm._l(_vm.newsInfo.cateList, function(item, index) {
                            return _c(
                              "div",
                              { key: item.index, staticClass: "tag_bac_div" },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          _vm._l(_vm.newsInfo.channelList, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: item.index, staticClass: "tag_bac_div" },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ]
                : _vm._e(),
              _vm.currentModule != 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [_vm._v(" " + _vm._s(_vm.newsInfo.title) + " ")]
                  )
                : _vm._e(),
              _vm.currentModule == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "封面" } },
                    [
                      _c(
                        "viewer",
                        {
                          attrs: {
                            images: [_vm.$pathPrefix + _vm.newsInfo.cover]
                          }
                        },
                        [
                          _vm.newsInfo.cover
                            ? _c("img", {
                                staticStyle: {
                                  width: "80px",
                                  height: "80px",
                                  display: "block",
                                  "object-fit": "cover"
                                },
                                attrs: {
                                  src: _vm.$pathPrefix + _vm.newsInfo.cover
                                }
                              })
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentModule != 9
                ? _c("el-form-item", { attrs: { label: "正文内容" } }, [
                    _c("div", {
                      staticClass: "con_div_bac",
                      domProps: { innerHTML: _vm._s(_vm.newsInfo.content) }
                    })
                  ])
                : _vm._e(),
              _vm.currentModule == 3
                ? _c("el-form-item", { attrs: { label: "正文图片" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      _vm._l(_vm.urlList, function(url) {
                        return _c("img", {
                          key: url,
                          staticStyle: {
                            width: "80px",
                            height: "80px",
                            display: "block",
                            "object-fit": "cover",
                            "margin-right": "10px"
                          },
                          attrs: { src: _vm.$pathPrefix + url }
                        })
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.currentModule == 9
                ? [
                    _c("el-form-item", { attrs: { label: "简介" } }, [
                      _vm._v(_vm._s(_vm.newsInfo.content))
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "封面" } },
                      [
                        _c(
                          "viewer",
                          {
                            attrs: {
                              images: [_vm.$pathPrefix + _vm.newsInfo.cover]
                            }
                          },
                          [
                            _vm.newsInfo.cover
                              ? _c("img", {
                                  staticStyle: {
                                    width: "80px",
                                    height: "80px",
                                    display: "block",
                                    "object-fit": "cover"
                                  },
                                  attrs: {
                                    src: _vm.$pathPrefix + _vm.newsInfo.cover
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    ),
                    _vm.newsInfo.videoInfo
                      ? [
                          _c("el-form-item", { attrs: { label: "中文字幕" } }, [
                            _vm.newsInfo.videoInfo.subtitleZh
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$pathPrefix +
                                          _vm.newsInfo.videoInfo.subtitleZh
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "subtitle_but",
                                      on: {
                                        click: function($event) {
                                          return _vm.returnUrl(
                                            _vm.$pathPrefix +
                                              _vm.newsInfo.videoInfo.subtitleZh
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("预览")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "subtitle_but",
                                      on: {
                                        click: function($event) {
                                          return _vm.returnUrl("")
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-form-item", { attrs: { label: "英文字幕" } }, [
                            _vm.newsInfo.videoInfo.subtitleEn
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$pathPrefix +
                                          _vm.newsInfo.videoInfo.subtitleEn
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "subtitle_but",
                                      on: {
                                        click: function($event) {
                                          return _vm.returnUrl(
                                            _vm.$pathPrefix +
                                              _vm.newsInfo.videoInfo.subtitleEn
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("预览")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "subtitle_but",
                                      on: {
                                        click: function($event) {
                                          return _vm.returnUrl("")
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      : _vm._e()
                  ]
                : _vm._e(),
              [1, 3, 9].includes(_vm.currentModule)
                ? _c("el-form-item", { attrs: { label: "选择标签" } }, [
                    _c(
                      "div",
                      { staticClass: "tag_list_bac" },
                      _vm._l(_vm.newsInfo.labelList, function(item, index) {
                        return _c(
                          "div",
                          { key: item.index, staticClass: "tag_bac_div" },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.currentModule != 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "权重排序", prop: "infoSort" } },
                    [_vm._v(" " + _vm._s(_vm.newsInfo.infoSort) + " ")]
                  )
                : _vm._e(),
              _vm.currentModule != 2
                ? _c("el-form-item", { attrs: { label: "权重有效时间" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("showDate")(_vm.newsInfo.infoSortExpire)
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "phone_righ_bac" },
        [
          _vm.currentModule == 9
            ? _c("articleVideoModel", {
                ref: "articleVideoModelRef",
                staticClass: "edit_model_com",
                attrs: {
                  height: Math.min(_vm.windowSize.height * 0.8, 667),
                  videoUrl: _vm.newsInfo.videoInfo.videoUrl,
                  html: _vm.newsInfo.content,
                  title: _vm.newsInfo.title,
                  tags: _vm.newsInfo.labelList,
                  currentModule: _vm.currentModule,
                  nickname: _vm.newsInfo.nickName,
                  headPic: _vm.newsInfo.headPic,
                  date: _vm.newsInfo.date,
                  newsId: _vm.InfoId
                },
                on: {
                  "update:videoUrl": function($event) {
                    return _vm.$set(_vm.newsInfo.videoInfo, "videoUrl", $event)
                  },
                  "update:video-url": function($event) {
                    return _vm.$set(_vm.newsInfo.videoInfo, "videoUrl", $event)
                  },
                  "update:html": function($event) {
                    return _vm.$set(_vm.newsInfo, "content", $event)
                  },
                  "update:title": function($event) {
                    return _vm.$set(_vm.newsInfo, "title", $event)
                  },
                  "update:tags": function($event) {
                    return _vm.$set(_vm.newsInfo, "labelList", $event)
                  },
                  "update:currentModule": function($event) {
                    _vm.currentModule = $event
                  },
                  "update:current-module": function($event) {
                    _vm.currentModule = $event
                  },
                  "update:nickname": function($event) {
                    return _vm.$set(_vm.newsInfo, "nickName", $event)
                  },
                  "update:headPic": function($event) {
                    return _vm.$set(_vm.newsInfo, "headPic", $event)
                  },
                  "update:head-pic": function($event) {
                    return _vm.$set(_vm.newsInfo, "headPic", $event)
                  },
                  "update:date": function($event) {
                    return _vm.$set(_vm.newsInfo, "date", $event)
                  }
                }
              })
            : _c("articleModel", {
                staticClass: "edit_model_com",
                attrs: {
                  newsId: _vm.InfoId,
                  height: Math.min(_vm.windowSize.height * 0.8, 667),
                  title: _vm.newsInfo.title,
                  nickname: _vm.auditInfo.nickName,
                  date: _vm.newsInfo.date,
                  html: _vm.newsInfo.content,
                  currentModule: _vm.currentModule,
                  urlList: _vm.urlList,
                  headPic: _vm.auditInfo.headPic,
                  tags: _vm.newsInfo.labelList,
                  company: _vm.company
                },
                on: {
                  "update:title": function($event) {
                    return _vm.$set(_vm.newsInfo, "title", $event)
                  },
                  "update:nickname": function($event) {
                    return _vm.$set(_vm.auditInfo, "nickName", $event)
                  },
                  "update:date": function($event) {
                    return _vm.$set(_vm.newsInfo, "date", $event)
                  },
                  "update:html": function($event) {
                    return _vm.$set(_vm.newsInfo, "content", $event)
                  },
                  "update:currentModule": function($event) {
                    _vm.currentModule = $event
                  },
                  "update:current-module": function($event) {
                    _vm.currentModule = $event
                  },
                  "update:urlList": function($event) {
                    _vm.urlList = $event
                  },
                  "update:url-list": function($event) {
                    _vm.urlList = $event
                  },
                  "update:tags": function($event) {
                    return _vm.$set(_vm.newsInfo, "labelList", $event)
                  }
                }
              })
        ],
        1
      ),
      _vm.currentModule != 9 && _vm.showEdit
        ? _c("editDiaCom", {
            ref: "editDiaComRef",
            attrs: { show: _vm.showEdit },
            on: {
              returnGetData: function($event) {
                return _vm.initUpdate()
              },
              "update:show": function($event) {
                _vm.showEdit = $event
              }
            }
          })
        : _vm._e(),
      _vm.currentModule == 9 && _vm.showEdit
        ? _c("videoEditDiaCom", {
            ref: "videoEditDiaComRef",
            attrs: { show: _vm.showEdit },
            on: {
              returnGetData: function($event) {
                return _vm.initUpdate()
              },
              "update:show": function($event) {
                _vm.showEdit = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }