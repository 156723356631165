<template>
  <div class="container">
    <div style="margin-bottom: 14px;">
      <el-radio-group v-model="advertisingTypeV" @change="getDataList()" size="mini">
        <el-radio-button :label="item.value" v-for="item in advertisingType" :key="item.value">{{ item.name }}</el-radio-button>
      </el-radio-group>
    </div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" style="padding: 10px 0;">
      <el-form-item label="标题">
        <el-input v-model="dataForm.search1" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="作者昵称">
        <el-input v-model="dataForm.search2" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="作者ID">
        <el-input v-model="dataForm.search3" size="mini" placeholder="请输入" clearable @clear="getDataList()"></el-input>
      </el-form-item>
      <el-form-item label="文章状态">
        <el-select v-model="dataForm.select1" size="mini" placeholder="请选择" clearable  @change="getDataList()">
          <el-option v-for="item in stateDisct" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="精选日期区间">
        <el-date-picker v-model="dataForm.createTime" type="daterange" @change="getDataList()"  size="mini"
          range-separator="-" start-placeholder="开始" end-placeholder="结束">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="精选日期">
          <el-date-picker 
            v-model="dataForm.groupByDate"
            type="date"
            placeholder="选择日期"
            @change="getDataList()">
          </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="openAdd()">新增精选文章</el-button>
        <!--后端接口查询时间长导致失败，先注释-->
        <!-- <el-button type="primary" plain icon="el-icon-download" size="small" @click="downLoadFile" >导出数据</el-button> -->
      </el-form-item>
    </el-form>

    <!--用户列表   height="500" v-adaptive="{bottomOffset: 50,iModal: false}"-->
    <el-table
      :data="dataList" border v-loading="dataListLoading"
      height="100" v-adaptive="{bottomOffset: 50,iModal: false}"
      :cell-style="tableStyle" :header-cell-style="tableStyle" style="width: 100%;">
      <el-table-column label="类型" width="100">
        <template slot-scope="scope">
          {{scope.row.isVideo ? '视频' : '资讯'}}
        </template>
      </el-table-column>
      <el-table-column prop="dailyShowTime" label="精选时间">
        <template slot-scope="scope">
          {{scope.row.dailyShowTime | showDate()}}
        </template>
      </el-table-column>
      <el-table-column prop="dailyId" label="ID"></el-table-column>
      <el-table-column prop="newsTitle" label="标题"> </el-table-column>
      <el-table-column prop="creatorId" label="创作者ID"> </el-table-column>
      <el-table-column prop="creatorName" label="创作者昵称"> </el-table-column>
      <el-table-column label="文章状态">
        <template slot-scope="scope">
          {{scope.row.newsState | stateFilter(stateDisct)}}
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="240" label="操作">
        <template slot-scope="scope">
          <div style="line-height:40px;justify-content: space-between;">
              <el-button type="success" plain size="small" @click="goAuditOrEdit(scope.row)">浏览</el-button>
              <el-button type="primary" size="mini" plain @click="interactFun(scope.row)">评论</el-button>
            <el-button type="warning" plain size="mini" @click="deleteFun(scope.row.dailyId)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- @size-change="sizeChangeHandle" layout="total, sizes, prev, pager, next, jumper"> -->
      <el-pagination 
        background
        @current-change="getDataList"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total,prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 新增精选 -->
    <el-dialog
      title="新增精选"
      :close-on-click-modal="false"
      :visible.sync="newsVisible" width="60vw">
      <el-form :model="ruleForm" label-width="auto" class="demo-ruleForm" style="min-height: 40vh;">
        <el-form-item label="选择时间" prop="type">
          <el-date-picker 
            v-model="ruleForm.preTime"
            type="date"
            placeholder="选择日期"
            @change="changeGetNews()">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择文章" prop="type">
          <selectNewsCom @refreshDataList="getDataList(pageIndex)"  inputId="2"
          v-if="newsVisible" :newsIds.sync="ruleForm.newsIds" :newsList.sync="selectNewsList"/>
        </el-form-item>
      </el-form> 
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        <el-button @click="newsVisible=false">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" 
        :disabled="ruleForm.newsIds.length==0 || !ruleForm.preTime"
          v-preventReClick>提交</el-button>
      </span>
    </el-dialog>
    <!-- 预览弹窗 -->
    <previewModel ref="previewModelRef"/>
    <!-- 评论点赞弹窗 -->
    <InteractDiaCom ref="InteractDiaComRef"/>
    <!-- 预览弹窗 -->
    <previewVideoModel ref="previewVideoModellRef"/>
    
  </div>
</template>

<script>
import { formatDateTime,utcUpdatedDate, dateUpdateUtc } from '@/utils'
import { dailyList, dailyListByDate, dailySetListByDate, dailyDelete } from '@/api/configManage'
import selectNewsCom from './selectNewsCom'
import InteractDiaCom from '../com/InteractDiaCom.vue'
import { newsStateDisct,advertisingTypeE} from '@/utils/disct.js'
export default {
  name: 'highlightsNews',
  data () {
    return {
      pageModule: 1,
      advertisingType: advertisingTypeE,
      stateDisct: newsStateDisct, // 审核状态（1000：等待审核；1001：审核通过；1003已被编辑过；1004：回收站）
      advertisingTypeV: 2,
      dataForm: {
        search1: '', //
        search2: '', //
        search3: '', //
        select1: '',
        createTime: [],//注册时间
        groupByDate: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      visibleTui: false,
      recommendList: [],
      infoId: 1,

      newsVisible: false,
      ruleForm: {
        preTime: '',
        newsIds: [],
      },
      selectNewsList: [],
    }
  },
  components: {
    selectNewsCom,
    InteractDiaCom
  },
  created () {
    
  },
  mounted () {
    this.getDataList()
  },
  filters:{
    stateFilter(state, stateDisct) {
      let item = stateDisct.find( item => item.value === state)
      return item ? item.name : '';
    },
    showDate (value) {
      if (value) {
        // 将时间戳转换成date对象
        return utcUpdatedDate(value)
      }
    },
    showCollege (value) {
      if (value && value.length>0) {
        let valueStr = ""
          for (const key in value) {
            valueStr = valueStr + value[key] + " ";
          }
        return valueStr
      }
    },
  },
  methods: {
    //列表内容居中
    tableStyle(){
        return "text-align:center"
    },
    // 条件禁用列
    checkSelectable(row){
      if (row.qualState == 0) {
        return true
      } else {
        return false
      }
    },
    // 获取数据列表
    getDataList (pageNum) {
      this.pageIndex = pageNum ? pageNum : 1
      this.dataListLoading = true
      let obj = {
        pageNum: this.pageIndex,
        pageSize: this.pageSize,
        platform: this.advertisingTypeV
      }
      if(this.dataForm.search1) obj.title = this.dataForm.search1.trim()
      if(this.dataForm.search2) obj.creatorName = this.dataForm.search2.trim()
      if(this.dataForm.search3) obj.creatorId = this.dataForm.search3.trim()
      if(this.dataForm.select1) obj.newsState = this.dataForm.select1
      //时间 
      console.log("区间",(this.dataForm.groupByDate + ' 00:00:00').replace(/\-/g, '/'))
      if(this.dataForm.createTime && this.dataForm.createTime.length > 0){
        obj.startDatetime = this.dataForm.createTime.length != 0 ? dateUpdateUtc(this.dataForm.createTime[0]) : null
        obj.endDatetime = this.dataForm.createTime.length != 0 ? dateUpdateUtc(this.dataForm.createTime[1]) : null
      }
      // 日期
      if(this.dataForm.groupByDate) obj.groupByDate = dateUpdateUtc(this.dataForm.groupByDate)
      dailyList(obj).then(( data ) => {
        this.dataList = data.data
        this.totalPage = data.totalCount
        this.dataListLoading = false
      }).catch((error) => {
        this.dataListLoading = false
        this.$message.error(error);
      })
    },

    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },

    openAdd(){
      this.newsVisible = true 
      this.ruleForm = {
        preTime: '',
        newsIds: [],
      },
      this.selectNewsList =  []
    },
    // 查询指定日期下的精选
    changeGetNews(){
      this.selectNewsList = []
      if(!this.ruleForm.preTime) {
        return 
      }
      let obj = {
        dateUTCTimestamp: dateUpdateUtc(this.ruleForm.preTime),
        platform: this.advertisingTypeV
      }
      dailyListByDate(obj).then(( data ) => {
        let list = []
        if(data.data && data.data.length != 0){
          data.data.forEach( item => {
            list.push({ id: item.newsId, title: item.newsTitle })
          })
        }
        this.selectNewsList = list
      }).catch((error) => {
        this.dataListLoading = false
        this.$message.error(error);
      })
    },
    // 新增
    submitForm(id){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          dailyDate: dateUpdateUtc(this.ruleForm.preTime),
          newsIds: this.ruleForm.newsIds,
          platform: this.advertisingTypeV
        }
        console.log("参数",data)
        dailySetListByDate( data ).then( res => {
          this.getDataList(this.pageIndex)  
          this.newsVisible = false
          this.$message.success("设置成功")
        }).catch((error) => {
          this.$message("设置失败"+error)
        })
      }).catch(() => { });
    },


    // 预览
    goAuditOrEdit(row){
      let info = {
        id: row.newsId,
        module: row.isVideo ? 9 : 1,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      if(row.isVideo){
        this.$refs.previewVideoModellRef.open(info)
      }else{
        this.$refs.previewModelRef.open(info)
      }
    },
    // 删除
    deleteFun(id){
      this.$confirm('请再次确认以免操作有误！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dailyDelete( { dailyId: id } ).then( res => {
          this.getDataList(this.pageIndex)  
          this.$message.success("删除成功")
        }).catch((error) => {
          this.$message("删除失败"+error)
        })
      }).catch(() => { });
    },
    //评论/点赞
    interactFun(row){
      let info = {
        id: row.newsId,
        module: row.isVideo ? 9 : 1,
        isRelation: true,// false预览不需要查国家，业务，分类，等,true要
      }
      this.$refs.InteractDiaComRef.open(info)
    },


  }
}
</script>
<style scoped>
.tui_col_div{
  width: 100%;
  text-align: center;
  margin-bottom: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.id_name_div{
  text-align: left;
  line-height: 10px;
  width: 180px; /* 设置div的宽度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>